import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { SubmitSurveyContent, submitquestion, questionMentee } from '../api/apiHandler.js'
import Swal from 'sweetalert2'
import { useSelector, useDispatch } from 'react-redux';
import { setContentDetails } from '../store/slice/contentDetail.js';
import { setLoading } from '../store/slice/loaderSlice';
import Loader from '../components/Loader';

const ContentQ1 = () => {
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.loader);
    const [isState, setIsState] = useState({});
    const navigate = useNavigate();
    const [questiondata, setquestiondata] = useState()
    console.log(questiondata, "questiondataaaaaaaaaaaaa");
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [survayId, setSurveyId] = useState();
    const [tags, setTags] = useState([]);
    const [Questionid, setQuestionid] = useState();
    const [option, setoption] = useState();
    const [selectedOptions, setSelectedOptions] = useState({});
    const [contenttype, setContentType] = useState();
    console.log(contenttype, "contenttype");



    useEffect(() => {
        dispatch(setLoading(true))
        questionMentee().then((response) => {
            console.log('response: ', response);
            dispatch(setLoading(false))
            if (response.status == 200) {
                setquestiondata(response.data[0].questions)
                setSurveyId(response.data[0].id)
            }

        }).catch((error) => {
            console.error(error);
        })

    }, [])


    // const handleContinue = () => {

    //     if (currentQuestionIndex < questiondata.length) {
    //         if (isState[Questionid] != 1) {
    //             setIsState((prevOptions) => ({
    //                 ...prevOptions,
    //                 [Questionid]: 0,
    //             }));

    //         }
    //         if (currentQuestionIndex < 3) {
    //             setCurrentQuestionIndex(currentQuestionIndex + 1);
    //         } else {

    //             // setContentType(option)
    //             setCurrentQuestionIndex(currentQuestionIndex)
    //         }
    //         handlesubmitquestion(option)

    //     }
    // };

    const handleContinue = () => {
        if (currentQuestionIndex < questiondata.length) {
            const currentQuestionId = questiondata[currentQuestionIndex].id;
            if (!selectedOptions[currentQuestionId]) {
                // If no option is selected for the current question, return without proceeding
                return;
            }

            // Proceed to the next question
            setCurrentQuestionIndex(currentQuestionIndex + 1);
            handlesubmitquestion(option);
        }
    };


    const handlesubmitquestion = (option) => {

        if (tags.length < 3) {
            tags.push(option);
            setTags([...tags])
        } else {
            const index = tags.findIndex((item) => item === option);
            if (index !== -1) {
                tags.splice(index, 1);
            }
            else {
                // setContentType(option)
                if (tags.length == 3) {
                    const survaydata = {
                        content_type: option,
                        tags: tags
                    }
                    localStorage.setItem("submitsurvay", JSON.stringify(survaydata))
                    SubmitSurveyContent(survaydata).then((response) => {
                        if (response.status === 200) {
                            localStorage.setItem("user_category", response.data.results[0].content_type)
                            localStorage.setItem("survayresponse", JSON.stringify(response.data.results))
                            localStorage.setItem("content_question", "true")
                            localStorage.removeItem("user_search", "")
                            navigate("/content")


                            // navigate("/feedback")
                            // localStorage.removeItem("questions_for")
                        } else if (response.status === 204) {
                            Swal.fire({
                                position: "top-right",
                                icon: "info",
                                title: "Didn't found any content based on your survey answers",
                                toast: true,
                                showConfirmButton: false,
                                timer: 2000
                            });
                            navigate("/content")
                        }
                    }).catch((error) => {
                        Swal.fire({
                            position: "top-right",
                            icon: "error",
                            title: error.response.data.errors,
                            toast: true,
                            showConfirmButton: false,
                            timer: 2000
                        });
                    })

                }
                // const questionIndex = tags.findIndex((item) => item.question_id === question_id);
                // if (questionIndex !== -1) {
                //     QuestionAns.splice(questionIndex, 1);
                // }
            }
            // tags.push(option);
        }

    }

    const handleprev = () => {
        if (currentQuestionIndex >= 1) {
            setCurrentQuestionIndex(currentQuestionIndex - 1);
            setoption(option)
            setIsState((prevOptions) => ({
                ...prevOptions,
                [Questionid]: 1,
            }));
        } else {
            navigate(-1)

        }
    }

    const handlequestion = (question_id, option) => {
        setQuestionid(question_id)
        setoption(option)
        setSelectedOptions((prevOptions) => ({
            ...prevOptions,
            [question_id]: option,
        }));

        setIsState((prevOptions) => ({
            ...prevOptions,
            [question_id]: 1,
        }));
    }



    return (
        <>
            {isLoading ?
                (<Loader loader_status={isLoading} />) :
                (
                    <main>
                        <section className="section-survey">
                            <div className="container">
                                <div>
                                    <img className='back-arrow-icon' onClick={handleprev} src={process.env.PUBLIC_URL + "/assets/images/icon/left-arrow.svg"} alt="" />
                                </div>

                                <div className="row justify-content-center">
                                    {questiondata && questiondata.map((question, index) => (
                                        <div key={question.id} className={`col-lg-7 col-xl-7 col-12 ${index === currentQuestionIndex ? '' : 'd-none'}`}>
                                            <p className="text-black font_30 font_600 mb-5 mt-4">{question.question}</p>
                                            {question.options && question.options.map((option) => (
                                                <Link to="#" className={
                                                    selectedOptions[question.id] === option.option
                                                        ? "select-survey-ans selected-option"
                                                        : "select-survey-ans"
                                                } key={option.id} onClick={() => handlequestion(question.id, option.option)} >
                                                    {option.option}
                                                </Link>
                                            ))}
                                        </div>
                                    ))}
                                </div>

                                <div className="row justify-content-center">
                                    <div className="col-lg-6 col-xl-6 col-12">
                                        <button to="#" onClick={handleContinue} disabled={!selectedOptions[Questionid]} className="select-option mt-4 w-100">
                                            Continue
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </section>
                    </main>
                )
            }
        </>
    )
}

export default ContentQ1