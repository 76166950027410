import React, { useState, useEffect } from 'react'
import { Link, NavLink, Navigate, useNavigate } from 'react-router-dom'
import { interests } from '../api/apiHandler.js'
import { useDispatch, useSelector } from 'react-redux';




const Sidebar = () => {
    const navigate = useNavigate()
    // const [activeBar, setActiveBar] = useState(false);
    const [allInterests, setAllInterests] = useState();
    const [activeBars, setActiveBars] = useState([]);
    const [activeIndex, setActiveIndex] = useState(null);
    const UserData = useSelector((state) => JSON.parse(state.userdata))
    console.log('UserData: ', UserData);


    useEffect(() => {
        interests().then((response) => {
            if (response.status === 200) {
                setAllInterests(response.data.interests)
            }
        }).catch((error) => {
            console.log(error);
        })
    }, [])


    const handleItemClick = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };


    // const handleLinkClick = () => {
    //     setActiveBar(!activeBar);
    // };
    // const [activeMenuItem, setActiveMenuItem] = useState('questions');

    // const handleMenuItemClick = (menuItem) => {
    //     setActiveMenuItem(menuItem);
    // };

    // const handleItemClick = (index) => {
    //     const newActiveBars = [...activeBars];
    //     newActiveBars[index] = !newActiveBars[index];
    //     setActiveBars(newActiveBars);
    // };

    const handlenav = () => {
        navigate("/group-discussions")
    }

    const handleFreeTrialPage = () => {
        if (UserData && UserData.data.free_trial_used) {
            navigate("/subscription-plans")
        } else {
            navigate("/subscription")
        }
    }


    return (
        <>

            {/* <div id='mysidebar' className={localStorage.getItem("collapsed") == "true" ? "sidebar" : "sidebar collapsed"}> */}
            <div id='mysidebar' className='sidebar'>

                <div className="sidebar_profile d-flex align-items-center">
                    {/* <div class="sidebar_profile_image">
      <img src="assets/images/user/user-2.png" class="img-fluid" alt="user">
  </div> */}
                    <div>
                        <h6 className="font_20 font_300 mb-0 text-uppercase">Menu</h6>
                        <p />
                    </div>
                </div>
                <ul className="menu_ul list-unstyled">

                    <li >
                        {/* <Link to="/group-discussions" className={activeBar != true ? "menu_li" : "menu_li active"} onClick={() => setActiveBar(!activeBar)}> */}
                        <NavLink to="/group-discussions" className="menu_li " activeClassName="active">
                            <div className="menu_icon">
                                <img
                                    src="assets/images/icon/question.svg"
                                    className="img-fluid"
                                    alt="icon"
                                />
                            </div>
                            <div className="flex-1">
                                <h6 className="menu_name font_13 font_400 mb-0">Questions</h6>
                            </div>
                        </NavLink>
                    </li>
                    <li>
                        <a
                            className="menu_li"

                            // className={activeBar != true ? "menu_li" : "menu_li active"} onClick={() => setActiveBar(!activeBar)}
                            data-bs-toggle="collapse"
                            href="#collapseExample1"
                            role="button"
                            aria-expanded="false"
                            aria-controls="collapseExample"
                            onClick={handlenav}
                        >

                            <div className="menu_icon">
                                <img
                                    src="assets/images/icon/explore-topics.svg"
                                    className="img-fluid"
                                    alt="icon"
                                />
                            </div>
                            <div className="flex-1">
                                <h6 className="menu_name font_13 font_400 mb-0">Explore Topics</h6>
                            </div>
                        </a>
                    </li>

                    <li>
                        <NavLink
                            to="/communites"
                            className="menu_li"
                            activeClassName="active"
                            // className={activeBar != true ? "menu_li" : "menu_li active"} onClick={() => setActiveBar(!activeBar)}
                            data-bs-toggle="collapse"
                            href="#collapseExample2"
                            role="button"
                            aria-expanded="false"
                            aria-controls="collapseExample"
                            onClick={handlenav}
                        >

                            <div className="menu_icon">
                                <img
                                    src="assets/images/icon/community.svg"
                                    className="img-fluid"
                                    alt="icon"
                                />
                            </div>
                            <div className="flex-1" onClick={localStorage.setItem("explore", true)}>
                                <h6 className="menu_name font_13 font_400 mb-0">Communites</h6>
                            </div>
                        </NavLink>
                    </li>
                    <li>
                        <div className="collapse" id="collapseExample1">
                            <ul className="list-unstyled ms-4">
                                {allInterests && allInterests.map((item, index) => (
                                    <li key={index}>
                                        <a
                                            className={index === activeIndex ? "menu_li active" : "menu_li"}
                                            onClick={() => handleItemClick(index)}
                                        >
                                            <div className="menu_icon">
                                                {/* <img src="assets/images/icon/explore-topics.svg" class="img-fluid" alt="icon"> */}
                                                <i className="fa-solid fa fa-circle" />
                                            </div>
                                            <div className="flex-1" style={{ cursor: "pointer" }}
                                                onClick={() => localStorage.setItem("ExploreTopics", item.id)}
                                            >
                                                <h6 className="menu_name font_13 font_400 mb-0">{item.interest}</h6>
                                            </div>
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </li>

                    <li>
                        <div className="collapse" id="collapseExample2">
                            <ul className="list-unstyled ms-4">
                                {allInterests && allInterests.map((item, index) => (
                                    <li key={index}>
                                        <a
                                            className={index === activeIndex ? "menu_li active" : "menu_li"}
                                            onClick={() => handleItemClick(index)}
                                        >
                                            <div className="menu_icon">
                                                {/* <img src="assets/images/icon/explore-topics.svg" class="img-fluid" alt="icon"> */}
                                                <i className="fa-solid fa fa-circle" />
                                            </div>
                                            <div className="flex-1" style={{ cursor: "pointer" }}
                                                onClick={() => localStorage.setItem("ExploreTopics", item.id)}
                                            >
                                                <h6 className="menu_name font_13 font_400 mb-0">{item.interest}</h6>
                                            </div>
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </li>
                </ul>
                <div className="sidebar_profile d-flex align-items-center">
                    {/* <div class="sidebar_profile_image">
      <img src="assets/images/user/user-2.png" class="img-fluid" alt="user">
  </div> */}
                    <div>
                        <h6 className="font_20 font_300 mb-0 text-uppercase">
                            PERSONAL NAVIGATOR
                        </h6>
                        <p />
                    </div>
                </div>
                <ul className="menu_ul list-unstyled">
                    <li className="">
                        <NavLink to="/my-questions" className="menu_li " activeClassName="active">
                            <div className="menu_icon">
                                <img
                                    src="assets/images/icon/Question-sidebar.svg"
                                    className="img-fluid"
                                    alt="icon"
                                />
                            </div>
                            <div className="flex-1">
                                <h6 className="menu_name font_13 font_400 mb-0">My Questions</h6>
                            </div>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/my-answers" className="menu_li " activeClassName="active">
                            <div className="menu_icon">
                                <img
                                    src="assets/images/icon/ChatsCircle.svg"
                                    className="img-fluid"
                                    alt="icon"
                                />
                            </div>
                            <div className="flex-1">
                                <h6 className="menu_name font_13 font_400 mb-0">My Answers</h6>
                            </div>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/my-likes" className="menu_li" activeClassName="active">
                            <div className="menu_icon">
                                <img
                                    src="assets/images/icon/ThumbsUp.svg"
                                    className="img-fluid"
                                    alt="icon"
                                />
                            </div>
                            <div className="flex-1">
                                <h6 className="menu_name font_13 font_400 mb-0">My Likes</h6>
                            </div>
                        </NavLink>
                    </li>
                </ul>
                {UserData && UserData.data.is_subscription_active == true ? "" :
                    <div className="upgrade mt-auto">
                        <div className="upgrade-image text-center">
                            <img
                                src="assets/images/upgrade-vector.svg"
                                className="img-fluid"
                                alt="image"
                            />
                        </div>
                        <div className="upgrade-box">
                            <h6 className="font_20 font_400 text-center text-black mt-3">
                                Upgrade to Premium
                            </h6>
                            <button className="upgrade-btn font_20" onClick={handleFreeTrialPage}>
                                <img src="assets/images/icon/arrow-up.png" alt="arrow-up" />
                                Upgrade Now
                            </button>
                        </div>
                    </div>}
            </div>


        </>
    )
}

export default Sidebar

