import React, { useState, useEffect } from 'react'
import Footer from '../components/Footer'
import OuterHeader from '../components/OuterHeader'
import { Link, useNavigate, useParams } from 'react-router-dom'
import ScrollToTop from '../components/ScrollToTop';
import { EventDetails, Eventlikedata, EventRegistration } from '../api/apiHandler';
import moment from 'moment';
import MyMapComponent from './GoogleMap';
import Swal from 'sweetalert2'
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../store/slice/loaderSlice';
import Loader from '../components/Loader';


const EventDescription = () => {
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.loader);
    const navigate = useNavigate()
    const params = useParams();
    const [scroll, setScroll] = useState(true)
    const [eventdetails, setEventDetails] = useState()
    // console.log(eventdetails.latitude, "latitudeeee");
    const [eventdata, setEventdata] = useState()
    const [noeventdata, setNoEventdata] = useState(204)
    const [dropdownvalue, setDropdownvalue] = useState("")
    const [attendanceStatus, setAttendanceStatus] = useState()
    const [isRegistered, setIsRegistered] = useState(false);

    const handleRegistration = () => {
        if (!isRegistered) {
            const registerdata = {
                event: parseInt(params.id),
                name: localStorage.getItem('username'),
                email: localStorage.getItem('email')
            };

            EventRegistration(registerdata).then((response) => {
                window.open(eventdetails && eventdetails.event_booking_url, '_blank');
                if (response.status === 201) {
                    setAttendanceStatus('Registered');
                    setIsRegistered(true);
                }
            }).catch((error) => {
                Swal.fire({
                    position: 'top-right',
                    icon: 'error',
                    title: error.response.data.errors,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                });
            });
        } else {
            window.open(eventdetails && eventdetails.event_booking_url, '_blank');
        }
    };


    useEffect(() => {
        geteventdetails(params.id)
    }, [])



    useEffect(() => {
        geteventdetails(params.id)
    }, [])

    const geteventdetails = (id) => {
        dispatch(setLoading(true))
        EventDetails({ id: id }).then((response) => {
            dispatch(setLoading(false))
            if (response.status == 200) {
                setEventDetails(response.data)
                setAttendanceStatus(response.data.attendance_status)
                navigate(`/event-description/${id}`)
            }
        }).catch((error) => {
            console.log(error);
        })

        Eventlikedata().then((response) => {
            if (response.status == 200) {
                let filterdata = response.data.results.filter(element => {
                    return element.id != id
                });
                filterdata = filterdata.slice(0, 3);
                setEventdata(filterdata)
                setNoEventdata(filterdata.length >= 1 ? 200 : 204)

            } else if (response.status == 204) {
                setNoEventdata(204)
            }

        }).catch((error) => {
            console.log(error);
        })

    }

    const handleLinkClick = () => {
        handleRegistration()
    };

    const handleLinkopen = () => {
        window.open(eventdetails && eventdetails.event_booking_url, '_blank');
    }

    return (
        <>
            {scroll ? <ScrollToTop /> : null}
            {isLoading ?
                (<Loader loader_status={isLoading} />) :
                (
                    <main className="helprr-main events-main">
                        <OuterHeader />
                        <section className="events-description-title">
                            <div className="events-bgimg">
                                <img src={eventdetails && eventdetails.image} alt="image" />
                            </div>

                            <div className="container">
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-lg-7 col-xl-7 col-xxl-7 col-md-6 col-12">
                                        <Link to="/events" className="back-btn mb-3">
                                            <i className="fa-solid fa-chevron-left pe-2" />
                                            Back
                                        </Link>
                                        <p className="font_64 font_700 text-white">
                                            {eventdetails && eventdetails.title}
                                        </p>
                                        <p className="font_36 font_700 text-white">{eventdetails && eventdetails.type == "in_person" ? "In Person" : "Virtual"}</p>
                                        <p className="font_16 text-white">
                                            {eventdetails && eventdetails.description}
                                        </p>
                                    </div>

                                    {eventdetails && eventdetails.event_booking_url != "" && eventdetails.event_booking_url != undefined && eventdetails.event_booking_url != null ?

                                        <div className="col-lg-4 col-xl-4 col-xxl-3 col-md-6 col-12">
                                            <div className="date-time-card">
                                                <p className="font_24 font_700 text-black">Date &amp; time</p>
                                                <p className="font_18 text-gray">{eventdetails && moment(eventdetails.start_date).format("dddd, MMMM D YYYY")},</p>
                                                <p className="font_18 text-gray">{eventdetails && moment.utc(eventdetails.start_date + " " + eventdetails.start_time).tz("Canada/Eastern").format("h:mm A")} (EST)</p>
                                                {/* <p className="font_18 text-gray">{eventdetails && moment.utc(eventdetails.start_date + " " + eventdetails.start_time).tz("America/Toronto").format("h:mm A")}</p> */}
                                                <select className="custom-event-select form-select mb-2" aria-label="Default select example" onChange={(e) => (setDropdownvalue(e.target.value))}>
                                                    <option selected="">Select</option>
                                                    <option value="Going">Going</option>
                                                    <option value="Interested">Interested</option>
                                                </select>
                                                {eventdetails && attendanceStatus == "None" ?
                                                    <>

                                                        {/* <button onClick={handleLinkClick} className="register-btn w-100">
                                                    Register Now
                                                </button> */}
                                                    </>
                                                    : eventdetails && eventdetails.attendance_status == "Hosted" ?
                                                        <h4 className="font_24 font_700 text-black">Event Hosted</h4> :
                                                        <h4 className="font_24 font_700 text-black">Event Registered</h4>
                                                }
                                            </div>
                                        </div>
                                        :
                                        <div className="col-lg-4 col-xl-4 col-xxl-3 col-md-6 col-12">
                                            <div className="date-time-card">
                                                <p className="font_24 font_700 text-black">Date &amp; time</p>
                                                <p className="font_18 text-gray">{eventdetails && moment(eventdetails.start_date).format("dddd, MMMM D YYYY")},</p>
                                                <p className="font_18 text-gray">{eventdetails && moment.utc(eventdetails.start_date + " " + eventdetails.start_time).tz("Canada/Eastern").format("h:mm A")} (EST)</p>
                                                {eventdetails && attendanceStatus == "None" ?
                                                    <>
                                                        <select className="custom-event-select form-select mb-2" aria-label="Default select example" required>
                                                            <option selected="">Select</option>
                                                            <option value="Going">Going</option>
                                                            <option value="Interested">Interested</option>

                                                        </select>
                                                        <button onClick={() => { navigate(`/register-event/${eventdetails.id}`) }} className="register-btn w-100">
                                                            Register Now
                                                        </button>
                                                    </>
                                                    : eventdetails && eventdetails.attendance_status == "Hosted" ?
                                                        <h4 className="font_24 font_700 text-black">Event Hosted</h4> :
                                                        <h4 className="font_24 font_700 text-black">Event Registered</h4>
                                                }
                                            </div>
                                        </div>}
                                </div>
                            </div>
                        </section>
                        <section className="event-description bg-white">
                            <div className="container">
                                <div className="row justify-content-between">
                                    <div className="col-lg-6 col-xl-6 col-xxl-5 col-12">
                                        <div className="mb-5">
                                            <p className="font_24 font_700 text-black">Description</p>
                                            <p className="title font_16">
                                                {eventdetails && eventdetails.description}
                                            </p>
                                        </div>
                                        <div className="mb-5">
                                            <p className="font_24 font_700 text-black">Event Time</p>
                                            <p className="font_16 text-gray">
                                                Date:{" "}
                                                <span className="font_18 font_700 text-mave">{eventdetails && moment(eventdetails.start_date).format("MMMM DD")}</span>
                                            </p>
                                            <p className="font_16 text-gray">
                                                Time:{" "}
                                                <span className="font_18 font_700 text-mave">{eventdetails && moment.utc(eventdetails.start_date + " " + eventdetails.start_time).tz("Canada/Eastern").format("h:mm A")} (EST)</span>
                                            </p>
                                            {/* 
                                    const canadaTime = eventdetails && moment.utc(eventdetails.start_time, "HH:mm:ss").tz("America/Toronto").format("h:mm A");
                                    console.log('startTimeChange: ', moment(eventdetails.start_time, "HH:mm:ss").tz("Canada/Eastern").format("h:mm A")); */}
                                        </div>
                                        {eventdetails && eventdetails.attendance_status != "Registered" && eventdetails.attendance_status != "Hosted" &&
                                            <div className="mb-5">
                                                <button onClick={handleLinkClick} className="register-btn">
                                                    Register Now
                                                </button>
                                            </div>}
                                    </div>
                                    {eventdetails && eventdetails.type == "virtual" ?
                                        <div className="col-lg-6 col-xl-6 col-xxl-4 col-12">
                                            <div className="mb-5">
                                                <p className="title font_16">Virtual, join from anywhere.</p>
                                            </div>
                                        </div> :
                                        <div className="col-lg-6 col-xl-6 col-xxl-4 col-12">
                                            <div className="mb-5">
                                                <p className="font_24 font_700 text-black">Event location</p>

                                                <MyMapComponent
                                                    key={`${eventdetails && eventdetails.latitude}-${eventdetails && eventdetails.longitude}`}
                                                    data={{ lat: parseFloat(eventdetails && eventdetails.latitude), lng: parseFloat(eventdetails && eventdetails.longitude) }}
                                                    containerElement={
                                                        <div style={{ height: "400px", width: "100%", maxWidth: "100%" }} />
                                                    }
                                                    mapElement={
                                                        <div style={{ height: "100%", width: "100%" }} />
                                                    }
                                                    isMarkerShown
                                                />
                                            </div>
                                        </div>
                                    }

                                </div>
                            </div>
                        </section>
                        <section className="other-events">
                            <div className="container">
                                {noeventdata && noeventdata == 204 ? "" :
                                    <p className="font_24 text-black font_700 pb-4">
                                        Other events you may like
                                    </p>}
                                <div className="row">

                                    {eventdata && eventdata.map((item, key) => {
                                        return (
                                            <>
                                                <div className="col-lg-4 col-xl-4 col-md-6 col-12">
                                                    <div className="event-card" onClick={() => geteventdetails(item.id)}>
                                                        <div className="event-img">
                                                            <img src={item.image} alt="event" />
                                                            <div className="free-tab">free</div>
                                                        </div>
                                                        <div>
                                                            <p className="text-black mb-2">
                                                                {item.title}-
                                                                {item.type == "virtual" ? "Virtual Event" :
                                                                    item.venue}
                                                            </p>
                                                            {item.type == "virtual" ?
                                                                <p className="font_12 text-gray mb-0">
                                                                    ONLINE EVENT - Attend anywhere
                                                                </p> :
                                                                <p className="font_12 text-gray mb-0">
                                                                    IN-PERSON EVENT - Attend in person
                                                                </p>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })}


                                </div>
                            </div>
                        </section>
                        <Footer />

                    </main>)}

        </>
    )
}

export default EventDescription