import { createSlice } from "@reduxjs/toolkit";

const bookDetailsSlice = createSlice({
    name: 'bookdata',
    initialState: null,
    reducers: {
        setBookDetails(state, action) {
            state = action.payload;
            return state;
        }
    }
});

export const { setBookDetails } = bookDetailsSlice.actions;
export default bookDetailsSlice.reducer;