import React from 'react'
import { Link } from 'react-router-dom'
import '../css2/errorpage.css'


const Error = () => {
    return (
        <>
            {/* <div className="page-404 section--bg" data-bg="img/section/section.jpg">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-404__wrap">
                                <div className="page-404__content">
                                    <h1 className="page-404__title">404</h1>
                                    <p className="page-404__text">The page you are looking for not available!</p>
                                    <Link to="/" className="page-404__btn">go back</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}



            <div className="container text-center h-100">
                <div className='error-sec'>

                    <div className="number">404</div>
                    <div className="text">Oops! The page you're looking for doesn't exist.</div>
                    <p>
                        Go back to{" "}
                        <Link to="/" className="link">
                            home
                        </Link>
                        .
                    </p>
                </div>

            </div>

        </>
    )
}

export default Error


