import React, { useState, useEffect, useCallback } from 'react'
import OuterHeader from '../components/OuterHeader'
import Footer from '../components/Footer'
import { Link, useNavigate } from 'react-router-dom'
import Select2 from '../pages/Select2';
import '../css2/select2.css'
import { MyBooksList, UpdateBookStatus, ContentRating, HealingJourneydata } from '../api/apiHandler.js'
import Swal from 'sweetalert2'
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../components/Loader';
import { setLoading } from '../store/slice/loaderSlice';


const BookDashboard = () => {
    const dispatch = useDispatch();
    const UserData = useSelector((state) => JSON.parse(state.userdata))
    const isLoading = useSelector((state) => state.loader);
    const navigate = useNavigate()
    const [mybook, SetMyBook] = useState([])
    const [rating, setRating] = useState()
    const [status, SetStatus] = useState()
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const itemsPerPage = 10;
    const [tasksPerPage, setTaskPerPage] = useState(10);
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [loadMoreFlag, setLoadMoreFlag] = useState(false);
    const [taskToDisplay, setTaskToDisplay] = useState("");
    const [bookcount, setBookcount] = useState()
    const [nocontent, setNocontent] = useState();
    const [healingdata, sethealingdata] = useState("")

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < Math.ceil(totalCount / itemsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };



    const Readdata = () => {
        HealingJourneydata().then((response) => {
            if (response.status == 200) {
                sethealingdata(response.data.healing_journey_data)
            }
        }).catch((error) => {
            console.log(error);
        })
    }



    const mybooklist = () => {
        dispatch(setLoading(true))
        MyBooksList().then((response) => {
            dispatch(setLoading(false))
            if (response.status == 200) {
                setRating(response.data)
                const startIndex = (currentPageNo - 1) * tasksPerPage;
                const endIndex = startIndex + tasksPerPage;
                SetMyBook(response.data.saved_content.slice(0, endIndex));

            } else if (response.status == 204) {
                setNocontent(204)
                SetMyBook([])
            }

        }).catch((error) => {
            console.log(error);
        })

    }

    const handlesavecontent = (event, content_id) => {
        const selectedValue = event.target.value;

        const booknotedata = {
            status: selectedValue
        }
        localStorage.setItem("content_id", content_id)
        // SetStatus(status)
        UpdateBookStatus(booknotedata).then((response) => {
            if (response.status == 200) {
                Readdata()
                Swal.fire({
                    position: "top-right",
                    icon: "success",
                    title: "Book status successfully updated",
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                });
                // mybooklist({ page: currentPage })
            }

        }).catch((error) => {
            console.log(error);
        })
        // }
    };

    useEffect(() => {
        Readdata()
        mybooklist()
    }, [status, currentPageNo, tasksPerPage])

    const handleRatingClick = (content_id, rating) => {
        const ratingdata = {
            content: content_id,
            rating: rating
        }
        ContentRating(ratingdata).then((response) => {
            console.log(response.data);
            if (response.status == 200) {
                Swal.fire({
                    position: "top-right",
                    icon: "success",
                    title: "Thank you for your review",
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                });
                mybooklist({ page: currentPage });

            }
        }).catch((error) => {
            // dispatch(setLoading(false))
            console.log(error, 'error');
        })
    }

    function loadMoreTasks() {
        // setHandleLoadData(false);
        const startIndex = (currentPageNo - 1) * tasksPerPage;
        const endIndex = startIndex + tasksPerPage;
        // setTaskToDisplay(savedata.slice(startIndex, endIndex))
        const newTasks = mybook.slice(startIndex, endIndex);
        setTaskToDisplay(prevTasks => [...prevTasks, ...newTasks]);
        // setTaskToDisplay(prevTasks => [...prevTasks, ...savedata.slice(startIndex, endIndex)]);
        setCurrentPageNo(currentPageNo + 1);
        checkPagination();
    }

    function checkPagination() {
        const startIndex = (currentPageNo - 1) * tasksPerPage;
        const endIndex = startIndex + tasksPerPage;
        if (startIndex >= mybook.length - 1) {
            setLoadMoreFlag(true);
        }
        else {
            setLoadMoreFlag(false);
        }
    }


    const handlePagination = () => {
        if (loadMoreFlag) {
            // No more data available
            Swal.fire({
                position: "top-right",
                icon: "info",
                title: "No More Data Available",
                toast: true,
                showConfirmButton: false,
                timer: 2000
            });
        } else {
            loadMoreTasks();
        }
    }


    return (
        <>
            {isLoading ?
                (<Loader loader_status={isLoading} />) :
                (
                    <main className="helprr-main">
                        <OuterHeader />
                        <section className="bg-orange">
                            <div className="banner-sec mt-0">
                                <div className="container">

                                    <div className='d-flex justify-content-between align-items-center flex-wrap'>
                                        <div className="banner-profile-sec">
                                            <div className="banner-profile-img">

                                                {localStorage.getItem("photo") != undefined && localStorage.getItem("photo") != null && localStorage.getItem("photo") != "" ?
                                                    <img src={localStorage.getItem("photo")} className="account-dropdown" alt="" /> :
                                                    <img src={process.env.PUBLIC_URL + "/assets/images/defalt.png"} className="account-dropdown" alt="" />
                                                }

                                            </div>
                                            <div className="banner-text">
                                                <p className="mb-0 font_18 text-white font_700">Welcome back</p>
                                                <p className="mb-0 font_26 text-white font_700"> {localStorage.getItem("username")}</p>
                                            </div>

                                        </div>

                                        <div>
                                            <Link to="/book-question" className="decide-btn" onClick={() => localStorage.setItem('questions_for', "book recommendations")}>Recommend me a new book</Link>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="back-section book-plan-dash pb-5">
                                <div className="container position-relative">
                                    <div className="action-section">
                                        <div className="card-progress first-card">
                                            <div className="d-flex align-items-center justify-content-between">
                                                {nocontent && nocontent == 204 ?
                                                    <div>
                                                        <p className="mb-1 font_22">You haven't read any books yet. Start reading now!</p>
                                                        <p className="mb-0 font_18 font_500">
                                                            Start reading to earn badges and track your progress!
                                                        </p>
                                                    </div>
                                                    :
                                                    healingdata.books_read >= 1 && healingdata.books_read <= 5 ?
                                                        <div>
                                                            <p className="mb-1 font_22">You have read {healingdata && healingdata.books_read} books!</p>
                                                            <p className="mb-0 font_18  font_500">
                                                                You have earned yourself a bronze badge, keep reading and
                                                                earning badges!
                                                            </p>

                                                        </div> : healingdata.books_read >= 5 && healingdata.books_read <= 10 ?
                                                            <div>
                                                                <p className="mb-1 font_22">You have read {healingdata && healingdata.books_read} books!</p>
                                                                <p className="mb-0 font_18  font_500">
                                                                    You have earned yourself a silver badge, keep reading and
                                                                    earning badges!
                                                                </p>

                                                            </div> : healingdata.books_read >= 10 ?
                                                                <div>
                                                                    <p className="mb-1 font_22">You have read {healingdata && healingdata.books_read} books!</p>
                                                                    <p className="mb-0 font_18  font_500">
                                                                        You have earned yourself a gold badge, congratulations!
                                                                    </p>

                                                                </div> : <div>
                                                                    <p className="mb-1 font_22">You have read {healingdata && healingdata.books_read} books!</p>
                                                                    <p className="mb-0 font_18  font_500">
                                                                        Start reading to earn badges and track your progress!
                                                                    </p>

                                                                </div>
                                                }



                                                {healingdata.books_read >= 1 && healingdata.books_read <= 5 ?
                                                    <div className="card-image">
                                                        <img src={process.env.PUBLIC_URL + "/assets/images/icon/Bronze-Medal.png"}
                                                            alt=""
                                                            className="object-fit-contain"
                                                        />
                                                    </div> : healingdata.books_read >= 5 && healingdata.books_read <= 10 ?
                                                        <div className="card-image">
                                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/Silver-Medal.png"}
                                                                alt=""
                                                                className="object-fit-contain"
                                                            />
                                                        </div> : healingdata.books_read >= 10 ?
                                                            <div className="card-image">
                                                                <img src={process.env.PUBLIC_URL + "/assets/images/icon/Gold-Medal.png"}
                                                                    alt=""
                                                                    className="object-fit-contain"
                                                                />
                                                            </div> : ""}
                                            </div>

                                        </div>
                                    </div>
                                    <div className="book-list d-flex justify-content-between align-items-center">
                                        {nocontent && nocontent == 204 ?
                                            "" :
                                            <p className="font_30 font_500 text-orange">All Books</p>}

                                    </div>

                                    {
                                        mybook && mybook.map((item, idx) => {
                                            console.log('item: ', item);
                                            return (
                                                <>
                                                    <div key={idx} className="card-progress  bg-mave">
                                                        <div className="d-flex align-items-center justify-content-between">
                                                            <div className="text-truncate">
                                                                <p className="mb-0 text font_22">{item.content.title}</p>
                                                                <p className="mb-0 text font_20 font_700 text-truncate">{item.content.description}</p>
                                                                <button

                                                                    onClick={() => {
                                                                        localStorage.setItem('content_id', item.id);
                                                                        navigate(`/book-details/${item.id}`);
                                                                    }}
                                                                    className="mb-2 font_16 text-decoration-underline "
                                                                >
                                                                    Show More
                                                                </button>

                                                                <div className="read-bookdash-select">
                                                                    <select className="form-select w-auto" defaultValue={item.status} onChange={(e) => handlesavecontent(e, item.id)}
                                                                        onchange="this.className=this.options[this.selectedIndex].className"
                                                                        aria-label="Default select example">
                                                                        <option className="form-select" value={'read_later'}>Read Later</option>
                                                                        <option className="form-select greenText" value={'read'}>Read</option>
                                                                        <option className="form-select orangeText" value={'reading'}>Reading</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="text-center rate-bookdash mx-2">
                                                                <div class="star-rating">
                                                                    <input id={`star-15-${item.id}`} type="radio" name="rating-3" value="star-5" />
                                                                    {item.content.user_rating == 5 ?
                                                                        <>
                                                                            <label for="star-15" title="5 stars">
                                                                                <i class="active fa fa-star" aria-hidden="true" onClick={() => handleRatingClick(item.content.id, 5)}></i>
                                                                            </label>
                                                                            <label for="star-15" title="5 stars">
                                                                                <i class="active fa fa-star" aria-hidden="true" onClick={() => handleRatingClick(item.content.id, 4)}></i>
                                                                            </label>
                                                                            <label for="star-15" title="5 stars">
                                                                                <i class="active fa fa-star" aria-hidden="true" onClick={() => handleRatingClick(item.content.id, 3)}></i>
                                                                            </label>
                                                                            <label for="star-15" title="5 stars">
                                                                                <i class="active fa fa-star" aria-hidden="true" onClick={() => handleRatingClick(item.content.id, 2)}></i>
                                                                            </label>
                                                                            <label for="star-15" title="5 stars">
                                                                                <i class="active fa fa-star" aria-hidden="true" onClick={() => handleRatingClick(item.content.id, 1)}></i>
                                                                            </label>
                                                                        </> : item.content.user_rating == 4 ?

                                                                            <>
                                                                                <label for="star-15" title="5 stars">
                                                                                    <i class="fa fa-star" aria-hidden="true" onClick={() => handleRatingClick(item.content.id, 5)}></i>
                                                                                </label>
                                                                                <label for="star-15" title="5 stars">
                                                                                    <i class="active fa fa-star" aria-hidden="true" onClick={() => handleRatingClick(item.content.id, 4)}></i>
                                                                                </label>
                                                                                <label for="star-15" title="5 stars">
                                                                                    <i class="active fa fa-star" aria-hidden="true" onClick={() => handleRatingClick(item.content.id, 3)}></i>
                                                                                </label>
                                                                                <label for="star-15" title="5 stars">
                                                                                    <i class="active fa fa-star" aria-hidden="true" onClick={() => handleRatingClick(item.content.id, 2)}></i>
                                                                                </label>
                                                                                <label for="star-15" title="5 stars">
                                                                                    <i class="active fa fa-star" aria-hidden="true" onClick={() => handleRatingClick(item.content.id, 1)}></i>
                                                                                </label>
                                                                            </> :
                                                                            item.content.user_rating == 3 ?

                                                                                <>
                                                                                    <label for="star-15" title="5 stars">
                                                                                        <i class="fa fa-star" aria-hidden="true" onClick={() => handleRatingClick(item.content.id, 5)}></i>
                                                                                    </label>
                                                                                    <label for="star-15" title="5 stars">
                                                                                        <i class=" fa fa-star" aria-hidden="true" onClick={() => handleRatingClick(item.content.id, 4)}></i>
                                                                                    </label>
                                                                                    <label for="star-15" title="5 stars">
                                                                                        <i class="active fa fa-star" aria-hidden="true" onClick={() => handleRatingClick(item.content.id, 3)}></i>
                                                                                    </label>
                                                                                    <label for="star-15" title="5 stars">
                                                                                        <i class="active fa fa-star" aria-hidden="true" onClick={() => handleRatingClick(item.content.id, 2)}></i>
                                                                                    </label>
                                                                                    <label for="star-15" title="5 stars">
                                                                                        <i class="active fa fa-star" aria-hidden="true" onClick={() => handleRatingClick(item.content.id, 1)}></i>
                                                                                    </label>
                                                                                </> :
                                                                                item.content.user_rating == 2 ?

                                                                                    <>
                                                                                        <label for="star-15" title="5 stars">
                                                                                            <i class="fa fa-star" aria-hidden="true" onClick={() => handleRatingClick(item.content.id, 5)}></i>
                                                                                        </label>
                                                                                        <label for="star-15" title="5 stars">
                                                                                            <i class="fa fa-star" aria-hidden="true" onClick={() => handleRatingClick(item.content.id, 4)}></i>
                                                                                        </label>
                                                                                        <label for="star-15" title="5 stars">
                                                                                            <i class="fa fa-star" aria-hidden="true" onClick={() => handleRatingClick(item.content.id, 3)}></i>
                                                                                        </label>
                                                                                        <label for="star-15" title="5 stars">
                                                                                            <i class="active fa fa-star" aria-hidden="true" onClick={() => handleRatingClick(item.content.id, 2)}></i>
                                                                                        </label>
                                                                                        <label for="star-15" title="5 stars">
                                                                                            <i class="active fa fa-star" aria-hidden="true" onClick={() => handleRatingClick(item.content.id, 1)}></i>
                                                                                        </label>
                                                                                    </> :
                                                                                    item.content.user_rating == 1 ?

                                                                                        <>
                                                                                            <label for="star-15" title="5 stars">
                                                                                                <i class="fa fa-star" aria-hidden="true" onClick={() => handleRatingClick(item.content.id, 5)}></i>
                                                                                            </label>
                                                                                            <label for="star-15" title="5 stars">
                                                                                                <i class=" fa fa-star" aria-hidden="true" onClick={() => handleRatingClick(item.content.id, 4)}></i>
                                                                                            </label>
                                                                                            <label for="star-15" title="5 stars">
                                                                                                <i class=" fa fa-star" aria-hidden="true" onClick={() => handleRatingClick(item.content.id, 3)}></i>
                                                                                            </label>
                                                                                            <label for="star-15" title="5 stars">
                                                                                                <i class="fa fa-star" aria-hidden="true" onClick={() => handleRatingClick(item.content.id, 2)}></i>
                                                                                            </label>
                                                                                            <label for="star-15" title="5 stars">
                                                                                                <i class="active fa fa-star" aria-hidden="true" onClick={() => handleRatingClick(item.content.id, 1)}></i>
                                                                                            </label>
                                                                                        </> :

                                                                                        <>
                                                                                            <label for="star-15" title="5 stars">
                                                                                                <i class="fa fa-star" aria-hidden="true" onClick={() => handleRatingClick(item.content.id, 5)}></i>
                                                                                            </label>
                                                                                            <label for="star-15" title="5 stars">
                                                                                                <i class=" fa fa-star" aria-hidden="true" onClick={() => handleRatingClick(item.content.id, 4)}></i>
                                                                                            </label>
                                                                                            <label for="star-15" title="5 stars">
                                                                                                <i class=" fa fa-star" aria-hidden="true" onClick={() => handleRatingClick(item.content.id, 3)}></i>
                                                                                            </label>
                                                                                            <label for="star-15" title="5 stars">
                                                                                                <i class="fa fa-star" aria-hidden="true" onClick={() => handleRatingClick(item.content.id, 2)}></i>
                                                                                            </label>
                                                                                            <label for="star-15" title="5 stars">
                                                                                                <i class=" fa fa-star" aria-hidden="true" onClick={() => handleRatingClick(item.content.id, 1)}></i>
                                                                                            </label>
                                                                                        </>

                                                                    }

                                                                </div>
                                                                <p className="text-orange font_20 mb-0" onClick={() => handleRatingClick(item.id)}>Rate this book</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })}

                                    {
                                        (mybook && mybook.length < 10) || nocontent === 204 ? null : (
                                            <div className="row mt-5 justify-content-center">
                                                <div className="col-lg-3 col-md-6 col-6">
                                                    <button className="edit-btn" onClick={handlePagination}>
                                                        Load More
                                                    </button>
                                                </div>
                                            </div>
                                        )
                                    }

                                </div>
                            </div>
                        </section>
                        <Footer />

                    </main>
                )
            }

        </>
    )
}

export default BookDashboard