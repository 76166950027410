import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { UpdateUserDetails } from '../api/apiHandler.js'
import Swal from 'sweetalert2'

const RequestPage = () => {
    const navigate = useNavigate();
    const [description, setDescription] = useState(null);

    const handleSubmit = () => {
        if (description != null) {
            const mentorDescObj = {
                "mentor_description": description
            }
            UpdateUserDetails(mentorDescObj).then((response) => {
                console.log("response", response);
                if (response.status == 200) {
                    navigate("/comet-chat");
                }
            }).catch((error) => {
                Swal.fire({
                    position: "top-right",
                    icon: "error",
                    title: error.response.data.errors,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                });
            })
        } else {
            navigate("/comet-chat");
        }
    }
    return (
        <>
            <main>
                <section className="section-survey">
                    <div className="container">
                        <div>
                            <Link to="/questions">
                                <img className='back-arrow-icon' src="assets/images/icon/left-arrow.svg" alt="" />
                            </Link>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-lg-8 col-xl-8 col-12">
                                <p className="text-black font_30 font_600 mb-5 mt-4 text-center">
                                    Anything else you would like to share?
                                </p>
                                <div className="q5-input">
                                    <textarea
                                        className="form-control"
                                        placeholder="Anything else we need to know? type here.."
                                        id="floatingTextarea2"
                                        defaultValue={""}
                                        onChange={(e) => setDescription(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-7 col-xl-6 col-12">
                                <button data-bs-toggle="modal" data-bs-target={localStorage.getItem('questions_for') == "mentor" ? "#mentormodal" : "#menteemodal"} className="select-option w-100">
                                    Continue
                                </button>
                                <button className="select-option w-100" onClick={() => navigate('/comet-chat')}>
                                    Skip
                                </button>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            <div
                className="modal fade"
                id="mentormodal"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered start-topic-modal">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">
                                <i className="fa-solid fa-circle-exclamation text-white pe-1" />{" "}
                                Good stuff!
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            />
                        </div>

                        <div className="modal-body pt-0 ps-0">
                            <div className="mb-3 ps-5">
                                <p className='font_14 text-white'>We have recevived your response and you will be matched with a mentor in next 48 hours


                                </p>
                            </div>
                        </div>


                        <div className="modal-footer border-0 justify-content-start">
                            {/* <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> */}
                            <button type="button" className="btn submit-btn" data-bs-dismiss="modal" onClick={handleSubmit}>
                                Submit
                            </button>
                        </div>


                    </div>
                </div>
            </div>



            <div
                className="modal fade"
                id="menteemodal"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered start-topic-modal">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">
                                <i className="fa-solid fa-circle-exclamation text-white pe-1" />{" "}
                                Thank you for your interest!
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            />
                        </div>

                        <div className="modal-body pt-0 ps-0">
                            <div className="mb-3 ps-5">
                                <p className='font_14 text-white'>We will review your response and will inform you when a match is found. Thanks again,
                                    your kindness is congtagious!:)
                                </p>
                            </div>

                        </div>

                        <div className="modal-footer border-0 justify-content-start">
                            {/* <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> */}
                            <button type="button" className="btn submit-btn" data-bs-dismiss="modal">
                                Submit
                            </button>
                        </div>


                    </div>
                </div>
            </div>

        </>
    )
}

export default RequestPage