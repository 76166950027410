import { createSlice } from "@reduxjs/toolkit";

const userDetailSlice = createSlice({
    name: 'userdata',
    initialState: null,
    reducers: {
        setUserDetails(state, action) {

            state = action.payload;
            return state;
        }
    }
});

export const { setUserDetails } = userDetailSlice.actions;
export default userDetailSlice.reducer;