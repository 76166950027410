import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { questionMentee, submitactionplan } from '../api/apiHandler.js'
import Swal from 'sweetalert2'
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../store/slice/loaderSlice';
import Loader from '../components/Loader';



const ActionPlanQ1 = () => {
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.loader);
    const UserData = useSelector((state) => JSON.parse(state.userdata))
    const [isState, setIsState] = useState({});
    const navigate = useNavigate();
    const [questiondata, setquestiondata] = useState()
    console.log('questiondata: ', questiondata);
    const [questioncategory, setquestioncategory] = useState()
    const [questionoption, setquestionoption] = useState()
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [survayId, setSurveyId] = useState();
    const [QuestionAns, setQuestionAns] = useState([]);
    console.log('QuestionAns: ', QuestionAns);
    const [Questionid, setQuestionid] = useState();
    const [optionid, setoptionid] = useState();
    const [selectedOptions, setSelectedOptions] = useState({});
    const [handleCategory, setHandleCategory] = useState([]);
    const [result, setResult] = useState([]);

    useEffect(() => {
        dispatch(setLoading(true))
        questionMentee().then((response) => {
            dispatch(setLoading(false))
            if (response.status == 200) {
                setquestiondata(response.data[0].questions)
                setSurveyId(response.data[0].id)
                setquestioncategory(response.data[0].questions[0].question_category)
                setquestionoption(response.data[0].questions[0].options)
            }

        }).catch((error) => {
            console.error(error);
        })

    }, [])

    const mergedResult = {
        ...result,
        ...handleCategory.reduce((acc, obj) => ({ ...acc, ...obj }), {}),
    };

    const handleContinue = () => {
        // console.log(currentQuestionIndex, "currentQuestionIndex");
        // console.log(questiondata.length - 1, "questiondata.length");
        // if (currentQuestionIndex <= questiondata.length - 1) {
        const currentQuestionId = questiondata[currentQuestionIndex].id;
        if (!selectedOptions[currentQuestionId]) {
            // If no option is selected for the current question, return without proceeding
            return;
        }

        // Proceed to the next question
        setCurrentQuestionIndex(currentQuestionIndex + 1);
        handlesubmitquestion(Questionid, optionid);
        // } else {
        //     const survaydata = {
        //         survey_id: survayId,
        //         question_answers: QuestionAns,
        //         result: mergedResult

        //     }
        //     if (UserData && UserData.data.is_subscription_active == true) {
        //         submitactionplan(survaydata).then((response) => {
        //             if (response.status === 201) {
        //                 localStorage.setItem("active_actionplan", true);
        //                 console.log('Survey completed!');
        //                 navigate("/actionplan-dashboard")

        //                 // localStorage.removeItem("questions_for")
        //             }
        //         }).catch((error) => {
        //             Swal.fire({
        //                 position: "top-right",
        //                 icon: "error",
        //                 title: error.response.data.errors,
        //                 toast: true,
        //                 showConfirmButton: false,
        //                 timer: 2000
        //             });
        //         })
        //     } else {
        //         localStorage.setItem("actionplansurvay", JSON.stringify(survaydata))
        //         navigate("/subscription")
        //     }


        // }
    };

    const handlesubmitquestion = (question_id, option_id) => {
        console.log("entersubmit");
        let survay = {
            question_id: question_id,
            option_id: option_id

        }

        if (QuestionAns.length === 0) {
            QuestionAns.push(survay);
        } else {
            const index = QuestionAns.findIndex((item) => item.question_id === question_id && item.option_id === option_id);
            if (index !== -1) {
                QuestionAns.splice(index, 1);
            } else {

                const questionIndex = QuestionAns.findIndex((item) => item.question_id === question_id);
                if (questionIndex !== -1) {
                    QuestionAns.splice(questionIndex, 1);
                }
            }
            QuestionAns.push(survay);
        }
        if (currentQuestionIndex === questiondata.length - 1) {
            const survaydata = {
                survey_id: survayId,
                question_answers: QuestionAns,
                result: mergedResult

            }
            if (UserData && UserData.data.is_subscription_active == true) {
                dispatch(setLoading(true))
                submitactionplan(survaydata).then((response) => {
                    if (response.status === 201) {
                        localStorage.setItem("active_actionplan", true);
                        console.log('Survey completed!');
                        navigate("/actionplan-dashboard")

                        // localStorage.removeItem("questions_for")
                    }
                }).catch((error) => {
                    dispatch(setLoading(false))
                    Swal.fire({
                        position: "top-right",
                        icon: "error",
                        title: error.response.data.errors,
                        toast: true,
                        showConfirmButton: false,
                        timer: 2000
                    });
                })
            } else {
                localStorage.setItem("actionplansurvay", JSON.stringify(survaydata))
                navigate("/subscription")
            }
        }


    }

    const handleprev = () => {
        if (currentQuestionIndex >= 1) {
            setCurrentQuestionIndex(currentQuestionIndex - 1);
            setoptionid(optionid)
            setIsState((prevOptions) => ({
                ...prevOptions,
                [Questionid]: 1,
            }));
        } else {
            navigate(-1)
        }
    }

    const handlequestion = (question_id, option_id, question_category, option) => {
        setQuestionid(question_id)
        setoptionid(option_id)
        const actionPlanObj = {
            [question_category]: option
        }
        setHandleCategory([...handleCategory, actionPlanObj])
        setquestioncategory(question_category)
        setquestionoption(option)
        setSelectedOptions((prevOptions) => ({
            ...prevOptions,
            [question_id]: option_id,
        }));

        setIsState((prevOptions) => ({
            ...prevOptions,
            [question_id]: 1,
        }));
    }



    return (
        <>
            {isLoading ?
                (<Loader loader_status={isLoading} />) :
                (
                    <main>
                        <section className="section-survey">
                            <div className="container">
                                <div>
                                    <img className='back-arrow-icon' onClick={handleprev} src={process.env.PUBLIC_URL + "/assets/images/icon/left-arrow.svg"} alt="" />
                                </div>

                                <div className="row justify-content-center">
                                    {questiondata && questiondata.map((question, index) => (
                                        <div key={question.id} className={`col-lg-7 col-xl-7 col-12 ${index === currentQuestionIndex ? '' : 'd-none'}`}>
                                            <p className="text-black font_30 font_600 mb-5 mt-4">{question.question}</p>
                                            {question.options && question.options.map((option, idx) => (
                                                <Link to="#" className={
                                                    selectedOptions[question.id] === option.id
                                                        ? "select-survey-ans selected-option"
                                                        : "select-survey-ans"
                                                } key={option.id} onClick={() => handlequestion(question.id, option.id, question.question_category, option.option)} >
                                                    {option.option}
                                                </Link>
                                            ))}
                                        </div>
                                    ))}
                                </div>

                                <div className="row justify-content-center">
                                    <div className="col-lg-6 col-xl-6 col-12">
                                        <button to="#" onClick={handleContinue} disabled={!selectedOptions[Questionid]} className="select-option mt-4 w-100">
                                            Continue
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </section>
                    </main>
                )}

            <div
                className="modal fade"
                id="congratulation-modal"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered start-topic-modal">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">
                                <i className="fa-solid fa-circle-exclamation text-white pe-1" />{" "}
                                Congratulations!!
                                <img className='icon ps-2' src={process.env.PUBLIC_URL + "/assets/images/icon/congratulations.png"} alt="icon" />
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            />
                        </div>

                        <div className="modal-body pt-0 ps-0">
                            <div className="mb-3 ps-5">
                                <p className='font_14 text-white'>Kudos to you for taking steps towards a better you, you will start receiving your action items in your helprr profile starting tomorrow! Good luck and happy healing :)


                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default ActionPlanQ1