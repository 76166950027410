import React, { useRef } from 'react'
import Footer from '../components/Footer'
import OuterHeader from '../components/OuterHeader'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';


const PeerNetwork = () => {
    const UserData = useSelector((state) => JSON.parse(state.userdata))
    // const location = process.env.REACT_APP_API_BASE_URL + window.location.href;
    // console.log('location: ', location);
    const baseUrl = process.env.REACT_APP_WEBSITE_BASE_URL;
    const path = window.location.pathname;
    const completeUrl = baseUrl + path;


    const handlesubscription = () => {
        localStorage.setItem('completeUrl', completeUrl)
    }

    return (
        <>
            <main className="helprr-main">
                <OuterHeader />
                <section className="other-res-pad pt-2">
                    <div className="container">
                        <p className="font_50 font_600 mb-sm-5">Peer Mentorship</p>
                        <div className="row justify-content-center">
                            <div className="col-md-8 col-lg-6 col-xl-6 col-8">
                                <div className="position-relative w-max-content">
                                    <div className="card-comment card-width">
                                        <p className="font_22 ">
                                            Discover the power of personal growth through our Peer
                                            Mentorship feature. Connect with individuals who have walked a
                                            similar path and are eager to offer guidance and support.
                                            Whether you're seeking advice on career development, personal
                                            challenges, or simply a friend who understands, our peer
                                            mentorship platform is your haven for growth.
                                        </p>
                                    </div>
                                    <div className="vector-side">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/icon/vector-side-tri.svg"} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-4 col-xl-4 col-4">
                                <div className="d-flex justify-content-center">
                                    <div className="home-page-vector set-vector">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/vector--2.svg"} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="other-res-pad pt-5">
                    <div className="container">
                        <p className="font_50 font_600 mb-0">How it works:</p>
                        <div className="row">
                            <div className="col-lg-4 col-xl-4 col-12">
                                <div className="get-matched" data-match-height="groupName">
                                    <p className="font_30">Get Matched</p>
                                    <p className="font_20 text">
                                        helprr matches you with a peer mentor/mentee who aligns with your
                                        needs.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-xl-4 col-12">
                                <div className="get-matched bg-mave" data-match-height="groupName">
                                    <p className="font_30">Get Talking</p>
                                    <p className="font_20 text">
                                        Engage in meaningful conversations with your mentor/mentee.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-xl-4 col-12">
                                <div className="get-matched" data-match-height="groupName">
                                    <p className="font_30">Get Growing</p>
                                    <p className="font_20 text">
                                        Embark on a journey of self improvement and personal development.
                                    </p>
                                </div>
                            </div>
                        </div>
                        {UserData && UserData.data.is_subscription_active == true ?
                            <div className="row justify-content-center mt-4">
                                <div className="col-lg-7 col-xl-6 col-md-7 col-12">
                                    <div>
                                        <Link
                                            to="/peer-community"
                                            className="find-btn text-white font_600 font_22"
                                        >
                                            Find me a Mentor/Mentee
                                        </Link>
                                    </div>
                                </div>
                            </div> :
                            <div className="row justify-content-center mt-4" onClick={handlesubscription}>
                                <div className="col-lg-7 col-xl-6 col-md-7 col-12">
                                    <div>
                                        <Link
                                            to="/subscription"
                                            className="find-btn text-white font_600 font_22"
                                        >
                                            Find me a Mentor/Mentee
                                        </Link>
                                    </div>
                                </div>
                            </div>}
                    </div>
                </section>
                <Footer />
            </main>

        </>
    )
}

export default PeerNetwork