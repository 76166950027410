import React, { useState, useEffect } from 'react'
import OuterHeader from './OuterHeader.js'
import Footer from './Footer.js'
import { Link, useNavigate } from 'react-router-dom'
import Slider from "react-slick";
import Select2 from '../pages/Select2.js';
import '../css2/select2.css'
import Swal from 'sweetalert2'
import { ContentDefaultlist } from '../api/apiHandler.js';
import { useDispatch, useSelector } from 'react-redux';



const DefaultContent = () => {
    const navigate = useNavigate()

    // let defaultdata = props.defaultdata
    // console.log(defaultdata, "defaultdata");
    // const [manageDefaultContent, setManageDefaultContent] = useState(props.managedata);
    const [defaultdata, setDefaultData] = useState([]);
    const UserData = useSelector((state) => JSON.parse(state.userdata))


    // useEffect(() => {
    //     ContentDefaultlist().then((response) => {
    //         if (response.status == 200) {
    //             if (localStorage.getItem("total_interest") > 0) {
    //                 setDefaultData([])
    //             } else {
    //                 setDefaultData(response.data.results)
    //             }
    //         }

    //     }).catch((error) => {
    //         console.log(error);
    //     })
    // }, [])


    useEffect(() => {
        ContentDefaultlist({ page: 1 }).then((response) => {
            if (response.status == 200) {
                // console.log(localStorage.getItem("user_search"), "usersearch");
                // console.log("===================================");

                // console.log(localStorage.getItem("user_search") == "" || localStorage.getItem("user_search") == undefined);
                // console.log("===================================");

                if (localStorage.getItem("user_search") == "" || localStorage.getItem("user_search") == undefined) {
                    // if (manageDefaultContent) {
                    //     setDefaultData([])
                    // } else {
                    setDefaultData(response.data.results)
                    // }
                }

            }

        }).catch((error) => {
            console.log(error);
        })
    }, [])



    const settings = {
        dots: false,
        infinite: true,
        arrows: true,
        speed: 300,
        slidesToShow: 6,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 5,
                    infinite: true,
                },
            },
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 4,
                    infinite: true,
                },
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    infinite: true,
                },
            },
            {
                breakpoint: 575.99,
                settings: {
                    slidesToShow: 1,
                    infinite: true,

                },
            },
        ],
    };


    const handleCreateEventClick = () => {
        Swal.fire({
            position: "top-right",
            icon: "error",
            title: "Subscription required",
            toast: true,
            showConfirmButton: false,
            timer: 2000
        });
    }



    return (
        <>
            <div className="container">
                <div className="d-flex justify-content-end align-items-center mb-4 flex-wrap">
                    {defaultdata && defaultdata.length > 6 ?
                        <button className="font_24 mb-0 see-more-icon font_400"
                            onClick={() => navigate('/interest-content')}
                        >
                            See More
                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/blue-arroww.svg"} className="ps-2" />
                        </button> :
                        ""
                    }
                </div>
                <div className="testimonial-area">
                    <div>
                        <Slider {...settings}>
                            {defaultdata && defaultdata.map((item, idx) => (
                                <div key={idx} className="col-12 wow fadeInUp" data-wow-delay=".2s">
                                    {UserData && UserData.data.is_subscription_active == true ? (
                                        item.content_type === "Book" ? (
                                            <div onClick={() => navigate(`/book-description/${item.id}`)} className="single-testi">
                                                <Link to="/bookdescription" className="self-care-card d-block">
                                                    <div className='position-relative'>
                                                        <div className="selfcare-img">
                                                            <img src={item && item.thumbnail_url != null ? item.thumbnail_url : item.thumbnail} alt="" />
                                                        </div>
                                                    </div>
                                                    <div className="title">
                                                        <p className="font_20 mb-1">{item.title}</p>
                                                        <p className="font_14 mb-0">{item.authors_details}</p>
                                                    </div>
                                                </Link>
                                            </div>
                                        ) : item.content_type === "Video" ? (
                                            <div onClick={() => navigate(`/content-video/${item.id}`)} className="single-testi">
                                                <Link to="#" className="self-care-card d-block">
                                                    <div className='position-relative'>
                                                        <div className="selfcare-img">
                                                            <img src={item.thumbnail} alt="" />
                                                        </div>
                                                    </div>
                                                    <div className="title">
                                                        <p className="font_20 mb-1">{item.title}</p>
                                                        <p className="font_14 mb-0">{item.authors_details}</p>
                                                    </div>
                                                </Link>
                                            </div>
                                        ) : item.content_type === "Audio" ? (
                                            <div onClick={() => navigate(`/content-audio/${item.id}`)} className="single-testi">
                                                <Link to="#" className="self-care-card d-block">
                                                    <div className='position-relative'>
                                                        <div className="selfcare-img">
                                                            <img src={item.thumbnail} alt="" />
                                                        </div>
                                                    </div>
                                                    <div className="title">
                                                        <p className="font_20 mb-1">{item.title}</p>
                                                        <p className="font_14 mb-0">{item.authors_details}</p>
                                                    </div>
                                                </Link>
                                            </div>
                                        ) : (
                                            <div onClick={() => navigate(`/content-audio/${item.id}`)} className="single-testi">
                                                <Link to="#" className="self-care-card d-block">
                                                    <div className='position-relative'>
                                                        <div className="selfcare-img">
                                                            <img src={item.thumbnail} alt="" />
                                                        </div>
                                                    </div>
                                                    <div className="title">
                                                        <p className="font_20 mb-1">{item.title}</p>
                                                        <p className="font_14 mb-0">{item.authors_details}</p>
                                                    </div>
                                                </Link>
                                            </div>
                                        )
                                    ) : (
                                        item.content_type === "Book" ? (
                                            <div onClick={() => navigate(`/book-description/${item.id}`)} className="single-testi">
                                                <Link to="/bookdescription" className="self-care-card d-block">
                                                    <div className='position-relative'>
                                                        <div className="selfcare-img">
                                                            <img src={item && item.thumbnail_url != null ? item.thumbnail_url : item.thumbnail} alt="" />
                                                        </div>
                                                    </div>
                                                    <div className="title">
                                                        <p className="font_20 mb-1">{item.title}</p>
                                                        <p className="font_14 mb-0">{item.authors_details}</p>
                                                    </div>
                                                </Link>
                                            </div>
                                        ) : (
                                            <div onClick={item.is_paid ? handleCreateEventClick : () => navigate(`/content-video/${item.id}`)} className="single-testi">
                                                <Link to="#" className="self-care-card d-block">
                                                    <div className='position-relative'>
                                                        <div className="selfcare-img">
                                                            <img src={item.thumbnail} alt="" />
                                                        </div>
                                                        {item.is_paid && (
                                                            <div className="lock-icon">
                                                                <i className="fa-solid fa-lock text-mave font_24"></i>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="title">
                                                        <p className="font_20 mb-1">{item.title}</p>
                                                        <p className="font_14 mb-0">{item.authors_details}</p>
                                                    </div>
                                                </Link>
                                            </div>
                                        )
                                    )}
                                </div>
                            ))}
                        </Slider>
                    </div>

                </div>
            </div>



        </>
    )
}

export default DefaultContent