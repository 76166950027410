
// import React, { useEffect, useState } from 'react'
// import OuterHeader from '../components/OuterHeader'
// import Footer from '../components/Footer'
// import { Link } from 'react-router-dom'
// import { actionplantaskdaywise, actionplantaskupdate } from '../api/apiHandler.js'
// import Swal from 'sweetalert2'



// const TaskNotes = () => {

//     const [data, setData] = useState(0)
//     const [feedbackdata, setFeedbackData] = useState("")
//     const [myfeedback, setMyfeedback] = useState([])


//     const handlesave = () => {

//         const editdata = {
//             "task_feedback": feedbackdata
//         }

//         actionplantaskupdate(editdata).then((response) => {
//             console.log(response, "responseactionplan");
//             if (response.status == 200) {
//                 setMyfeedback((prev) => [...prev, response.data.task_feedback])
//                 Swal.fire({
//                     position: "top-right",
//                     icon: "success",
//                     title: "Success",
//                     toast: true,
//                     showConfirmButton: false,
//                     timer: 2000
//                 });
//             }
//         }).catch((error) => {
//             Swal.fire({
//                 position: "top-right",
//                 icon: "error",
//                 title: error.response.data.errors,
//                 toast: true,
//                 showConfirmButton: false,
//                 timer: 2000
//             });
//         })
//     }

//     useEffect(() => {
//         actionplantaskdaywise().then((response) => {
//             console.log(response.data, "feedback");
//             setData(response.data);
//             setFeedbackData(response.data.task_feedback)
//         }).catch((error) => {
//             console.log(error);
//         })
//     }, [])

//     const handleedit = () => {

//     }

//     return (
//         <>
//             <main className="helprr-main">
//                 <OuterHeader />
//                 <section>
//                     <div className="container">
//                         <p className="font_30 font_700 text-orange pt-4">
//                             {data && data.task_name}
//                         </p>
//                         <p className="text-mave">
//                             {data && data.task_description}
//                         </p>
//                         <hr />
//                         <p className="font_22 text-orange font_700">Journal Notes</p>

//                         {myfeedback.map((feedback) => {
//                             return <p className="text-mave" onClick={handleedit}>
//                                 {feedback}
//                             </p>
//                         })

//                         }


//                         <textarea className="collapse border-2" defaultValue={feedbackdata != null && feedbackdata != "" ? feedbackdata : ""} onChange={(e) => setFeedbackData(e.target.value)} name="taskfeedback" id="collapseExample" cols="100" rows="5"></textarea>

//                         <div className="row mt-5 justify-content-center mb-4">

//                             {feedbackdata != null && feedbackdata != "" ?

//                                 <div className="col-lg-3">
//                                     <a className="edit-btn" onClick={() => handlesave()} >
//                                         Save
//                                     </a>
//                                 </div> :
//                                 <div className="col-lg-3">
//                                     <a className="edit-btn" data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
//                                         Edit
//                                     </a>
//                                 </div>
//                             }
//                             <div className="col-lg-3">
//                                 <Link to="/actionplan-dashboard" className="goback-btn">
//                                     Go Back
//                                 </Link>
//                             </div>
//                         </div>
//                     </div>
//                 </section>
//                 <Footer />
//             </main>

//         </>
//     )
// }


// export default TaskNotes



import React, { useEffect, useState } from 'react'
import OuterHeader from '../components/OuterHeader'
import Footer from '../components/Footer'
import { Link } from 'react-router-dom'
import { actionplantaskdaywise, actionplantaskupdatedaywise } from '../api/apiHandler.js'
import Swal from 'sweetalert2'



const TaskNotes = () => {

    const [data, setData] = useState(0)
    const [feedbackdata, setFeedbackData] = useState("")
    const [myfeedback, setMyfeedback] = useState([])
    const [handleSaveEditBtn, setHandleSaveEditBtn] = useState(1);


    const handlesave = () => {
        if (feedbackdata != null) {
            const editdata = {
                "task_feedback": feedbackdata
            }

            actionplantaskupdatedaywise(editdata).then((response) => {
                if (response.status == 200) {
                    setHandleSaveEditBtn(1)
                    Swal.fire({
                        position: "top-right",
                        icon: "success",
                        title: "Journal notes added successfully",
                        toast: true,
                        showConfirmButton: false,
                        timer: 2000
                    });
                }
            }).catch((error) => {
                Swal.fire({
                    position: "top-right",
                    icon: "error",
                    title: error.response.data.errors,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                });
            })
        }
    }

    useEffect(() => {
        actionplantaskdaywise().then((response) => {
            setData(response.data);
            setFeedbackData(response.data.task_feedback)
        }).catch((error) => {
            console.log(error);
        })
    }, [])


    return (
        <>
            <main className="helprr-main">
                <OuterHeader />
                <section>
                    <div className="container">
                        <p className="font_30 font_700 text-orange pt-4">
                            {data && data.task_name}
                        </p>
                        <p className="text-mave">
                            {data && data.task_description}
                        </p>
                        <hr />
                        <p className="font_22 text-orange font_700">Journal Notes</p>

                        {/* <p className="text-mave">
                            {feedbackdata}
                        </p> */}

                        {handleSaveEditBtn !== 1 ? (
                            <textarea
                                className="collapse border-2"
                                defaultValue={feedbackdata != null && feedbackdata !== '' ? feedbackdata : ''}
                                onChange={(e) => setFeedbackData(e.target.value)}
                                name="taskfeedback"
                                id="collapseExample"
                                cols="100"
                                rows="5"
                            />
                        ) : (
                            <p className="text-mave">{feedbackdata}</p>
                        )}

                        <textarea className="collapse border-2 responsive-textarea" defaultValue={feedbackdata != null && feedbackdata != "" ? feedbackdata : ""} onChange={(e) => setFeedbackData(e.target.value)} name="taskfeedback" id="collapseExample" cols="100" rows="5"></textarea>
                        <div className="row mt-5 justify-content-center mb-4">
                            {handleSaveEditBtn != 1 ?

                                <div className="col-lg-3">
                                    <a className="edit-btn" data-bs-toggle="collapse" href="#collapseExample" onClick={() => handlesave()} >
                                        Save
                                    </a>
                                </div> :
                                <div className="col-lg-3">
                                    <a className="edit-btn" data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample" onClick={() => setHandleSaveEditBtn(0)}>
                                        Edit
                                    </a>
                                </div>
                            }
                            <div className="col-lg-3">
                                <Link to="/actionplan-dashboard" className="goback-btn">
                                    Go Back
                                </Link>
                            </div>
                        </div>

                    </div>
                </section>
                <Footer />
            </main>

        </>
    )
}


export default TaskNotes












