import React, { useState, useEffect } from 'react'
import Footer from '../components/Footer.js'
import { Link, useNavigate } from 'react-router-dom'
import Header from '../components/Header.js'
import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import checkValidation from '../validation/ValidationSchema.js';
import Swal from 'sweetalert2'
import { VerifyOTP, forgotPasswordOTP, registration, resendOTP } from '../api/apiHandler.js'
import Loader from '../components/Loader.js';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../store/slice/loaderSlice.js';





const VerifyOtp = () => {
    // const [timer, setTimer] = useState(30);
    const schema = checkValidation(['otp']);
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.loader);
    const [isResending, setIsResending] = useState(false);

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });
    const navigate = useNavigate();

    const userdata = JSON.parse(localStorage.getItem("userdata"))
    // if (localStorage.getItem("latitude") != null && localStorage.getItem("longitude") != null) {
    //     userdata.latitude = localStorage.getItem("latitude")
    //     userdata.longitude = localStorage.getItem("longitude")
    // }



    const onSubmit = (data) => {
        const otpobj = {
            email: localStorage.getItem('verification_for') === "registration" ? userdata.email : localStorage.getItem('email'),
            otp: data.otp
        }
        // dispatch(setLoading(true))
        if (localStorage.getItem('verification_for') === "registration") {
            VerifyOTP(otpobj).then((response) => {
                if (response.status === 200) {
                    Swal.fire({
                        position: "top-right",
                        icon: "success",
                        title: "OTP verifed successfully",
                        toast: true,
                        showConfirmButton: false,
                        timer: 2000
                    });
                    registration(userdata).then((response) => {
                        // dispatch(setLoading(false))
                        if (response.status === 201) {
                            localStorage.setItem("token", response.data['X-Token'])
                            localStorage.setItem("uuid", response.data['uuid'])
                            localStorage.setItem("isadd", true)
                            localStorage.setItem("current_streak", response.data['current_streak'])
                            localStorage.setItem("longest_streak", response.data['longest_streak'])
                            localStorage.setItem("username", response.data['username'])
                            localStorage.setItem("email", response.data['email'])
                            localStorage.setItem("total_interest", response.data['interests'].length);
                            localStorage.setItem("user_id", response.data['id'])
                            localStorage.setItem("islogin", "true")
                            localStorage.setItem("isSignup", "true")
                            localStorage.setItem("active_actionplan", response.data["has_action_plan"]);
                            localStorage.setItem("is_mentee", response.data['is_mentee'] == null ? "false" : response.data['is_mentee']);
                            localStorage.removeItem('verification_for')
                            navigate('/interests')
                        }
                    }).catch((error) => {
                        Swal.fire({
                            position: "top-right",
                            icon: "error",
                            title: error.response.data.errors,
                            toast: true,
                            showConfirmButton: false,
                            timer: 2000
                        });
                        console.log(error);
                    })

                }

            }).catch((error) => {
                Swal.fire({
                    position: "top-right",
                    icon: "error",
                    title: error.response.data.errors,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                });
            })

        } else {
            forgotPasswordOTP(otpobj).then((response) => {
                // dispatch(setLoading(false))
                if (response.status === 200) {
                    localStorage.removeItem('verification_for')
                    Swal.fire({
                        position: "top-right",
                        icon: "success",
                        title: "OTP verified successfully",
                        toast: true,
                        showConfirmButton: false,
                        timer: 2000
                    });
                    navigate('/reset-password')
                }

            }).catch((error) => {
                Swal.fire({
                    position: "top-right",
                    icon: "error",
                    title: error.response.data.errors,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                });
                console.log(error);
            })
        }
    }

    const resendOtp = () => {
        const userdata = JSON.parse(localStorage.getItem("userdata"))
        const resetotpobj = {
            email: localStorage.getItem('verification_for') === "registration" ? userdata.email : localStorage.getItem('email')
            // email: userdata.email
        }

        if (isResending == false) {
            // setIsResending(true)
            // setTimeout(() => {
            //     setIsResending(false)
            // }, 5000);
            resendOTP(resetotpobj).then((response) => {
                if (response.status === 200) {
                    Swal.fire({
                        position: "top-right",
                        icon: "success",
                        title: "OTP resent successfully",
                        toast: true,
                        showConfirmButton: false,
                        timer: 2000
                    });
                    navigate('/verify-otp')
                }

            }).catch((error) => {
                Swal.fire({
                    position: "top-right",
                    icon: "error",
                    title: error.response.data.errors,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                });
            })

        }

    }

    const handleback = () => {
        navigate(-1)
    }


    // useEffect(() => {
    //     if (localStorage.getItem("isSignup") == "true") {
    //         localStorage.clear();
    //         navigate("/signup")
    //     } else if (localStorage.getItem("isragister") == undefined || localStorage.getItem("isragister") != "filldata") {
    //         navigate("/signup")
    //     } else {
    //         localStorage.removeItem('isragister')
    //     }
    // }, [])


    // useEffect(() => {
    //     const countdown = setInterval(() => {
    //         if (timer > 0) {
    //             setTimer((prevTimer) => prevTimer - 1);
    //         }
    //     }, 1000);

    //     return () => clearInterval(countdown);
    // }, [timer]);

    // const handleResendOtp = () => {
    //     if (timer == 0) {
    //         setTimer(30)
    //     }
    // }


    useEffect(() => {
        if (localStorage.getItem("verification_for") != "forgot_password") {
            const disableBackButton = (e) => {
                e.preventDefault();
                window.history.forward(); // Forward the user when they try to go back
            };

            window.history.pushState(null, null, window.location.pathname);
            window.addEventListener('popstate', disableBackButton); // Listen for back button press

            return () => {
                window.removeEventListener('popstate', disableBackButton); // Cleanup the event listener when the component unmounts
            };
        }

    }, []);


    return (
        <>

            {isLoading ?
                (<Loader loader_status={isLoading} />) :
                (
                    <main className="helprr-main">
                        {/* <Header /> */}
                        <nav className="navbar navbar-expand-lg navbar-light navbar-header">
                            <div className="container d-flex">
                                <Link className="navbar-brand" to="/">
                                    <img src={process.env.PUBLIC_URL + "/assets/images/logo.png"} />
                                </Link>
                            </div>
                        </nav>
                        <section className="login-sec">
                            <div className="container">
                                {/* <div className="d-flex gap-3 mb-3 align-items-center" onClick={handleback}>
                                    <img className='back-arrow-icon' src={process.env.PUBLIC_URL + "/assets/images/icon/left-arrow.svg"} alt="" />
                                    <p className="mb-0 font_24">Go Back</p>
                                </div> */}
                                <div className="row justify-content-center">
                                    <div className="col-lg-6 col-xl-6 col-12">
                                        <p className="title font_40 pb-0 text-center pb-3" >OTP Verification</p>

                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <div className="mb-4 mt-3">
                                                <input
                                                    type="text"
                                                    className="form-custom form-control font_16"
                                                    id="exampleFormControlInput1"
                                                    placeholder=" Enter OTP"
                                                    name="otp"
                                                    {...register('otp')}

                                                />
                                            </div>
                                            {errors.otp && <p role="alert" className='error-text'>{errors.otp.message}</p>}

                                            {/* <p className='text-center'>Expires In: <span>00:{String(timer).padStart(2, '0')}</span></p> */}
                                            <a type='button' className="font_16 text-decoration-underline" onClick={resendOtp}>
                                                Resend OTP
                                            </a>
                                            <button
                                                type="submit"
                                                className="login-btn font_16">
                                                Verify OTP
                                            </button>

                                        </form>

                                    </div>
                                </div>
                            </div>
                        </section>
                        <div style={{ paddingTop: "10%" }}>
                            <Footer />
                        </div>
                    </main>
                )
            }

        </>
    )
}

export default VerifyOtp