import React, { useState, useEffect } from 'react';
import OuterHeader from '../components/OuterHeader';
import Footer from '../components/Footer';
import { FAQs } from '../api/apiHandler.js';
import Swal from 'sweetalert2'
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../store/slice/loaderSlice';
import Loader from '../components/Loader';
import Header from '../components/Header.js';

const Faqs = () => {
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.loader);
    const [FaqsData, setFaqsData] = useState("");

    useEffect(() => {
        dispatch(setLoading(true))
        FAQs({}).then((response) => {
            dispatch(setLoading(false))
            setFaqsData(response.data)
            console.log(response.data, 'response');

        }).catch((error) => {
            // dispatch(setLoading(false))
            console.log(error, 'error');
        })
    }, [])

    return (
        <>
            {isLoading ?
                (<Loader loader_status={isLoading} />) :
                (
                    <main className="helprr-main">
                        {localStorage.getItem("token") ?
                            <OuterHeader /> :
                            <Header />
                        }
                        <section className="login-sec faq-sec">
                            <div className="container">
                                <div className="row align-items-end">
                                    <div className="col-lg-12 col-xl-12 col-12">
                                        <p className="font_46 font_600">Help &amp; FAQs</p>
                                        <div className="accordion accordion-flush" id="accordionFlushExample">
                                            {FaqsData && FaqsData.results.map((item, index) => {
                                                const questionId = `flush-heading-${index}`;
                                                const answerId = `flush-collapse-${index}`;
                                                return (
                                                    <div className="accordion-item" key={index}>
                                                        <h2 className="accordion-header" id={questionId}>
                                                            <button
                                                                className="accordion-button collapsed font_22 font_600"
                                                                type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target={`#${answerId}`}
                                                                aria-expanded="false"
                                                                aria-controls={answerId}
                                                            >
                                                                {item.question}
                                                            </button>
                                                        </h2>
                                                        <div
                                                            id={answerId}
                                                            className="accordion-collapse collapse"
                                                            aria-labelledby={questionId}
                                                            data-bs-parent="#accordionFlushExample"
                                                        >
                                                            <div className="accordion-body">
                                                                {item.answer}
                                                            </div>
                                                        </div>

                                                    </div>)
                                            })}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-xl-3 col-12"></div>
                            </div>
                        </section>
                        <Footer />
                    </main>)}
        </>

    )
}

export default Faqs