import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

const Subscription = () => {
    const navigate = useNavigate();
    return (
        <>
            <main className="helprr-main">
                <header className='p-2'>
                    <nav className="navbar navbar-expand-lg navbar-light navbar-header">
                        <div className="container d-flex p-0">
                            <Link className="navbar-brand" to="/">
                                <img src={process.env.PUBLIC_URL + "/assets/images/logo.png"} />
                            </Link>
                            <form className="d-flex ms-auto">
                                <Link className=" font_20" onClick={() => navigate(-1)}>
                                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/x-close.png"} alt="close-icon" />
                                </Link>
                            </form>
                        </div>
                    </nav>
                </header>
                <section className="subscribe-sec">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-9 col-xl-9 col-md-8 col-12">
                                <p className="text-mave font_36 font_700 text-center">
                                    Try helprr Premium free for 10 days
                                </p>
                                <div className="subscribelist">
                                    <p className="text-mave font_24 font_700 ps-4">
                                        Premium membership gives you access to{" "}
                                    </p>
                                    <ul className="list-unstyled">
                                        <li className="text-mave font_18 pb-2">
                                            <img
                                                src={process.env.PUBLIC_URL + "/assets/images/icon/check-orange.svg"}
                                                alt="checkmark"
                                                className="pe-2"
                                            />
                                            Personalized action plans
                                        </li>
                                        <li className="text-mave font_18 pb-2">
                                            <img
                                                src={process.env.PUBLIC_URL + "/assets/images/icon/check-orange.svg"}
                                                alt="checkmark"
                                                className="pe-2"
                                            />
                                            Dashboard to track your journey
                                        </li>
                                        <li className="text-mave font_18 pb-2">
                                            <img
                                                src={process.env.PUBLIC_URL + "/assets/images/icon/check-orange.svg"}
                                                alt="checkmark"
                                                className="pe-2"
                                            />
                                            Handpicked content tailored to your needs
                                        </li>
                                        <li className="text-mave font_18 pb-2">
                                            <img
                                                src={process.env.PUBLIC_URL + "/assets/images/icon/check-orange.svg"}
                                                alt="checkmark"
                                                className="pe-2"
                                            />
                                            Chat anytime with your AI friend helprr{" "}
                                        </li>
                                        <li className="text-mave font_18 pb-2">
                                            <img
                                                src={process.env.PUBLIC_URL + "/assets/images/icon/check-orange.svg"}
                                                alt="checkmark"
                                                className="pe-2"
                                            />
                                            Peer mentors
                                        </li>
                                        <li className="text-mave font_18 pb-2">
                                            <img
                                                src={process.env.PUBLIC_URL + "/assets/images/icon/check-orange.svg"}
                                                alt="checkmark"
                                                className="pe-2"
                                            />
                                            Organize meetups and events
                                        </li>
                                    </ul>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-4 col-xl-4 col-12">
                                        <Link to="/subscription-plans" className="select-option">
                                            Continue
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </main>


        </>
    )
}

export default Subscription