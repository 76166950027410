import React, { useRef, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCards } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-cards';
import { CometChat } from "@cometchat/chat-sdk-javascript";
import { UIKitSettingsBuilder } from "@cometchat/uikit-shared";
import { CometChatUIKit, CometChatLocalize } from "@cometchat/chat-uikit-react";
import { logout, userDetails, todayAffirmation, Notifications, UpdateNotifications, actionplantasktoday, actionplantaskupdate, actionplantasklist, UpdateUserDetails } from '../api/apiHandler.js'
import Swal from 'sweetalert2'
import { setUserDetails } from '../store/slice/userDetail';
import html2canvas from 'html2canvas';
import { setActionplanDetails } from '../store/slice/actionplandetails.js';
import { setLoading } from '../store/slice/loaderSlice';
import { initializeCometChat } from '../config/CometChatInit.js';

const OuterHeader = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [notifacationdata, setnotificationdata] = useState()
    const [notifacationcount, setnotificationcount] = useState(0)
    const [affirmationdata, setAffirmationdata] = useState();
    const [wohooSlider, setWohooSlider] = useState(0);
    const textareaRef = useRef(null);
    const [todaytask, setTodayTask] = useState()
    const [cometChatTitle, setCometChatTitle] = useState('Users');
    var reduxUserData = useSelector((state) => state.userdata);
    reduxUserData = (reduxUserData != undefined) ? JSON.parse(reduxUserData) : null;
    const [loginUserData, setLoginUserData] = useState(reduxUserData);
    //console.log('loginUserData: ', loginUserData);
    const swiperRef = useRef(null);
    const submitButtonRef = useRef(null);
    const skipButtonRef = useRef(null);
    const [isCometChatIni, setIsCometChatIni] = useState(false);



    // Initialize related code

    useEffect(() => {
        initializeCometChat()
            .then(() => {
                //setIsCometChatReady(true);
                // You can now use CometChat features
            })
            .catch(error => {
                console.error('CometChat initialization error:', error);
            });
    }, []);

    useEffect(() => {
        //if (loginUserData === null) {
        userDetails().then((response) => {
            if (response.status === 200) {
                localStorage.setItem("loggedUserDetail", JSON.stringify(response.data));
                localStorage.setItem("active_actionplan", true)
                dispatch(setUserDetails(JSON.stringify(response)));
                setLoginUserData(response)
                setCometChatTitle((response.data.is_mentee) ? 'Mentors' : 'Mentees');
                //console.log('called')
            } else {
                console.log("userdetails failed");
            }
        }).catch((error) => {
            console.log(error);
        })
        //}

        //setCometChatTitle((loginUserData != null && loginUserData.data.is_mentee) ? 'Mentors' : 'Mentees');

    }, []);

    useEffect(() => {
        getNotifications()
    }, [])

    // setTimeout(() => {
    //     getNotifications()
    // }, 10000);

    const getNotifications = () => {
        Notifications().then((response) => {
            if (response.status == 200) {
                setnotificationdata(response.data.notifications)
                setnotificationcount(response.data.counts)
            } else if (response.status == 204) {
                setnotificationdata()
                setnotificationcount(0)
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    useEffect(() => {
        actionplantasktoday().then((response) => {
            if (response.status == 200) {
                setTodayTask(response.data);
            }
        }).catch((error) => {
            console.log(error);
        })

    }, [])



    function myFunctionswiper() {

        const flexCheckChecked1 = document.getElementById("flexCheckChecked1");
        const flexCheckChecked2 = document.getElementById("flexCheckChecked2");

        flexCheckChecked1.checked = false
        flexCheckChecked2.checked = false
        var element = document.getElementById("panel");
        element.classList.add("stylepanel");

        setWohooSlider(0);
        const swiper = swiperRef.current.swiper;
        swiper.slideTo(0);
    }

    // function closemodal() {
    //     setWohooSlider(0)
    //     textareaRef.current.value = "";
    //     document.getElementById("panel").classList.remove("stylepanel");
    // }

    function closemodal() {
        let tasknote
        if (textareaRef.current.value != "") {
            tasknote = {
                "is_completed": true,
                "task_feedback": textareaRef.current.value
            }
        } else {
            tasknote = {
                "is_completed": true,
                "task_feedback": null
            }
        }
        actionplantaskupdate(tasknote).then((response) => {
            if (response.status == 200) {
                const updatenotificationobj = {
                    "id": localStorage.getItem("notificationid"),
                    "is_viewed": true
                }
                updatednotification(updatenotificationobj)
                localStorage.setItem("actionplandays", response.data.day)
                // setIsNotificationread(true)
                setTodayTask(response.data)
                actionplantasklist().then((response) => {
                    if (response.status == 200) {
                        dispatch(setActionplanDetails(response.data))
                    } else if (response.status == 204) {
                        Swal.fire({
                            position: "top-right",
                            icon: "info",
                            title: "User has no active action plan",
                            toast: true,
                            showConfirmButton: false,
                            timer: 2000
                        });
                    }
                }).catch((error) => {
                    console.log(error);
                })

            }
        }).catch((error) => {
            console.log(error);
        })
        textareaRef.current.value = "";
        document.getElementById("panel").classList.remove("stylepanel");
    }


    function firstclosemodal() {
        setWohooSlider(0)
        document.getElementById("panel").classList.remove("stylepanel");
    }

    const handleslider = () => {
        const flexCheckChecked1 = document.getElementById("flexCheckChecked1");
        const flexCheckChecked2 = document.getElementById("flexCheckChecked2");

        if (flexCheckChecked1.checked && flexCheckChecked2.checked) {
            const swiper = swiperRef.current.swiper;

            swiper.activeIndex = 2
            setWohooSlider(1)

        }
    }

    const getvalue = () => {
        const textareaValue = textareaRef.current.value;
        if (textareaValue != "") {
            submitButtonRef.current.style.cursor = "pointer"
            submitButtonRef.current.style.pointerEvents = "auto"
            skipButtonRef.current.style.cursor = "default"
            skipButtonRef.current.style.pointerEvents = "none"
        } else {
            submitButtonRef.current.style.cursor = "default"
            submitButtonRef.current.style.pointerEvents = "none"
            skipButtonRef.current.style.cursor = "pointer"
            skipButtonRef.current.style.pointerEvents = "auto"
        }
    }

    useEffect(() => {

        CometChatUIKit.getLoggedinUser().then((user) => {

            if (user) {
                CometChat.disconnect();
            }
        });
    }, []);

    const handlelogout = async () => {
        // logout cometchat 
        logout().then(async (response) => {
            Swal.fire({
                position: "top-right",
                icon: "success",
                title: "User logged out successfully",
                toast: true,
                showConfirmButton: false,
                timer: 2000
            });
            localStorage.clear();
            try {
                const authToken = localStorage.getItem('cometChatToken')

                if (authToken) {
                    await CometChatUIKit.logout();
                }
                console.log("User logged out successfully");
            } catch (error) {
                console.error("Logout failed with error:", error);
            }
            navigate('/login')

        }).catch((error) => {
            console.log(error);
        })

    }

    const handleAffirmation = () => {
        todayAffirmation().then((response) => {
            if (response.status == 200) {
                setAffirmationdata(response.data)
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    const handlenotification = (id, type) => {
        const updatenotificationobj = {
            "id": id,
            "is_viewed": true
        }
        if (type != "Action plan item" && type != "Action plan") {
            updatednotification(updatenotificationobj)
        } else {
            localStorage.setItem("notificationid", id)
        }

    }

    const updatednotification = (updatenotificationobj) => {
        UpdateNotifications(updatenotificationobj).then((response) => {
            if (response.status == 200) {
                getNotifications()
            }

        }).catch((error) => {
            console.log(error);
        })

    }

    const divRef = useRef(null);
    const handleDownloadClick = () => {
        captureAndDownload();
    };
    const captureAndDownload = () => {
        html2canvas(divRef.current).then((canvas) => {
            const dataUrl = canvas.toDataURL();
            const downloadLink = document.createElement('a');
            downloadLink.href = dataUrl;
            downloadLink.download = 'captured_image.png';
            downloadLink.click();
        });
    };

    const handleSubmit = () => {
        const loggedUserDetail = (loginUserData) ? loginUserData.data : null;
        console.log(loggedUserDetail);
        if (loggedUserDetail.cometchat_uid != null) {
            navigate("/comet-chat");
        }
        else {
            navigate("/peer-mentorship");
        }
        //

        // if (description != null) {
        //     const mentorDescObj = {
        //         "mentor_description": description
        //     }
        //     UpdateUserDetails(mentorDescObj).then((response) => {
        //         console.log("response", response);
        //         if (response.status == 200) {
        //             navigate("/comet-chat");
        //         }
        //     }).catch((error) => {
        //         Swal.fire({
        //             position: "top-right",
        //             icon: "error",
        //             title: error.response.data.errors,
        //             toast: true,
        //             showConfirmButton: false,
        //             timer: 2000
        //         });
        //     })
        // } else {
        //     navigate("/comet-chat");
        // }
    }
    const homeurl = process.env.REACT_APP_WEBSITE_BASE_URL + "/home"

    const handlesubscription = () => {
        // alert("hiiii")
        localStorage.setItem("completeUrl", homeurl)

    }

    const handlechatsubscription = () => {
        navigate("/subscription")
    }

    const handleFreeTrialPage = () => {
        const loggedUserDetail = (loginUserData) ? loginUserData.data : null;
        if (loggedUserDetail && loggedUserDetail.free_trial_used) {
            navigate("/subscription-plans")
        } else {
            navigate("/subscription")
        }
    }


    return (
        <>

            <header>
                <nav className="navbar navbar-expand-lg navbar-light navbar-header bg-white pe-0 ps-0">
                    <div className="container">
                        <Link className="navbar-brand" to="/home">
                            <img src={process.env.PUBLIC_URL + "/assets/images/logo.png"} />
                        </Link>
                        <div className="d-flex gap-2">
                            <form className="d-flex  d-lg-none">
                                {loginUserData != null && loginUserData.data.is_subscription_active == false ? <button
                                    className="primary-btn-outline font_24"
                                    onClick={handleFreeTrialPage}
                                // to="/subscription"
                                >
                                    Upgrade Now
                                </button> : ""}

                            </form>
                            <button
                                className="navbar-toggler"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#navbarSupportedContent"
                                aria-controls="navbarSupportedContent"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                            >
                                <span className="navbar-toggler-icon" />
                            </button>
                        </div>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav ms-auto mb-2 mb-lg-0 set-align-items-center">
                                <li className="nav-item">
                                    <Link
                                        className="nav-link active font_18"
                                        aria-current="page"
                                        to="/resource-search"
                                    >
                                        Search
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link font_18" to="/content">
                                        Content
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link font_18" to="/community">
                                        Community
                                    </Link>
                                </li>


                                <li className="nav-item dropdown notification-dropdown">
                                    <a
                                        className="nav-link dropdown-toggle ps-2"
                                        href="#"
                                        id="navbarDropdown"
                                        role="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        <button type="button" className="btn  position-relative">
                                            <img
                                                className="height-notify"
                                                src={process.env.PUBLIC_URL + "/assets/images/icon/notification.svg"}
                                                alt="notification"
                                            />
                                            {notifacationcount && notifacationcount != 0 ?
                                                <span className="notified-circle" title="New alerts">
                                                    <span className="visually-hidden">New alerts</span>
                                                </span> : ""

                                            }


                                        </button>
                                    </a>


                                    <ul className="dropdown-menu dropdown-menu-lg-end" aria-labelledby="navbarDropdown">
                                        <li className="font_16 font_600 pt-2 pb-1 p-3">
                                            Notifications({notifacationcount ? notifacationcount : 0})
                                        </li>

                                        {notifacationdata && notifacationdata.map((item, key) => (
                                            <li key={key} onClick={() => handlenotification(item.id, item.title)}>
                                                {item.title === "Action plan item" ?
                                                    <Link to="/today-task" className="dropdown-item">
                                                        <div className="notification-remind d-flex gap-2">
                                                            <img src={process.env.PUBLIC_URL + "/assets/images/Avocado.png"} className="img-avo" alt="avocado" />
                                                            <div>
                                                                <p className="font_14 mb-1">{item.title}</p>
                                                                <p className="title">{item.message}</p>
                                                            </div>
                                                        </div>
                                                    </Link> :
                                                    item.title === "Affirmation of the day" ?
                                                        <button className="dropdown-item" onClick={() => handleAffirmation(item.id)} data-bs-toggle="modal" data-bs-target="#actiontask3">
                                                            <div className="notification-remind d-flex gap-2">
                                                                <img src={process.env.PUBLIC_URL + "/assets/images/Avocado.png"} className="img-avo" alt="avocado" />
                                                                <div>
                                                                    <p className="font_14 mb-1">{item.title}</p>
                                                                    <p className="title">{item.message}</p>
                                                                </div>
                                                            </div>
                                                        </button> :
                                                        item.title === "Action plan reminder" ?
                                                            <Link className="dropdown-item" onClick={myFunctionswiper}>
                                                                <div className="notification-remind d-flex gap-2">
                                                                    <img src={process.env.PUBLIC_URL + "/assets/images/Avocado.png"} className="img-avo" alt="avocado" />
                                                                    <div>
                                                                        <p className="font_14 mb-1">{item.title}</p>
                                                                        <p className="title">{item.message}</p>
                                                                    </div>
                                                                </div>
                                                            </Link> :
                                                            item.title === "Action plan" ?
                                                                <Link className="dropdown-item" onClick={myFunctionswiper}>
                                                                    <div className="notification-remind d-flex gap-2">
                                                                        <img src={process.env.PUBLIC_URL + "/assets/images/Avocado.png"} className="img-avo" alt="avocado" />
                                                                        <div>
                                                                            <p className="font_14 mb-1">{item.title}</p>
                                                                            <p className="title">{item.message}</p>
                                                                        </div>
                                                                    </div>
                                                                </Link> :
                                                                <button className="dropdown-item" onClick={() => handleAffirmation(item.id)}>
                                                                    <div className="notification-remind d-flex gap-2">
                                                                        <img src={process.env.PUBLIC_URL + "/assets/images/Avocado.png"} className="img-avo" alt="avocado" />
                                                                        <div>
                                                                            <p className="font_14 mb-1">{item.title}</p>
                                                                            <p className="title">{item.message}</p>
                                                                        </div>
                                                                    </div>
                                                                </button>
                                                }
                                            </li>
                                        ))}




                                    </ul>


                                </li>
                                {localStorage.getItem("islogin") == "true" ?
                                    <li className="nav-item dropdown">
                                        <a
                                            className="nav-link dropdown-toggle ps-2"
                                            href="#"
                                            id="navbarDropdownMenuLink"
                                            role="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            {/* <img
                                            className="account-dropdown"
                                            src="assets/images/icon/header-account.svg"
                                            alt=""
                                        /> */}

                                            {/* {loginUserData != null && loginUserData.data.photo != null ?
                                                <img src={loginUserData.data.photo.image} className="account-dropdown" alt="" /> :
                                                <img src={process.env.PUBLIC_URL + "/assets/images/defalt.png"} className="account-dropdown" alt="" />
                                            } */}

                                            {localStorage.getItem("photo") != undefined && localStorage.getItem("photo") != null && localStorage.getItem("photo") != "" ?
                                                <img src={localStorage.getItem("photo")} className="account-dropdown" alt="" /> :
                                                <img src={process.env.PUBLIC_URL + "/assets/images/defalt.png"} className="account-dropdown" alt="" />
                                            }
                                        </a>
                                        <ul
                                            className="dropdown-menu"
                                            aria-labelledby="navbarDropdownMenuLink"
                                        >
                                            <li>
                                                <Link className="dropdown-item" to="/account">
                                                    My Account
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="dropdown-item" to="/bookdashboard">
                                                    My Books
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="dropdown-item" to="/community-events">
                                                    My Events
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="dropdown-item" to="/healing-journey">
                                                    Healing Journey
                                                </Link>
                                            </li>
                                            <li>
                                                {loginUserData != null && loginUserData.data.is_subscription_active == true ?
                                                    <>
                                                        {localStorage.getItem("active_actionplan") == "false" ?
                                                            <Link className="dropdown-item" to="/create-actionplan">
                                                                My Action Plan
                                                            </Link> :
                                                            <Link className="dropdown-item" to="/actionplan-dashboard">
                                                                My Action Plan
                                                            </Link>}
                                                    </>
                                                    :
                                                    <Link className="dropdown-item" to="/create-actionplan">
                                                        My Action Plan
                                                    </Link>
                                                }
                                            </li>
                                            <li>
                                                <button className="dropdown-item font_16" onClick={handlelogout}>
                                                    Logout
                                                </button>
                                            </li>
                                        </ul>
                                    </li>
                                    :
                                    <form className="d-flex ms-auto">
                                        <Link className="primary-btn font_18" to="/login">
                                            Login
                                        </Link>
                                    </form>
                                }

                                <li className="nav-item">

                                    {loginUserData != null && loginUserData.data.is_subscription_active == false ?
                                        <a className="nav-link font_18 ps-2" >
                                            <img
                                                src={process.env.PUBLIC_URL + "/assets/images/icon/chaticon.png"}
                                                className="chaticon"
                                                alt="image"
                                                onClick={handlechatsubscription}
                                            />
                                        </a> : <a className="nav-link font_18 ps-2" >
                                            <img
                                                src={process.env.PUBLIC_URL + "/assets/images/icon/chaticon.png"}
                                                className="chaticon"
                                                alt="image"
                                                onClick={handleSubmit}
                                            />
                                        </a>}


                                </li>

                                <li className="nav-item" onClick={handlesubscription}>
                                    {loginUserData != null && loginUserData.data.is_subscription_active == false ? <button
                                        className="primary-btn-outline d-none d-lg-block"
                                        onClick={handleFreeTrialPage}
                                    // to="/subscription"
                                    >
                                        Upgrade Now
                                    </button>
                                        : ""}
                                </li>
                            </ul>
                            {/* <form class="d-flex d-none d-lg-block">
                  <a class="primary-btn font_24 bg-mave" href="login.php">Login</a>
              </form> */}
                        </div>
                    </div>
                </nav>
            </header>

            {<section id="panel" className="panel" >
                <Swiper effect={'cards'} grabCursor={true} fadeEffect={{ crossFade: true }} modules={[EffectCards]}
                    className="mySwiper"
                    ref={swiperRef}  >
                    <div className="swiper mySwiper">
                        <div className="swiper-wrapper">
                            <div className="swiper-slide">
                                <SwiperSlide key={0}>
                                    <div className="notify-card">
                                        <div className="d-flex justify-content-end">
                                            <img
                                                id="close-btn"
                                                src="assets/images/icon/close-modal.png"
                                                alt=""
                                                onClick={firstclosemodal}
                                            />
                                        </div>
                                        <div className="d-flex align-items-center justify-content-center">
                                            <p className="font_28 title mb-0">Hey you!</p>
                                            <img
                                                src={process.env.PUBLIC_URL + "/assets/images/Avocado.png"}
                                                className="avacado-img"
                                                alt=""
                                            />
                                        </div>
                                        <div className="hr-line mb-4" />
                                        <p className="mb-0 text">
                                            {todaytask && todaytask.task_category}
                                        </p>
                                    </div>
                                </SwiperSlide>
                            </div>
                            <div className="swiper-slide">
                                <SwiperSlide key={1}>
                                    <div className="notify-card">
                                        <div className="d-flex align-items-center justify-content-center">
                                            <p className="font_28 title mb-0">Today</p>
                                            <img
                                                src={process.env.PUBLIC_URL + "/assets/images/Avocado.png"}
                                                className="avacado-img"
                                                alt=""
                                            />
                                        </div>
                                        <div className="hr-line mb-4"></div>
                                        <div className="d-flex gap-2">
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    defaultValue=""
                                                    id="flexCheckChecked1"
                                                    defaultChecked=""
                                                />
                                            </div>
                                            <div>
                                                <p className=" text font_18 font_700 d-flex align-items-center mb-1">
                                                    {todaytask && todaytask.task_name}
                                                </p>
                                                <p className="text  text-white">
                                                    {todaytask && todaytask.task_description}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="d-flex gap-2">
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    defaultValue=""
                                                    id="flexCheckChecked2"
                                                    defaultChecked=""

                                                />
                                            </div>
                                            <div>
                                                <p className=" text font_18 font_700 d-flex align-items-center mb-1">
                                                    Affirmation of the day
                                                </p>
                                                <p className="text  text-white">
                                                    {todaytask && todaytask.task_affirmation}
                                                </p>
                                            </div>
                                        </div>
                                        <Link onClick={handleslider}

                                            className="accept-btn"
                                        // data-bs-toggle="modal"
                                        // data-bs-target="#actiontask3"
                                        // data-bs-dismiss="modal"
                                        >
                                            {" "}
                                            I did it!
                                        </Link>
                                    </div>
                                </SwiperSlide>

                            </div>

                            {wohooSlider == 1 ?

                                <div className="swiper-slide" id="actiontask3">
                                    <SwiperSlide key={2}>
                                        <div className="notify-card bg-white">
                                            <div className="d-flex align-items-center justify-content-center">
                                                <p className="font_28 title mb-0 text-orange">Wohoo!</p>
                                                <img
                                                    src={process.env.PUBLIC_URL + "/assets/images/Avocado.png"}
                                                    className="avacado-img"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="hr-line mb-4"></div>
                                            <p className="text text-orange">
                                                Congrats on taking steps towards a better you. How do you feel
                                                after completing the task?
                                            </p>
                                            <textarea
                                                ref={textareaRef}
                                                className="form-control"
                                                id="exampleFormControlTextarea1"
                                                rows={2}
                                                placeholder="type here.."
                                                // defaultValue={""}
                                                onKeyUp={getvalue}
                                            />

                                            <a type='button'
                                                href="javascript:void(0)"
                                                ref={submitButtonRef}
                                                className="skip-btn font_700 btn-disabled"
                                                data-bs-dismiss="modal"
                                                style={{ cursor: "default", pointerEvents: "none" }}
                                                onClick={closemodal}
                                            >
                                                Submit
                                            </a>

                                            <a
                                                href="javascript:void(0)"
                                                ref={skipButtonRef}
                                                className="skip-btn font_700"
                                                data-bs-dismiss="modal"
                                                style={{ cursor: "pointer" }}
                                                onClick={closemodal}
                                            >
                                                Skip
                                            </a>


                                        </div>
                                    </SwiperSlide>

                                </div> : ""
                            }

                        </div>
                    </div>

                </Swiper>
            </section>}



            <div
                ref={divRef}
                className="modal fade"
                id="actiontask3"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog  modal-dialog-centered ">
                    <div className="modal-content modal-task-content">
                        <div className="modal-header border-0 w-100 justify-content-end">
                            <div className="d-flex justify-content-end align-items-center gap-2">
                                <button className="bg-transparent" onClick={handleDownloadClick}>
                                    <img src="assets/images/download.png" alt="image" height={26} />
                                </button>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                />
                            </div>
                        </div>
                        <div className="modal-body p-20">
                            <div className="d-flex align-items-center justify-content-center">
                                <p className="font_50 title mb-0 text-start">
                                    {affirmationdata && affirmationdata[0].affirmation}
                                </p>
                            </div>
                            <div className="d-flex justify-content-end">
                                <img
                                    src="assets/images/Avocado.png"
                                    className="avacado-img--1"
                                    alt=""
                                />
                            </div>
                            <div className="hr-line mb-4"></div>
                            <div className="d-flex justify-content-end">
                                <img
                                    src="assets/images/logo-helprr.png"
                                    className="logo-helprr"
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div
                className="modal fade"
                id="actiontask4"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog  modal-dialog-centered ">
                    <div className="modal-content modal-task-content">
                        <div className="modal-header border-0 w-100 justify-content-end">
                            <div className="d-flex justify-content-end align-items-center gap-2">
                                {/* <button className="bg-transparent" onClick={handleDownloadClick}>
                                    <img src="assets/images/download.png" alt="image" height={26} />
                                </button> */}
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                />
                            </div>
                        </div>
                        <div className="modal-body p-20">
                            <div className="d-flex align-items-center justify-content-center">
                                <p className="font_50 title mb-0 text-start">
                                    { }
                                </p>
                            </div>
                            <div className="d-flex justify-content-end">
                                <img
                                    src="assets/images/Avocado.png"
                                    className="avacado-img--1"
                                    alt=""
                                />
                            </div>
                            <div className="hr-line mb-4"></div>
                            <div className="d-flex justify-content-end">
                                <img
                                    src="assets/images/logo-helprr.png"
                                    className="logo-helprr"
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </>
    )
}

export default OuterHeader
