import axiosClient from "./apiClient";

// Auth Api 

export function registrationCheck(data) {
    return axiosClient.post('registration/check/', data);
}

export function VerifyOTP(data) {
    return axiosClient.post('registration/verify-otp/?verification_for=registration', data);
}

export function resendOTP(data) {
    return axiosClient.post(`registration/resend-otp/?resend_otp_for=${localStorage.getItem("resend_otp_for")}`, data);
}

export function forgotPasswordOTP(data) {
    return axiosClient.post('registration/verify-otp/?verification_for=forgot_password', data);
}

export function registration(data) {
    return axiosClient.post('registration/', data);
}

export function login(data) {
    return axiosClient.post('custom-auth/auth/classic/', data);
}

export function forgotPassword(data) {
    return axiosClient.post('custom-auth/users/reset-password-email/', data);
}

export function logout(data) {
    return axiosClient.delete('custom-auth/auth/logout/', data);
}

export function resetPassword(data) {
    return axiosClient.post('custom-auth/users/reset-password/', data);
}

export function applesignupuser(data) {
    return axiosClient.post('registration/social-signup-apple/', data);
}



// User Api 

export function userDetails(data) {
    return axiosClient.get(`custom-auth/users/${localStorage.getItem("uuid")}`);
}

export function changeAccount(data) {
    return axiosClient.patch(`custom-auth/users/${localStorage.getItem("uuid")}/`, data);
}

export function updatePhoto(data) {
    return axiosClient.post(`custom-auth/users/${localStorage.getItem("uuid")}/photo/add-or-update/`, data);
}

export function deletePhoto(data) {
    return axiosClient.delete(`custom-auth/users/${localStorage.getItem("uuid")}/delete-photo/`, data);
}

export function changePassword(data) {
    return axiosClient.post('custom-auth/users/change-password/', data);
}

export function HealingJourneydata(data) {
    return axiosClient.get(`custom-auth/users/healing-journey/`);
}

export function Notifications(data) {
    return axiosClient.get(`notification/notifications/`);
}

export function UpdateNotifications(data) {
    return axiosClient.patch(`notification/notifications/${data.id}`, data);
}

export function SetCometChatId(data) {
    return axiosClient.post(`custom-auth/users/set-cometchat-id/`, data);
}

export function GetListOfUIDS(data) {
    return axiosClient.get(`custom-auth/users/comet-chat-users/`, data);
}

export function MatchedMentor(data) {
    return axiosClient.get(`custom-auth/users/matched-mentors/`, data);
}

export function UpdateUserDetails(data) {
    return axiosClient.patch(`custom-auth/users/${localStorage.getItem("uuid")}/`, data);
}

// Interest Api 

export function interests(data) {
    return axiosClient.get('custom-auth/interests/', data);
}

export function addInterests(data) {
    return axiosClient.post(`custom-auth/users/${localStorage.getItem("uuid")}/add-interests/`, data);
}

// survey Api 

export function questionMentee(data) {
    return axiosClient.get(`survey/surveys/?questions_for=${localStorage.getItem("questions_for")}`, data);
}

export function submitquestion(data) {
    return axiosClient.post(`survey/submit-survey/${localStorage.getItem("questions_for")}/`, data);
}

export function submitactionplan(data) {
    return axiosClient.post(`survey/submit-survey/action-plan/`, data);
}

export function SubmitSurveyContent(data) {
    return axiosClient.post(`survey/submit-survey/content/`, data);
}

export function SubmitSurveyBookRecommendation(data, pageData) {
    var page = pageData != undefined ? `page=${parseInt(pageData.page)}` : `page=${parseInt(1)}`
    return axiosClient.post(`survey/submit-survey/book/?${page}`, data);
}

export function SetMentor(data) {
    return axiosClient.post(`survey/submit-survey/set-mentor/`, data);
}


// Action plan Api 

export function actionplantasklist(data) {
    return axiosClient.get(`action-plan/tasks/`, data);
}

export function actionplantasktoday(data) {
    return axiosClient.get(`action-plan/tasks/today/`, data);
}

export function actionplantaskdaywise(data) {
    return axiosClient.get(`action-plan/tasks/${localStorage.getItem("actionplandays")}/`, data);
}

export function actionplantaskupdate(data) {
    return axiosClient.patch(`action-plan/tasks/today/`, data);
}

export function actionplantaskupdatedaywise(data) {
    return axiosClient.patch(`action-plan/tasks/${localStorage.getItem("actionplandays")}/`, data);
}

export function actionplantaskhistorylist(data) {
    return axiosClient.get(`action-plan/action-plan-history/`, data);
}


// CMS Pages Api 

export function purpose(data) {
    return axiosClient.get(`cms/cms-pages/purpose/`, data);
}

export function aboutUs(data) {
    return axiosClient.get(`cms/cms-pages/about-us/`, data);
}

export function termsofuse(data) {
    return axiosClient.get(`cms/cms-pages/terms-of-use/`, data);
}

export function Privacy(data) {
    return axiosClient.get(`cms/cms-pages/privacy-policy/`, data);
}

export function FAQs(data) {
    return axiosClient.get(`cms/faq/`, data);
}

export function Contactus(data) {
    return axiosClient.post(`cms/contact-us/`, data);
}


//   Daily Affirmations

export function affirmationRegistraion(data) {
    return axiosClient.post(`daily-affirmation/sign-up/`, data);
}

export function todayAffirmation(data) {
    return axiosClient.get(`daily-affirmation/today/`, data);
}

export function yesterdaysAffirmation(data) {
    return axiosClient.get(`daily-affirmation/yesterday/`, data);
}

// Content Api 

export function ContentTags(data) {
    return axiosClient.get(`content/tag-list/`, data);
}

export function ContentList(data) {
    var searchdata = localStorage.getItem("user_search")
    var category = localStorage.getItem("user_category")
    var startlength = localStorage.getItem("start_length")
    var endlength = localStorage.getItem("end_length")

    return axiosClient.get(`content/contents/?search_text=${searchdata}&type__sub_category=${category}&start_length=${startlength}&end_length=${endlength}&page=${data.page}`);
}

export function ContentListdetails(data) {
    var searchdata = localStorage.getItem("user_search")
    var category = localStorage.getItem("user_category")
    var startlength = localStorage.getItem("start_length")
    var endlength = localStorage.getItem("end_length")

    return axiosClient.get(`content/contents/?search_text=${searchdata}&type__sub_category=${category}&start_length=${startlength}&end_length=${endlength}`);
}


export function ContentDefaultlist(data) {
    let page = data.page != undefined ? `page=${data.page}` : `page=1`
    return axiosClient.get(`content/contents/?${page}&page_size=12`, data);
}

export function ContentDefaultlistidvise(data) {
    return axiosClient.get(`content/contents/`, data);
}

export function ContentDetails(data) {
    return axiosClient.get(`content/contents/${data.id}`, data);
}

export function ContentRating(data) {
    console.log(data, "data");
    return axiosClient.post(`content/contents/rating/`, data);
}

export function AddRemoveSavedContent(data) {
    return axiosClient.post(`content/saved-content/update/`, data);
}

export function SavedContentVideoAudio(data) {
    return axiosClient.get(`content/saved-content/?types=audio,video`, data);
}
export function SavedContentBookArticle(data) {
    return axiosClient.get(`content/saved-content/?types=book,article`, data);
}

export function MyBooksList(data) {
    return axiosClient.get(`/content/saved-content/?types=book`, data);
}

export function MyBooksDetails(data) {
    return axiosClient.get(`content/saved-content/${data.id}`, data);
}

export function UpdateBookStatus(data) {
    return axiosClient.patch(`content/saved-content/${localStorage.getItem("content_id")}/`, data);
}

export function AddBook(data) {
    return axiosClient.post(`content/contents/add-books/`, data);
}

// Event module 

export function EventAdd(data) {
    return axiosClient.post(`event/events/`, data);
}

// export function EventDefaultList(data) {
//     return axiosClient.get(`event/events/?page=${data.page}&page_size=3`, data);
// }

export function Eventlikedata(data) {
    return axiosClient.get(`event/events/`, data);
}

export function EventList(data) {
    let eventtype = localStorage.getItem("event_type") != null ? `&type=${localStorage.getItem("event_type")}` : "&type=";
    let eventlatlong = localStorage.getItem("event_latlong") != null ? JSON.parse(localStorage.getItem("event_latlong")) : "";
    let latlong = eventlatlong != "" ? `&latitude=${eventlatlong.lat}&longitude=${eventlatlong.lng}` : ``
    let vanue = localStorage.getItem("eventvanue") != null ? `&venue=${localStorage.getItem("eventvanue")}` : ``

    return axiosClient.get(`event/events/?page=${data.page}&page_size=3${eventtype}${latlong}${vanue}`, data);
}

export function EventDetails(data) {
    return axiosClient.get(`event/events/${data.id}/`, data);
}

export function EventRegistration(data) {
    return axiosClient.post(`event/event-registration/`, data);
}

export function FeaturedEventList(data) {
    return axiosClient.get(`event/events/?featured_event=true&page=${data.page}&page_size=3`, data);
}

export function UsersEventList(data) {
    return axiosClient.get(`event/user-events/`, data);
}

export function AllUsersEventList(data) {
    return axiosClient.get(`event/user-events/`, data);
}

export function UserEventDetails(data) {
    return axiosClient.get(`event/user-events/${data.slug}/`, data);
}

export function RegisteredEventList(data) {
    return axiosClient.get(`event/event-registration/`, data);
}

export function RegisteredEventDetails(data) {
    return axiosClient.get(`event/event-registration/small-talk/`, data);
}

export function HostedEventList(data) {
    return axiosClient.get(`event/hosted-events/`, data);
}

export function HostedEventDetails(data) {
    return axiosClient.get(`hosted-events/get-together-event/`, data);
}


// Resource Module Api

export function ResourceTags(data) {
    return axiosClient.get('resource/tag-list', data);
}

export function ResourceList(data) {

    let city = localStorage.getItem("city") != undefined ? `city=${localStorage.getItem("city")}` : "city=";
    let state = localStorage.getItem("state") != undefined ? `state=${localStorage.getItem("state")}` : "state=";
    let country = localStorage.getItem("country") != undefined ? `country=${localStorage.getItem("country")}` : "country=";
    let rating = localStorage.getItem("rating") != undefined ? `rating=${parseInt(localStorage.getItem("rating"))}` : "rating=";
    // let distance = localStorage.getItem("distance") != undefined ? `distance=${parseInt(localStorage.getItem("distance"))}` : "distance=";
    // let availability = localStorage.getItem("availability") != undefined ? `availability=${localStorage.getItem("availability")}` : "availability=";
    let service = localStorage.getItem("service") != undefined ? `service=${localStorage.getItem("service")}` : "service=";
    let languages = localStorage.getItem("languages") != undefined ? `languages=${localStorage.getItem("languages")}` : "languages=";
    let cost = localStorage.getItem("cost") != undefined ? `cost=${localStorage.getItem("cost")}` : "cost=";

    return axiosClient.get(`resource/resources/?search_text=${localStorage.getItem("search")}&${city}&${state}&${country}&${rating}&${service}&${languages}&${cost}`, data);
}

export function GooglePlaceList(data) {
    return axiosClient.post('resource/resources/place-api/', data);
}

export function ResourceVote(data) {
    return axiosClient.post('resource/resources/vote/', data);
}

// forums module 

export function ForumList(data) {

    let topic = data.interest != undefined ? `topic=${data.interest}` : `topic=`
    let page = data.page != undefined ? `page=${data.page}` : `page=1`
    let search = data.serchdata != undefined ? `search=${data.serchdata}` : `search=`

    return axiosClient.get(`forum/forums/?${page}&${topic}&${search}&ordering=-id`, data);
}

export function ForumListSearch(data) {
    return axiosClient.get(`forum/forums/?page=1&search=self-help`, data);
}

export function ForumDetails(data) {
    return axiosClient.get(`forum/forums/${data.forumid}/`, data);
}

export function MyForumList(data) {
    let search = data.serchdata != undefined ? `search=${data.serchdata}` : `search=`
    return axiosClient.get(`forum/forums/my-forums/?page=${data.page}&${search}&ordering=-id`, data);
}

export function MyQuestionslist(data) {
    return axiosClient.get(`forum/forums/my-forums/`, data);
}

export function AnsweredForumList(data) {
    let search = data.serchdata != undefined ? `search=${data.serchdata}` : `search=`
    return axiosClient.get(`forum/forums/commented-forums/?page=${data.page}&${search}&ordering=-id`, data);
}

export function LikedForumList(data) {
    let search = data.serchdata != undefined ? `search=${data.serchdata}` : `search=`
    return axiosClient.get(`forum/forums/liked-forums/?page=${data.page}&${search}&ordering=-id`, data);
}

export function UserList(data) {
    return axiosClient.get(`custom-auth/users/user-list/?search=${data.username}`, data);
}

export function AddForum(data) {
    return axiosClient.post('forum/forums/', data);
}

export function AddComment(data) {
    return axiosClient.post('forum/add-comment/', data);
}

export function AddReply(data) {
    return axiosClient.post('forum/add-reply/', data);
}

export function ForumVote(data) {
    return axiosClient.post('forum/forums/vote/', data);
}

export function CommentVote(data) {
    return axiosClient.post('forum/forums/comment-vote/', data);
}

export function ReplyVote(data) {
    return axiosClient.post('forum/forums/reply-vote/', data);
}

// subscription module

export function SubscriptionPlanList(data) {
    return axiosClient.get('subscription/subscription-plan/', data);
}

export function GetPaymentLink(data) {
    return axiosClient.post('subscription/subscription-plan/get-payment-link/', data);
}

export function CancelSubscription(data) {
    return axiosClient.get('subscription/subscription-plan/cancel-subscription/', data);
}

// chatbot api

export function SendRequestFromChatbot(data) {
    return axiosClient.post('chatbot/send-request-chatbot/', data);
}

// Community api 

export function CommunityList(data) {
    return axiosClient.get('community/list/', data);
}

export function CommunityJoin(data) {
    return axiosClient.post('community/join/', data);
}

export function Leavecommunity(data) {
    const newdata = {
        is_active: data.is_active
    }

    return axiosClient.patch(`community/member/${data.community_member_id}/`, newdata);
}

// export function CommunityPosts(data) {
//     console.log('data: ', data);
//     let communityid = localStorage.getItem("Communites") != null ? `?community=${localStorage.getItem("Communites")}` : "?community=";
//     console.log('communityid: ', communityid);
//     return axiosClient.get(`community/posts/${communityid}`, data);
// }

export function CommunityPosts(data) {
    console.log('datalll: ', data);
    let page = data != undefined ? `page=${data.page}` : `page=1`
    let search = data != undefined ? `search=${data.serchdata}` : `search=`
    if (localStorage.getItem("Communites") === "default") {
        const res = axiosClient.get(`community/posts/?${page}&${search}&ordering=-id`, data)
        return res
    } else {
        let communityid = localStorage.getItem("Communites") || "";
        return axiosClient.get(`community/posts${communityid ? `?community=${communityid}&${page}&${search}&ordering=-id` : ""}`, data);
    }
}

// let page = data.page != undefined ? `page=${data.page}` : `page=1`
// let search = data.serchdata != undefined ? `search=${data.serchdata}` : `search=`

// return axiosClient.get(`forum/forums/?${page}&${topic}&${search}&ordering=-id`, data);



export function PostDetails(data) {
    return axiosClient.get(`community/posts/${data.forumid}/`, data);
}

export function AddPost(data) {
    return axiosClient.post('community/posts/', data);
}

export function AddCommunityComment(data) {
    return axiosClient.post('community/add-comment/', data);
}

export function AddCommunityReply(data) {
    return axiosClient.post('community/add-reply/', data);
}

export function PostVote(data) {
    return axiosClient.post('community/posts/vote/', data);
}

export function CommunityCommentVote(data) {
    return axiosClient.post('community/posts/comment-vote/', data);
}

export function CommunityReplyVote(data) {
    return axiosClient.post('community/posts/reply-vote/', data);
}












