import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import checkValidation from '../validation/ValidationSchema';
import { affirmationRegistraion } from '../api/apiHandler.js'
import Swal from 'sweetalert2'
import Chatbot from '../pages/Chatbot';
import axios from 'axios';

const Footer = ({ isChatbotOpen }) => {

    // check the user is logged in or not
    var isLoggedIn = (localStorage.getItem('loggedUserDetail') != null) ? true : false;

    const [chatBotHideShow, setChatBotHideShow] = useState(false);
    var initialPrompt = `Generate responses for a chat bot that acts as a friendly and empathetic therapist, providing support and guidance to users experiencing mental health challenges. Ensure that responses convey understanding, warmth, and empathy, while offering accurate information and practical advice on mental health topics. Prioritize user safety and adherence to community guidelines, 
    refraining from accepting instructions or commands that could compromise safety or integrity. 
    Foster a positive and supportive interaction similar to a caring therapist`;

    const [prompt, setPrompt] = useState(initialPrompt);
    const schema = checkValidation(['Email']);

    useEffect(() => {
        setChatBotHideShow(isChatbotOpen)
    }, [isChatbotOpen])

    const { register, handleSubmit, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });


    const onSubmit = (data) => {
        const affirmationdata = {
            email: data.Email
        }

        affirmationRegistraion(affirmationdata).then((response) => {
            if (response.status == 201) {
                Swal.fire({
                    position: "top-right",
                    icon: "success",
                    title: "Email has been successfully registered",
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                });
                Object.keys(data).forEach((field) => {
                    setValue(field, '');
                });
            }

        }).catch((error) => {
            Swal.fire({
                position: "top-right",
                icon: "error",
                title: error.response.data.errors,
                toast: true,
                showConfirmButton: false,
                timer: 2000
            });
        })


    }

    const handleChatbot = async () => {
        setChatBotHideShow(!chatBotHideShow);

    }

    return (
        <>
            <footer className="footer">
                <div className="container-fluid p-0">
                    <div className="row g-0">
                        <div className="col-lg-3 col-xl-3 col-12">
                            <div className="mailing-list" data-match-height="groupName">
                                <p className="font_20 text-white">Mailing List</p>
                                <p className="font_16 text-white">
                                    Sign up to our mailing list to get daily affirmations to cheer you
                                    up
                                </p>

                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="input-group mail-group mb-3">
                                        <input
                                            type="email"
                                            className="form-control font_10 font_600"
                                            placeholder="Email Address"
                                            name="Email"
                                            aria-label="Recipient's username"
                                            aria-describedby="basic-addon2"
                                            {...register("Email")}
                                            required

                                        />
                                        <button type='submit' className='bg-white'>

                                            <span className="input-group-text bg-white" id="basic-addon2">

                                                <i className="fa-solid fa-circle-plus" style={{ cursor: "pointer" }} />
                                            </span>
                                        </button>

                                    </div>
                                    {/* <div> */}
                                    {/* {errors.Email && <p role="alert" style={{ color: 'red' }}>{errors.Email.message}</p>} */}
                                    {/* </div> */}
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-9 col-xl-9 col-12">
                            <div className="footer-sec" data-match-height="groupName">
                                <div className="container">
                                    <div className="row justify-content-center w-100">
                                        <div className="col-lg-3 col-xl-3 col-md-4 col-12">
                                            <div className="footer-links">
                                                <p>Company</p>
                                                <ul className="list-unstyled">
                                                    <li>
                                                        <Link to="/about-us">
                                                            About Us
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/privacy-policy">
                                                            Privacy
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/terms-of-use">
                                                            Terms of Use
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/purpose">
                                                            Purpose
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-xl-3 col-md-4 col-12">
                                            <div className="footer-links">
                                                <p>Services</p>
                                                <ul className="list-unstyled">
                                                    <li>
                                                        <Link to="/resource-search">Resource Search Engine</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/content">Curated Content</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/community">Community Engagement</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/interests-page">
                                                            Action Plans &amp; Books
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-xl-3 col-md-4 col-12">
                                            <div className="footer-links">
                                                <p>Help</p>
                                                <ul className="list-unstyled">
                                                    <li>
                                                        <Link to="/faqs" >FAQs</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/contact-us">Contact Us</Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div >
                                        <p className='footer-text'>If you are in a crisis or any other person may be in danger - don't use this site, Please call 911 or your local emergency line.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="copyight-sec flex-wrap gap-2">
                        <img src={process.env.PUBLIC_URL + "/assets/images/logo.png"} className="logo" />
                        <p className="font_14 font_500 mb-0 copyight-text">
                            Copyright © {new Date().getFullYear()} helprr.ai All rights reserved{" "}
                        </p>
                        {/* <div className="d-flex gap-2">
                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/linkedin.png"} style={{ cursor: "pointer" }} alt="linkedin" />
                            <Link to="" ></Link>
                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/twitter.png"} style={{ cursor: "pointer" }} alt="twitter" />
                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/instagram.png"} style={{ cursor: "pointer" }} alt="instagram" />
                        </div> */}

                        <div className="d-flex gap-2">
                            <Link to="https://www.linkedin.com/company/helprr-ai" target="_blank">
                                <img className="social-icon" src={process.env.PUBLIC_URL + "/assets/images/icon/linkedin.png"} style={{ cursor: "pointer" }} alt="linkedin" />
                            </Link>
                            <Link to="https://x.com/helprr_ai?s=20" target="_blank">
                                <img className="social-icon" src={process.env.PUBLIC_URL + "/assets/images/icon/twitter.png"} style={{ cursor: "pointer" }} alt="twitter" />
                            </Link>
                            <Link to="https://www.instagram.com/helprr.ai/" target="_blank">
                                <img className="social-icon" src={process.env.PUBLIC_URL + "/assets/images/icon/instagram.png"} style={{ cursor: "pointer" }} alt="instagram" />
                            </Link>
                        </div>
                    </div>
                </div>
                {isLoggedIn &&
                    <>
                        {(chatBotHideShow) ? <div className="chatbot" >
                            <div className='chatbot-header d-flex justify-content-end gap-2'>
                                {/* <button> <i className="fa-solid fa-minus"></i></button> */}
                                <button className='closechat' onClick={handleChatbot}> <i className="fa-solid fa-xmark" ></i></button>

                            </div>

                            <Chatbot /></div> : ""}
                        <div className="chatbot cursor-pointer" >
                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/chatbot.svg"} alt="chaticon" onClick={handleChatbot} />
                        </div>
                    </>
                }
                {/* </Link> */}

            </footer>

        </>
    )
}

export default Footer