import { initializeApp } from "firebase/app";
import { FacebookAuthProvider, getAuth, GoogleAuthProvider, OAuthProvider } from "firebase/auth"

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_WEB_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_PROJECT_NUMBER,
    appId: process.env.REACT_APP_FIREBASE_APP_ID
};


initializeApp(firebaseConfig);
export const auth = getAuth()
export const google = new GoogleAuthProvider()
google.addScope('email')
export const facebook = new FacebookAuthProvider()
facebook.addScope('email')
export const apple = new OAuthProvider('apple.com');
apple.addScope('email');
apple.addScope('name');