// import React, { useState, useEffect } from 'react'
// import OuterHeader from '../components/OuterHeader'
// import Footer from '../components/Footer'
// import { Link, useNavigate } from 'react-router-dom'
// import { actionplantasktoday, actionplantaskupdate, UpdateNotifications } from '../api/apiHandler.js'
// import Swal from 'sweetalert2'



// const ActionPlanScreenDaywise = () => {
//     const navigate = useNavigate();
//     const [data, setData] = useState()
//     const [showMore, setShowMore] = useState(false);

//     useEffect(() => {
//         actionplantasktoday().then((response) => {
//             if (response.status == 200) {
//                 setData(response.data);
//             }
//         }).catch((error) => {
//             Swal.fire({
//                 position: "top-right",
//                 icon: "error",
//                 title: error.response.data.errors,
//                 toast: true,
//                 showConfirmButton: false,
//                 timer: 2000
//             });
//         })

//     }, [])

//     const handleaccept = () => {
//         const acceptdata = {
//             "is_accepted": true
//         }
//         actionplantaskupdate(acceptdata).then((response) => {
//             if (response.status === 200) {
//                 let notificationid = localStorage.getItem("notificationid")

//                 UpdateNotifications({ "id": notificationid, "is_viewed": true }).then((response) => {
//                     if (response.status == 200) {
//                         localStorage.removeItem("notificationid")
//                         navigate('/accept-challenge');
//                     }

//                 }).catch((error) => {
//                     console.log(error);
//                 })

//             }
//         }).catch((error) => {
//             Swal.fire({
//                 position: "top-right",
//                 icon: "error",
//                 title: error.response.data.errors,
//                 toast: true,
//                 showConfirmButton: false,
//                 timer: 2000
//             });

//         })

//     }

//     const handleShowMore = () => {
//         setShowMore(!showMore);
//     };


//     // const truncatedDescription = data && data.task_description.substring(0, 200);
//     // const displayDescription = showMore ? data && data.task_description : truncatedDescription;
//     // const buttonText = showMore ? 'Show Less' : 'Show More';

//     const truncatedDescription = data && data.task_description.substring(0, 200);
//     const displayDescription = showMore ? data && data.task_description : truncatedDescription;
//     const buttonText = showMore ? 'Show Less' : 'Show More';

//     const cancelchallange = () => {
//         navigate("/cancel-challenge")
//     }

//     return (
//         <>
//             <main className="helprr-main">
//                 <OuterHeader />
//                 <section className="bg-mave">
//                     <div className="banner-sec mt-0 bg-mave">
//                         <div className="container">
//                             <div className="banner-profile-sec">
//                                 <div className="banner-profile-img">
//                                     <img
//                                         src="assets/images/icon/Rectangle.png"
//                                         className="object-fit-contain"
//                                         alt="profile-pic"
//                                     />
//                                 </div>
//                                 <div className="banner-profile-img">
//                                     <img
//                                         src="assets/images/icon/Rectangle.png"
//                                         className="object-fit-contain"
//                                         alt="profile-pic"
//                                     />
//                                 </div>
//                                 <div className="banner-text">
//                                     <p className="mb-2 font_30 text-white font_700">
//                                         {data && data.task_name}
//                                     </p>
//                                     <p className="day-btn mb-0">Day {data && data.day}</p>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="back-section pb-5">
//                         <div className="container ">
//                             <div className="pt-5">

//                                 {/* <div>
//                                     <p className={`font_18 text-darkgray ${showMore ? '' : 'longtext'}`}>
//                                         {displayDescription}
//                                     </p>
//                                     {!showMore && (
//                                         <p className="font_18 text-orange text-decoration-underline" onClick={handleShowMore} style={{ cursor: 'pointer' }}>
//                                             {buttonText}
//                                         </p>
//                                     )}
//                                 </div> */}
//                                 <div>
//                                     <p className={`font_18 text-darkgray ${!showMore && displayDescription && displayDescription.length > 200 ? 'longtext' : ''}`}>
//                                         {displayDescription}
//                                     </p>
//                                     {!showMore && displayDescription && displayDescription.length > 200 && (
//                                         <p className="font_18 text-orange text-decoration-underline" onClick={handleShowMore} style={{ cursor: 'pointer' }}>
//                                             {buttonText}
//                                         </p>
//                                     )}
//                                 </div>

//                                 <div className="row mt-5">
//                                     <div className="col-lg-3 col-md-6 col-6">
//                                         <button className="edit-btn font_16" onClick={handleaccept}>
//                                             Accept
//                                         </button>
//                                     </div>
//                                     <div className="col-lg-3 col-md-6 col-6">
//                                         <button className="goback-btn font_16" onClick={cancelchallange}>
//                                             Cancel
//                                         </button>
//                                     </div>
//                                 </div>
//                                 <div className="row">
//                                     <div className="col-lg-6">
//                                         <div className="your-actions">
//                                             <div className="icon">
//                                                 <img src={process.env.PUBLIC_URL + "/assets/images/Ellipse-1.png"} alt="" />
//                                             </div>
//                                             <div>
//                                                 <p className="mb-0 font_20 text-darkgray font_500">
//                                                     Your current actions
//                                                 </p>
//                                                 <p className="mb-0 font_18 text-gray font_500">
//                                                     {data && data.challenges} Challenges • {data && data.completed} Completed
//                                                 </p>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </section>
//                 <Footer />
//             </main>

//         </>
//     )
// }

// export default ActionPlanScreenDaywise

import React, { useState, useEffect } from 'react';
import OuterHeader from '../components/OuterHeader';
import Footer from '../components/Footer';
import { Link, useNavigate } from 'react-router-dom';
import { actionplantasktoday, actionplantaskupdate, UpdateNotifications } from '../api/apiHandler.js';
import Swal from 'sweetalert2';

const ActionPlanScreenDaywise = () => {
    const navigate = useNavigate();
    const [data, setData] = useState();
    const [showMore, setShowMore] = useState(false);
    const [accepting, setAccepting] = useState(false);

    useEffect(() => {
        actionplantasktoday().then((response) => {
            if (response.status === 200) {
                setData(response.data);
            }
        }).catch((error) => {
            Swal.fire({
                position: "top-right",
                icon: "error",
                title: error.response.data.errors,
                toast: true,
                showConfirmButton: false,
                timer: 2000
            });
        });
    }, []);

    const handleaccept = () => {
        setAccepting(true)
        const acceptdata = {
            "is_accepted": true
        };
        actionplantaskupdate(acceptdata).then((response) => {
            if (response.status === 200) {
                let notificationid = localStorage.getItem("notificationid");

                UpdateNotifications({ "id": notificationid, "is_viewed": true }).then((response) => {
                    if (response.status === 200) {
                        localStorage.removeItem("notificationid");
                        navigate('/accept-challenge');
                    }

                }).catch((error) => {
                    console.log(error);
                });

            }
        }).catch((error) => {
            Swal.fire({
                position: "top-right",
                icon: "error",
                title: error.response.data.errors,
                toast: true,
                showConfirmButton: false,
                timer: 2000
            }).then(() => {
                setAccepting(false); // Re-enable the button if there's an error
            });

        });

    };

    const handleShowMore = () => {
        setShowMore(!showMore);
    };

    const cancelchallange = () => {
        navigate("/cancel-challenge");
    };

    const truncatedDescription = data && data.task_description.substring(0, 200);
    const displayDescription = showMore ? data && data.task_description : truncatedDescription;
    const buttonText = showMore ? 'Show Less' : 'Show More';

    return (
        <>
            <main className="helprr-main">
                <OuterHeader />
                <section className="bg-mave">
                    <div className="banner-sec mt-0 bg-mave">
                        <div className="container">
                            <div className="banner-profile-sec">
                                <div className="banner-profile-img">
                                    <img
                                        src="assets/images/icon/Rectangle.png"
                                        className="object-fit-contain"
                                        alt="profile-pic"
                                    />
                                </div>
                                <div className="banner-profile-img">
                                    <img
                                        src="assets/images/icon/Rectangle.png"
                                        className="object-fit-contain"
                                        alt="profile-pic"
                                    />
                                </div>
                                <div className="banner-text">
                                    <p className="mb-2 font_30 text-white font_700">
                                        {data && data.task_name}
                                    </p>
                                    <p className="day-btn mb-0">Day {data && data.day}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="back-section pb-5">
                        <div className="container ">
                            <div className="pt-5">
                                <div>
                                    <p className={`font_18 text-darkgray ${!showMore && displayDescription && displayDescription.length > 200 ? 'longtext' : ''}`}>
                                        {displayDescription}
                                    </p>
                                    {!showMore && displayDescription && displayDescription.length > 200 && (
                                        <p className="font_18 text-orange text-decoration-underline" onClick={handleShowMore} style={{ cursor: 'pointer' }}>
                                            {buttonText}
                                        </p>
                                    )}
                                </div>

                                <div className="row mt-5">
                                    <div className="col-lg-3 col-md-6 col-6">
                                        <button className="edit-btn font_16" onClick={handleaccept} disabled={accepting}>
                                            {accepting ? 'Accept' : 'Accept'}
                                        </button>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-6">
                                        <button className="goback-btn font_16" onClick={cancelchallange}>
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="your-actions">
                                            <div className="icon">
                                                <img src={process.env.PUBLIC_URL + "/assets/images/Ellipse-1.png"} alt="" />
                                            </div>
                                            <div>
                                                <p className="mb-0 font_20 text-darkgray font_500">
                                                    Your current actions
                                                </p>
                                                <p className="mb-0 font_18 text-gray font_500">
                                                    {data && data.challenges} Challenges • {data && data.completed} Completed
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </main>
        </>
    );
};

export default ActionPlanScreenDaywise;
