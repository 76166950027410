import React, { useState, useEffect } from 'react'
import Footer from '../components/Footer'
import OuterHeader from '../components/OuterHeader'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import Select2 from '../pages/Select2';
import '../css2/select2.css'
import Swal from 'sweetalert2'
import { ResourceTags, userDetails } from '../api/apiHandler.js';
import PlacesAutocomplete, { geocodeByAddress, getLatLng, } from "react-places-autocomplete";
import { useDispatch, useSelector } from 'react-redux';
import { setUserDetails } from '../store/slice/userDetail';
import { submitactionplan } from '../api/apiHandler.js';
import Header from '../components/Header.js';
import Loader from '../components/Loader';
import { setLoading } from '../store/slice/loaderSlice';
const Home = () => {

    const isLoading = useSelector((state) => state.loader);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [state, setState] = useState(false);
    const [statecity, setStateCity] = useState(false);
    const [searchQuery, setSearchQuery] = useState(localStorage.getItem("search") != null ? localStorage.getItem("search") : "");
    const tagarray = [
        { "id": 1, "tag": "Depression and Anxiety" },
        { "id": 2, "tag": "Stress Management" },
        { "id": 3, "tag": "Suicide Prevention" },
        { "id": 4, "tag": "Addiction and Substance Abuse" },
        { "id": 5, "tag": "Youth Mental Health" },
        { "id": 6, "tag": "Trauma and PTSD" }
    ]
    const [tags, setTags] = useState(tagarray);
    const [address, setAddress] = useState(localStorage.getItem("full_address") != null ? localStorage.getItem("full_address") : "");
    const [handleAddress, setHandleAddress] = useState(false);
    const [loginUserData, setLoginUserData] = useState(JSON.parse(localStorage.getItem("loggedUserDetail")));

    const handleSelect = async (value) => {
        try {
            const results = await geocodeByAddress(value);
            const ll = await getLatLng(results[0]);

            // Extract country, state, and city from the address components
            const addressComponents = results[0].address_components || [];
            let country, state, city;

            for (const component of addressComponents) {
                if (component.types.includes('country')) {
                    country = component.long_name;
                } else if (component.types.includes('administrative_area_level_1')) {
                    state = component.long_name;
                } else if (component.types.includes('locality')) {
                    city = component.long_name;
                }
            }

            // You can also store these values in localStorage if needed
            if (country != undefined) {
                localStorage.setItem('country', country);
                localStorage.removeItem('state');
                localStorage.removeItem('city');
            } else {
                localStorage.removeItem('country');
            }

            if (state != undefined) {
                localStorage.setItem('state', state);
                localStorage.removeItem('city');
            } else {
                localStorage.removeItem('state');
            }

            if (city != undefined) {
                localStorage.setItem('city', city);
            } else {
                localStorage.removeItem('city');
            }

            setAddress(value);
            localStorage.setItem("full_address", value)
            setHandleAddress(true);
            // setCoordinates(ll);
        } catch (error) {
            console.error('Error handling selection:', error);
        }
    };

    const handleSearchChange = (e) => {
        localStorage.setItem("search", e.target.value)
        setSearchQuery(e.target.value);
    };

    const handleInputValue = (e) => {
        localStorage.setItem("search", e.target.innerText)
        setSearchQuery(e.target.innerText);
    };

    const filteredItems = tags && tags.filter(item =>
        item.tag.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const SearchData = async() => {
        const results = await geocodeByAddress(address);
        
        // Extract country, state, and city from the address components
        const addressComponents = results[0].address_components || [];
        let country, state, city;

        for (const component of addressComponents) {
            if (component.types.includes('country')) {
                country = component.long_name;
            } else if (component.types.includes('administrative_area_level_1')) {
                state = component.long_name;
            } else if (component.types.includes('locality')) {
                city = component.long_name;
            }
        }
        
        // You can also store these values in localStorage if needed
        if (country != undefined) {
            localStorage.setItem('country', country);
            localStorage.removeItem('state');
            localStorage.removeItem('city');
        } else {
            localStorage.removeItem('country');
        }

        if (state != undefined) {
            localStorage.setItem('state', state);
            localStorage.removeItem('city');
        } else {
            localStorage.removeItem('state');
        }

        if (city != undefined) {
            localStorage.setItem('city', city);
        } else {
            localStorage.removeItem('city');
        }

        if (searchQuery != "" && address != "") {
            localStorage.setItem("full_address", address)
            localStorage.removeItem("rating")
            localStorage.removeItem("distance")
            localStorage.removeItem("service")
            localStorage.removeItem("languages")
            localStorage.removeItem("cost")
            // if (handleAddress == false) {
            //     console.log("handle false");
            //     var split_city = address.split(",")
            //     localStorage.setItem("city", split_city[0])
            //     localStorage.removeItem("country")
            //     localStorage.removeItem("state")
            // } else {
            //     setHandleAddress(false);
            // }
            navigate("/resource")
        } else {
            Swal.fire({
                position: "top-right",
                icon: "warning",
                title: "Please select a value",
                toast: true,
                showConfirmButton: false,
                timer: 2000
            });
        }

    }

    // useEffect(() => {

    //     ResourceTags({}).then((response) => {
    //         if (searchQuery !== '') {

    //             setTags(response.data.tags);
    //         } else {

    //             setTags(tagarray);
    //         }
    //     }).catch((error) => {
    //         console.log(error, 'error');
    //     });

    // }, [searchQuery]);

    useEffect(() => {
        const disableBackButton = (e) => {
            e.preventDefault();
            window.history.forward(); // Forward the user when they try to go back
        };

        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', disableBackButton); // Listen for back button press

        return () => {
            window.removeEventListener('popstate', disableBackButton); // Cleanup the event listener when the component unmounts
        };
    }, []);


    // useEffect(() => {
    //     actionplan()
    // }, []);

    // const userData = () => {

    //     userDetails().then((response) => {
    //         if (response.status === 200) {
    //             localStorage.setItem("loggedUserDetail", JSON.stringify(response.data));
    //             localStorage.setItem("active_actionplan", response.data.has_action_plan);
    //             dispatch(setUserDetails(JSON.stringify(response)));
    //             setLoginUserData(response.data);

    //         } else {
    //             console.log("userdetails failed");
    //         }
    //     }).catch((error) => {
    //         console.log(error);
    //     })
    // }

    // const actionplan = () => {
    //     if (localStorage.getItem("actionplansurvay") != undefined) {
    //         const survaydata = JSON.parse(localStorage.getItem("actionplansurvay"))
    //         console.log('survaydata: ', survaydata);
    //         submitactionplan(survaydata).then((response) => {
    //             if (response.status === 201) {
    //                 // localStorage.setItem("active_actionplan", true);
    //                 localStorage.removeItem("actionplansurvay")
    //                 userData();

    //                 // localStorage.removeItem("questions_for")
    //             }
    //         }).catch((error) => {
    //             Swal.fire({
    //                 position: "top-right",
    //                 icon: "error",
    //                 title: error.response.data.errors,
    //                 toast: true,
    //                 showConfirmButton: false,
    //                 timer: 2000
    //             });
    //         })

    //     }
    // }


    
    return (
        <>
        
            <main className="helprr-main">
                {localStorage.getItem("token") ?
                    <OuterHeader /> :
                    <Header />
                }
                <section className="login-sec pb-0">
                    <div className="container" onClick={() => {
                        setStateCity(false)
                        setState(false)
                    }}>
                        <div className="row">
                            <div className="col-lg-6 col-xl-6 col-md-6 col-12">
                                <div className="position-relative w-max-content">
                                    <div className="card-comment">
                                        <p className="font_50 font_600">Help Made Easy &amp; Fast</p>
                                        <p className="font_25">
                                            At helprr.ai our goal is to provide you the resources in the
                                            quickest and easiest way.
                                        </p>
                                    </div>
                                    <div className="vector-side">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/icon/vector-side-tri.svg"} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-6 col-md-6 col-12">
                                <div className="d-flex justify-content-center">
                                    <div className="home-page-vector">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/vector--1.svg"} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <div className="section-search">
                            <div className="row justify-content-center">
                                <div className="col-lg-7 col-xl-7 col-12">
                                    <p className="font_32 pt-4">
                                        Finding help should never be overwhelming and we get that.
                                    </p>

                                    <div className="mb-4" onClick={() => {
                                        setState(!state)
                                        setStateCity(false)
                                    }}>



                                        <div className="position-relative">
                                            <div className="input-group border-0 custom-dropdown mb-3">
                                                <input
                                                    type="text"
                                                    className="form-control border-0"
                                                    id="toggle"
                                                    placeholder="What do you need help with?"
                                                    aria-label="Recipient's username"
                                                    aria-describedby="basic-addon2"
                                                    autoComplete="off"
                                                    maxLength={64}
                                                    value={searchQuery}
                                                    onChange={handleSearchChange}
                                                />
                                                <span
                                                    className="input-group-text arrow-icon border-0 p-2"
                                                    id="basic-addon2"
                                                >
                                                    <img
                                                        src="assets/images/icon/dropdown-icon.svg"
                                                        alt="image"
                                                        id="arrow-image"
                                                        className={state == false ? "" : "rotated-image"}
                                                    />
                                                </span>
                                            </div>
                                            <div id="content" className={state == false ? "is-hidden drop-sec" : "drop-sec"}>
                                                <div className="row">
                                                    <div className="col-lg-12 col-xl-12 col-12">

                                                        <ul className="list-unstyled">
                                                            {filteredItems && filteredItems.map((item, index) => (
                                                                <li key={index} onClick={(e) => handleInputValue(e)} >{item.tag}</li>
                                                            ))}

                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>

                                    <div className="mb-4" onClick={() => {
                                        setStateCity(!statecity)
                                        setState(false)
                                    }}>
                                        {/* <PlacesAutocomplete
                                            value={address}
                                            onChange={setAddress}
                                            onSelect={handleSelect}
                                            googleCallbackName="initPlaces"
                                        >
                                            {({
                                                getInputProps,
                                                suggestions,
                                                getSuggestionItemProps,
                                                loading,
                                            }) => (<>
                                                <div className="mb-4" onClick={() => setStateCity(!statecity)}>
                                                    <div className="position-relative">
                                                        <div className="input-group border-0 custom-dropdown mb-3">
                                                            <input
                                                                className="form-control border-0"
                                                                id="address"
                                                                placeholder="Where Are You Looking? (City or Area)"
                                                                defaultValue={address}
                                                                {...getInputProps()}
                                                            />
                                                            <span
                                                                className="input-group-text arrow-icon border-0 p-2"
                                                                id="basic-addon2"
                                                            >
                                                                <img
                                                                    src="assets/images/icon/dropdown-icon.svg"
                                                                    alt=""
                                                                    id="arrow-image"
                                                                    className={statecity == false ? "" : "rotated-image"}
                                                                />
                                                            </span>
                                                        </div>
                                                        <div id="content" className={statecity == false ? "is-hidden drop-sec" : "drop-sec"}>
                                                            <div className="row">
                                                                <div className="col-lg-12 col-xl-12 col-12">

                                                                    <ul className="list-unstyled">
                                                                        {suggestions.map((suggestion, idx) => {
                                                                            const style = {
                                                                                backgroundColor: suggestion.active
                                                                                    ? "#f1f0ff"
                                                                                    : "#fff",
                                                                                textAlign: "left",
                                                                            };

                                                                            return (
                                                                                <li key={idx}
                                                                                    className="form-control"
                                                                                    {...getSuggestionItemProps(suggestion, {
                                                                                        style,
                                                                                    })}
                                                                                >
                                                                                    {suggestion.description}
                                                                                </li>
                                                                            );
                                                                        })}
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            )}
                                        </PlacesAutocomplete> */}

                                        <PlacesAutocomplete
                                            value={address}
                                            onChange={setAddress}
                                            onSelect={handleSelect}
                                            googleCallbackName="initPlaces"
                                        >
                                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                <div className="mb-4" onClick={() => setStateCity(!statecity)}>
                                                    <div className="position-relative">
                                                        <div className="input-group border-0 custom-dropdown mb-3">
                                                            <input
                                                                className="form-control border-0"
                                                                id="address"
                                                                placeholder="Where Are You Looking? (City or Area)"
                                                                {...getInputProps()}
                                                            />
                                                            <span
                                                                className="input-group-text arrow-icon border-0 p-2"
                                                                id="basic-addon2"
                                                            >
                                                                <img
                                                                    src="assets/images/icon/dropdown-icon.svg"
                                                                    alt=""
                                                                    id="arrow-image"
                                                                    className={statecity ? "rotated-image" : ""}
                                                                />
                                                            </span>
                                                        </div>
                                                        <div id="content" className={statecity ? "drop-sec" : "is-hidden drop-sec"}>
                                                            <div className="row">
                                                                <div className="col-lg-12 col-xl-12 col-12">
                                                                    <ul className="list-unstyled">
                                                                        {suggestions.map((suggestion, idx) => {
                                                                            const style = {
                                                                                backgroundColor: suggestion.active
                                                                                    ? "#f1f0ff"
                                                                                    : "#fff",
                                                                                textAlign: "left",
                                                                            };

                                                                            return (
                                                                                <li key={idx}
                                                                                    className="form-control"
                                                                                    {...getSuggestionItemProps(suggestion, {
                                                                                        style,
                                                                                    })}
                                                                                >
                                                                                    {suggestion.description}
                                                                                </li>
                                                                            );
                                                                        })}
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </PlacesAutocomplete>
                                    </div>

                                    <button className="search-btn font_20 mt-4" onClick={() => SearchData()}>
                                        Search
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="welcome-sec" onClick={() => {
                    setStateCity(false)
                    setState(false)
                }}>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-6 col-xl-6 col-12">
                                <p className="font_30 text-center title">
                                    Welcome to helprr.ai your comprehensive resource hub for mental
                                    health, domestic violence support, disability resources, and more.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container" onClick={() => {
                        setStateCity(false)
                        setState(false)
                    }}>
                        <div className="revolution-section">
                            <div className="row">
                                <div className="col-lg-6 col-xl-6 col-12">
                                    <p className="font_50 font_600">
                                        Revolutionizing support one click at a time
                                    </p>
                                    <div className="vector2-img">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/vector--2.svg"} alt="" />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xl-6 col-12">
                                    <ul>
                                        <li className="font_28">
                                            Experience a revolutionary approach to finding help.
                                        </li>
                                        <li className="font_28">
                                            With helprr.ai, we've made it our mission to simplify the
                                            process and make support easily accessible.
                                        </li>
                                        <li className="font_28">
                                            Discover why we're the best way to find the help you need
                                        </li>
                                    </ul>
                                    <div className="d-flex justify-content-center">
                                        <Link to="/search-more" className="learn-more font_20">
                                            Learn More
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content-sec">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-xl-6 col-md-7 col-12">
                                <div className="postion-relative">
                                    <div className="card-search finds">
                                        <p className="font_50 font_600 text-white">Search</p>
                                        <p className="font_24 text-white">
                                            Find the help you need, when you need it, with our extensive
                                            database of trusted resources.
                                        </p>
                                        <Link to="/resource-search" className="absolute-arrow">
                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/arrow-right.png"} />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-end">
                            <div className="col-lg-6 col-xl-6 col-md-7 col-12">
                                <div className="postion-relative">
                                    <div className="card-search community">
                                        <p className="font_50 font_600 ">Community</p>
                                        <p className="font_24 ">
                                            Join a vibrant community of like-minded individuals who share
                                            similar experiences and goals.
                                        </p>
                                        <Link to="/community" className="absolute-arrow">
                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/arrow-right.png"} />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-xl-6 col-md-7 col-12">
                                <div className="postion-relative">
                                    <div className="card-search content">
                                        <p className="font_50 font_600 text-white">Content</p>
                                        <p className="font_24 text-white">
                                            Browse our collection of motivational videos, inspiring stories
                                            of survivors, and empowering content.
                                        </p>
                                        <Link to="/content" className="absolute-arrow">
                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/arrow-right.png"} />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-end">
                            <div className="col-lg-6 col-xl-6 col-md-7 col-12">
                                <div className="postion-relative">
                                    <div className="card-search community content bg-orange" style={{ backgroundColor: '#f2875f' }}>
                                        <p className="font_50 font_600 ">Books &amp; Action Plan</p>
                                        <p className="font_24 ">
                                            Get personalized book recommendations tailored to your needs or start
                                            a 30 day self care challenge.
                                        </p>
                                        <Link
                                            to="/interests-page"
                                            className="absolute-arrow"
                                        >
                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/arrow-right.png"} />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>

                <Footer />
            </main>



        </>
    )
}

export default Home