import React, { useState, useEffect } from 'react'
import Footer from '../components/Footer'
import OuterHeader from '../components/OuterHeader'
import Select2 from '../pages/Select2';
import '../css2/select2.css'
import ScrollToTop from '../components/ScrollToTop';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'
import { ContentTags, ContentDetails, ContentRating, ContentListdetails, ContentDefaultlistidvise } from '../api/apiHandler.js'
import { param } from 'jquery';
import { useDispatch, useSelector } from 'react-redux';


const ResourceAudio = () => {

    const navigate = useNavigate()

    const params = useParams();
    const [data, setdata] = useState()
    console.log('data: ', data);
    const [scroll, setScroll] = useState(true)
    const [state, setState] = useState(false);
    const [selectedValue, setSelectedValue] = useState(null);
    const [userrating, setuserRating] = useState()
    const [query, setQuery] = useState(null)
    const [rating, setRating] = useState()
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [showMore, setShowMore] = useState(false);
    const [savedata, setSaveddata] = useState()
    const [nocontent, setNocontent] = useState()
    const [myuserrating, setmyuserrating] = useState()
    const UserData = useSelector((state) => JSON.parse(state.userdata))
    const [defaultdata, setDefaultData] = useState([]);
    const [handledefaultdata, sethandleDefaultData] = useState(false);




    useEffect(() => {
        getaudiodetails(params.id)

    }, [])

    const getaudiodetails = (id) => {
        setScroll(true)
        ContentDetails({ id: id }).then((response) => {
            setdata(response.data)
            setRating(parseInt(response.data.average_rating))
            setmyuserrating(response.data.user_rating)

        }).catch((error) => {
            console.log(error);
        })

        ContentListdetails().then((response) => {
            let filterdata = response.data.results.filter(element => {
                return element.id != id
            });
            filterdata = filterdata.slice(0, 6);
            if (response.status == 200) {
                setSaveddata(filterdata)

            } else if (response.status == 204) {
                setNocontent(204)
            }
        }).catch((error) => {
            console.log(error);
        })

    }



    const handlerating = () => {

        // if (isSubmitted) {
        //     return;
        // }

        const ratingdata = {
            content: params.id,
            rating: myuserrating
        }
        ContentRating(ratingdata).then((response) => {
            console.log(response.data);
            if (response.status == 200) {
                getaudiodetails(params.id)
                // setIsSubmitted(true);
                Swal.fire({
                    position: "top-right",
                    icon: "success",
                    title: "Thank you for your review",
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                });
            }
        }).catch((error) => {
            // dispatch(setLoading(false))
            console.log(error, 'error');
        })

    }

    const handleShowMore = () => {
        setScroll(false)
        setShowMore(!showMore);
    };

    // const truncatedDescription = data && data.description.substring(0, 200);
    // const displayDescription = showMore ? data && data.description : truncatedDescription;
    // const buttonText = showMore ? 'Show Less' : 'Load More';

    const displayDescription = data && data.description;
    const buttonText = showMore ? 'Show Less' : 'Load More';

    const handleCreateEventClick = () => {
        Swal.fire({
            position: "top-right",
            icon: "error",
            title: "Subscription required",
            toast: true,
            showConfirmButton: false,
            timer: 2000
        });
    }


    useEffect(() => {
        ContentDefaultlistidvise(data).then((response) => {
            if (response.status == 200) {
                setDefaultData(response.data.results)
                if (localStorage.getItem("user_category") != undefined) {
                    sethandleDefaultData(true)
                }
            }

        }).catch((error) => {
            console.log(error);
        })
    }, [])

    return (
        <>
            {scroll ? <ScrollToTop /> : null}
            <main className="helprr-main">
                <OuterHeader />
                <div className="container">
                    <p className="font_64 font_400">Contents</p>
                    <p className="font_40">{data && data.title}</p>
                    <div className="d-flex align-items-center flex-wrap">
                        <div className="d-flex gap-3 mb-2 me-5">
                            {rating && Math.floor(rating) == 1 ?
                                <>
                                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-colored.svg"} id='1' alt="" />
                                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-deselected.svg"} alt="" />
                                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-deselected.svg"} alt="" />
                                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-deselected.svg"} alt="" />
                                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-deselected.svg"} alt="" />
                                </> : Math.floor(rating) == 2 ?
                                    <>
                                        <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-colored.svg"} id='1' alt="" />
                                        <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-colored.svg"} alt="" />
                                        <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-deselected.svg"} alt="" />
                                        <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-deselected.svg"} alt="" />
                                        <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-deselected.svg"} alt="" />
                                    </> : Math.floor(rating) == 3 ?
                                        <>
                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-colored.svg"} id='1' alt="" />
                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-colored.svg"} alt="" />
                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-colored.svg"} alt="" />
                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-deselected.svg"} alt="" />
                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-deselected.svg"} alt="" />
                                        </> : Math.floor(rating) == 4 ?
                                            <>
                                                <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-colored.svg"} id='1' alt="" />
                                                <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-colored.svg"} alt="" />
                                                <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-colored.svg"} alt="" />
                                                <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-colored.svg"} alt="" />
                                                <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-deselected.svg"} alt="" />
                                            </> : Math.floor(rating) == 5 ?
                                                <>
                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-colored.svg"} id='1' alt="" />
                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-colored.svg"} alt="" />
                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-colored.svg"} alt="" />
                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-colored.svg"} alt="" />
                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-colored.svg"} alt="" />
                                                </> : <>
                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-deselected.svg"} id='1' alt="" />
                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-deselected.svg"} alt="" />
                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-deselected.svg"} alt="" />
                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-deselected.svg"} alt="" />
                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-deselected.svg"} alt="" />
                                                </>
                            }
                        </div>
                        <p className="font_16 mb-0"> Total Length: {data && data.length} minutes</p>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-12">


                            <div className='mt-4 my-4'>
                                {data ? <audio controls>
                                    <source
                                        src={data?.content_url}
                                        type="audio/mpeg"
                                    />
                                    Your browser does not support the
                                    audio element.
                                </audio> : <></>}
                            </div>



                        </div>
                        {/* <div className="col-lg-6 col-xl-6 col-12">
                            <p className="font_48 font_600">Closed Captions</p>
                            <p className="font_24">
                                
                                <div>
                                    <p className={`font_24  ${showMore ? '' : 'longtext'}`}>
                                        {displayDescription}
                                    </p>
                                    {!showMore && (
                                        <p className="font_24 text-decoration-underline" onClick={handleShowMore} style={{ cursor: 'pointer' }}>
                                            {buttonText}
                                        </p>
                                    )}
                                </div>
                            </p>
                           
                        </div> */}

                        <div className="col-lg-6 col-xl-6 col-12">
                            {/* <p className="font_48 font_600">Closed Captions</p> */}
                            <div>
                                <p className={`font_24 ${!showMore && displayDescription && displayDescription.length > 150 ? 'longtext' : ''}`}>
                                    {displayDescription}
                                </p>
                                {!showMore && displayDescription && displayDescription.length > 150 && (
                                    <p className="font_24 text-decoration-underline" onClick={handleShowMore} style={{ cursor: 'pointer' }}>
                                        {buttonText}
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                    <section className="resource-article-pad">
                        <div className="row justify-content-center">
                            <div className="col-lg-10 col-xl-10 col-12">
                                <div className="rate-this-sec align-items-center flex-wrap gap-3">
                                    <p className="font_36 font_600 mb-0">Rate This:</p>

                                    {<div className="star-rating rate-video stars-div">
                                        <div className='star-rating' onClick={() => setScroll(false)}>
                                            {myuserrating == 5 ?
                                                <>

                                                    <input id="star-130" type="radio" name="rating" value="5" checked={myuserrating == 5} />
                                                    <label for="star-130" title="5 stars" >
                                                        <i class="active fa fa-star" aria-hidden="true" onClick={() => setmyuserrating(5)}></i>
                                                    </label>
                                                    <input id="star-129" type="radio" name="rating" value="4" checked={myuserrating == 4} />
                                                    <label for="star-129" title="4 stars" >
                                                        <i class="active fa fa-star" aria-hidden="true" onClick={() => setmyuserrating(4)}></i>
                                                    </label>
                                                    <input id="star-128" type="radio" name="rating" value="3" checked={myuserrating == 3} />
                                                    <label for="star-128" title="3 stars" >
                                                        <i class="active fa fa-star" aria-hidden="true" onClick={() => setmyuserrating(3)}></i>
                                                    </label>
                                                    <input id="star-127" type="radio" name="rating" value="2" checked={myuserrating == 2} />
                                                    <label for="star-127" title="2 stars" >
                                                        <i class="active fa fa-star" aria-hidden="true" onClick={() => setmyuserrating(2)}></i>
                                                    </label>
                                                    <input id="star-126" type="radio" name="rating" value="1" checked={myuserrating == 1} />
                                                    <label for="star-126" title="1 stars" >
                                                        <i class="active fa fa-star" aria-hidden="true" onClick={() => setmyuserrating(1)}></i>
                                                    </label>
                                                </> : myuserrating == 4 ?
                                                    <>

                                                        <input id="star-125" type="radio" name="rating" value="5" checked={myuserrating == 5} />
                                                        <label for="star-125" title="5 stars" >
                                                            <i class="fa fa-star" aria-hidden="true" onClick={() => setmyuserrating(5)}></i>
                                                        </label>
                                                        <input id="star-124" type="radio" name="rating" value="4" checked={myuserrating == 4} />
                                                        <label for="star-124" title="4 stars" >
                                                            <i class="active fa fa-star" aria-hidden="true" onClick={() => setmyuserrating(4)}></i>
                                                        </label>
                                                        <input id="star-123" type="radio" name="rating" value="3" checked={myuserrating == 3} />
                                                        <label for="star-123" title="3 stars" >
                                                            <i class="active fa fa-star" aria-hidden="true" onClick={() => setmyuserrating(3)}></i>
                                                        </label>
                                                        <input id="star-122" type="radio" name="rating" value="2" checked={myuserrating == 2} />
                                                        <label for="star-122" title="2 stars" >
                                                            <i class="active fa fa-star" aria-hidden="true" onClick={() => setmyuserrating(2)}></i>
                                                        </label>
                                                        <input id="star-121" type="radio" name="rating" value="1" checked={myuserrating == 1} />
                                                        <label for="star-121" title="1 stars" >
                                                            <i class="active fa fa-star" aria-hidden="true" onClick={() => setmyuserrating(1)}></i>
                                                        </label>
                                                    </> : myuserrating == 3 ?
                                                        <>

                                                            <input id="star-120" type="radio" name="rating" value="5" checked={myuserrating == 5} />
                                                            <label for="star-120" title="5 stars" >
                                                                <i class="fa fa-star" aria-hidden="true" onClick={() => setmyuserrating(5)}></i>
                                                            </label>
                                                            <input id="star-119" type="radio" name="rating" value="4" checked={myuserrating == 4} />
                                                            <label for="star-119" title="4 stars" >
                                                                <i class="fa fa-star" aria-hidden="true" onClick={() => setmyuserrating(4)}></i>
                                                            </label>
                                                            <input id="star-118" type="radio" name="rating" value="3" checked={myuserrating == 3} />
                                                            <label for="star-118" title="3 stars" >
                                                                <i class="active fa fa-star" aria-hidden="true" onClick={() => setmyuserrating(3)}></i>
                                                            </label>
                                                            <input id="star-117" type="radio" name="rating" value="2" checked={myuserrating == 2} />
                                                            <label for="star-117" title="2 stars"  >
                                                                <i class="active fa fa-star" aria-hidden="true" onClick={() => setmyuserrating(2)}></i>
                                                            </label>
                                                            <input id="star-116" type="radio" name="rating" value="1" checked={myuserrating == 1} />
                                                            <label for="star-116" title="1 stars" >
                                                                <i class="active fa fa-star" aria-hidden="true" onClick={() => setmyuserrating(1)}></i>
                                                            </label>
                                                        </> : myuserrating == 2 ?
                                                            <>

                                                                <input id="star-115" type="radio" name="rating" value="5" checked={myuserrating == 5} />
                                                                <label for="star-115" title="5 stars" >
                                                                    <i class="fa fa-star" aria-hidden="true" onClick={() => setmyuserrating(5)}></i>
                                                                </label>
                                                                <input id="star-114" type="radio" name="rating" value="4" checked={myuserrating == 4} />
                                                                <label for="star-114" title="4 stars" >
                                                                    <i class="fa fa-star" aria-hidden="true" onClick={() => setmyuserrating(4)}></i>
                                                                </label>
                                                                <input id="star-113" type="radio" name="rating" value="3" checked={myuserrating == 3} />
                                                                <label for="star-113" title="3 stars" >
                                                                    <i class="fa fa-star" aria-hidden="true" onClick={() => setmyuserrating(3)}></i>
                                                                </label>
                                                                <input id="star-112" type="radio" name="rating" value="2" checked={myuserrating == 2} />
                                                                <label for="star-112" title="2 stars" >
                                                                    <i class="active fa fa-star" aria-hidden="true" onClick={() => setmyuserrating(2)}></i>
                                                                </label>
                                                                <input id="star-111" type="radio" name="rating" value="1" checked={myuserrating == 1} />
                                                                <label for="star-111" title="1 stars" >
                                                                    <i class="active fa fa-star" aria-hidden="true" onClick={() => setmyuserrating(1)}></i>
                                                                </label>
                                                            </> : myuserrating == 1 ?
                                                                <>

                                                                    <input id="star-110" type="radio" name="rating" value="5" checked={myuserrating == 5} />
                                                                    <label for="star-110" title="5 star" >
                                                                        <i class="fa fa-star" aria-hidden="true" onClick={() => setmyuserrating(5)}></i>
                                                                    </label>
                                                                    <input id="star-109" type="radio" name="rating" value="4" checked={myuserrating == 4} />
                                                                    <label for="star-109" title="4 star" >
                                                                        <i class="fa fa-star" aria-hidden="true" onClick={() => setmyuserrating(4)}></i>
                                                                    </label>
                                                                    <input id="star-108" type="radio" name="rating" value="3" checked={myuserrating == 3} />
                                                                    <label for="star-108" title="3 star" >
                                                                        <i class="fa fa-star" aria-hidden="true" onClick={() => setmyuserrating(3)}></i>
                                                                    </label>
                                                                    <input id="star-107" type="radio" name="rating" value="2" checked={myuserrating == 2} />
                                                                    <label for="star-107" title="2 star" >
                                                                        <i class="fa fa-star" aria-hidden="true" onClick={() => setmyuserrating(2)}></i>
                                                                    </label>
                                                                    <input id="star-106" type="radio" name="rating" value="1" checked={myuserrating == 1} />
                                                                    <label for="star-106" title="1 star" >
                                                                        <i class="active fa fa-star" aria-hidden="true" onClick={() => setmyuserrating(1)}></i>
                                                                    </label>
                                                                </> :
                                                                <>

                                                                    <input id="star-105" type="radio" name="rating" value="5" checked={myuserrating == 5} />
                                                                    <label for="star-105" title="5 star" >
                                                                        <i class="fa fa-star" aria-hidden="true" onClick={() => setmyuserrating(5)}></i>
                                                                    </label>
                                                                    <input id="star-104" type="radio" name="rating" value="4" checked={myuserrating == 4} />
                                                                    <label for="star-104" title="4 star" >
                                                                        <i class="fa fa-star" aria-hidden="true" onClick={() => setmyuserrating(4)}></i>
                                                                    </label>
                                                                    <input id="star-103" type="radio" name="rating" value="3" checked={myuserrating == 3} />
                                                                    <label for="star-103" title="3 star" >
                                                                        <i class="fa fa-star" aria-hidden="true" onClick={() => setmyuserrating(3)}></i>
                                                                    </label>
                                                                    <input id="star-102" type="radio" name="rating" value="2" checked={myuserrating == 2} />
                                                                    <label for="star-102" title="2 star" >
                                                                        <i class="fa fa-star" aria-hidden="true" onClick={() => setmyuserrating(2)}></i>
                                                                    </label>
                                                                    <input id="star-101" type="radio" name="rating" value="1" checked={myuserrating == 1} />
                                                                    <label for="star-101" title="1 star" >
                                                                        <i class="fa fa-star" aria-hidden="true" onClick={() => setmyuserrating(1)}></i>
                                                                    </label>
                                                                </>

                                            }


                                        </div>

                                    </div>}

                                    <button
                                        className="submit-btn font_16"
                                        onClick={handlerating}
                                    // disabled={isSubmitted}
                                    >
                                        {isSubmitted ? 'Submit' : 'Submit'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <section className="other-res-pad more-content-sec pt-0">
                    <div className="container">
                        <div className="d-flex justify-content-between align-items-center mb-4">
                            {nocontent == 204 ? "" : <>
                                {handledefaultdata ?
                                    <p className="font_50 font_600 mb-0 title">More Contents Like This</p>
                                    : ""}
                                {/* <p className="font_20 mb-0 see-more-icon">
                                        See More{" "}
                                        <img src={process.env.PUBLIC_URL + "/assets/images/icon/blue-arroww.svg"} className="ps-2" />
                                    </p> */}
                            </>

                            }

                        </div>
                        <div className="row">
                            {savedata && savedata.map((item, key) => {
                                return (
                                    <>

                                        {UserData && UserData.data.is_subscription_active == true ?
                                            <div className="col-lg-4 col-xl-2 col-md-4 col-12">
                                                <div className="self-care-card" onClick={() => getaudiodetails(item.id)}>
                                                    <div className='position-relative'>
                                                        <div className="selfcare-img">
                                                            <img src={item.thumbnail} alt="" />
                                                        </div>
                                                    </div>
                                                    <div className="title">
                                                        <p className="font_24 mb-1">{item.title}</p>
                                                        <p className="font_14 mb-0">Author</p>
                                                    </div>
                                                </div>
                                            </div> :
                                            <>
                                                {item.is_paid == true ?
                                                    <div className="col-lg-4 col-xl-2 col-md-4 col-12" onClick={handleCreateEventClick}>
                                                        <div className="self-care-card">
                                                            <div className='position-relative'>
                                                                <div className="selfcare-img">
                                                                    <img src={item.thumbnail} alt="" />
                                                                </div>
                                                                <div class="lock-icon">
                                                                    <i class="fa-solid fa-lock text-mave font_24"></i>
                                                                </div>
                                                            </div>

                                                            <div className="title">
                                                                <p className="font_24 mb-1">{item.title}</p>
                                                                <p className="font_14 mb-0">Author</p>
                                                            </div>
                                                        </div>
                                                    </div> :
                                                    <div className="col-lg-4 col-xl-2 col-md-4 col-12">
                                                        <div className="self-care-card" onClick={() => getaudiodetails(item.id)}>
                                                            <div className='position-relative'>
                                                                <div className="selfcare-img">
                                                                    <img src={item.thumbnail} alt="" />
                                                                </div>
                                                            </div>
                                                            <div className="title">
                                                                <p className="font_24 mb-1">{item.title}</p>
                                                                <p className="font_14 mb-0">Author</p>
                                                            </div>
                                                        </div>
                                                    </div>}
                                            </>}

                                    </>
                                )
                            })}

                        </div>
                    </div>
                </section>
                <Footer />
            </main>
        </>
    )
}

export default ResourceAudio