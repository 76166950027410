import React, { useState, useEffect } from 'react'
import OuterHeader from '../components/OuterHeader'
import Footer from '../components/Footer'
import { Link, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import checkValidation from '../validation/ValidationSchema';
import { userDetails, changeAccount, updatePhoto, deletePhoto } from '../api/apiHandler.js'
import Loader from '../components/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../store/slice/loaderSlice';
import Swal from 'sweetalert2'
import axios from 'axios';
import { event } from 'jquery';




const ChangeAccountDetails = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.loader);
    const [profileImage, setProfileImage] = useState(null);
    const [username, setUsername] = useState()
    const [fullname, setFullname] = useState()
    const [uploadImg, setUploadImg] = useState(null);

    useEffect(() => {
        dispatch(setLoading(true))
        userDetails().then((response) => {
            dispatch(setLoading(false))
            if (response.status === 200) {

                setUsername(response.data.username)
                setFullname(response.data.fullname)
                setProfileImage(response.data.photo.image);
            }

        }).catch((error) => {
            console.log(error);
        })

    }, [])

    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            setProfileImage(URL.createObjectURL(event.target.files[0]));
            setUploadImg(event.target.files[0])
        }
    }

    const updateData = (e) => {
        e.preventDefault()
        if (uploadImg != null) {
            uploadImage()
        }
        else {

            const updateobj = {
                username: username,
                fullname: fullname,
            }

            changeAccount(updateobj).then((response) => {
                dispatch(setLoading(false))

                if (response.status === 200) {

                    localStorage.setItem("username", response.data['username'])
                    Swal.fire({
                        position: "top-right",
                        icon: "success",
                        title: "Account updated successfully",
                        toast: true,
                        showConfirmButton: false,
                        timer: 2000
                    });
                    navigate("/account")

                }
            })
                .catch((error) => {
                    Swal.fire({
                        position: "top-right",
                        icon: "error",
                        title: error.response.data.errors,
                        toast: true,
                        showConfirmButton: false,
                        timer: 2000
                    });
                    console.log("error", error);
                });
        }
    }


    const uploadImage = (e) => {

        let formdata = new FormData();
        formdata.append("image", uploadImg);

        axios({
            method: 'post',
            url: process.env.REACT_APP_API_BASE_URL + "/custom-auth/users/" + localStorage.getItem("uuid") + "/photo/add-or-update/",
            headers: {
                'api-key': process.env.REACT_APP_API_KEY,
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Token ' + localStorage.getItem("token")
            },
            data: formdata,
        }).then((response) => {

            if (response.status === 201) {
                setUploadImg(null);
                const updateobj = {
                    username: username,
                    fullname: fullname,
                }

                changeAccount(updateobj).then((response) => {

                    if (response.status == 200) {

                        localStorage.setItem("username", response.data['username']);
                        setProfileImage(response.data.photo.image);
                        localStorage.setItem("photo", response.data.photo.image);
                        navigate('/account')
                        Swal.fire({
                            position: "top-right",
                            icon: "success",
                            title: "Account updated successfully",
                            toast: true,
                            showConfirmButton: false,
                            timer: 2000
                        });

                    }
                })
                    .catch((error) => {
                        Swal.fire({
                            position: "top-right",
                            icon: "error",
                            title: error.response.data.errors,
                            toast: true,
                            showConfirmButton: false,
                            timer: 2000
                        });
                        console.log("error", error);
                    });


            } else {
                console.log("image can't upload");
            }
        })
            .catch((error) => {
                console.log("--------------<<<", error);
            });

    }

    const removeImage = () => {


        deletePhoto().then((response) => {
            if (response.status == 204) {
                localStorage.removeItem("photo")
                setProfileImage(null);
            }

        })
    }


    return (
        <>
            {isLoading ?
                (<Loader loader_status={isLoading} />) :
                (
                    <main className="helprr-main">
                        <OuterHeader />
                        <section className="login-sec">
                            <div className="container">
                                <p className="title font_46 pb-2">Change Account Details</p>
                                <Link to="/account" className="d-flex gap-3 mb-3">
                                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/left-arrow.svg"} alt="" />
                                    <p className="mb-0 font_20">Go Back</p>
                                </Link>
                                <div className="row justify-content-center">
                                    <form onSubmit={updateData} enctype="multipart/form-data">


                                        <div className="col-lg-8 col-xl-8 col-xxl-7 col-12">

                                            <div className="mb-4">
                                                <input
                                                    type="text"
                                                    className="form-custom form-control font_18"
                                                    id="exampleFormControlInput1"
                                                    placeholder="Username"
                                                    name='username'
                                                    value={username}
                                                    onChange={(e) => setUsername(e.target.value)}

                                                />

                                            </div>
                                            <div className="mb-4">
                                                <input
                                                    type="text"
                                                    className="form-custom form-control font_18"
                                                    id="exampleFormControlInput1"
                                                    placeholder="Name"
                                                    name='fullname'
                                                    value={fullname}
                                                    onChange={(e) => setFullname(e.target.value)}
                                                />

                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 col-lg-5 col-xl-5 col-12">
                                                    <div className="change-img">

                                                        {profileImage == null ?
                                                            <img src={process.env.PUBLIC_URL + "/assets/images/defalt.png"} alt="" /> :
                                                            <img src={profileImage} alt="" />
                                                        }

                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-7 col-xl-7 col-12">
                                                    <div className="upload-btn">

                                                        <label style={{ cursor: "pointer" }}>
                                                            Upload Photo
                                                            <input type="file" style={{ display: "none" }} onChange={(e) => onImageChange(e)} accept='image/*' />

                                                        </label>

                                                        <a type='button' className="font_18" onClick={removeImage}>
                                                            Remove Photo
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <button type='submit' className="login-btn font_18">
                                                Update Account
                                            </button>
                                        </div>

                                    </form>

                                </div>
                            </div>
                        </section>

                        <Footer />
                    </main>
                )
            }



        </>
    )
}

export default ChangeAccountDetails
