import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import OuterHeader from '../components/OuterHeader'
import Footer from '../components/Footer'
import { HealingJourneydata } from '../api/apiHandler'
import { useDispatch, useSelector } from 'react-redux';



const HealingJourney = () => {
    const [healingdata, sethealingdata] = useState()
    var reduxUserData = useSelector((state) => state.userdata);
    reduxUserData = (reduxUserData != undefined) ? JSON.parse(reduxUserData) : null;
    const [loginUserData, setLoginUserData] = useState(reduxUserData);


    useEffect(() => {
        HealingJourneydata().then((response) => {
            if (response.status == 200) {
                sethealingdata(response.data.healing_journey_data)
            }
        }).catch((error) => {
            console.log(error);
        })
    }, [])

    const calculateTotalCommunityLeadership = () => {
        if (healingdata && healingdata.community_leadership) {
            const { asked_questions, answered_questions, mentorship } = healingdata.community_leadership;
            return asked_questions + answered_questions + mentorship;
        }
        return 0; // Default value if data is not available
    };


    return (
        <>
            <main className="helprr-main">

                <OuterHeader />
                <section className="bg-mave">
                    <div className="banner-sec healing-banner mt-0 bg-mave">
                        <div className="container">
                            <div className="banner-profile-sec">
                                <div className="banner-profile-img">
                                    {localStorage.getItem("photo") != undefined && localStorage.getItem("photo") != null && localStorage.getItem("photo") != "" ?
                                        <img src={localStorage.getItem("photo")} className="account-dropdown" alt="" /> :
                                        <img src={process.env.PUBLIC_URL + "/assets/images/defalt.png"} className="account-dropdown" alt="" />
                                    }
                                </div>
                                <div className="banner-text">
                                    <p className="mb-0 font_18 text-white font_700">Welcome back</p>
                                    <p className="mb-0 font_26 text-white font_700"> {localStorage.getItem("username")}</p>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="back-section pb-5 ">
                        <div className="container position-relative">
                            <div className="action-section">
                                <div className="card-progress shadow-design pb-0">
                                    <div className="row">
                                        <div className="col-lg-10 col-md-8 col-6">
                                            <p className="font_20 font_500 mb-0">
                                                {localStorage.getItem("username")}, here is a summary of your self care journey!
                                            </p>
                                        </div>
                                        <div className="col-lg-2 col-md-4 col-6">
                                            <div className="orange-vector">
                                                <img src={process.env.PUBLIC_URL + "/assets/images/orange-vector.svg"} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className=" healing-tasks">
                                {/* <p class="font_30 font_500 text-orange">All Challenges</p> */}
                                <div className="row">
                                    <div className="col-lg-3 col-6">
                                        <div className="task-card">
                                            <Link to="/bookdashboard">
                                                <div className="icon">
                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/books-read.svg"} alt="" />
                                                </div>
                                                <div>
                                                    <p className="font_18 mb-0 text">Books Read</p>
                                                    <p className="font_25 mb-0">{healingdata && healingdata.books_read}</p>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>



                                    <div className="col-lg-3 col-6">
                                        <div className="task-card">

                                            {loginUserData != null && loginUserData.data.is_subscription_active == true ?
                                                <>
                                                    {localStorage.getItem("active_actionplan") == "false" ?
                                                        <Link to="/create-actionplan">
                                                            <div className="icon">
                                                                <img
                                                                    src={process.env.PUBLIC_URL + "/assets/images/icon/healing-journey--2.svg"}
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <div>
                                                                <p className="font_18 mb-0 text">Self Care Challenges</p>
                                                                <p className="font_25 mb-0">{healingdata && healingdata.self_care_challenge}</p>
                                                            </div>
                                                        </Link> :
                                                        <Link to="/actionplan-dashboard">
                                                            <div className="icon">
                                                                <img
                                                                    src={process.env.PUBLIC_URL + "/assets/images/icon/healing-journey--2.svg"}
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <div>
                                                                <p className="font_18 mb-0 text">Self Care Challenges</p>
                                                                <p className="font_25 mb-0">{healingdata && healingdata.self_care_challenge}</p>
                                                            </div>
                                                        </Link>}
                                                </>
                                                :
                                                <Link to="/create-actionplan">
                                                    <div className="icon">
                                                        <img
                                                            src={process.env.PUBLIC_URL + "/assets/images/icon/healing-journey--2.svg"}
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div>
                                                        <p className="font_18 mb-0 text">Self Care Challenges</p>
                                                        <p className="font_25 mb-0">{healingdata && healingdata.self_care_challenge}</p>
                                                    </div>
                                                </Link>
                                            }

                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-6">
                                        <div className="task-card">
                                            <Link to="/community-events">
                                                <div className="icon">
                                                    <img
                                                        src={process.env.PUBLIC_URL + "/assets/images/icon/healing-journey--3.svg"}
                                                        alt=""
                                                    />
                                                </div>
                                                <div>
                                                    <p className="font_18 mb-0 text">Events</p>
                                                    <p className="font_25 mb-0">{healingdata && healingdata.events}</p>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-6">
                                        <div className="task-card">
                                            <Link to="/community-leadership">
                                                <div className="icon">
                                                    <img
                                                        src={process.env.PUBLIC_URL + "/assets/images/icon/healing-journey--4.svg"}
                                                        alt=""
                                                    />
                                                </div>
                                                <div>
                                                    <p className="font_18 mb-0 text">Community Leadership</p>
                                                    <p className="font_25 mb-0">{calculateTotalCommunityLeadership()}</p>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Footer />
            </main>


        </>
    )
}

export default HealingJourney