import Swal from 'sweetalert2'

export function loginRedirectCall() {
  let path = window.location.protocol + "//" + window.location.host + "/admin"
  //    window.open(path,'mywindow').focus()
  console.log(path)
  window.location.href = path;
}

export function logOutRedirectCall() {
  localStorage.clear();
  window.location.href = 'login'
}


export function loginStoreData(data) {
  //console.log('localhost permi===========',data.permission)
  // localStorage.setItem("AisLogin",true);
  // localStorage.setItem("Atoken",data.token);
  // localStorage.setItem("Aid",data._id);
  // localStorage.setItem("Aemail",data.email);
  // localStorage.setItem("Acountry_code",data.country_code);
  // localStorage.setItem("Aphone",data.phone);
  // localStorage.setItem("Afirst_name",data.first_name);
  // localStorage.setItem("Alast_name",data.last_name);
  // localStorage.setItem("Arole",data.role);
  // localStorage.setItem("profile_image",data.profile_image);

}
export const showMessage = (msg) => {
  Swal.fire({
    position: 'top-end',
    icon: 'success',
    title: msg,
    toast: true,
    showConfirmButton: false,
    timer: 1500
  })
}
export const showErrorMessage = (msg) => {
  Swal.fire({
    position: 'top-end',
    icon: 'error',
    title: msg,
    toast: true,
    showConfirmButton: false,
    timer: 1500
  })
}


