import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { Link, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import checkValidation from '../validation/ValidationSchema';
import { forgotPassword } from '../api/apiHandler.js'
import Loader from '../components/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../store/slice/loaderSlice';


const ForgotPassword = () => {
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.loader);
    const schema = checkValidation(['Email']);

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    const navigate = useNavigate();
    const onSubmit = (data) => {
        const forgotpassobj = {
            email: data.Email
        }
        dispatch(setLoading(true))
        forgotPassword(forgotpassobj).then((response) => {
            dispatch(setLoading(false))
            if (response.status === 200) {
                localStorage.setItem('email', data.Email)
                localStorage.setItem("resend_otp_for", "forgot_password")
                localStorage.setItem('verification_for', 'forgot_password')
                navigate('/verify-otp');
            } else {
                console.log("Login failed");
            }

        }).catch((error) => {
            console.log(error);
        })

    };


    const handleback = () => {
        navigate(-1)
    }

    return (
        <>

            {isLoading ?
                (<Loader loader_status={isLoading} />) :
                (

                    <main className="helprr-main">
                        {/* <Header /> */}
                        <nav className="navbar navbar-expand-lg navbar-light navbar-header">
                            <div className="container d-flex">
                                <Link className="navbar-brand" to="/">
                                    <img src={process.env.PUBLIC_URL + "/assets/images/logo.png"} />
                                </Link>
                            </div>
                        </nav>
                        <section className="login-sec">
                            <div className="container">
                                <div className="d-flex gap-3 mb-3 align-items-center" onClick={handleback}>
                                    <img className='back-arrow-icon' src={process.env.PUBLIC_URL + "/assets/images/icon/left-arrow.svg"} alt="" />
                                    <p className="mb-0 font_24">Go Back</p>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-6 col-xl-6 col-12">
                                        <p className="title font_50">Forgot Password?</p>
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <div className="mb-3">
                                                <input
                                                    type="email"
                                                    className="form-custom form-control font_18"
                                                    id="exampleFormControlInput1"
                                                    name='Email'
                                                    placeholder="Email"
                                                    {...register("Email")}

                                                />

                                            </div>
                                            {errors.Email && <p role="alert" className='error-text'>{errors.Email.message}</p>}
                                            <a className="font_18">
                                                We’ll send you a code to reset your password.
                                            </a>
                                            {/* <a href="javascript:void(0)" class="font_20 text-decoration-underline">Forgot Password</a> */}
                                            {/* <Link to="/reset-password" className="login-btn font_18">
                                        Submit
                                    </Link> */}

                                            <button
                                                type="submit"
                                                className="login-btn font_18">
                                                Submit
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </main>
                )
            }

        </>
    )
}

export default ForgotPassword