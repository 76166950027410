import React, { useState, useEffect } from 'react'
import Footer from '../components/Footer'
import OuterHeader from '../components/OuterHeader'
import { termsofuse } from '../api/apiHandler'
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../store/slice/loaderSlice';
import Loader from '../components/Loader';
import Header from '../components/Header';


const TermsUse = () => {
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.loader);
    const [data, setdata] = useState();
    useEffect(() => {
        dispatch(setLoading(true))
        termsofuse().then((response) => {
            dispatch(setLoading(false))
            setdata(response.data)
        }).catch((error) => {
            console.log(error);
        })
    }, [])
    return (
        <>
            {isLoading ?
                (<Loader loader_status={isLoading} />) :
                (
                    <main className="helprr-main">
                        {localStorage.getItem("token") ?
                            <OuterHeader /> :
                            <Header />
                        }

                        <section className="login-sec">
                            <div className="container">
                                <div className="row align-items-end">
                                    <div className="col-lg-9 col-xl-9 col-12">
                                        <p className="font_46 font_600">Terms Of Use</p>
                                        <div dangerouslySetInnerHTML={{ __html: data && data.meta_value }} />
                                    </div>
                                    <div className="col-lg-3 col-xl-3 col-12"></div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </main>
                )
            }

        </>
    )
}

export default TermsUse