import React from 'react'
import OuterHeader from '../components/OuterHeader'
import Footer from '../components/Footer'
import { Link } from 'react-router-dom'

const ActionPlanDashboard2 = () => {


    const actionplanurl = process.env.REACT_APP_WEBSITE_BASE_URL + "/actionplan-dashboard"

    const handleactionplan = () => {
        localStorage.setItem('questions_for', "action plan")
        localStorage.setItem('completeUrl', actionplanurl)

    }
    return (
        <>
            <main className="helprr-main">

                <OuterHeader />
                <section className="bg-orange">
                    <div className="banner-sec mt-0">
                        <div className="container">
                            <div className="banner-profile-sec">
                                <div className="banner-profile-img">
                                    {localStorage.getItem("photo") != undefined && localStorage.getItem("photo") != null && localStorage.getItem("photo") != "" ?
                                        <img src={localStorage.getItem("photo")} className="account-dropdown" alt="" /> :
                                        <img src={process.env.PUBLIC_URL + "/assets/images/defalt.png"} className="account-dropdown" alt="" />
                                    }
                                </div>
                                <div className="banner-text">
                                    <p className="mb-0 font_18 text-white font_700">Welcome back</p>
                                    <p className="mb-0 font_26 text-white font_700">{localStorage.getItem("username")}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="back-section pb-5">
                        <div className="container position-relative">
                            <div className="action-section">
                                <div className="card-progress first-card">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div>
                                            <p className="mb-0  font_22">30 day Challenge</p>

                                            <div className="award-progress mt-4">
                                                <div className="mb-4 d-flex gap-3">
                                                    <div className="icon-card">
                                                        <img src={process.env.PUBLIC_URL + "/assets/images/icon/award.png"} alt="award" />
                                                    </div>
                                                    <div>
                                                        <p className="mb-0 font_18 font_500">Your Progress</p>
                                                        <p className="mb-0 font_25 font_700">0%</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-image">
                                            <img src={process.env.PUBLIC_URL + "/assets/images/card-Image.png"} alt="" />
                                        </div>
                                    </div>
                                    <div className="award-progress">
                                        <div className="progress">
                                            <div
                                                className="progress-bar"
                                                role="progressbar"
                                                style={{ width: "0%" }}
                                                aria-valuenow={10}
                                                aria-valuemin={0}
                                                aria-valuemax={100}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="task-list">
                                {/* <p class="font_30 font_500 text-orange">All Challenges</p> */}
                            </div>
                            <div className="card-progress bg-mave text-center">
                                <p className="mb-0  font_24 text-white font_500">
                                    Nothing here.. Start a new challenge?
                                </p>

                            </div>
                            <div className="row mt-5 justify-content-center">
                                <div className="col-lg-3 col-md-6 col-6">
                                    <Link to="/actionplan-question" className="edit-btn"
                                        onClick={handleactionplan}
                                    // onClick={() => localStorage.setItem('questions_for', "action plan")}
                                    >
                                        Lets go!
                                    </Link>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </main>

        </>
    )
}

export default ActionPlanDashboard2