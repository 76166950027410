import React from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import checkValidation from '../validation/ValidationSchema';
import { EventRegistration } from '../api/apiHandler';
import Swal from 'sweetalert2'


const RegisterEvent = () => {
    const navigate = useNavigate()
    const params = useParams();
    const schema = checkValidation(['name', 'Email']);

    const { register, handleSubmit, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmit = (data) => {
        const registerdata = {
            event: parseInt(params.id),
            name: data.name,
            email: data.Email
        }

        EventRegistration(registerdata).then((response) => {
            if (response.status == 201) {
                Swal.fire({
                    position: "top-right",
                    icon: "success",
                    title: "Event registation successfully",
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                });

                Object.keys(data).forEach((field) => {
                    setValue(field, '');
                });
                navigate("/events")

            }
        }).catch((error) => {
            Swal.fire({
                position: "top-right",
                icon: "error",
                title: error.response.data.errors,
                toast: true,
                showConfirmButton: false,
                timer: 2000
            });

        })

    };
    return (
        <>
            <main className="helprr-main events-main h-100">
                <header className='p-2'>
                    <nav className="navbar navbar-expand-lg navbar-light navbar-header">
                        <div className="container-fluid d-flex p-0">
                            <Link className="navbar-brand" to="/">
                                <img src={process.env.PUBLIC_URL + "/assets/images/logo.png"} />
                            </Link>
                        </div>
                    </nav>
                </header>
                <section className="create-event-sec">
                    <div className="container">
                        <p className="font_36 font_700 text-black text-center">Registration</p>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row justify-content-center">
                                <div className="col-lg-9 col-xl-9 col-12">
                                    <div className="mb-4">
                                        <label
                                            htmlFor="exampleFormControlInput1"
                                            className="form-label text-black font_12"
                                        >
                                            Your name
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="exampleFormControlInput1"
                                            placeholder="Enter your name"
                                            name="name"
                                            {...register("name")}
                                        />
                                        {errors.name && <p role="alert" className='error-text' >{errors.name.message}</p>}

                                    </div>
                                    <div className="mb-4">
                                        <label
                                            htmlFor="exampleFormControlInput1"
                                            className="form-label text-black font_12"
                                        >
                                            Your Email
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="exampleFormControlInput1"
                                            placeholder="Enter your email "
                                            name="Email"
                                            {...register("Email")}
                                        />
                                        {errors.Email && <p role="alert" className='error-text' >{errors.Email.message}</p>}

                                    </div>
                                    <button type="submit" className="load-btn font_18 w-100">
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </section>
            </main>

        </>
    )
}

export default RegisterEvent