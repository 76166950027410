import React, { useState, useEffect } from 'react'
import OuterHeader from './OuterHeader.js'
import Footer from './Footer.js'
import { Link, useNavigate } from 'react-router-dom'
import Slider from "react-slick";
import Select2 from '../pages/Select2.js';
import '../css2/select2.css'
import Swal from 'sweetalert2'
import { ContentTags, ContentList } from '../api/apiHandler.js'
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../store/slice/loaderSlice';


const Book = (props) => {
    const navigate = useNavigate()
    const UserData = useSelector((state) => JSON.parse(state.userdata))
    const [mybookdata, setmybookdata] = useState(false)



    const settings2 = {
        dots: false,
        infinite: false,
        arrows: true,
        speed: 300,
        slidesToShow: 6,
        slidesToScroll: 1,
        responsive: [{
            breakpoint: 1440,
            settings: {
                dots: false,
                slidesToShow: 6,
                slidesToScroll: 1,
                infinite: true,
            },
        },
        {
            breakpoint: 1025,
            settings: {
                dots: false,
                slidesToShow: 4,
                slidesToScroll: 1,
                infinite: true,
            },
        },
        {
            breakpoint: 992,
            settings: {
                dots: false,
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
            },
        },
        {
            breakpoint: 575.99,
            settings: {
                dots: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
            },
        },
        ],
    };

    let bookdata = props.bookdata

    // filterdata = bookdata.slice(0, 6);
    // setmybookdata(filterdata)

    return (
        <>
            <div className="container">
                <div className="d-flex justify-content-between align-items-center mb-4 flex-wrap">
                    {bookdata && bookdata.length > 0 ?
                        <p className="font_46 font_600 mb-0 sec-title">{localStorage.getItem("user_category")}</p> : ""
                    }
                    {bookdata && bookdata.length > 6 ?
                        <button className="font_24 mb-0 see-more-icon"
                            onClick={() => navigate('/more-books', { state: bookdata })}>
                            See More{" "}
                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/blue-arroww.svg"} className="ps-2" />
                        </button> :
                        ""
                    }
                </div>

                <div className="testimonial-area">
                    <div className='row'>
                        <Slider {...settings2}>
                            {bookdata && bookdata.map((item, idx) => {
                                return (
                                    <>
                                        <div key={idx} className="col-12 wow fadeInUp" data-wow-delay=".2s"
                                            onClick={() => navigate(`/book-description/${item.id}`)}>
                                            <div className="single-testi">
                                                <Link to="/bookdescription" className="self-care-card d-block">
                                                    <div className='position-relative'>
                                                        <div className="selfcare-img">
                                                            {/* <img src={process.env.PUBLIC_URL + "/assets/images/care-img--1.svg"} alt="" /> */}

                                                            <img src={item && item.thumbnail_url != null ? item.thumbnail_url : item.thumbnail} alt="" />

                                                        </div>
                                                    </div>

                                                    <div className="title">
                                                        <p className="font_20 mb-1">{item.title}</p>
                                                        <p className="font_14 mb-0">{item.authors_details}</p>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>

                                    </>
                                )
                            })}
                        </Slider>
                    </div>
                </div>

            </div>

        </>
    )
}

export default Book