import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { SubscriptionPlanList, GetPaymentLink } from '../api/apiHandler';
import Swal from 'sweetalert2';
import { setLoading } from '../store/slice/loaderSlice';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../components/Loader';

function SubscriptionPlans() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.loader);
    const [subscriptionPlan, setSubscriptionPlan] = useState();

    useEffect(() => {
        dispatch(setLoading(true))
        SubscriptionPlanList().then((response) => {
            console.log("res", response);
            dispatch(setLoading(false))
            if (response.status === 200) {
                setSubscriptionPlan(response.data.subscription_plans)
            } else if (response.status === 204) {
                Swal.fire({
                    position: "top-right",
                    icon: "info",
                    title: "Subscription plans not found",
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                });
            }
        }).catch((error) => {
            Swal.fire({
                position: "top-right",
                icon: "error",
                title: error.response.data.errors,
                toast: true,
                showConfirmButton: false,
                timer: 2000
            });
            console.log(error);
        })
    }, [])

    // const handlePlan = (plan_id) => {

    //     const planObj = {
    //         "plan": plan_id
    //     }

    //     dispatch(setLoading(true))
    //     GetPaymentLink(planObj).then((response) => {
    //         dispatch(setLoading(false))
    //         if (response.status == 200) {
    //             // window.location.href = response.data.payment_url
    //             window.open(response.data.payment_url, '_blank');
    //         }
    //     }).catch((error) => {
    //         Swal.fire({
    //             position: "top-right",
    //             icon: "error",
    //             title: error.response.data.errors,
    //             toast: true,
    //             showConfirmButton: false,
    //             timer: 2000
    //         });
    //     })

    // }

    const handlePlan = async (plan_id) => {
        const Redirecturl = localStorage.getItem("completeUrl")
        const homeurl = process.env.REACT_APP_WEBSITE_BASE_URL + "/home"

        const planObj = {
            "plan": plan_id,
            "redirect_url": Redirecturl ? Redirecturl : homeurl
        };

        dispatch(setLoading(true));

        try {
            const response = await GetPaymentLink(planObj);
            dispatch(setLoading(false));

            if (response.status === 200) {
                localStorage.removeItem("completeUrl")
                window.location.href = response.data.payment_url;
            } else if (response.status == 204) {
                Swal.fire({
                    position: "top-right",
                    icon: "info",
                    title: "Subscription plans not found",
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                });
            }
        } catch (error) {
            Swal.fire({
                position: "top-right",
                icon: "error",
                title: error.response.data.errors,
                toast: true,
                showConfirmButton: false,
                timer: 2000
            });
        }
    }


    // useEffect(() => {
    //     if (localStorage.getItem("isSignup") == "true") {
    //         navigate("/home")
    //     }
    // }, [])




    return (
        <>
            {isLoading ?
                (<Loader loader_status={isLoading} />) :
                (
                    <main className="helprr-main">
                        <header className='p-2'>
                            <nav className="navbar navbar-expand-lg navbar-light navbar-header">
                                <div className="container d-flex p-0">
                                    <Link className="navbar-brand" to="/">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/logo.png"} />
                                    </Link>
                                    <form className="d-flex ms-auto">
                                        <Link className=" font_20" onClick={() => navigate(-1)}>
                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/x-close.png"} alt="close-icon" />
                                        </Link>
                                    </form>
                                </div>
                            </nav>
                        </header>
                        <section className="other-res-pad pt-5">
                            <div className="container">
                                <p className="font_50 font_600 mb-4">Subscription Plans</p>
                                <div className="row">
                                    {subscriptionPlan && subscriptionPlan.map((item) => {
                                        return (
                                            <div className="col-lg-4 col-xl-4 col-md-6 col-12 cursor-pointer mb-3" key={item.id} onClick={() => handlePlan(item.id)}>
                                                <div className="get-matched m-0 h-100" data-match-height="groupName">
                                                    <h1 className="font_30">{item.plan_name}</h1>
                                                    <h2 className="font_20 text">
                                                        {item.description}
                                                    </h2>
                                                    <h4>${item.amount}</h4>
                                                </div>
                                            </div>)
                                    })}
                                </div>
                            </div>
                        </section>
                    </main>
                )
            }
        </>
    )
}

export default SubscriptionPlans;
