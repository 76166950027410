import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'


import { CometChat } from "@cometchat/chat-sdk-javascript";
import { CometChatUsersWithMessages } from '@cometchat/chat-uikit-react';
import OuterHeader from '../components/OuterHeader';
import Footer from '../components/Footer';
import { SetCometChatId, GetListOfUIDS } from '../api/apiHandler.js'
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../store/slice/loaderSlice';
import Loader from '../components/Loader';
import Swal from 'sweetalert2'
import { UIKitSettingsBuilder, UsersConfiguration } from "@cometchat/uikit-shared";
import { CometChatUIKit, CometChatLocalize } from "@cometchat/chat-uikit-react";
import { initializeCometChat } from '../config/CometChatInit';

const CometChatPage = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loader);
  const loggedUserDetail = (localStorage.getItem('loggedUserDetail')) ? JSON.parse(localStorage.getItem('loggedUserDetail')) : null;
  const [cometChatLoginUserDetail, setCometChatLoginUserDetail] = useState(null);
  const [loggedUID, setLoggedUID] = useState((loggedUserDetail.cometchat_uid != null) ? loggedUserDetail.cometchat_uid : loggedUserDetail.username);
  const [selectedUsers, setSelectedUsers] = useState();
  const [usersUIDs, setUsersUIDs] = useState([]);
  const [isMobile, setIsMobile] = useState(true);
  const [isLoadings, setIsLoadings] = useState(true);
  //const [uid, setUid] = useState([]);
  const [isCometChatIni, setIsCometChatIni] = useState(false);
  const [cometChatTitle, setCometChatTitle] = useState('Users');
  const [isCometChatReady, setIsCometChatReady] = useState(false);

  useEffect(() => {
    if (selectedUsers && cometChatLoginUserDetail) {
      setIsLoadings(false);
    }
  }, [selectedUsers, cometChatLoginUserDetail]);

// Initialize related code

useEffect(() => {
  initializeCometChat()
    .then(() => {
      setIsCometChatReady(true);
      
    })
    .catch(error => {
      console.error('CometChat initialization error:', error);
    });
}, []);

//   useEffect(() => {
//     //

//     console.log(isCometChatIni)
//     if (!isCometChatIni) {
//         const COMETCHAT_CONSTANTS = {
//             APP_ID: process.env.REACT_APP_COMETCHAT_APP_ID, //Replace with your App ID
//             REGION: process.env.REACT_APP_COMETCHAT_APP_REGION, //Replace with your App Region
//             AUTH_KEY: process.env.REACT_APP_COMETCHAT_AUTH_KEY  //Replace with your Auth Key
//         }
//        // console.log(COMETCHAT_CONSTANTS)
//         //create the builder
//         const UIKitSettings = new UIKitSettingsBuilder()
//             .setAppId(COMETCHAT_CONSTANTS.APP_ID)
//             .setRegion(COMETCHAT_CONSTANTS.REGION)
//             .setAuthKey(COMETCHAT_CONSTANTS.AUTH_KEY)
//             .subscribePresenceForFriends(false)
//             .setAutoEstablishSocketConnection(false)
//             .build();
      
//         //Initialize CometChat UIKit
//         CometChatUIKit.init(UIKitSettings).then(() => {
//             CometChatLocalize.init("en", {
//                 en: {
//                     "USERS": cometChatTitle
//                 }
//             });
//             setIsCometChatIni(true);
//             console.log('ini')
//             setIsLoadings(false);    
//             // You can now call login function.
//         }).catch(console.log);


//     }

// }, [isCometChatIni]);

// Design related code
  useEffect(() => {
    // Function to handle screen resize
    function handleResize() {
      if (window.innerWidth <= 768) { // 768px is a common breakpoint for mobile view
      
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    }

    // Listen for window resize events
    window.addEventListener('resize', handleResize);

    // Run the function initially
    handleResize();

    // Cleanup function to remove the event listener
    return () => window.removeEventListener('resize', handleResize);

  }, []);

 
  const checkUserAvailableInCometChat = async (itemParam) => {
    //console.log('UID: ', UID);
    try {
      const user = await CometChat.getUser(itemParam);
     // console.log("User details fetched for user:", user);
      return itemParam;
    } catch (error) {
      Swal.fire({
        position: "top-right",
        icon: "error",
        title: "This user is not available in comet chat account",
        toast: true,
        showConfirmButton: false,
        timer: 5000
      });
     // console.log("User details fetching failed with error:", error);
      setIsLoadings(false);
      // Handle error accordingly, for now, returning null
      return null;
    }
  };

  useEffect(() => {
   
    // Get list of UIDS from database
    const getUIDSList = async () => {
     
      try {
        const response = await GetListOfUIDS();
        
  
        if (response.data.cometchat_uids.length > 0) {
          const responseUids = response.data.cometchat_uids.filter(element => element != null);
          
          setUsersUIDs(responseUids || []);
         
          if (responseUids.length > 0) {
            for (const item of responseUids) {
              const UID = await checkUserAvailableInCometChat(item);
            
            }
            setIsLoadings(false);
          }
        } else {
          setIsLoadings(false);
        }
      } catch (error) {
        setIsLoadings(false);
        console.log(error);
      }
    };

// get cometchat logged user
    const getCometChatUser = async () => {
      //CometChatUIKit.logout();
     
      // user is alredy exist in cometchat. so let's get them login

      CometChatUIKit.getLoggedinUser().then((user) => {
         //console.log('user', user)
         
        if (!user) {
          //Login user      
     //     console.log(loggedUID)
          loginCometChatUser();

        } else {

          CometChat.connect();
          getUIDSList();
          setCometChatLoginUserDetail(user);
        }
      });
    }

// Create new user
    const createNewCometChatUser = () => {

      // user is not found, so we can create new user
      const newUser = new CometChat.User(loggedUID);
  
      newUser.setName((loggedUserDetail.fullname != "") ? loggedUserDetail.fullname : loggedUserDetail.username);
      let metadata = {
        "@private":
        {
          "email": loggedUserDetail.email
        }
      }
  
      newUser.setMetadata(JSON.stringify(metadata));
  
      CometChatUIKit.createUser(newUser, process.env.REACT_APP_COMETCHAT_AUTH_KEY)
        .then((createdUser) => {
        //  console.log('User created:', createdUser);
          setCometChatIdFunction(createdUser, loggedUserDetail.id)
  
        })
        .catch((error) => {
          setIsLoadings(false);
  
          let errorMessage = "Oops! It seems like we've hit a limit.";
          errorMessage += " The maximum number of users you can create with the current plan is 25.";
          errorMessage += " Please consider upgrading your plan to create more users.";
  
          Swal.fire({
            position: "top-right",
            icon: "error",
            // title: error.message,
            title: errorMessage,
            toast: true,
            showConfirmButton: false,
            timer: 5000
          });
          console.log('Error from create cometchat user', error);
        })
    }
  
    const setCometChatIdFunction = (cometChatData, mentee_id) => {
      const cometChatObj = {
        "user": mentee_id,
        "cometchat_uid": cometChatData.uid
      }
  
      SetCometChatId(cometChatObj).then((response) => {
       // console.log("response", response);
        if (response.status == 200) {
          loginCometChatUser()
        }
      }).catch((error) => {
        setIsLoadings(false);
  
      //  console.log('Error from set cometchat id', error);
        Swal.fire({
          position: "top-right",
          icon: "error",
          title: error.response ? error.response.data.errors : "Something went wrong",
          toast: true,
          showConfirmButton: false,
          timer: 2000
        });
      })
    }
    
// Login user
    const loginCometChatUser = async () => {
    
      try {
        const authToken = localStorage.getItem('cometChatToken')
        
        if(authToken){
          await CometChatUIKit.logout();
        }
        
        //console.log("User logged out successfully");
  
      } catch (error) {
        
        console.error("Logout failed with error:", error);
      }
     try {
      await CometChatUIKit.login(loggedUID, process.env.REACT_APP_COMETCHAT_AUTH_KEY).then((user) => {
        console.log("Login Successful:", user.authToken);
        localStorage.setItem('cometChatToken', user.authToken);
        CometChat.connect();
        getUIDSList();
        setCometChatLoginUserDetail(user);
  
        //mount your app
      })
     
        .catch((error) => {
         // console.log("==========login===========");
          console.log('Error from login cometchat user', error);
        //  console.log("==========login===========");
          createNewCometChatUser();
        });
     } catch (error) {

      console.error("login failed with error:", error);
     }
      
      // });
    }

    
    getCometChatUser();
    
    // setTimeout(() => {
    //   console.log('called 2000')
    //   getUIDSList();
    // },2000)
    

  }, [loggedUID])


  useEffect(() => {
    // get list of users for sidebar
    const getListOfUsers = async () => {


      try {
      
        //let UIDs = ["kaushik", "chintan", "narendram"];
        // let UIDs = JSON.parse(JSON.stringify(usersUIDs))
        if (usersUIDs.length > 0) {
          let limit = 10;
          let usersRequest = new CometChat.UsersRequestBuilder()
            .setLimit(limit)
            .setUIDs(usersUIDs);

          setSelectedUsers(new UsersConfiguration({ usersRequestBuilder: usersRequest }));
        }
      } catch (error) {
        console.log('error: ', error);
      }

    }
    
    if(usersUIDs.length > 0){
      getListOfUsers();
    }
    
  },[usersUIDs])
  //console.log(selectedUsers)

  if (selectedUsers == undefined && cometChatLoginUserDetail == null) return (<><div></div></>);

  return (
    <>
      {isLoadings ?
        (<Loader loader_status={isLoading} />) :
        (
          <main className="helprr-main">
            <OuterHeader />
            <div className="container">
              {localStorage.getItem("is_mentee") == "true" ?
                <Link to="/matched-mentor-list">
                  {/* <img className='back-arrow-icon' src={process.env.PUBLIC_URL + "/assets/images/icon/left-arrow.svg"} alt="" /> */}
                  <section className='book-description'>
                    <button className="back-btn">
                      <i className="fa-solid fa-chevron-left" />
                      Back
                    </button>

                  </section>
                </Link> : ""}
            </div>
            <section className="other-res-pad pt-3">
              <div className="container">
                <div className='comet-main'>

                  {(cometChatLoginUserDetail && selectedUsers) ?
                    <>
                      <CometChatUsersWithMessages isMobileView={isMobile} usersConfiguration={selectedUsers} />
                    </>

                    :
                    <div>We will notify you once we find the right mentor/mentee for you.</div>
                  }

                </div>
              </div>
            </section>

            <Footer />
          </main>)}
    </>
  )
}

export default CometChatPage;

