import { createSlice } from "@reduxjs/toolkit";

const ContentDetails = createSlice({
    name: 'contentdetails',
    initialState: null,
    reducers: {
        setContentDetails(state, action) {
            state = action.payload;
            return state;
        }
    }
});

export const { setContentDetails } = ContentDetails.actions;
export default ContentDetails.reducer;