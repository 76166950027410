import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { SubmitSurveyBookRecommendation, submitquestion, questionMentee, AddBook } from '../api/apiHandler.js'
import Swal from 'sweetalert2'
import Loader from '../components/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../store/slice/loaderSlice';
import axios, { all } from 'axios';
import { setBookDetails } from '../store/slice/bookDetails';


const QForBook = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.loader);
    const [Questionid, setQuestionid] = useState();
    const [option, setoption] = useState();
    const [contenttype, setContentType] = useState();
    const [questiondata, setquestiondata] = useState([]);
    const [surveyId, setSurveyId] = useState(null);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [isState, setIsState] = useState({});
    const [tags, setTags] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState({});
    const [chatGptResponse, setChatGptResponse] = useState([]);

    const [bookResponse, setBookResponse] = useState([]);
    const [survayData, setSurvayData] = useState(null);

    useEffect(() => {
        dispatch(setLoading(true))
        // localStorage.removeItem("booksurveydata");
        questionMentee().then((response) => {
            if (response.status == 200) {
                dispatch(setLoading(false))
                setquestiondata(response.data[0].questions);
                setSurveyId(response.data[0].id);
            }

        }).catch((error) => {
            console.error(error);
        });

    }, []);

    // const handleContinue = () => {
    //     if (currentQuestionIndex < questiondata.length) {
    //         if (isState[Questionid] !== 1) {
    //             setIsState((prevOptions) => ({
    //                 ...prevOptions,
    //                 [Questionid]: 0,
    //             }));
    //         }

    //         if (currentQuestionIndex < 4) {
    //             setCurrentQuestionIndex(currentQuestionIndex + 1);
    //         } else {
    //             setCurrentQuestionIndex(currentQuestionIndex);
    //         }

    //         handlesubmitquestion(option, Questionid);
    //     }
    // };


    const handleContinue = () => {
        if (currentQuestionIndex < questiondata.length) {
            const currentQuestionId = questiondata[currentQuestionIndex].id;
            if (!selectedOptions[currentQuestionId]) {
                // If no option is selected for the current question, return without proceeding
                return;
            }

            // Proceed to the next question
            setCurrentQuestionIndex(currentQuestionIndex + 1);
            handlesubmitquestion(option, Questionid);
        }
    };

    const handlesubmitquestion = (option, Questionid) => {
        // Check if the tags array already contains an object with the same Questionid
        const existingIndex = tags.findIndex((item) => item.Questionid === Questionid);
        if (tags.length < 4) {
            // If the tags array doesn't have an object with the same Questionid, push a new object
            if (existingIndex === -1) {
                tags.push({ Questionid, option });
                setTags([...tags]);
            } else {
                // Replace the existing answer for the same Questionid with the new one
                tags.splice(existingIndex, 1, { Questionid, option });
                setTags([...tags]);
            }
        } else {
            // If the tags array already has an object with the same Questionid, replace it
            if (existingIndex !== -1) {
                // Remove the existing answer for the same Questionid
                tags.splice(existingIndex, 1);
            }

            // Push the new answer
            tags.push({ Questionid, option });

            if (tags.length === 5) {
                const survaydata = {
                    content_type: "book",
                    tags: tags.map(item => item.option),
                };
                setSurvayData(survaydata)
                dispatch(setLoading(true))
                SubmitSurveyBookRecommendation(survaydata)
                    .then((response) => {
                        // localStorage.setItem("booksurveydata", JSON.stringify(survaydata))
                        if (response.status === 200) {

                            if (response.data.books) {
                                // chatgpt response
                                if (response.data.books.length > 0) {
                                    setChatGptResponse(response.data.books);
                                    // dispatch(setLoading(false))
                                }
                                else {
                                    Swal.fire({
                                        position: "top-right",
                                        icon: "error",
                                        title: "Books not found from chatgpt",
                                        toast: true,
                                        showConfirmButton: false,
                                        timer: 2000,
                                    });
                                    dispatch(setLoading(false))
                                }
                            }
                            else {
                                dispatch(setBookDetails(JSON.stringify(response.data.results)));
                                dispatch(setLoading(false))
                                navigate('/book-recommendations')
                            }

                        }
                        // else if (response.status === 204) {
                        //     chatGptApiCall(survaydata)

                        // }
                    })
                    .catch((error) => {
                        dispatch(setLoading(false))
                        Swal.fire({
                            position: "top-right",
                            icon: "error",
                            title: error.response ? error.response.data.errors : "Something went wrong",
                            toast: true,
                            showConfirmButton: false,
                            timer: 2000,
                        });
                    });

                // Clear the tags array after submitting the survey
                setTags([]);
            }
        }
    };

    const handleprev = () => {
        if (currentQuestionIndex >= 1) {
            setCurrentQuestionIndex(currentQuestionIndex - 1);

            // Get the previously selected option for the current question from selectedOptions
            const previousOption = selectedOptions[Questionid] || null;

            // Set the previous option as the current option
            setoption(previousOption);

            // Update isState accordingly
            setIsState((prevOptions) => ({
                ...prevOptions,
                [Questionid]: 1,
            }));
        } else {
            navigate(-1);
        }
    };

    const handlequestion = (question_id, option) => {
        setQuestionid(question_id);
        setoption(option);
        setSelectedOptions((prevOptions) => ({
            ...prevOptions,
            [question_id]: option,
        }));

        setIsState((prevOptions) => ({
            ...prevOptions,
            [question_id]: 1,
        }));
    };

    const chatGptApiCall = async (option) => {

        // let prompt =
        //     `Given a user who is currently ${option.tags[0]}, prefers ${option.tags[1]},
        //     is interested in the theme of ${option.tags[2]}, enjoys ${option.tags[3]},
        //     and seeks ${option.tags[4]}, please recommend books related to mental health, self-help, and personal development  in the following format: \n\n"
        //     "Book Title: [Title]\n"
        //     "Book Author: [Author Name]\n"
        //     "ISBN: [ISBN Number]\n\n"
        //     "Please provide multiple recommendations.`

        let prompt =
            `Given a user who is currently ${option.tags[0]}, prefers ${option.tags[1]},
                    is interested in the theme of ${option.tags[2]}, enjoys ${option.tags[3]},
                    and seeks ${option.tags[4]}, please recommend books related to mental health, self-help, and personal development  in the following format: \n\n"
                    "Book Title: [Title]\n"
                    "Book Author: [Author Name]\n"
                    "ISBN: [ISBN Number]\n\n"
        "Amazon buy link with amazon affiliate associate tag  helprrai-20 \n\n "
                    "Please provide multiple recommendations`
        //console.log("prompt", prompt);

        let requestData = {
            "model": "gpt-3.5-turbo-instruct",
            "max_tokens": 512,
            "temperature": 0,
            "prompt": prompt
        };

        axios.post(process.env.REACT_APP_CHATGPT_API_URL, requestData, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${process.env.REACT_APP_CHATGPT_API_KEY}`
            },
        }).then((res) => {
            // const parsedData = res.data.choices[0].text;
            // const titles = parsedData.match(/Book Title: (.+?)\n/g).map(title => title.replace('Book Title: ', '').trim());

            // if (titles.length > 0) {
            //     setChatGptResponse(titles);
            // }
            const parsedData = res.data.choices[0].text;
            const bookEntries = parsedData.split('\n\n');
            const books = [];

            bookEntries.forEach(entry => {
                const lines = entry.split('\n');
                const titleLine = lines.find(line => line.startsWith('Book Title: '));
                const buyLinkLine = lines.find(line => line.startsWith('Amazon buy link: '));
                if (titleLine && buyLinkLine) {
                    const title = titleLine.split(': ')[1];
                    const buyLink = buyLinkLine.split(': ')[1];
                    books.push({ title, buyLink });
                }
            });

            if (books.length > 0) {
                setChatGptResponse(books);
            }
        })
            .catch((error) => {
                dispatch(setLoading(false))
                Swal.fire({
                    position: "top-right",
                    icon: "error",
                    title: "Something went wrong",
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                });
                // console.error("chatgpr---error---------", error);
            });
    }

    const googleBookApi = (book_data) => {
        const encodedSearchTerm = encodeURIComponent(book_data.title);
        const apiUrl = `${process.env.REACT_APP_GOOGLE_BOOK_API_URL}/volumes?q=${encodedSearchTerm}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`;
        // console.log("googel book url", apiUrl);
        axios.get(apiUrl).then((res) => {
            // console.log("item---1", res.data.items[0]);
            // const bookTags = JSON.parse(localStorage.getItem("booksurveydata"));
            // const isbn13 = res.data.items[0].volumeInfo.industryIdentifiers.find(identifier => identifier.type === "ISBN_13").identifier;
            // const last10Digits = isbn13.slice(-10);
            // console.log("last10Digits", last10Digits);
            const addBookObj = {
                "title": res.data.items[0].volumeInfo.title != undefined ? res.data.items[0].volumeInfo.title : res.data.items[0].volumeInfo.subtitle,
                "description": res.data.items[0].volumeInfo.description.toLowerCase(),
                "authors": res.data.items[0].volumeInfo.authors,
                "tags": survayData.tags,
                "thumbnail_url": res.data.items[0].volumeInfo.imageLinks.thumbnail,
                "content_url": book_data.buyLink,
                "average_rating": res.data.items[0].volumeInfo.averageRating != undefined ? res.data.items[0].volumeInfo.averageRating : 0
            }
            setBookResponse(prevBooks => [...prevBooks, addBookObj]);
        }).catch((error) => {
            dispatch(setLoading(false))
            Swal.fire({
                position: "top-right",
                icon: "error",
                title: "Something went wrong",
                toast: true,
                showConfirmButton: false,
                timer: 2000
            });
            console.log("error", error);
        });
    }

    // if(chatGptResponse.length > 0){
    //     chatGptResponse.map((item)=>{
    //         console.log("item",item);
    //         setTimeout(()=>{
    //             googleBookApi(item)
    //         },3000)
    //     })
    // }

    useEffect(() => {

        const fetchBooks = () => {
            dispatch(setLoading(true))
            var bar = new Promise((resolve, reject) => {
                chatGptResponse.forEach((item, index) => {
                    setTimeout(() => {
                        googleBookApi(item);
                    }, 2000 * index);
                    if (index == chatGptResponse.length - 1) resolve();
                });
            });
            bar.then(() => {
                console.log('All done!');
            });

        };

        if (chatGptResponse.length > 0) {
            fetchBooks();
        }

    }, [chatGptResponse]);



    const AddAllBook = () => {

        AddBook({ "books": bookResponse }).then((response) => {

            if (response.status == 200) {
                dispatch(setBookDetails(JSON.stringify(response.data)));
                dispatch(setLoading(false))
                setChatGptResponse([])
                setBookResponse([])
                navigate("/book-recommendations")
            }

        }).catch((error) => {
            dispatch(setLoading(false))
            Swal.fire({
                position: "top-right",
                icon: "error",
                title: error.response ? error.response.data.errors : "Something went wrong",
                toast: true,
                showConfirmButton: false,
                timer: 2000
            });
        });
    }

    if (chatGptResponse.length > 0 && bookResponse.length > 0) {
        if (chatGptResponse.length == bookResponse.length) {
            AddAllBook()
        }
    }

    return (
        <>
            {isLoading ?
                (<Loader loader_status={isLoading} />) :
                (
                    <main>
                        <section className="section-survey">
                            <div className="container">
                                <div>
                                    <img className='back-arrow-icon cursor-pointer' onClick={handleprev} src={process.env.PUBLIC_URL + "/assets/images/icon/left-arrow.svg"} alt="" />
                                </div>

                                <div className="row justify-content-center">
                                    {questiondata && questiondata.map((question, index) => (
                                        <div key={question.id} className={`col-lg-7 col-xl-7 col-12 ${index === currentQuestionIndex ? '' : 'd-none'}`}>
                                            <p className="text-black font_30 font_600 mb-5 mt-4">{question.question}</p>
                                            {question.options && question.options.map((option, idx) => (
                                                <Link to="#" className={
                                                    selectedOptions[question.id] === option.option
                                                        ? "select-survey-ans selected-option"
                                                        : "select-survey-ans"
                                                } key={option.id} onClick={() => handlequestion(question.id, option.option)} >
                                                    {option.option}
                                                </Link>
                                            ))}
                                        </div>
                                    ))}
                                </div>

                                <div className="row justify-content-center">
                                    <div className="col-lg-6 col-xl-6 col-12">
                                        <button to="#" onClick={handleContinue} disabled={!selectedOptions[Questionid]} className="select-option mt-4 w-100">
                                            Continue
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </section>
                    </main>
                )}
        </>
    )
}

export default QForBook