import React, { useState, useEffect } from 'react'
import OuterHeader from '../components/OuterHeader'
import Footer from '../components/Footer'
import { HealingJourneydata } from '../api/apiHandler'


const CommunityLeadership = () => {

    const [healingdata, sethealingdata] = useState()

    useEffect(() => {
        HealingJourneydata().then((response) => {
            sethealingdata(response.data.healing_journey_data)

        }).catch((error) => {
            console.log(error);
        })
    }, [])

    const calculateTotalCommunityLeadership = () => {
        if (healingdata && healingdata.community_leadership) {
            const { asked_questions, answered_questions, mentorship } = healingdata.community_leadership;
            return asked_questions + answered_questions + mentorship;
        }
        return 0; // Default value if data is not available
    };



    return (
        <>
            <main className="helprr-main">
                <OuterHeader />
                <section className="bg-orange">
                    <div className="banner-sec mt-0">
                        <div className="container">
                            <div className="banner-profile-sec">
                                <div className="banner-profile-img">
                                    {localStorage.getItem("photo") != undefined && localStorage.getItem("photo") != null && localStorage.getItem("photo") != "" ?
                                        <img src={localStorage.getItem("photo")} className="account-dropdown" alt="" /> :
                                        <img src={process.env.PUBLIC_URL + "/assets/images/defalt.png"} className="account-dropdown" alt="" />
                                    }
                                </div>
                                <div className="banner-text">
                                    <p className="mb-0 font_18 text-white font_700">Welcome back</p>
                                    <p className="mb-0 font_26 text-white font_700"> {localStorage.getItem("username")}</p>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="back-section pb-5">
                        <div className="container position-relative">
                            <div className="action-section">
                                <div className="card-progress first-card">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div>
                                            <p className="mb-0  font_22">Community Leadership</p>
                                            <div className="award-progress mt-4">
                                                <div className="mb-4 d-flex gap-3">
                                                    <div className="icon-card">
                                                        <img src={process.env.PUBLIC_URL + "/assets/images/icon/award.png"} alt="award" />
                                                    </div>
                                                    <div>
                                                        <p className="mb-0 font_18 font_500"> Your Score</p>
                                                        <p className="mb-0 font_25 font_700">{calculateTotalCommunityLeadership()}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-image">
                                            <img src={process.env.PUBLIC_URL + "/assets/images/card-Image.png"} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="task-list">
                                <p className="font_30 font_500 text-orange">All Activities</p>
                            </div>
                            <div className="card-progress bg-mave">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div>
                                        <p className="mb-2 text font_22">Questions Asked</p>
                                        <p className="mb-0 text font_20 font_700">{healingdata && healingdata.community_leadership.asked_questions}</p>
                                    </div>
                                    <div className="card-image">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/icon/Card-Image-2.png"} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="card-progress bg-mave">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div>
                                        <p className="mb-2 text font_22">Questions Answered</p>
                                        <p className="mb-0 text font_20 font_700">{healingdata && healingdata.community_leadership.answered_questions}</p>
                                    </div>
                                    <div className="card-image">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/icon/Card-Image-2.png"} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="card-progress bg-mave">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div>
                                        <p className="mb-2 text font_22">Mentorship</p>
                                        <p className="mb-0 text font_20 font_700">{healingdata && healingdata.community_leadership.mentorship}</p>
                                    </div>
                                    <div className="card-image">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/icon/Card-Image-2.png"} alt="" />
                                    </div>
                                </div>
                            </div>
                            {/* <div className="row mt-5 justify-content-center">
                                <div className="col-lg-3 col-md-6 col-6">
                                    <a href="#" className="edit-btn">
                                        Load more
                                    </a>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </section>
                <Footer />
            </main>

        </>
    )
}

export default CommunityLeadership