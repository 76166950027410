import React, { useState, useEffect, useRef } from 'react'
import Footer from '../components/Footer'
import OuterHeader from '../components/OuterHeader'
import { Link, useParams, useNavigate } from 'react-router-dom'
import Select2 from '../pages/Select2';
import '../css2/select2.css'
import ScrollToTop from '../components/ScrollToTop';
import { ContentDetails, AddRemoveSavedContent, ContentListdetails } from '../api/apiHandler.js'
import Swal from 'sweetalert2'




const BookDescription = () => {
    const bookRef = useRef(null);
    const navigate = useNavigate()
    const params = useParams();
    const [scroll, setScroll] = useState(true)
    const [data, setdata] = useState()
    const [rating, setRating] = useState()
    const [savedata, setSaveddata] = useState()
    console.log(savedata, "savedataaaa");
    const [nocontent, setNocontent] = useState()



    const handlesavecontent = (selectedValue) => {
        console.log('selectedValue: ', selectedValue);


        if (selectedValue !== null) {
            let status;
            if (selectedValue === 'Read Later') {
                status = 'read_later';
            } else if (selectedValue === 'Reading Now') {
                status = 'reading';
            } else if (selectedValue === 'Read') {
                status = 'read';
            }
            const mybookdata = {
                content_id: params.id,
                status: status
            }
            console.log('mybookdata: ', mybookdata);

            AddRemoveSavedContent(mybookdata).then((response) => {
                if (response.status == 201) {
                    Swal.fire({
                        position: "top-right",
                        icon: "success",
                        title: "Book status successfully updated",
                        toast: true,
                        showConfirmButton: false,
                        timer: 2000
                    });
                }
            }).catch((error) => {
                console.log(error);
            })
        }

    }

    useEffect(() => {
        getbookdetails(params.id)
    }, [])

    const getbookdetails = (id) => {

        ContentDetails({ id: id }).then((response) => {
            if (response.status == 200) {
                setdata(response.data)
                setRating(response.data.average_rating)
                // navigate(`/book-description/${id}`)
            }

        }).catch((error) => {
            console.log(error);
        })


        ContentListdetails().then((response) => {
            let filterdata = response.data.results.filter(element => {
                return element.id != id
            });
            filterdata = filterdata.slice(0, 6);
            if (response.status == 200) {

                setSaveddata(filterdata)
                setNocontent([{}])

            } else if (response.status == 404 || response.status == 204) {
                setNocontent(null)
            }
        }).catch((error) => {
            console.log(error);
            // Swal.fire({
            //     position: "top-right",
            //     icon: "error",
            //     title: error.response.data.errors,
            //     toast: true,
            //     showConfirmButton: false,
            //     timer: 2000
            // });
        })
        // if (bookRef.current) {
        //     bookRef.current.scrollIntoView({ behavior: 'smooth' });
        // }
    }


    useEffect(() => {
        const disableBackButton = (e) => {
            e.preventDefault();
            window.history.forward(); // Forward the user when they try to go back
        };

        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', disableBackButton); // Listen for back button press

        return () => {
            window.removeEventListener('popstate', disableBackButton); // Cleanup the event listener when the component unmounts
        };
    }, []);

    const handleback = () => {

        if (localStorage.getItem("questions_for") == "book recommendations") {
            navigate("/book-recommendations")
        } else {
            navigate("/content")
        }
    }



    return (

        <>
            {scroll ? <ScrollToTop /> : null}

            <main className="helprr-main">

                <OuterHeader />

                <section className="event-description book-description">
                    <div className="container">
                        <button className="back-btn mb-5" onClick={handleback}>
                            <i className="fa-solid fa-chevron-left pe-2" />
                            Back
                        </button>
                        <div className="row justify-content-between">
                            <div className="col-lg-5 col-xl-4 col-xxl-4 col-12">
                                <div className="book-img-rcom mb-5">
                                    <img src={data && data.thumbnail_url} alt="" />
                                </div>
                                <div className="mb-4 read-book-select">
                                    <Select2 data-minimum-results-for-search="Infinity" className="country-select bg-light form-control js-example-basic-single"
                                        data={['Read Later', 'Reading Now', 'Read']}
                                        options={
                                            {
                                                placeholder: 'Read',
                                                tags: false
                                            }

                                        }
                                        value={null}
                                        onChange={(e) => handlesavecontent(e.target.value)}
                                    />



                                </div>
                                <a href={data && data.content_url} className="buy-now mb-4 w-100" target='_blank'>
                                    Buy Now
                                </a>
                                {/* <button class="buy-now w-100 mb-4">Buy Now</button> */}
                            </div>
                            <div className="col-lg-6 col-xl-6 col-xxl-6 col-12">
                                <div className="mb-sm-5">
                                    <p className="font_30 font_700 text-black mb-2">{data && data.title}</p>
                                    <p className="font_24 font_700 text-black mb-sm-5">{data && data.authors_details && data.authors_details[0]}</p>
                                    <div className="mb-sm-5">
                                        <p className="font_24 font_700 text-black mb-2">Description</p>
                                        <p className="font_16 text-gray">
                                            {data && data.description}
                                        </p>
                                    </div>
                                    {/* <div className="mb-sm-5">
                                        <p className="font_24 font_700 text-black">About the Author</p>
                                        <p className="font_16 text-gray">
                                            Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                            Exercitationem porro nostrum aliquid! Corrupti cumque, aperiam
                                            quas tenetur saepe quasi, commodi at quisquam, explicabo ducimus
                                            voluptatem.
                                        </p>
                                    </div> */}
                                </div>
                                <div className="mb-sm-5">
                                    <p className="font_24 font_700 text-black">Ratings</p>
                                    <div className="d-flex gap-2 stars-img">
                                        {rating && Math.floor(rating) == 1 ?
                                            <>
                                                <img src={process.env.PUBLIC_URL + "/assets/images/icon/stars-bar-yellow.png"} alt="star" />
                                                <img src={process.env.PUBLIC_URL + "/assets/images/icon/stars-bar-gray.png"} alt="star" />
                                                <img src={process.env.PUBLIC_URL + "/assets/images/icon/stars-bar-gray.png"} alt="star" />
                                                <img src={process.env.PUBLIC_URL + "/assets/images/icon/stars-bar-gray.png"} alt="star" />
                                                <img src={process.env.PUBLIC_URL + "/assets/images/icon/stars-bar-gray.png"} alt="star" />
                                            </> : Math.floor(rating) == 2 ?
                                                <>
                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/stars-bar-yellow.png"} alt="star" />
                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/stars-bar-yellow.png"} alt="star" />
                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/stars-bar-gray.png"} alt="star" />
                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/stars-bar-gray.png"} alt="star" />
                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/stars-bar-gray.png"} alt="star" />
                                                </> : Math.floor(rating) == 3 ?
                                                    <>
                                                        <img src={process.env.PUBLIC_URL + "/assets/images/icon/stars-bar-yellow.png"} alt="star" />
                                                        <img src={process.env.PUBLIC_URL + "/assets/images/icon/stars-bar-yellow.png"} alt="star" />
                                                        <img src={process.env.PUBLIC_URL + "/assets/images/icon/stars-bar-yellow.png"} alt="star" />
                                                        <img src={process.env.PUBLIC_URL + "/assets/images/icon/stars-bar-gray.png"} alt="star" />
                                                        <img src={process.env.PUBLIC_URL + "/assets/images/icon/stars-bar-gray.png"} alt="star" />
                                                    </> : Math.floor(rating) == 4 ?
                                                        <>
                                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/stars-bar-yellow.png"} alt="star" />
                                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/stars-bar-yellow.png"} alt="star" />
                                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/stars-bar-yellow.png"} alt="star" />
                                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/stars-bar-yellow.png"} alt="star" />
                                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/stars-bar-gray.png"} alt="star" />
                                                        </> : Math.floor(rating) == 5 ?
                                                            <>
                                                                <img src={process.env.PUBLIC_URL + "/assets/images/icon/stars-bar-yellow.png"} alt="star" />
                                                                <img src={process.env.PUBLIC_URL + "/assets/images/icon/stars-bar-yellow.png"} alt="star" />
                                                                <img src={process.env.PUBLIC_URL + "/assets/images/icon/stars-bar-yellow.png"} alt="star" />
                                                                <img src={process.env.PUBLIC_URL + "/assets/images/icon/stars-bar-yellow.png"} alt="star" />
                                                                <img src={process.env.PUBLIC_URL + "/assets/images/icon/stars-bar-yellow.png"} alt="star" />
                                                            </> : <>
                                                                <img src={process.env.PUBLIC_URL + "/assets/images/icon/stars-bar-gray.png"} alt="star" />
                                                                <img src={process.env.PUBLIC_URL + "/assets/images/icon/stars-bar-gray.png"} alt="star" />
                                                                <img src={process.env.PUBLIC_URL + "/assets/images/icon/stars-bar-gray.png"} alt="star" />
                                                                <img src={process.env.PUBLIC_URL + "/assets/images/icon/stars-bar-gray.png"} alt="star" />
                                                                <img src={process.env.PUBLIC_URL + "/assets/images/icon/stars-bar-gray.png"} alt="star" />
                                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="other-events bg-transparent">
                    <div className="container">
                        {nocontent && nocontent != null && nocontent != "" ?
                            <>
                                <p className="font_24 text-black font_700 pb-sm-4">
                                    Other books you may like
                                </p>
                            </>
                            : ""}
                        <div className="row">
                            {savedata && savedata.map((item, key) => {
                                return (
                                    <>
                                        <div className="col-lg-4 col-xl-4 col-md-6 col-12 mb-3" >
                                            <div className="event-card" onClick={() => getbookdetails(item.id)}>
                                                <div className="book-img-rcom">
                                                    <img src={item.thumbnail_url} alt="event" />
                                                    {/* <div class="free-tab">free</div> */}
                                                </div>
                                                <div>
                                                    <p className="text-black mb-2">{item.title}</p>
                                                    <p className="font_12 text-purple mb-2 text-truncate">
                                                        {item.description}
                                                    </p>
                                                    <p className="font_12 text-gray mb-0">{item.authors_details}</p>
                                                    <a
                                                        href={item.content_url}
                                                        className="buy-btn"
                                                        target='_blank'
                                                    >
                                                        Buy Now
                                                    </a>
                                                    <a href="javascript:;" className="buy-btn bg-mave" onClick={() => handlesavecontent("Read Later")}>
                                                        Read Later
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            })}


                        </div>
                    </div>
                </section>
                <Footer />
            </main>

        </>


    )
}

export default BookDescription