import React from 'react'
import { Link } from 'react-router-dom'


const ActionPlanBookRecommendation = () => {

    const handlebookrecommendations = () => {
        localStorage.setItem('questions_for', "book recommendations")
        localStorage.setItem('book-question', true)
    }

    const handleactionplan = () => {
        localStorage.setItem('questions_for', "action plan")
        localStorage.setItem('actionplan-question', true)
    }

    return (
        <>
            <main>
                <section className="section-survey">
                    <div className="container">
                        <div>
                            <Link to="/">
                                <img className='back-arrow-icon' src={process.env.PUBLIC_URL + "/assets/images/icon/left-arrow.svg"} alt="" />
                            </Link>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-lg-7 col-xl-7 col-12">
                                <p className="text-black font_30 font_600 mb-5 mt-4 text-center">
                                    What are you interested in?
                                </p>
                                <Link to="/book-question" className="select-survey-ans" onClick={handlebookrecommendations}>
                                    Book Recommendations
                                </Link>
                                <Link to="/actionplan-question" className="select-survey-ans" onClick={handleactionplan}>
                                    Action Plan
                                </Link>

                            </div>
                        </div>

                    </div>
                </section>
            </main>

            <div
                className="modal fade"
                id="congratulation-modal"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered start-topic-modal">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">
                                <i className="fa-solid fa-circle-exclamation text-white pe-1" />{" "}
                                Congratulations!!
                                <img className='icon ps-2' src={process.env.PUBLIC_URL + "/assets/images/icon/congratulations.png"} alt="icon" />
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            />
                        </div>

                        <div className="modal-body pt-0 ps-0">
                            <div className="mb-3 ps-5">
                                <p className='font_14 text-white'>Kudos to you for taking steps towards a better you, you will start receiving your action items in your helprr profile starting tomorrow! Good luck and happy healing :)


                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}

export default ActionPlanBookRecommendation
