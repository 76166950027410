import { createSlice } from "@reduxjs/toolkit";

const ActionplanDetails = createSlice({
    name: 'actionplandetails',
    initialState: null,
    reducers: {
        setActionplanDetails(state, action) {
            state = action.payload;
            return state;
        }
    }
});

export const { setActionplanDetails } = ActionplanDetails.actions;
export default ActionplanDetails.reducer;