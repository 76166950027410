import React, { useState } from 'react'
import OuterHeader from '../components/OuterHeader'
import Footer from '../components/Footer'
import { Link } from 'react-router-dom'
import ScrollToTop from '../components/ScrollToTop';
import Header from '../components/Header';



const CommunityHomePage = () => {
    const [scroll, setScroll] = useState(true)
    console.log("enter or not");
    return (
        <>
            {scroll ? <ScrollToTop /> : null}

            <main className="helprr-main">
                {localStorage.getItem("token") ?
                    <OuterHeader /> :
                    <Header />
                }

                <section className="other-res-pad pt-3">
                    <div className="container">
                        <p className="font_48 font_600">
                            How would you like to engage with the community?
                        </p>
                        <div className="row">
                            <div className="col-md-6 col-lg-6 col-xl-6 col-6">
                                <Link to="/events" className="events--1" onClick={() => localStorage.setItem("Find_events", true)}>
                                    <p className="font_46 font_600  mb-0">Find Local/ Virtual Events</p>
                                </Link>
                                <Link to="/peer-mentorship" className="events--2" onClick={() => localStorage.setItem("Peer_mentor", true)}>
                                    <p className="font_46 font_600 mb-0">
                                        Find or Become a Peer Mentor
                                    </p>
                                </Link>
                            </div>
                            <div className="col-md-6 col-lg-6 col-xl-6 col-6">
                                <div className="vector-networking">
                                    <img src={process.env.PUBLIC_URL + "/assets/images/vecor-networking.svg"} alt="" />
                                </div>
                                <Link to="/group-discussions" className="events--3">
                                    <p className="font_46 font_600 mb-0">See Group Discussions</p>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </main>

        </>
    )
}

export default CommunityHomePage