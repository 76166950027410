import React, { useEffect, useState } from 'react'
import OuterHeader from '../components/OuterHeader'
import Header from '../components/Header';
import Footer from '../components/Footer'
import Slider from "react-slick";
import { SubmitSurveyBookRecommendation, AddRemoveSavedContent } from '../api/apiHandler.js'
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../components/Loader';
import { setLoading } from '../store/slice/loaderSlice';


const BookRecommendations = () => {

    const bookDetails = useSelector((state) => JSON.parse(state.bookdetails));
    const [bookData, setBookData] = useState(bookDetails);
    const [handleLoader, setHandleLoader] = useState(true);
    const [nocontent, setNocontent] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(bookDetails && bookDetails.length);
    const itemsPerPage = 10;
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.loader);
    const navigate = useNavigate();

    // useEffect(() => {
    //     bookList({ page: currentPage })
    // }, [currentPage])

    // const bookList = (pagedata) => {
    //     if (handleLoader) {
    //         dispatch(setLoading(true))
    //     }
    //     SubmitSurveyBookRecommendation(JSON.parse(localStorage.getItem("booksurveydata")), pagedata)
    //         .then((response) => {
    //             dispatch(setLoading(false))
    //             setHandleLoader(false)
    //             if (response.status === 200) {
    //                 console.log("res", response.data);
    //                 setBookData(response.data.results)
    //                 setTotalCount(response.data.total_count)
    //             } else if (response.status === 204) {
    //                 setNocontent(204)
    //                 navigate("/interests-page")
    //                 Swal.fire({
    //                     position: "top-right",
    //                     icon: "info",
    //                     title: "Didn't found any book based on your survey answers",
    //                     toast: true,
    //                     showConfirmButton: false,
    //                     timer: 2000,
    //                 });
    //             }
    //         })
    //         .catch((error) => {
    //             dispatch(setLoading(false))
    //             Swal.fire({
    //                 position: "top-right",
    //                 icon: "error",
    //                 title: error.response.data.errors,
    //                 toast: true,
    //                 showConfirmButton: false,
    //                 timer: 2000,
    //             });
    //         });
    // }

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < Math.ceil(totalCount / itemsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };



    const handleReadLater = (book_id) => {
        if (localStorage.getItem("islogin") == "true") {
            const bookSavedObj = {
                "content_id": book_id,
                "status": "read_later"
            }
            AddRemoveSavedContent(bookSavedObj).then((response) => {
                if (response.status == 201) {
                    Swal.fire({
                        position: "top-right",
                        icon: "success",
                        title: "Book status successfully updated",
                        toast: true,
                        showConfirmButton: false,
                        timer: 2000
                    });
                }
            }).catch((error) => {
                console.log(error);
                Swal.fire({
                    position: "top-right",
                    icon: "error",
                    title: error.response ? error.response.data.errors : "Something went wrong",
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                });
            })
        } else {
            navigate('/login')
        }
    }

    return (
        <>
            {isLoading ?
                (<Loader loader_status={isLoading} />) :
                (
                    <main className="helprr-main">

                        {localStorage.getItem("token") ?
                            <OuterHeader /> :
                            <Header />
                        }
                        <section className="other-res-pad pt-0 pb-0 mb-3">
                            <div className="container">
                                <div className="d-flex justify-content-between align-items-center mb-4">
                                    <p className="font_46 font_600 mb-0">Book Recommendations</p>
                                </div>
                                <div className="testimonial-area">
                                    <div className="testimonial-slider-bookrec justify-content-center">
                                        <div className='row'>
                                            {/* <Slider {...settings}> */}
                                            {bookData && bookData.map((item) => {
                                                return (
                                                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 wow fadeInUp cursor-pointer mb-3" data-wow-delay=".2s" key={item.id}>
                                                        <div className="single-testi"

                                                        >
                                                            <div className="self-care-card recom-card">

                                                                <div className="selfcare1-img" onClick={() => navigate(`/book-description/${item.id}`)}>
                                                                    <img src={item.thumbnail_url} alt="" />
                                                                </div>
                                                                <div className="title pt-0 bg-cream">
                                                                    <p className="font_20 mb-1 ">{item.title}</p>
                                                                    <p className="mb-0 font_14 text">
                                                                        {item.description}
                                                                    </p>
                                                                    <p className="font_14 mb-0 font_500 mt-1">{item.authors_details[0]}</p>
                                                                    <Link
                                                                        to={item.content_url}
                                                                        className="buy-btn"
                                                                        target="_blank"
                                                                    >
                                                                        Buy Now
                                                                    </Link>

                                                                    <Link className="buy-btn bg-mave" onClick={() => handleReadLater(item.id)}>Read Later</Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                )
                                            })}
                                            {(nocontent && nocontent == 204) ? "" :
                                                <div className="row justify-content-center mt-sm-5" >
                                                    <div className="col-lg-2 col-lg-3 col-xl-2 col-md-4 col-6">
                                                        {/* {currentPage == 1 ? "" :  */}
                                                        <button type="button" className={currentPage === 1 ? "load-btn-disable w-100" : "load-btn w-100"} onClick={handlePrevPage} disabled={currentPage === 1}>
                                                            Previous
                                                        </button>
                                                        {/* } */}

                                                    </div>
                                                    <div className="col-lg-2 col-xl-2 col-md-4 col-6">
                                                        {/* {currentPage === Math.ceil(totalCount / itemsPerPage) ? "" :  */}
                                                        <button type="button" className={currentPage === Math.ceil(totalCount / itemsPerPage) ? "load-btn-disable w-100" : "load-btn w-100"} onClick={handleNextPage} disabled={currentPage === Math.ceil(totalCount / itemsPerPage)}>
                                                            Next
                                                        </button>
                                                        {/* } */}

                                                    </div>
                                                </div>}
                                            {/* </Slider> */}
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </main>
                )
            }

        </>
    )
}

export default BookRecommendations