import React from 'react';
import '../css2/loader.css';

export default function Loader() {
    return (
        <div className="loader-container">
            <img src={process.env.PUBLIC_URL + "/assets/images/helprr_loading.gif"} alt="" />
        </div>
    )
}


