// src/cometchatInit.js

import { UIKitSettingsBuilder, UsersConfiguration } from "@cometchat/uikit-shared";
import { CometChatUIKit, CometChatLocalize } from "@cometchat/chat-uikit-react";

const COMETCHAT_CONSTANTS = {
    APP_ID: process.env.REACT_APP_COMETCHAT_APP_ID, //Replace with your App ID
    REGION: process.env.REACT_APP_COMETCHAT_APP_REGION, //Replace with your App Region
    AUTH_KEY: process.env.REACT_APP_COMETCHAT_AUTH_KEY  //Replace with your Auth Key
}

const cometChatTitle = 'Custom Title';

let isInitialized = false;

export const initializeCometChat = () => {

  if (isInitialized) return Promise.resolve();

   const UIKitSettings = new UIKitSettingsBuilder()
            .setAppId(COMETCHAT_CONSTANTS.APP_ID)
            .setRegion(COMETCHAT_CONSTANTS.REGION)
            .setAuthKey(COMETCHAT_CONSTANTS.AUTH_KEY)
            .subscribePresenceForFriends(false)
            .setAutoEstablishSocketConnection(false)
            .build();
      
            //Initialize CometChat UIKit
     return  CometChatUIKit.init(UIKitSettings).then(() => {
            CometChatLocalize.init("en", {
                en: {
                    "USERS": cometChatTitle
                }
            });
           
            // You can now call login function.
        }).catch(console.log);

};
