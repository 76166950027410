import React from 'react'
import { useNavigate } from 'react-router-dom'
import OuterHeader from '../components/OuterHeader';
import Footer from '../components/Footer';
import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import checkValidation from '../validation/ValidationSchema';
import { Contactus } from '../api/apiHandler.js';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../store/slice/loaderSlice';
import Loader from '../components/Loader';
import Header from '../components/Header.js';


const ContactUs = () => {
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.loader);
    const navigate = useNavigate();
    const schema = checkValidation(["Firstname", "Lastname", "Email", "Message"]);

    const { register, handleSubmit, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });
    const onSubmit = (data, e) => {
        const contactUsObj = {
            first_name: data.Firstname,
            last_name: data.Lastname,
            email: data.Email,
            message: data.Message
        }

        Contactus(contactUsObj).then((response) => {
            if (response.status === 201) {

                Swal.fire({
                    position: "top-right",
                    icon: "success",
                    title: "Thank you for contacting helprr",
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                });

                Object.keys(data).forEach((field) => {
                    setValue(field, '');
                });

            }

        }).catch((error) => {
            Swal.fire({
                position: "top-right",
                icon: "error",
                title: error.response.data.errors,
                toast: true,
                showConfirmButton: false,
                timer: 2000
            });
            // dispatch(setLoading(false))
            console.log(error, 'error');
        })
    }


    return (
        <>
            <main className="helprr-main">
                {localStorage.getItem("token") ?
                    <OuterHeader /> :
                    <Header />
                }
                <section className="login-sec faq-sec">
                    <div className="container">
                        <div className="row justify-content-center align-items-end">
                            <div className="col-lg-6 col-xl-6 col-12">
                                <p className="font_46 font_600">Contact Us</p>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="mb-4">
                                        <div className="group-custom input-group ">
                                            <input
                                                className=" form-control border-0 font_18"
                                                id="exampleFormControlInput1"
                                                placeholder="First name"
                                                name="Firstname"
                                                {...register("Firstname")}
                                            />
                                        </div>
                                        {errors.Firstname && <p role="alert" className='error-text'>{errors.Firstname.message}</p>}
                                    </div>
                                    <div className='mb-4'>
                                        <div className="group-custom input-group ">
                                            <input
                                                className="form-control border-0 font_18"
                                                id="exampleFormControlInput2"
                                                placeholder="Last name"
                                                name='Lastname'
                                                {...register("Lastname")}

                                            />
                                        </div>
                                        {errors.Lastname && <p role="alert" className='error-text '>{errors.Lastname.message}</p>}
                                    </div>
                                    <div className="mb-4">
                                        <div className="group-custom input-group ">
                                            <input
                                                className="form-control border-0 font_18"
                                                id="exampleFormControlInput3"
                                                name='Email'
                                                placeholder="Email"
                                                {...register("Email")}
                                            />
                                        </div>
                                        {errors.Email && <p role="alert" className='error-text'>{errors.Email.message}</p>}

                                    </div>
                                    <div className="mb-4">
                                        <div className="group-custom input-group overflow-hidden">
                                            <textarea
                                                className="form-control border-0 font_18"
                                                id="exampleFormControlInput4"
                                                name='Message'
                                                placeholder="Message"
                                                {...register("Message")}
                                            />
                                        </div>
                                    </div>
                                    <button type='submit' className="login-btn font_20">
                                        Send
                                    </button>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-3 col-xl-3 col-12"></div>
                    </div>
                </section>
                <Footer />
            </main>
        </>

    )
}

export default ContactUs