import React, { useState, useRef, useEffect, createContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCards } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-cards';
import { Helmet } from 'react-helmet';
import { HelmetProvider } from 'react-helmet-async';
import { logout, userDetails, todayAffirmation, UpdateNotifications, Notifications, actionplantasktoday, actionplantaskupdate, UpdateUserDetails, actionplantasklist } from '../api/apiHandler.js'
import Swal from 'sweetalert2'
import html2canvas from 'html2canvas';
import { User } from '@cometchat/chat-sdk-javascript';
import { useDispatch, useSelector } from 'react-redux';
import { startsearching } from '../store/slice/masterslice.js';
import { setActionplanDetails } from '../store/slice/actionplandetails.js';


const CommunityHeader = () => {
    const dispatch = useDispatch();
    const isSearching = useSelector((state) => state.masterSlice);
    // console.log('isSearching: ', isSearching);
    const [data, setData] = useState()
    const [wohooSlider, setWohooSlider] = useState(0);
    const [affirmationdata, setAffirmationdata] = useState();
    const [notifacationdata, setnotificationdata] = useState()
    const [notifacationcount, setnotificationcount] = useState(0)
    const [todaytask, setTodayTask] = useState()
    const SearchContext = createContext();
    const [searchValue, setSearchValue] = useState("");
    // const [collapsed, setCollapsed] = useState(false);
    const navigate = useNavigate();
    const textareaRef = useRef(null);
    const [description, setDescription] = useState(null);
    var reduxUserData = useSelector((state) => state.userdata);
    reduxUserData = (reduxUserData != undefined) ? JSON.parse(reduxUserData) : null;
    const [loginUserData, setLoginUserData] = useState(reduxUserData);



    // let textarea = document.getElementById("exampleFormControlTextarea1");

    const swiperRef = useRef(null);
    const submitButtonRef = useRef(null);
    const skipButtonRef = useRef(null);

    function myFunctionswiper() {
        const flexCheckChecked1 = document.getElementById("flexCheckChecked1");
        const flexCheckChecked2 = document.getElementById("flexCheckChecked2");
        setWohooSlider(0);
        flexCheckChecked1.checked = false
        flexCheckChecked2.checked = false
        var element = document.getElementById("panel");
        element.classList.add("stylepanel");


        const swiper = swiperRef.current.swiper;
        swiper.slideTo(0);
    }

    // function closemodal() {
    //     setWohooSlider(0)
    //     textareaRef.current.value = "";
    //     document.getElementById("panel").classList.remove("stylepanel");
    // }

    function closemodal() {
        let tasknote
        if (textareaRef.current.value != "") {
            tasknote = {
                "is_completed": true,
                "task_feedback": textareaRef.current.value
            }
        } else {
            tasknote = {
                "is_completed": true,
                "task_feedback": null
            }
        }
        actionplantaskupdate(tasknote).then((response) => {
            if (response.status == 200) {
                const updatenotificationobj = {
                    "id": localStorage.getItem("notificationid"),
                    "is_viewed": true
                }
                updatednotification(updatenotificationobj)
                localStorage.setItem("actionplandays", response.data.day)
                // setIsNotificationread(true)
                setTodayTask(response.data)
                actionplantasklist().then((response) => {
                    if (response.status == 200) {
                        dispatch(setActionplanDetails(response.data))
                    } else if (response.status == 204) {
                        Swal.fire({
                            position: "top-right",
                            icon: "info",
                            title: "User has no active action plan",
                            toast: true,
                            showConfirmButton: false,
                            timer: 2000
                        });
                    }
                }).catch((error) => {
                    console.log(error);
                })

            }
        }).catch((error) => {
            console.log(error);
        })
        textareaRef.current.value = "";
        document.getElementById("panel").classList.remove("stylepanel");
    }


    function firstclosemodal() {
        setWohooSlider(0)
        document.getElementById("panel").classList.remove("stylepanel");
    }

    const handleslider = () => {
        const flexCheckChecked1 = document.getElementById("flexCheckChecked1");
        const flexCheckChecked2 = document.getElementById("flexCheckChecked2");

        if (flexCheckChecked1.checked && flexCheckChecked2.checked) {
            const swiper = swiperRef.current.swiper;
            swiper.activeIndex = 2
            setWohooSlider(1)
        }
    }

    const getvalue = () => {
        const textareaValue = textareaRef.current.value;
        console.log(textareaValue, "textareaValue");
        if (textareaValue != "") {
            submitButtonRef.current.style.cursor = "pointer"
            submitButtonRef.current.style.pointerEvents = "auto"
            skipButtonRef.current.style.cursor = "default"
            skipButtonRef.current.style.pointerEvents = "none"
        } else {
            submitButtonRef.current.style.cursor = "default"
            submitButtonRef.current.style.pointerEvents = "none"
            skipButtonRef.current.style.cursor = "pointer"
            skipButtonRef.current.style.pointerEvents = "auto"
        }
    }

    const handleAffirmation = () => {
        todayAffirmation().then((response) => {
            setAffirmationdata(response.data)
        }).catch((error) => {
            console.log(error);
        })
    }

    const Handlelogout = () => {
        logout().then((response) => {
            Swal.fire({
                position: "top-right",
                icon: "success",
                title: "User logged out successfully",
                toast: true,
                showConfirmButton: false,
                timer: 2000
            });
            localStorage.clear();
            navigate('/login')

        }).catch((error) => {
            console.log(error);
        })
    }

    useEffect(() => {
        getNotifications()
    }, [])

    const getNotifications = () => {
        Notifications().then((response) => {
            if (response.status == 200) {
                setnotificationdata(response.data.notifications)
                setnotificationcount(response.data.counts)
            } else if (response.status == 204) {
                setnotificationdata()
                setnotificationcount(0)
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    useEffect(() => {
        actionplantasktoday().then((response) => {
            if (response.status == 200) {
                setTodayTask(response.data);
            } else if (response.status == 204) {
                // Swal.fire({
                //     position: "top-right",
                //     icon: "info",
                //     title: "No task found for today",
                //     toast: true,
                //     showConfirmButton: false,
                //     timer: 2000
                // });
            }
        }).catch((error) => {
            console.log(error);
        })

    }, [])



    useEffect(() => {

        var collapseButton = document.getElementById('collapse-button');
        console.log(collapseButton, 'collapseButton');

        // Get a reference to the sidebar
        var sidebar = document.getElementById('mysidebar');
        console.log(sidebar);

        // Check if elements exist before adding event listeners
        if (collapseButton && sidebar) {
            // Add a click event listener to the collapse button
            collapseButton.addEventListener('click', function () {
                // Toggle the 'collapsed' class on the sidebar
                // sidebar.classList.toggle('collapsed');
                sidebar.classList.toggle("collapsed")
            });
        }
        else {
            console.error("One or both elements not found.");
        }

    }, [])

    useEffect(() => {
        // dispatch(setLoading(true))
        userDetails().then((response) => {
            // dispatch(setLoading(false))
            if (response.status === 200) {
                setData(response.data)
            } else {
                console.log("userdetails failed");
            }
        }).catch((error) => {
            console.log(error);
        })

    }, [])

    const handlenotification = (id, type) => {
        const updatenotificationobj = {
            "id": id,
            "is_viewed": true
        }
        if (type != "Action plan item" && type != "Action plan") {
            updatednotification(updatenotificationobj)
        } else {
            localStorage.setItem("notificationid", id)
        }

    }

    const updatednotification = (updatenotificationobj) => {
        UpdateNotifications(updatenotificationobj).then((response) => {
            if (response.status == 200) {
                getNotifications()
            }

        }).catch((error) => {
            console.log(error);
        })

    }

    const divRef = useRef(null);
    const handleDownloadClick = () => {
        captureAndDownload();
    };
    const captureAndDownload = () => {
        html2canvas(divRef.current).then((canvas) => {
            const dataUrl = canvas.toDataURL();
            const downloadLink = document.createElement('a');
            downloadLink.href = dataUrl;
            downloadLink.download = 'captured_image.png';
            downloadLink.click();
        });
    };

    const handlesearch = (values) => {
        dispatch(startsearching(values))
    }

    const handleSubmit = () => {
        if (description != null) {
            const mentorDescObj = {
                "mentor_description": description
            }
            UpdateUserDetails(mentorDescObj).then((response) => {
                console.log("response", response);
                if (response.status == 200) {
                    navigate("/comet-chat");
                }
            }).catch((error) => {
                Swal.fire({
                    position: "top-right",
                    icon: "error",
                    title: error.response.data.errors,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                });
            })
        } else {
            navigate("/comet-chat");
        }
    }


    const handlechatsubscription = () => {
        navigate("/subscription")
    }


    return (
        <>

            <header className="community-header fixed-top">
                <nav className="navbar navbar-expand-lg navbar-light bg-white">
                    <div className="container align-items-center">
                        <Link className="navbar-brand" to="/home">
                            <img src={process.env.PUBLIC_URL + "/assets/images/logo.png"} />
                        </Link>

                        <div className="d-flex align-items-center">
                            <div id="collapse-button" className="d-lg-none">
                                <img src={process.env.PUBLIC_URL + "/assets/images/icon/menu.png"}
                                    className="sidebar-menu"
                                    alt="menu"
                                />
                            </div>


                            <div className="search-nav input-group">
                                <span
                                    className="input-group-text bg-transparent border-0"
                                    id="basic-addon1"
                                >
                                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/search-group-icon.png"} alt="" />
                                </span>
                                <input
                                    type="text"
                                    className="form-control bg-transparent border-0"
                                    placeholder="Search by keyword"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                    onChange={(e) => handlesearch(e.target.value)}

                                />
                            </div>

                            <button
                                className="navbar-toggler"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#navbarNavDropdown"
                                aria-controls="navbarNavDropdown"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                            >
                                <span className="navbar-toggler-icon" />
                            </button>

                        </div>

                        <div
                            className="collapse navbar-collapse grow-define-0"
                            id="navbarNavDropdown"
                        >
                            <ul className="navbar-nav ms-auto align-items-center">

                                <li className="nav-item dropdown notification-dropdown">
                                    <a
                                        className="nav-link dropdown-toggle"
                                        href="#"
                                        id="navbarDropdown"
                                        role="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        <button type="button" className="btn  position-relative">
                                            <img
                                                className="height-notify"
                                                src="assets/images/icon/notification.svg"
                                                alt="notification"
                                            />

                                            {notifacationcount && notifacationcount != 0 ?
                                                <span className="notified-circle" title="New alerts">
                                                    <span className="visually-hidden">New alerts</span>
                                                </span> : ""
                                            }
                                        </button>
                                    </a>
                                    <ul
                                        className="dropdown-menu dropdown-menu-lg-end"
                                        aria-labelledby="navbarDropdown"
                                    >
                                        <li className="font_16 font_600 pt-2 pb-1 p-3">
                                            Notifications({notifacationcount ? notifacationcount : 0})
                                        </li>

                                        {notifacationdata && notifacationdata.map((item, key) => {
                                            return (
                                                <>
                                                    <li key={key} onClick={() => handlenotification(item.id, item.title)}>


                                                        {item.title == "Action plan item" ?

                                                            <Link to="/today-task"
                                                                className="dropdown-item"
                                                            >
                                                                <div className="notification-remind d-flex gap-2">
                                                                    <img
                                                                        src={process.env.PUBLIC_URL + "/assets/images/Avocado.png"}
                                                                        className="img-avo"
                                                                        alt="avocado"
                                                                    />
                                                                    <div>
                                                                        <p className="font_14 mb-1">{item.title}</p>
                                                                        <p className="title">
                                                                            {item.message}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                            : item.title == "Affirmation of the day" ?
                                                                <button className="dropdown-item" onClick={() => handleAffirmation(item.id)} data-bs-toggle="modal" data-bs-target="#actiontask3">
                                                                    <div className="notification-remind d-flex gap-2">
                                                                        <img
                                                                            src={process.env.PUBLIC_URL + "/assets/images/Avocado.png"}
                                                                            className="img-avo"
                                                                            alt="avocado"
                                                                        />
                                                                        <div>
                                                                            <p className="font_14 mb-1">{item.title}</p>
                                                                            <p className="title">
                                                                                {item.message}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </button> :
                                                                item.title == "Action plan reminder" ?
                                                                    <Link
                                                                        className="dropdown-item"
                                                                        onClick={myFunctionswiper}
                                                                    >
                                                                        <div className="notification-remind d-flex gap-2">
                                                                            <img src={process.env.PUBLIC_URL + "/assets/images/Avocado.png"} className="img-avo" alt="avocado" />
                                                                            <div>
                                                                                <p className="font_14 mb-1">{item.title}</p>
                                                                                <p className="title">
                                                                                    {item.message}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </Link> :
                                                                    item.title == "Action plan" ?
                                                                        <Link
                                                                            className="dropdown-item"
                                                                            onClick={myFunctionswiper}
                                                                        >
                                                                            <div className="notification-remind d-flex gap-2">
                                                                                <img src={process.env.PUBLIC_URL + "/assets/images/Avocado.png"} className="img-avo" alt="avocado" />
                                                                                <div>
                                                                                    <p className="font_14 mb-1">{item.title}</p>
                                                                                    <p className="title">
                                                                                        {item.message}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </Link> :
                                                                        <button className="dropdown-item" onClick={() => handleAffirmation(item.id)}
                                                                        //  data-bs-toggle="modal" data-bs-target="#actiontask4"
                                                                        >
                                                                            <div className="notification-remind d-flex gap-2">
                                                                                <img
                                                                                    src={process.env.PUBLIC_URL + "/assets/images/Avocado.png"}
                                                                                    className="img-avo"
                                                                                    alt="avocado"
                                                                                />
                                                                                <div>
                                                                                    <p className="font_14 mb-1">{item.title}</p>
                                                                                    <p className="title">
                                                                                        {item.message}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </button>
                                                        }
                                                    </li>
                                                </>
                                            )
                                        })}

                                    </ul>
                                </li>

                                {localStorage.getItem("islogin") == "true" ?
                                    <li className="nav-item dropdown">
                                        <a
                                            className="nav-link dropdown-toggle"
                                            href="#"
                                            id="navbarDropdownMenuLink"
                                            role="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            {/* <img
                                            className="account-dropdown"
                                            src="assets/images/icon/header-account.svg"
                                            alt=""
                                        /> */}
                                            {data != undefined && data.photo != null ?
                                                <img src={data.photo.image} className="account-dropdown" alt="" /> :
                                                <img src={process.env.PUBLIC_URL + "/assets/images/defalt.png"} className="account-dropdown" alt="" />
                                            }

                                        </a>
                                        <ul
                                            className="dropdown-menu dropdown-menu-lg-end"
                                            aria-labelledby="navbarDropdownMenuLink"
                                        >
                                            <li>
                                                <Link className="dropdown-item" to="/account">
                                                    My Account
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="dropdown-item" to="/bookdashboard">
                                                    My Books
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="dropdown-item" to="/community-events">
                                                    My Events
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="dropdown-item" to="/healing-journey">
                                                    Healing Journey
                                                </Link>
                                            </li>
                                            <li>
                                                {loginUserData != null && loginUserData.data.is_subscription_active == true ?
                                                    <>
                                                        {localStorage.getItem("active_actionplan") == "false" ?
                                                            <Link className="dropdown-item" to="/create-actionplan">
                                                                My Action Plan
                                                            </Link> :
                                                            <Link className="dropdown-item" to="/actionplan-dashboard">
                                                                My Action Plan
                                                            </Link>}
                                                    </>
                                                    :
                                                    <Link className="dropdown-item" to="/create-actionplan">
                                                        My Action Plan
                                                    </Link>
                                                }
                                            </li>
                                            <li>
                                                <button className="dropdown-item font_16" onClick={Handlelogout}>
                                                    Logout
                                                </button>
                                            </li>
                                        </ul>
                                    </li>

                                    :
                                    <form className="d-flex ms-auto">
                                        <Link className="primary-btn font_18" to="/login">
                                            Login
                                        </Link>
                                    </form>
                                }
                                <li className="nav-item">

                                    {loginUserData != null && loginUserData.data.is_subscription_active == false ?
                                        <a className="nav-link font_18 ps-2" >
                                            <img
                                                src={process.env.PUBLIC_URL + "/assets/images/icon/chaticon.png"}
                                                className="chaticon"
                                                alt="image"
                                                onClick={handlechatsubscription}
                                            />
                                        </a> : <a className="nav-link font_18 ps-2" >
                                            <img
                                                src={process.env.PUBLIC_URL + "/assets/images/icon/chaticon.png"}
                                                className="chaticon"
                                                alt="image"
                                                onClick={handleSubmit}
                                            />
                                        </a>}


                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>

            {<section id="panel" className="panel">
                <Swiper effect={'cards'} grabCursor={true} fadeEffect={{ crossFade: true }} modules={[EffectCards]}
                    className="mySwiper"
                    ref={swiperRef}  >
                    <div className="swiper mySwiper">
                        <div className="swiper-wrapper">
                            <div className="swiper-slide">
                                <SwiperSlide key={0}>
                                    <div className="notify-card">
                                        <div className="d-flex justify-content-end">
                                            <img
                                                id="close-btn"
                                                src="assets/images/icon/close-modal.png"
                                                alt=""
                                                onClick={firstclosemodal}
                                            />
                                        </div>
                                        <div className="d-flex align-items-center justify-content-center">
                                            <p className="font_28 title mb-0">Hey you!</p>
                                            <img
                                                src={process.env.PUBLIC_URL + "/assets/images/Avocado.png"}
                                                className="avacado-img"
                                                alt=""
                                            />
                                        </div>
                                        <div className="hr-line mb-4" />
                                        <p className="mb-0 text">
                                            {todaytask && todaytask.task_category}
                                        </p>
                                    </div>
                                </SwiperSlide>
                            </div>
                            <div className="swiper-slide">
                                <SwiperSlide key={1}>
                                    <div className="notify-card">
                                        <div className="d-flex align-items-center justify-content-center">
                                            <p className="font_28 title mb-0">Today</p>
                                            <img
                                                src={process.env.PUBLIC_URL + "/assets/images/Avocado.png"}
                                                className="avacado-img"
                                                alt=""
                                            />
                                        </div>
                                        <div className="hr-line mb-4"></div>
                                        <div className="d-flex gap-2">
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    defaultValue=""
                                                    id="flexCheckChecked1"
                                                    defaultChecked=""
                                                />
                                            </div>
                                            <div>
                                                <p className=" text font_18 font_700 d-flex align-items-center mb-1">
                                                    {todaytask && todaytask.task_name}
                                                </p>
                                                <p className="text  text-white">
                                                    {todaytask && todaytask.task_description}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="d-flex gap-2">
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    defaultValue=""
                                                    id="flexCheckChecked2"
                                                    defaultChecked=""

                                                />
                                            </div>
                                            <div>
                                                <p className=" text font_18 font_700 d-flex align-items-center mb-1">
                                                    Affirmation of the day
                                                </p>
                                                <p className="text  text-white">
                                                    {todaytask && todaytask.task_affirmation}
                                                </p>
                                            </div>
                                        </div>
                                        <Link onClick={handleslider}

                                            className="accept-btn"
                                        // data-bs-toggle="modal"
                                        // data-bs-target="#actiontask3"
                                        // data-bs-dismiss="modal"
                                        >
                                            {" "}
                                            I did it!
                                        </Link>
                                    </div>
                                </SwiperSlide>

                            </div>

                            {wohooSlider == 1 ?

                                <div className="swiper-slide" id="actiontask3">
                                    <SwiperSlide key={2}>
                                        <div className="notify-card bg-white">
                                            <div className="d-flex align-items-center justify-content-center">
                                                <p className="font_28 title mb-0 text-orange">Wohoo!</p>
                                                <img
                                                    src={process.env.PUBLIC_URL + "/assets/images/Avocado.png"}
                                                    className="avacado-img"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="hr-line mb-4"></div>
                                            <p className="text text-orange">
                                                Congrats on taking steps towards a better you. How do you feel
                                                after completing the task?
                                            </p>
                                            <textarea
                                                ref={textareaRef}
                                                className="form-control"
                                                id="exampleFormControlTextarea1"
                                                rows={2}
                                                placeholder="type here.."
                                                // defaultValue={""}
                                                onKeyUp={getvalue}
                                            />

                                            <a type='button'
                                                href="javascript:void(0)"
                                                ref={submitButtonRef}
                                                className="skip-btn font_700 btn-disabled"
                                                data-bs-dismiss="modal"
                                                style={{ cursor: "default", pointerEvents: "none" }}
                                                onClick={closemodal}
                                            >
                                                Submit
                                            </a>

                                            <a
                                                href="javascript:void(0)"
                                                ref={skipButtonRef}
                                                className="skip-btn font_700"
                                                data-bs-dismiss="modal"
                                                style={{ cursor: "pointer" }}
                                                onClick={closemodal}
                                            >
                                                Skip
                                            </a>


                                        </div>
                                    </SwiperSlide>

                                </div> : ""
                            }

                        </div>
                    </div>

                </Swiper>
            </section>}

            <div
                ref={divRef}
                className="modal fade"
                id="actiontask3"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog  modal-dialog-centered ">
                    <div className="modal-content modal-task-content">
                        <div className="modal-header border-0 w-100 justify-content-end">
                            <div className="d-flex justify-content-end align-items-center gap-2">
                                <button className="bg-transparent" onClick={handleDownloadClick}>
                                    <img src="assets/images/download.png" alt="image" height={26} />
                                </button>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                />
                            </div>
                        </div>
                        <div className="modal-body p-20">
                            <div className="d-flex align-items-center justify-content-center">
                                <p className="font_50 title mb-0 text-start">
                                    {affirmationdata && affirmationdata[0].affirmation}
                                </p>
                            </div>
                            <div className="d-flex justify-content-end">
                                <img
                                    src="assets/images/Avocado.png"
                                    className="avacado-img--1"
                                    alt=""
                                />
                            </div>
                            <div className="hr-line mb-4"></div>
                            <div className="d-flex justify-content-end">
                                <img
                                    src="assets/images/logo-helprr.png"
                                    className="logo-helprr"
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default CommunityHeader





