import React, { useState, useEffect } from 'react'
import Footer from '../components/Footer'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import OuterHeader from '../components/OuterHeader'
import { EventList, EventDefaultList, FeaturedEventList } from '../api/apiHandler'
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from "react-places-autocomplete";
import Loader from '../components/Loader';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../store/slice/loaderSlice';
import ReactPaginate from 'react-paginate';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';



const EventsHomePage = () => {
    // const history = useHistory();
    // const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.loader);
    const [eventdata, setEventdata] = useState()
    console.log('eventdata: ', eventdata);
    const [featuredevent, setfeaturedEvent] = useState()
    const [address, setAddress] = useState(null);
    const [coordinates, setCoordinates] = useState("");
    const [eventType, setEventType] = useState("");
    // const [totalRecordCount, setTotalRecordCount] = useState(0)
    const [pageNo, setPageNo] = useState(1);
    const [perPage, setPerPage] = useState(3);
    const UserData = useSelector((state) => JSON.parse(state.userdata))
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const itemsPerPage = 3;
    const [featurecurrentPage, setfeatureCurrentPage] = useState(1);
    const [featuredtotalCount, setfeaturedtotalCount] = useState(0);
    const [nofeaturedContent, SetNofeaturedContent] = useState()
    const [nosearchContent, SetNosearchContent] = useState()
    const [defaulteventlist, Setdefaulteventlist] = useState()
    const [handleDafaultCallApi, setHandleDefaultCallApi] = useState(true)
    const featureitemsperpage = 3;

    const handleSelect = async (value) => {
        setCurrentPage(1)
        const results = await geocodeByAddress(value);
        const ll = await getLatLng(results[0]);
        setAddress(value);
        localStorage.setItem("eventvanue", value)
        console.log('valuee: ', value);
        setCoordinates(ll);
        localStorage.setItem("event_latlong", JSON.stringify(ll))
    };

    console.log(localStorage.getItem("eventvanue"), "eventvanue");
    console.log(localStorage.getItem("event_latlong"), "eventlatlong");
    console.log(localStorage.getItem("event_type"), "eventtype");




    const handleEventTypeChange = (event) => {
        setCurrentPage(1)
        if (event.target.value == "default") {
            localStorage.removeItem("event_type")
            localStorage.removeItem("event_latlong")
            localStorage.removeItem("eventvanue")
            setAddress("")
            setEventType("")
            // setCurrentPage(1)
            // getdefaulteventlist({ page: currentPage })
        } else {
            localStorage.setItem("event_type", event.target.value)
            setEventType(event.target.value);
            localStorage.removeItem("event_latlong")
            localStorage.removeItem("eventvanue")
            setAddress("")
            // setCurrentPage(1)
        }

    };

    useEffect(() => {
        if (handleDafaultCallApi) {
            getdefaulteventlist({ page: currentPage })
        }
    }, [currentPage])

    const handleSearchClick = () => {
        EventList({ page: currentPage }).then((response) => {
            // setCurrentPage(1)
            // localStorage.removeItem("event_type")
            // localStorage.removeItem("event_latlong")
            // localStorage.removeItem("eventvanue")
            setHandleDefaultCallApi(false)
            if (response.status == 200) {
                console.log(response.data.total_count, "totalcount");
                setEventdata(response.data.results)
                setTotalCount(response.data.total_count);

            } else if (response.status == 204) {
                setEventdata([])
                setAddress("")
                SetNosearchContent(204)
                Setdefaulteventlist(204)
                Swal.fire({
                    position: "top-right",
                    icon: "info",
                    title: "No data found",
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                });
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setHandleDefaultCallApi(true)
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < Math.ceil(totalCount / itemsPerPage)) {
            setHandleDefaultCallApi(true)
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrev = () => {
        if (featurecurrentPage > 1) {
            setfeatureCurrentPage(featurecurrentPage - 1);
        }
    };

    const handleNext = () => {
        if (featurecurrentPage < Math.ceil(featuredtotalCount / featureitemsperpage)) {
            setfeatureCurrentPage(featurecurrentPage + 1);
        }
    };

    useEffect(() => {
        handlefeaturedevent({ page: featurecurrentPage })
    }, [featurecurrentPage])

    const handlefeaturedevent = (data) => {
        FeaturedEventList(data).then((response) => {
            if (response.status == 200) {
                setfeaturedEvent(response.data.results)
                setfeaturedtotalCount(response.data.total_count)
            } else if (response.status == 204) {
                SetNofeaturedContent(204)
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    const getdefaulteventlist = (data) => {

        EventList(data).then((response) => {
            // localStorage.removeItem("event_type")
            // localStorage.removeItem("event_latlong")
            // localStorage.removeItem("eventvanue")
            setHandleDefaultCallApi(false)
            if (response.status == 200) {
                setEventdata(response.data.results)
                setTotalCount(response.data.total_count);

            } else if (response.status == 204) {
                setEventdata([])
                setAddress("")
                Setdefaulteventlist(204)
                SetNosearchContent(204)

            }
        }).catch((error) => {
            console.log(error);
        })
    }

    // if (coordinates != "") {
    //     localStorage.setItem("event_latlong", JSON.stringify(coordinates))
    // }


    const handleCreateEventClick = () => {
        Swal.fire({
            position: "top-right",
            icon: "error",
            title: "Subscription required",
            toast: true,
            showConfirmButton: false,
            timer: 2000
        });
    }

    const baseUrl = process.env.REACT_APP_WEBSITE_BASE_URL;
    const path = window.location.pathname;
    const completeUrl = baseUrl + path;

    const handlesubscription = () => {
        localStorage.setItem('completeUrl', completeUrl);
    }


    return (
        <>
            {isLoading ?
                (<Loader loader_status={isLoading} />) :
                (
                    <main className="helprr-main events-main">
                        <OuterHeader />
                        <section className="events-title">
                            <p className="font_64 font_700 text-white mb-0 text-center">
                                CONNECT. ENGAGE. EMPOWER.
                            </p>
                        </section>
                        <section>
                            <div className="container">
                                <div className="filter-sec">
                                    <div className="row w-100">
                                        <div className="col-lg-6 col-xl-6 col-12">
                                            <div>
                                                <p className="font_16 text-offwhite mb-2">Looking for</p>
                                                <select
                                                    className="custom-event-select form-select"
                                                    aria-label="Default select example"
                                                    value={eventType}
                                                    onChange={handleEventTypeChange}
                                                >
                                                    <option value="default">Choose event type</option>
                                                    <option value="in_person">In person</option>
                                                    <option value="virtual">Virtual</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-6 col-12">
                                            <div>
                                                <div className="input_box">
                                                    <label
                                                        htmlFor="exampleFormControlInput1"
                                                        className="font_16 text-offwhite mb-2"
                                                    >
                                                        Location
                                                    </label>
                                                    <PlacesAutocomplete

                                                        value={address}
                                                        onChange={setAddress}
                                                        onSelect={handleSelect}
                                                        googleCallbackName="initPlaces"
                                                    >
                                                        {({
                                                            getInputProps,
                                                            suggestions,
                                                            getSuggestionItemProps,
                                                            loading,
                                                        }) => (
                                                            <div className='position-relative' >
                                                                <input
                                                                    className="form-control"
                                                                    placeholder="Choose location"
                                                                    id="address"
                                                                    defaultValue={address}
                                                                    {...getInputProps()}
                                                                />
                                                                <div className='location-dropdown'>
                                                                    {loading ? (
                                                                        <div className="text-secondary">loading...</div>
                                                                    ) : null}

                                                                    {suggestions.map((suggestion) => {
                                                                        const style = {
                                                                            backgroundColor: suggestion.active ? '#f1f0ff' : '#fff',
                                                                            textAlign: 'left',
                                                                        };

                                                                        return (
                                                                            <div
                                                                                className="form-control"
                                                                                {...getSuggestionItemProps(suggestion, {
                                                                                    style,
                                                                                })}
                                                                            >
                                                                                {suggestion.description}
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </PlacesAutocomplete>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <select
                                            className="custom-event-select form-select"
                                            aria-label="Default select example"
                                            value={location}
                                            onChange={handleLocationChange}
                                        >
                                            <option selected="">Choose location</option>
                                            <option value={Ahmedabad}>Ahmedabad</option>

                                        </select> */}

                                    </div>
                                    <div className="search-icon" onClick={handleSearchClick} >
                                        <img src={process.env.PUBLIC_URL + "/assets/images/icon/search-purple.svg"} alt="search" />
                                    </div>
                                </div>


                                <div className="upcoming-events">
                                    <div className="row justify-content-between align-items-center mb-4">
                                        <div className="col-lg-4 col-xl-4 col-12 ">
                                            {defaulteventlist && defaulteventlist == 204 ?
                                                <div>
                                                    <p className="font_36 font_700 text-black mb-sm-0">
                                                        Upcoming <span className="text-mave">Events</span>
                                                    </p>
                                                    <h4>No Upcoming Events Scheduled. Stay tuned for future announcements!</h4>
                                                </div> :
                                                <p className="font_36 font_700 text-black mb-sm-0">
                                                    Upcoming <span className="text-mave">Events</span>
                                                </p>}
                                        </div>

                                    </div>

                                    <div className="row">
                                        {eventdata && eventdata.map((items, key) => {
                                            { console.log('items: ', items) };
                                            return (
                                                <>
                                                    {UserData && UserData.data.is_subscription_active == true ?
                                                        <div className="col-lg-4 col-xl-4 col-md-6 col-12 mb-3">
                                                            <div className="event-card" onClick={() => { navigate(`/event-description/${items.id}`) }}>

                                                                <div className="event-img">
                                                                    <img src={items.image} alt="event" />
                                                                    <div className="free-tab">free</div>
                                                                </div>
                                                                <div>
                                                                    <p className="text-black mb-2">
                                                                        {items.title}-
                                                                        {items.type == "virtual" ? "Virtual Event" :
                                                                            items.venue}
                                                                    </p>
                                                                    <p className="font_12 text-purple mb-2">
                                                                        {moment(items.start_date).format('dddd')}, {items.start_date}, {moment.utc(items.start_date + " " + items.start_time).tz("Canada/Eastern").format("h:mm A")} (EST)
                                                                    </p>
                                                                    {items.type == "virtual" ?
                                                                        <p className="font_12 text-gray mb-0">
                                                                            ONLINE EVENT - Attend anywhere
                                                                        </p> :
                                                                        <p className="font_12 text-gray mb-0">
                                                                            IN-PERSON EVENT - Attend in person
                                                                        </p>
                                                                    }
                                                                </div>

                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="col-lg-4 col-xl-4 col-md-6 col-12 mb-3" onClick={handleCreateEventClick}>
                                                            <div className="event-card">

                                                                <div className="event-img">
                                                                    <img src={items.image} alt="event" />
                                                                    <div className="free-tab"><i class="fa-solid fa-lock text-mave font_24"></i></div>
                                                                </div>
                                                                <div>
                                                                    <p className="text-black mb-2">
                                                                        {items.title}
                                                                    </p>
                                                                    <p className="font_12 text-purple mb-2">
                                                                        {moment(items.start_date).format('dddd')}, {items.start_date},  {moment.utc(items.start_date + " " + items.start_time).tz("Canada/Eastern").format("h:mm A")} (EST)
                                                                    </p>
                                                                    {items.type == "virtual" ?
                                                                        <p className="font_12 text-gray mb-0">
                                                                            ONLINE EVENT - Attend anywhere
                                                                        </p> :
                                                                        <p className="font_12 text-gray mb-0">
                                                                            IN-PERSON EVENT - Attend in person
                                                                        </p>
                                                                    }
                                                                </div>

                                                            </div>
                                                        </div>
                                                    }

                                                </>
                                            )
                                        })}


                                    </div>

                                    {defaulteventlist && defaulteventlist == 204 || nosearchContent && nosearchContent == 204 ? "" :
                                        <>
                                            <div className="row justify-content-center mt-sm-5" >
                                                <div className="col-lg-2 col-lg-3 col-xl-2 col-md-4 col-6">
                                                    {/* {currentPage === 1 ? "" : */}
                                                    <button type="button" className={currentPage === 1 ? "load-btn-disable w-100" : "load-btn w-100"} onClick={handlePrevPage} disabled={currentPage === 1}>
                                                        Previous
                                                    </button>
                                                    {/* } */}

                                                </div>
                                                <div className="col-lg-2 col-xl-2 col-md-4 col-6">
                                                    {/* {currentPage === Math.ceil(totalCount / itemsPerPage) ? "" : */}
                                                    <button type="button" className={currentPage === Math.ceil(totalCount / itemsPerPage) ? "load-btn-disable w-100" : "load-btn w-100"} onClick={handleNextPage} disabled={currentPage === Math.ceil(totalCount / itemsPerPage)}>
                                                        Next
                                                    </button>
                                                    {/* } */}
                                                </div>
                                            </div>
                                        </>}

                                </div>
                            </div>
                        </section>
                        <section className="bg-mave sec-margin">
                            <div className="container">
                                <div className="row align-items-center gap-sm-5">
                                    <div className="col-lg-6 col-xl-6 col-md-6 col-12">
                                        <div className="vectors-combined">
                                            <img src={process.env.PUBLIC_URL + "/assets/images/vectors-combined.png"} alt="" />
                                        </div>
                                    </div>
                                    <div className="col-lg-5 col-xl-4 col-md-5 col-12">
                                        <div>
                                            <p className="font_36 font_700 text-white">Make your own Event </p>
                                            <p className="font_18 text-white">
                                                Interested in hosting an event? Get started here and reach a wider
                                                audience
                                            </p>
                                            {UserData && UserData.data.is_subscription_active == true ?
                                                <Link to="/create-event" className="load-btn font_18">
                                                    Create Events
                                                </Link>
                                                :
                                                <Link to="/subscription" className="load-btn font_18" onClick={handlesubscription}>
                                                    Create Events
                                                </Link>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section>
                            <div className="container">
                                <div className="row">
                                    {nofeaturedContent && nofeaturedContent == 204 ?
                                        <div>
                                            <p className="font_36 font_700 text-black">
                                                Featured <span className="text-mave">Events</span>
                                            </p>
                                            <h4>No Featured Events Available Right Now. Check Out Other Upcoming Events!</h4>
                                        </div>
                                        :
                                        <p className="font_36 font_700 text-black">
                                            Featured <span className="text-mave">Events</span>
                                        </p>}
                                    {featuredevent && featuredevent.map((items, key) => {
                                        return (
                                            <>
                                                {UserData && UserData.data.is_subscription_active == true ?
                                                    <div className="col-lg-4 col-xl-4 col-md-6 col-12 mb-3">
                                                        <div className="feature-event-card" onClick={() => { navigate(`/event-description/${items.id}`) }}>
                                                            {/* <div className="featured-img">
                                                                <img src={items.image} alt="" />
                                                            </div> */}
                                                            <div className="featured-img">
                                                                <img src={items.image} alt="event" />
                                                                <div className="free-tab">free</div>
                                                            </div>
                                                            <div className="card-details">
                                                                <p className="font_24 font_700 text-black title">
                                                                    {items.title}
                                                                </p>
                                                                {items.type == "virtual" ?
                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                        <p className="font_500 text-black mb-0">Virtual Event</p>
                                                                    </div>
                                                                    :
                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                        <p className="font_500 text-black mb-0">{items.venue}</p>
                                                                    </div>}
                                                            </div>
                                                        </div>
                                                    </div> :
                                                    <div className="col-lg-4 col-xl-4 col-md-6 col-12 mb-3">
                                                        <div className="feature-event-card" onClick={handleCreateEventClick}>

                                                            <div className="featured-img">
                                                                <img src={items.image} alt="event" />
                                                                <div className="free-tab"><i class="fa-solid fa-lock text-mave font_24"></i></div>
                                                            </div>
                                                            <div className="card-details">
                                                                <p className="font_24 font_700 text-black title">
                                                                    {items.title}
                                                                </p>
                                                                {items.type == "virtual" ?
                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                        <p className="font_500 text-black mb-0">Virtual Event</p>
                                                                    </div>
                                                                    :
                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                        <p className="font_500 text-black mb-0">{items.venue}</p>
                                                                    </div>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </>
                                        )
                                    })}

                                </div>
                                {nofeaturedContent && nofeaturedContent == 204 ? "" :
                                    <>
                                        <div className="row justify-content-center mt-sm-5" >
                                            <div className="col-lg-2 col-lg-3 col-xl-2 col-md-4 col-6">
                                                <button type="button" className={featurecurrentPage === 1 ? "load-btn-disable w-100" : "load-btn w-100"} onClick={handlePrev} disabled={featurecurrentPage === 1}>
                                                    Previous
                                                </button>

                                            </div>
                                            <div className="col-lg-2 col-xl-2 col-md-4 col-6">
                                                <button type="button" className={featurecurrentPage === Math.ceil(featuredtotalCount / featureitemsperpage) ? "load-btn-disable w-100" : "load-btn w-100"} onClick={handleNext} disabled={featurecurrentPage === Math.ceil(featuredtotalCount / featureitemsperpage)}>
                                                    Next
                                                </button>

                                            </div>
                                        </div>
                                    </>}

                            </div>
                        </section>


                        <Footer />
                    </main>
                )
            }

        </>
    )
}

export default EventsHomePage