
import './App.css';

import CommunityHeader from './components/CommunityHeader';
import Outerheader from './components/OuterHeader';
import Sidebar from './components/Sidebar';
import Index from './pages/Index';
import Home from './pages/Home';
import Account from './pages/Account';
import ActionPlanBookRecommendation from './pages/ActionPlanBookRecommendation';
import BookDescription from './pages/BookDescription';
import BookRecommendations from './pages/BookRecommendations';
import ChangeAccountDetails from './pages/ChangeAccountDetails';
import ChangePassword from './pages/ChangePassword';
import CommunityHomePage from './pages/CommunityHomePage';
import CreateEvent from './pages/CreateEvent';
import DailyAffirmations from './pages/DailyAffirmations';
import EventDescription from './pages/EventDescription';
import EventsHomePage from './pages/EventsHomePage';
import FilterResults from './pages/FilterResults';
import ForgotPassword from './pages/ForgotPassword';
import GroupDiscussionsQuestions from './pages/GroupDiscussionsQuestions';
import GroupDiscussions from './pages/GroupDiscussions';
import HealingJourney from './pages/HealingJourney';
import Login from './pages/Login';
import PeerMentorship from './pages/PeerMentorship';
import PeerNetwork from './pages/PeerNetwork';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Purpose from './pages/Purpose';
import QForMentor from './pages/QForMentor';
import Q1 from './pages/Q1';
import RegisterEvent from './pages/RegisterEvent';
import ResetPassword from './pages/ResetPassword';
import ResourceArticle from './pages/ResourceArticle';
import ResourceVideo from './pages/ResourceVideo';
import Resources from './pages/Resources';
import Results from './pages/Results';
import SearchLearnMore from './pages/SearchLearnMore';
import SearchPage from './pages/SearchPage';
import Signup from './pages/Signup';
import Subscription from './pages/Subscription';
import SubscriptionPlans from './pages/SubscriptionPlans';
import TermsUse from './pages/TermsUse';
import Error from './pages/Error';
import { Routes, Route } from 'react-router-dom';
import PrivateComponent from './PrivetRoute';
import ActionPlanDashboard from './pages/ActionPlanDashboard';
import ActionPlanDashboard2 from './pages/ActionPlanDashboard2';
import ActionPlanScreenDaywise from './pages/ActionPlanScreenDaywise';
import BookDashboard from './pages/BookDashboard';
import BookNotesDetailed from './pages/BookNotesDetailed';
import CancelChallengeGodash from './pages/CancelChallengeGodash';
import CancelChallenge from './pages/CancelChallenge';
import CommunityEvents from './pages/CommunityEvents';
import CommunityLeadership from './pages/CommunityLeadership';
import CongratsChallenge from './pages/CongratsChallenge';
import Interests from './pages/Interests';
import TaskNotes from './pages/TaskNotes';
import VerifyOtp from './pages/VerifyOtp';
import Stats from './pages/Stats';
import About from './pages/About';
import RequestPage from './pages/RequestPage';
import ActionPlanQ1 from './pages/ActionPlanQ1';
import QForBook from './pages/QForBook';
import CometChatPage from './pages/CometChatPage';
import Faqs from './pages/Faqs';
import Contactus from './pages/ContactUs';
import ResourceAudio from './pages/ResourceAudio';
import ContentQ1 from './pages/ContentQ1';
import MatchedMentorListing from './pages/MatchedMentorListing';
import Allbooks from './components/Allbooks';
import GroupDiscussionsAnswers from './pages/GroupDiscussionsAnswers';
import GroupDiscussionsLike from './pages/GroupDiscussionsLike';
import InterestContent from './components/InterestContent';
import Chatbot from './pages/Chatbot';
import CheckPlace from './pages/check_place';
import Communites from './pages/Communites';


function App() {

  // const COMETCHAT_CONSTANTS = {
  //   APP_ID: process.env.REACT_APP_COMETCHAT_APP_ID, //Replace with your App ID
  //   REGION: process.env.REACT_APP_COMETCHAT_APP_REGION, //Replace with your App Region
  //   AUTH_KEY: process.env.REACT_APP_COMETCHAT_AUTH_KEY //Replace with your Auth Key
  // }



  //create the builder
  // const UIKitSettings = new UIKitSettingsBuilder()
  //   .setAppId(COMETCHAT_CONSTANTS.APP_ID)
  //   .setRegion(COMETCHAT_CONSTANTS.REGION)
  //   .setAuthKey(COMETCHAT_CONSTANTS.AUTH_KEY)
  //   .subscribePresenceForFriends()
  //   .build();

  // //Initialize CometChat UIKit
  // CometChatUIKit.init(UIKitSettings).then(() => {
  //   console.log("Initialization completed successfully");
  //   // You can now call login function.
  // }).catch(console.log);


  return (
    <>
      <Routes>
        <Route path='/' element={<Index />}></Route>
        <Route path='/check-place' element={<CheckPlace />}></Route>
        <Route path='/login' element={<Login />}></Route>
        <Route path='/signup' element={<Signup />}></Route>
        <Route path='/forgot-password' element={<ForgotPassword />}></Route>
        <Route path='/verify-otp' element={<VerifyOtp />}></Route>
        <Route path='/reset-password' element={<ResetPassword />}></Route>
        <Route path='/interests' element={<Interests />}></Route>
        <Route path='*' element={<Error />}></Route>
        <Route path='/comet-chat' element={<CometChatPage />}></Route>
        <Route path='/book-recommendations' element={<BookRecommendations />}></Route>
        <Route path='/about-us' element={<About />}></Route>
        <Route path='/privacy-policy' element={<PrivacyPolicy />}></Route>
        <Route path='/terms-of-use' element={<TermsUse />}></Route>
        <Route path='/purpose' element={<Purpose />}></Route>
        <Route path='/resource-search' element={<SearchPage />}></Route>
        <Route path='/content' element={<Resources />}></Route>
        <Route path='/community' element={<CommunityHomePage />}></Route>
        <Route path='/interests-page' element={<ActionPlanBookRecommendation />}></Route>
        <Route path='/group-discussions' element={<GroupDiscussions />}></Route>
        <Route path='/faqs' element={<Faqs />}></Route>
        <Route path='/contact-us' element={<Contactus />}></Route>
        <Route path='/interest-content' element={<InterestContent />}></Route>

        <Route element={<PrivateComponent />}>
          <Route path='/interests' element={<Interests />}></Route>
          <Route path='*' element={<Error />}></Route>
          <Route path='/' element={<Index />}></Route>
          <Route path='/mindful-content' element={<Q1 />}></Route>
          <Route path='/questions' element={<QForMentor />}></Route>
          <Route path='/results' element={<Results />}></Route>
          <Route path='/content-article/:id' element={<ResourceArticle />}></Route>
          <Route path='/account' element={<Account />}></Route>
          <Route path='/healing-journey' element={<HealingJourney />}></Route>
          <Route path='/subscription' element={<Subscription />}></Route>
          <Route path='/subscription-plans' element={<SubscriptionPlans />}></Route>
          <Route path='/resource' element={<FilterResults />}></Route>
          <Route path='/events' element={<EventsHomePage />}></Route>
          <Route path='/create-event' element={<CreateEvent />}></Route>
          <Route path='/event-description/:id' element={<EventDescription />}></Route>
          <Route path='/communityheader' element={<CommunityHeader />}></Route>
          <Route path='/outerheader' element={<Outerheader />}></Route>
          <Route path='/sidebar' element={<Sidebar />}></Route>
          <Route path='/home' element={<Home />}></Route>
          <Route path='/book-question' element={<QForBook />}></Route>
          <Route path='/book-description/:id' element={<BookDescription />}></Route>
          <Route path='/book-recommendations' element={<BookRecommendations />}></Route>
          <Route path='/change-account' element={<ChangeAccountDetails />}></Route>
          <Route path='/change-password' element={<ChangePassword />}></Route>
          <Route path='/affirmations' element={<DailyAffirmations />}></Route>
          <Route path='/my-questions' element={<GroupDiscussionsQuestions />}></Route>
          <Route path='/peer-community' element={<PeerMentorship />}></Route>
          <Route path='/peer-mentorship' element={<PeerNetwork />}></Route>
          <Route path='/register-event/:id' element={<RegisterEvent />}></Route>
          <Route path='/content-video/:id' element={<ResourceVideo />}></Route>
          <Route path='/search-more' element={<SearchLearnMore />}></Route>
          <Route path='/actionplan-dashboard' element={<ActionPlanDashboard />}></Route>
          <Route path='/create-actionplan' element={<ActionPlanDashboard2 />}></Route>
          <Route path='/today-task' element={<ActionPlanScreenDaywise />}></Route>
          <Route path='/bookdashboard' element={<BookDashboard />}></Route>
          <Route path='/book-details/:id' element={<BookNotesDetailed />}></Route>
          <Route path='/cancel-dashboard' element={<CancelChallengeGodash />}></Route>
          <Route path='/cancel-challenge' element={<CancelChallenge />}></Route>
          <Route path='/community-events' element={<CommunityEvents />}></Route>
          <Route path='/community-leadership' element={<CommunityLeadership />}></Route>
          <Route path='/accept-challenge' element={<CongratsChallenge />}></Route>
          <Route path='/actionplan-details' element={<TaskNotes />}></Route>
          <Route path='/more-books' element={<Allbooks />}></Route>
          <Route path='/stats' element={<Stats />}></Route>
          <Route path='/feedback' element={<RequestPage />}></Route>
          <Route path='/actionplan-question' element={<ActionPlanQ1 />}></Route>
          <Route path='/matched-mentor-list' element={<MatchedMentorListing />}></Route>
          <Route path='/content-audio/:id' element={<ResourceAudio />}></Route>
          <Route path='/content-question' element={<ContentQ1 />}></Route>
          <Route path='/my-answers' element={<GroupDiscussionsAnswers />}></Route>
          <Route path='/my-likes' element={<GroupDiscussionsLike />}></Route>
          <Route path='/chatbot' element={<Chatbot />}></Route>
          <Route path='/communites' element={<Communites />}></Route>


        </Route>

      </Routes>
    </>
  );
}




export default App;
