import React from 'react'
import OuterHeader from '../components/OuterHeader'
import Footer from '../components/Footer'
import { Link } from 'react-router-dom'

const SearchLearnMore = () => {
    return (
        <>
            <main className="helprr-main">
                <OuterHeader />
                <section className="login-sec">
                    <div className="container">
                        <p className="font_50 font_600">What Is So Different About helprr.</p>
                        <div className="row align-items-end">
                            <div className="col-lg-8 col-xl-8 col-12">
                                <p className="font_22 mb-5">
                                    Our App stands out by seamlessly combining easy access to
                                    comprehensive search resources, a vibrant community support system,
                                    and curated content tailored to your needs. Say goodbye to endless
                                    searches and information overload as our user-friendly interface
                                    provides a hassle-free way to find verified resources at your
                                    fingertips. Connect with like-minded individuals, share experiences,
                                    and find solace in our supportive community. Elevate your personal
                                    growth journey with curated content that enriches your mind, body,
                                    and soul. Discover the power of integration, where easy search,
                                    community support, and curated content come.{" "}
                                </p>
                                <Link to="/home" className="d-flex gap-3 mb-3 align-items-center">
                                    <img className='back-arrow-icon' src={process.env.PUBLIC_URL + "/assets/images/icon/left-arrow.svg"} alt="" />
                                    <p className="mb-0 font_18">Go Back</p>
                                </Link>
                            </div>
                            <div className="col-lg-4 col-xl-4 col-12">
                                <div className="vector-img--1">
                                    <img src={process.env.PUBLIC_URL + "/assets/images/vector--1.svg"} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </main>

        </>
    )
}

export default SearchLearnMore