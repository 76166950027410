// import { configureStore } from '@reduxjs/toolkit';
// import loaderReducer from './slice/loaderSlice';
// import userDetail from './slice/userDetail';
// import matchedMentor from './slice/matchedMentor';
// import contentDetail from './slice/contentDetail';
// import Masterslice from './slice/masterslice';
// import actionplandetails from './slice/actionplandetails';

// const store = configureStore({
//     reducer: {
//         loader: loaderReducer,
//         userdata: userDetail,
//         mentordata: matchedMentor,
//         contentdetails: contentDetail,
//         masterSlice: Masterslice,
//         actionplandetails: actionplandetails

//     },
// });

// export default store;


// import { configureStore, combineReducers } from '@reduxjs/toolkit';
// import { persistStore, persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
// import loaderReducer from './slice/loaderSlice';
// import userDetail from './slice/userDetail';
// import matchedMentor from './slice/matchedMentor';
// import contentDetail from './slice/contentDetail';
// import Masterslice from './slice/masterslice';
// import actionplandetails from './slice/actionplandetails';
// import allBook from './slice/bookDetails';

// const persistConfig = {
//     key: 'actionplandetails',
//     storage,
// };

// const persistedActionplanReducer = persistReducer(persistConfig, actionplandetails);

// const rootReducer = combineReducers({
//     loader: loaderReducer,
//     userdata: userDetail,
//     mentordata: matchedMentor,
//     contentdetails: contentDetail,
//     masterSlice: Masterslice,
//     actionplandetails: persistedActionplanReducer,
//     bookdetails: allBook
// });

// export const store = configureStore({
//     reducer: rootReducer,
// });

// export const persistor = persistStore(store);

import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import loaderReducer from './slice/loaderSlice';
import userDetailReducer from './slice/userDetail';
import matchedMentorReducer from './slice/matchedMentor';
import contentDetailReducer from './slice/contentDetail';
import masterSliceReducer from './slice/masterslice';
import actionPlanDetailsReducer from './slice/actionplandetails';
import allBookReducer from './slice/bookDetails';

const persistConfig = {
    key: 'root',
    storage,
};

const rootReducer = combineReducers({
    loader: loaderReducer,
    userdata: userDetailReducer,
    mentordata: matchedMentorReducer,
    contentdetails: contentDetailReducer,
    masterSlice: masterSliceReducer,
    actionplandetails: actionPlanDetailsReducer,
    bookdetails: allBookReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

const persistor = persistStore(store);

export { store, persistor };

