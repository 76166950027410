import React, { useState, useEffect } from 'react'
import CommunityHeader from '../components/CommunityHeader'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { interests, AddForum, ForumList, AddComment, AddReply, UserList, ForumVote, CommentVote, ReplyVote, ForumDetails, CommunityList, CommunityJoin, Leavecommunity, CommunityPosts, AddPost, AddCommunityComment, PostDetails, AddCommunityReply, PostVote, CommunityCommentVote, CommunityReplyVote } from '../api/apiHandler.js'
import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import checkValidation from '../validation/ValidationSchema';
import Swal from 'sweetalert2'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux';
import { startsearching } from '../store/slice/masterslice.js'
import { setLoading } from '../store/slice/loaderSlice';
import Loader from '../components/Loader';

const Communites = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch();
    const isSearching = useSelector((state) => state.masterSlice);
    const isLoading = useSelector((state) => state.loader);
    const UserData = useSelector((state) => JSON.parse(state.userdata))

    const [allInterests, setAllInterests] = useState();
    const [forum, SetForum] = useState()
    const [openItem, setOpenItem] = useState(null);
    const [openreply, setOpenreply] = useState(null);
    const [reply, setreply] = useState("")
    const [userlist, setUserlist] = useState()
    const [showUserSelect, setShowUserSelect] = useState(false);
    const [userid, Setuserid] = useState()
    const [comment, setComment] = useState()
    const [nocontent, setNocontent] = useState(204);
    const [explore, setexplore] = useState(localStorage.getItem("explore") ? true : false)
    const [visibleComments, setVisibleComments] = useState(3);
    const [visibleReply, setVisibleReply] = useState(1);
    const [activeIndex, setActiveIndex] = useState(null);
    const [viewmore, setviewmore] = useState(null)
    const [activeBars, setActiveBars] = useState("questions");
    const [activeBarsquestions, setActiveBarsquestions] = useState("questions");

    const [community, setCommunity] = useState([]);

    const [isJoin, setIsJoin] = useState(0);

    const [expand, setExpand] = useState(false);
    const [communitesexpand, setcommunitesExpand] = useState(false);


    const [communityData, setCommunityData] = useState("");

    const location = useLocation()

    useEffect(() => {
        if (location.pathname.match("communites")) {
            setActiveBars("Communites")
            if (!isJoin) {
                setcommunitesExpand(true);
            };
        };
    }, [])

    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [handlesearch, sethandlesearch] = useState(true)
    const itemsPerPage = 10;
    const [state, setState] = useState({
        inputValue: '',
    });

    const [showModal, setShowModal] = useState(false);
    const [usernameSelected, setUsernameSelected] = useState(false);
    const [communityPost, setCommunityPost] = useState()


    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    useEffect(() => {
        setexplore(localStorage.getItem("explore"))
    }, [localStorage.getItem("explore")])

    const schema = checkValidation(['communityTitle', 'communityDescription']);

    const { register, handleSubmit, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });
    useEffect(() => {
        interests().then((response) => {
            if (response.status === 200) {
                setAllInterests(response.data.interests)

            }
        }).catch((error) => {

        })
    }, [])

    useEffect(() => {
        dropdownList();
    }, [])

    const dropdownList = () => {
        CommunityList().then((response) => {
            if (response.status === 200) {
                setCommunity(response.data['community-list'])
                setcommunitesExpand(true);
            }
        }).catch((error) => {
            console.log(error);
        })
    }


    const handleleavecommunity = () => {
        if (communityData != "" && communityData.membership_details.community_member_id != null) {
            const leavecommunity = {
                is_active: false,
                community_member_id: communityData.membership_details.community_member_id
            }

            Leavecommunity(leavecommunity).then((response) => {
                if (response.status === 200) {

                    Swal.fire({
                        position: "top-right",
                        icon: "info",
                        title: "You have successfully left community.",
                        toast: true,
                        showConfirmButton: false,
                        timer: 2000
                    });
                    setIsJoin(2)
                    Allforumdata({ page: currentPage })
                    // defaultcommunity()
                }
            }).catch((error) => {
                console.log(error);
            })
        }

    }

    const handlejoinbutton = (communitydata) => {
        setCommunityData(communitydata)
        if (communitydata.membership_details.user_joined) {
            setIsJoin(1)
        } else {
            setIsJoin(2)
        }
    }


    const onSubmit = (data) => {

        // setclosemodle(true)

        const communitydata = {
            title: data.communityTitle,
            description: data.communityDescription,
            community: localStorage.getItem("Communites")
        }

        AddPost(communitydata).then((response) => {
            if (response.status == 201) {
                closeModal();
                // handlecommunitypost()
                Allforumdata({ page: currentPage })
                Object.keys(data).forEach((field) => {
                    setValue(field, '');
                });

                Swal.fire({
                    position: "top-right",
                    icon: "success",
                    title: "Community add successfully",
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                });
            }

        }).catch((error) => {
            Swal.fire({
                position: "top-right",
                icon: "error",
                title: error.response.data.errors,
                toast: true,
                showConfirmButton: false,
                timer: 2000
            });
        })
    }

    const handleComment = (items) => {
        const commentdata = {
            post: items,
            comment: comment
        }
        AddCommunityComment(commentdata).then((response) => {

            if (response.status == 201) {
                Allforumdata({ page: currentPage })
                setComment("")
            }

        }).catch((error) => {
            // Swal.fire({
            //     position: "top-right",
            //     icon: "error",
            //     title: error.response.data.errors,
            //     toast: true,
            //     showConfirmButton: false,
            //     timer: 2000
            // });

        })
    }

    const handlejoincommunity = () => {
        const communitydata = {
            community: localStorage.getItem('Communites')
        }
        CommunityJoin(communitydata).then((response) => {
            console.log('response: ', response);
            if (response.status == 201) {
                Swal.fire({
                    position: "top-right",
                    icon: "success",
                    title: "You have successfully joined community.",
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                });
                setIsJoin(1)
                dropdownList();
                // defaultcommunity()
            }

        }).catch((error) => {
            Swal.fire({
                position: "top-right",
                icon: "info",
                title: error.response.data.errors,
                toast: true,
                showConfirmButton: false,
                timer: 2000
            });

        })
    }


    useEffect(() => {
        const handleUserList = async () => {
            if (reply.includes('@') && !usernameSelected) { // Only show user list if no username has been selected
                const atIndex = reply.lastIndexOf('@');
                const searchQuery = reply.slice(atIndex + 1).trim();
                try {
                    // Call your user list API with the searchQuery
                    const response = await UserList({ username: searchQuery });
                    if (response.status === 200) {
                        setUserlist(response.data.users);
                        setShowUserSelect(true);
                    }
                } catch (error) {

                }
            } else {
                setShowUserSelect(false);
            }
        };

        handleUserList();
    }, [reply, usernameSelected]);

    const handlereplys = (e) => {
        setreply(e.target.value);
        // Reset the usernameSelected state when the user types '@'
        if (e.target.value.includes('@')) {
            setUsernameSelected(false);
        }
    };

    const handleReplay = (items) => {

        const replydata = {
            comment: items,
            // tagged_users: [20, 21, 25],
            reply: reply
        }
        // 

        AddCommunityReply(replydata).then((response) => {
            if (response.status == 201) {
                Allforumdata({ page: currentPage })
                setOpenreply(null)
                setreply("")

            }

        }).catch((error) => {

        })
    }



    const handleuserlist = (itemsid, username) => {
        setreply(reply.replace(/@[^@\s]*$/, `@${username} `));
        setShowUserSelect(false);
        setUsernameSelected(true); // Set the state to indicate that a username has been selected
    };
    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < Math.ceil(totalCount / itemsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };


    useEffect(() => {
        Allforumdata({ page: currentPage })
    }, [currentPage, isSearching])

    const Allforumdata = (data) => {

        if (handlesearch) {
            dispatch(startsearching(""))
        }
        data.serchdata = isSearching?.isSearch
        dispatch(setLoading(true))
        CommunityPosts(data).then((response) => {
            dispatch(setLoading(false))
            if (response.status == 200) {
                if (response.data.results.length > 0) {
                    sethandlesearch(false)
                    setCommunityPost(response.data.results)
                    setTotalCount(response.data.total_count)
                    setNocontent(200)

                }
            } else if (response.status == 204) {
                setCommunityPost([])
                Swal.fire({
                    position: "top-right",
                    icon: "info",
                    title: "No data found",
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                });
                setNocontent(204)

            }
        }).catch((error) => {

        })
    }

    const handleintrest = (interestid) => {
        Allforumdata({ page: currentPage, interest: interestid })
    }

    const handleformvote = (forumid, up_vote, down_vote, like_unlike) => {
        const postvotedata = {
            post: forumid,
            up_vote: up_vote,
            down_vote: down_vote
        }

        PostVote(postvotedata).then((response) => {
            if (response.status == 200) {
                Allforumdata({ page: currentPage })

            } else if (response.status == 204) {
                Allforumdata({ page: currentPage })
                Swal.fire({
                    position: "top-right",
                    icon: "warning",
                    title: "Forum has been removed due to multiple reporting",
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                });
            }

        }).catch((error) => {
            Swal.fire({
                position: "top-right",
                icon: "error",
                title: error.response.data.errors,
                toast: true,
                showConfirmButton: false,
                timer: 2000
            });
        })

    }

    const handleformCommentvote = (Commentid, up_vote, down_vote, like_unlike) => {

        const forumcommentvotedata = {
            comment: Commentid,
            up_vote: up_vote,
            down_vote: down_vote
        }

        CommunityCommentVote(forumcommentvotedata).then((response) => {
            if (response.status == 200) {
                Allforumdata({ page: currentPage })

            } else if (response.status == 204) {
                Allforumdata({ page: currentPage })
                Swal.fire({
                    position: "top-right",
                    icon: "warning",
                    title: "Comment has been removed due to multiple reporting",
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                });
            }

        }).catch((error) => {
            Swal.fire({
                position: "top-right",
                icon: "error",
                title: error.response.data.errors,
                toast: true,
                showConfirmButton: false,
                timer: 2000
            });
        })

    }


    const handleformCommentreplyvote = (replyid, up_vote, down_vote, like_unlike) => {
        const forumcommentreplyvotedata = {
            reply: replyid,
            up_vote: up_vote,
            down_vote: down_vote
        }

        CommunityReplyVote(forumcommentreplyvotedata).then((response) => {
            if (response.status == 200) {
                Allforumdata({ page: currentPage })

            } else if (response.status == 204) {
                Allforumdata({ page: currentPage })
                Swal.fire({
                    position: "top-right",
                    icon: "warning",
                    title: "Reply has been removed due to multiple reporting",
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                });
            }

        }).catch((error) => {
            Swal.fire({
                position: "top-right",
                icon: "error",
                title: error.response.data.errors,
                toast: true,
                showConfirmButton: false,
                timer: 2000
            });
        })

    }



    const handleCollapseToggle = (itemId) => {
        setOpenItem((prevItem) => (prevItem === itemId ? null : itemId));

        if (openItem !== itemId) {
            setOpenItem(itemId);

            PostDetails({ forumid: itemId }).then((response) => {
                if (response.status === 200) {
                    Allforumdata({ page: currentPage });
                }
            }).catch((error) => {

            });
        } else {
            setOpenItem(null);
        }
    };

    const handleCollapse2Toggle = (itemId, index) => {

        document.getElementById(index).value = ""
        setOpenreply((prevItem) => (prevItem === itemId ? null : itemId));

    };

    const handleShowMore = () => {
        setVisibleComments(visibleComments + 3);
    };

    const handleShowMorereply = (comment_id) => {
        setviewmore(comment_id)
        setVisibleReply(visibleReply + 2);


    };

    const handleItemClick = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };

    const handlecategory = () => {
        setCurrentPage(1)
    }

    const handleCommunites = () => {
        setCurrentPage(1)
    }

    const handlecategorydata = () => {
        setCurrentPage(1)

    }

    const handlequetion = () => {

    }


    const handlequationdata = () => {
        Allforumdata({ page: currentPage })
    }

    // const handleItemClick = (index) => {
    //     const newActiveBars = [...activeBars];
    //     newActiveBars[index] = !newActiveBars[index];
    //     setActiveBars(newActiveBars);
    // };

    // 
    // if (newForum) {
    //     navigate("/login")
    // }

    // const handlenewforum = () => {
    //     if (localStorage.getItem("token") != undefined && localStorage.getItem("token") != null && localStorage.getItem("token") != "") {
    //         navigate("/login")
    //     }
    // }

    const handlelogin = () => {
        localStorage.setItem("start_topic", true)
        navigate("/login")
    }

    const handleFreeTrialPage = () => {
        if (UserData && UserData.data.free_trial_used) {
            navigate("/subscription-plans")
        } else {
            navigate("/subscription")
        }
    }

    const handleexplore = () => {
        navigate('/group-discussions')
    }

    useEffect(() => {
        handlecommunitypost()
        defaultcommunity()
    }, [])



    const handlecommunitypost = (id) => {
        localStorage.setItem("Communites", id)
        CommunityPosts().then((response) => {
            if (response.status === 200) {
                setCommunityPost(response.data.results)
                setNocontent(200)

            } else if (response.status === 204) {
                Swal.fire({
                    position: "top-right",
                    icon: "error",
                    title: "No data found",
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                });
                setCommunityPost([])
                setNocontent(204)
            }
        }).catch((error) => {
            console.log(error);
            // Swal.fire({
            //     position: "top-right",
            //     icon: "error",
            //     title: error.response.data.errors,
            //     toast: true,
            //     showConfirmButton: false,
            //     timer: 2000
            // });
        })

    }

    const defaultcommunity = () => {
        setcommunitesExpand(true);
        // 
        // alert("function is calling")
        // localStorage.setItem("Communites", id)
        localStorage.setItem("Communites", "default")
        CommunityPosts().then((response) => {
            setIsJoin(0)
            if (response.status === 200) {
                setCommunityPost(response.data.results)
            }
        }).catch((error) => {
            console.log(error);
        })

    }

    return (
        <>
            <main className="helprr-dashboard">
                {localStorage.getItem("token") ?
                    <CommunityHeader /> :
                    <header className='fixed-top bg-white'>
                        <nav className="navbar navbar-expand-lg navbar-light navbar-header">
                            <div className="container d-flex">
                                <Link className="navbar-brand" to="/">
                                    <img src={process.env.PUBLIC_URL + "/assets/images/logo.png"} />
                                </Link>
                                <form className="d-flex ms-auto">
                                    <Link className="primary-btn font_18" to="/login">
                                        Login
                                    </Link>
                                </form>

                            </div>
                        </nav>
                    </header>
                }

                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-12">
                                {/* <Sidebar /> */}

                                <div id='mysidebar' className='sidebar '>

                                    <div className="sidebar_profile d-flex align-items-center">

                                        <div>
                                            <h6 className="font_20 font_300 mb-0 text-uppercase">Menu</h6>
                                            <p />
                                        </div>
                                    </div>
                                    <ul className="menu_ul list-unstyled">
                                        <div onClick={handlequationdata}>

                                            <li onClick={handlequetion}>
                                                <Link to="/group-discussions" className="menu_li">
                                                    {/* <Link to="/group-discussions" className="menu_li"> */}
                                                    <div className="menu_icon">
                                                        <img
                                                            src="assets/images/icon/question.svg"
                                                            className="img-fluid"
                                                            alt="icon"
                                                        />
                                                    </div>
                                                    <div className="flex-1">
                                                        <h6 className="menu_name font_13 font_400 mb-0">Questions</h6>
                                                    </div>
                                                </Link>
                                            </li>
                                        </div>
                                        <li onClick={handlecategory}>
                                            <Link to="/group-discussions"
                                                // className="menu_li"
                                                // className={activeBar != true ? "menu_li" : "menu_li active"} onClick={() => setActiveBar(!activeBar)}
                                                className={activeBars == "ExploreTopics" ? "menu_li active" : "menu_li"} onClick={() => setActiveBars("ExploreTopics")}
                                                data-bs-toggle="collapse"
                                                href="#collapseExample1"
                                                role="button"
                                                aria-expanded="false"
                                                aria-controls="collapseExample"
                                            >

                                                <div className="menu_icon">
                                                    <img
                                                        src="assets/images/icon/explore-topics.svg"
                                                        className="img-fluid"
                                                        alt="icon"
                                                    />
                                                </div>
                                                <div className="flex-1" >
                                                    <h6 className="menu_name font_13 font_400 mb-0" onClick={handleexplore}>Explore Topics</h6>
                                                </div>
                                            </Link>
                                        </li>

                                        <li onClick={handleCommunites}>
                                            <a
                                                // className="menu_li"
                                                // className={activeBars != true ? "menu_li" : "menu_li active"} onClick={() => setActiveBar(!activeBar)}
                                                className={activeBars == "Communites" ? "menu_li active" : "menu_li"} onClick={() => setActiveBars("Communites")}

                                                // className="menu_li active"
                                                data-bs-toggle="collapse"
                                                href="#collapseExample"
                                                role="button"
                                                aria-expanded={communitesexpand ? true : false}
                                                aria-controls="collapseExample"
                                            >

                                                <div className="menu_icon">
                                                    <img
                                                        src="assets/images/icon/community.svg"
                                                        className="img-fluid"
                                                        alt="icon"
                                                    />
                                                </div>
                                                <div className="flex-1" onClick={defaultcommunity}>
                                                    <h6 className="menu_name font_13 font_400 mb-0">Communites</h6>
                                                </div>
                                            </a>
                                        </li>


                                        <li>
                                            <div className="collapse show" id="collapseExample">
                                                <ul className="list-unstyled ms-4">
                                                    {community && community.map((item, index) => (
                                                        <div key={index} onClick={() => handlejoinbutton(item)}>
                                                            {item.is_public == false && item.membership_details.community_member_id != null && item.membership_details.user_joined == true && item.is_active == true ? <li>
                                                                <a
                                                                    className={activeBars == item.title ? "menu_li active" : "menu_li"} onClick={() => setActiveBars(item.title)}
                                                                >
                                                                    <div className="menu_icon">
                                                                        {/* <img src="assets/images/icon/explore-topics.svg" className="img-fluid" alt="icon"> */}
                                                                        <i className="fa-solid fa fa-circle" />
                                                                    </div>
                                                                    <div className="flex-1 cursor-pointer"
                                                                        // onClick={() => localStorage.setItem("Communites", item.id)}
                                                                        onClick={() => handlecommunitypost(item.id)}

                                                                    >
                                                                        <h6 className="menu_name font_13 font_400 mb-0" >{item.title}</h6>
                                                                    </div>
                                                                </a>
                                                            </li> : item.is_public == true ?
                                                                <li>
                                                                    <a
                                                                        className={activeBars == item.title ? "menu_li active" : "menu_li"} onClick={() => setActiveBars(item.title)}
                                                                    >
                                                                        <div className="menu_icon">
                                                                            {/* <img src="assets/images/icon/explore-topics.svg" className="img-fluid" alt="icon"> */}
                                                                            <i className="fa-solid fa fa-circle" />
                                                                        </div>
                                                                        <div className="flex-1 cursor-pointer"
                                                                            // onClick={() => localStorage.setItem("Communites", item.id)}
                                                                            onClick={() => handlecommunitypost(item.id)}

                                                                        >
                                                                            <h6 className="menu_name font_13 font_400 mb-0" >{item.title}</h6>
                                                                        </div>
                                                                    </a>
                                                                </li> : ""}
                                                        </div>
                                                    ))}
                                                </ul>
                                            </div>
                                        </li>

                                    </ul>
                                    <div className="sidebar_profile d-flex align-items-center">
                                        {/* <div className="sidebar_profile_image">
<img src="assets/images/user/user-2.png" className="img-fluid" alt="user">
</div> */}
                                        <div>
                                            <h6 className="font_20 font_300 mb-0 text-uppercase">
                                                PERSONAL NAVIGATOR
                                            </h6>
                                            <p />
                                        </div>
                                    </div>
                                    <ul className="menu_ul list-unstyled">
                                        <li className="" onClick={localStorage.setItem("PersonalTopics", "MyQuestions")}>
                                            <Link to="/my-questions"
                                                // className={activeBars == "MyQuestionsdata" ? "menu_li active" : "menu_li"} onClick={() => setActiveBars("MyQuestionsdata")}
                                                // className={localStorage.getItem("PersonalTopics") ? "menu_li active" : "menu_li"}
                                                className="menu_li"
                                            >
                                                <div className="menu_icon">
                                                    <img
                                                        src="assets/images/icon/Question-sidebar.svg"
                                                        className="img-fluid"
                                                        alt="icon"
                                                    />
                                                </div>
                                                <div className="flex-1">
                                                    <h6 className="menu_name font_13 font_400 mb-0">My Questions</h6>
                                                </div>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/my-answers" className={activeBars == "Myanswaredata" ? "menu_li active" : "menu_li"} onClick={() => setActiveBars("Myanswaredata")}>
                                                <div className="menu_icon">
                                                    <img
                                                        src="assets/images/icon/ChatsCircle.svg"
                                                        className="img-fluid"
                                                        alt="icon"
                                                    />
                                                </div>
                                                <div className="flex-1">
                                                    <h6 className="menu_name font_13 font_400 mb-0">My Answers</h6>
                                                </div>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/my-likes" className="menu_li">
                                                <div className="menu_icon">
                                                    <img
                                                        src="assets/images/icon/ThumbsUp.svg"
                                                        className="img-fluid"
                                                        alt="icon"
                                                    />
                                                </div>
                                                <div className="flex-1">
                                                    <h6 className="menu_name font_13 font_400 mb-0">My Likes</h6>
                                                </div>
                                            </Link>
                                        </li>
                                    </ul>
                                    {UserData && UserData.data.is_subscription_active == true ? "" :
                                        <div className="upgrade mt-auto">
                                            <div className="upgrade-image text-center">
                                                <img
                                                    src="assets/images/upgrade-vector.svg"
                                                    className="img-fluid"
                                                    alt="image"
                                                />
                                            </div>
                                            <div className="upgrade-box">
                                                <h6 className="font_20 font_400 text-center text-black mt-3">
                                                    Upgrade to Premium
                                                </h6>
                                                <button className="upgrade-btn font_20" onClick={handleFreeTrialPage}>
                                                    <img src="assets/images/icon/arrow-up.png" alt="arrow-up" />
                                                    Upgrade Now
                                                </button>
                                            </div>
                                        </div>}
                                </div>
                            </div>
                            <div className="col-xxl-9 col-xl-9 col-lg-9 col-12">
                                <div className="body-page">
                                    <div className="body-wrapper ">
                                        <div className="row">
                                            <div className="col-lg-9 col-xl-9 col-xxl-9 col-12">
                                                <div className="d-flex justify-content-between flex-wrap">

                                                    <button className="start-topic d-lg-none mb-3" onClick={openModal}
                                                    // data-bs-toggle="modal"
                                                    //     data-bs-target="#staticBackdrop1"
                                                    ><i className="fa-solid fa-plus pe-2"></i>
                                                        Start a New
                                                        Topic</button>
                                                </div>

                                                <div className="tab-content" id="pills-tabContent">
                                                    <div
                                                        className="tab-pane fade show active"
                                                        id="pills-home"
                                                        role="tabpanel"
                                                        aria-labelledby="pills-home-tab"
                                                    >
                                                        {communityPost && communityPost.map((item, key) => {
                                                            return (
                                                                <>
                                                                    <div key={key} className="community-card">
                                                                        <div className="d-flex gap-2 card-wrap">

                                                                            <div className='flex-grow-2 mb-2'>
                                                                                <div className="d-flex gap-2 justify-content-between">
                                                                                    <p className="title font_18 mb-0 font_600">
                                                                                        {item.title}
                                                                                    </p>
                                                                                    {/* <Link to="#" className="que-btn">
                                                                                            Question
                                                                                        </Link> */}
                                                                                </div>
                                                                                <div>
                                                                                    <p className="text font_15 mb-2">{moment(item.created).format('hh:mm A')}</p>
                                                                                    <p className="title font_16 mb-2">
                                                                                        {item.description}
                                                                                    </p>
                                                                                    {/* <img
                                                                                        className="com-chat-img"
                                                                                        src={process.env.PUBLIC_URL + "/assets/images/fs12v3j0z1b51.webp"}
                                                                                        alt=""
                                                                                    /> */}
                                                                                </div>

                                                                            </div>
                                                                            <div className="column-card">
                                                                                {item.user.photo != null && item.user.photo != undefined ?
                                                                                    <img
                                                                                        src={item.user.photo.image}
                                                                                        className="user-img"
                                                                                        alt=""
                                                                                    /> :
                                                                                    <>
                                                                                        <img
                                                                                            src={process.env.PUBLIC_URL + "/assets/images/defalt.png"}
                                                                                            className="user-img"
                                                                                            alt=""
                                                                                        />
                                                                                    </>
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                        <div className="d-flex align-items-center gap-2 justify-content-end">
                                                                            <p className="mb-0 font_600 font_17 viewp">
                                                                                <img
                                                                                    src={process.env.PUBLIC_URL + "/assets/images/icon/thumb-up.png"}
                                                                                    alt=""
                                                                                    onClick={() => handleformvote(item.id, true, false, "like")}
                                                                                />
                                                                                {item.up_vote_count}
                                                                            </p>
                                                                            {/* <p className="mb-0 font_600 font_17 viewp">
                                                                                <img
                                                                                    src={process.env.PUBLIC_URL + "/assets/images/icon/thumb-down.png"}
                                                                                    alt=""
                                                                                />
                                                                                {item.down_vote_count}
                                                                            </p> */}
                                                                            {/* <p className="mb-0 font_600 font_17 viewp">
                                                                                <img
                                                                                    src={process.env.PUBLIC_URL + "/assets/images/icon/view-icon.png"}
                                                                                    alt=""
                                                                                />
                                                                                {item.view_count}
                                                                            </p> */}

                                                                            <p
                                                                                onClick={() => handleCollapseToggle(item.id)}
                                                                                className="mb-0 font_600 font_17 viewp"
                                                                                type="button"
                                                                                data-bs-toggle="collapse"
                                                                                data-bs-target={`#collapseExample${item.id}`}
                                                                                aria-expanded={openItem === item.id}
                                                                                aria-controls={`collapseExample${item.id}`}
                                                                            >
                                                                                <img
                                                                                    src={process.env.PUBLIC_URL + "/assets/images/icon/chat-bubble.png"}
                                                                                    alt=""
                                                                                />
                                                                                {item.comments.length}
                                                                            </p>
                                                                            <img
                                                                                src="assets/images/icon/clarity_flag-solid.png"
                                                                                className="ps-1 object-fit-contain"
                                                                                onClick={() => handleformvote(item.id, false, true, "dislike")}

                                                                            />
                                                                            {item.down_vote_count}

                                                                        </div>
                                                                        {/* <div className="collapse" id={"#collapseExample" + item.id}> */}
                                                                        <div className={`collapse ${openItem === item.id ? 'show' : ''}`} id={`collapseExample${item.id}`}>

                                                                            <div className="hr-line"></div>
                                                                            {item.comments.slice(0, visibleComments).map((item, index) => {
                                                                                return (
                                                                                    <>

                                                                                        <div key={index}>
                                                                                            <div className="subsec gap-2 card-wrap">
                                                                                                {item.user.photo != null && item.user.photo != undefined ?
                                                                                                    <img
                                                                                                        src={item.user.photo.image}
                                                                                                        className="user-img"
                                                                                                        alt=""
                                                                                                    /> :
                                                                                                    <>
                                                                                                        <img
                                                                                                            src={process.env.PUBLIC_URL + "/assets/images/defalt.png"}
                                                                                                            className="user-img"
                                                                                                            alt=""
                                                                                                        />
                                                                                                    </>
                                                                                                }
                                                                                                <div className="flex-1">
                                                                                                    <p className="mb-0 font_16">
                                                                                                        {item.user.username}
                                                                                                        {/* <img
                                                                                                            src="assets/images/icon/check-blue.png"
                                                                                                            alt="check-blue"
                                                                                                            className="check-icon"
                                                                                                        /> */}
                                                                                                    </p>
                                                                                                    <p className="title font_16 mb-2">
                                                                                                        {item.comment}
                                                                                                    </p>
                                                                                                    <div className="d-flex gap-3">
                                                                                                        <p className="font_15 text-gray" style={{ cursor: "pointer" }} onClick={() => handleCollapse2Toggle(item.id, index)}
                                                                                                            data-bs-toggle="collapse"
                                                                                                            data-bs-target={`#collapsereply${item.id}`}
                                                                                                            aria-expanded={openItem === item.id}
                                                                                                            aria-controls={`collapsereply${item.id}`}
                                                                                                        >Reply</p>
                                                                                                        {/* <p className="font_15 text-gray" style={{ cursor: "pointer" }}>Share</p> */}
                                                                                                        {/* <p className="font_15 text-gray" style={{ cursor: "pointer" }}>Report</p> */}
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className="d-flex column-display justify-content-between">
                                                                                                    <p className="time-define mb-0 font_15">
                                                                                                        {moment(item.created).fromNow()}
                                                                                                    </p>
                                                                                                    <div className="d-flex">
                                                                                                        <p className="mb-0 font_600 font_17 viewp">
                                                                                                            <img
                                                                                                                src={process.env.PUBLIC_URL + "/assets/images/icon/thumb-up.png"}
                                                                                                                alt=""
                                                                                                                onClick={() => handleformCommentvote(item.id, true, false, "like")}
                                                                                                            />
                                                                                                            {item.up_vote_count}
                                                                                                        </p>
                                                                                                        <img
                                                                                                            src="assets/images/icon/clarity_flag-solid.png"
                                                                                                            className="ps-3 object-fit-contain"
                                                                                                            onClick={() => handleformCommentvote(item.id, false, true, "dislike")}
                                                                                                        />
                                                                                                        {item.down_vote_count}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            {item.replies.slice(0, visibleReply).map((item2, index) => {
                                                                                                return (
                                                                                                    <>
                                                                                                        <div key={index} className="subsec gap-2 card-wrap ms-5">
                                                                                                            {item2.user.photo != null && item2.user.photo != undefined ?
                                                                                                                <img
                                                                                                                    src={item2.user.photo.image}
                                                                                                                    className="user-img"
                                                                                                                    alt=""
                                                                                                                /> :
                                                                                                                <>
                                                                                                                    <img
                                                                                                                        src={process.env.PUBLIC_URL + "/assets/images/defalt.png"}
                                                                                                                        className="user-img"
                                                                                                                        alt=""
                                                                                                                    />
                                                                                                                </>
                                                                                                            }
                                                                                                            <div className="flex-1">
                                                                                                                <p className="mb-0 font_16">{item2.user.username}</p>

                                                                                                                <p className="title font_16 mb-2">
                                                                                                                    {item2.reply}
                                                                                                                </p>
                                                                                                                {/* <div className="d-flex gap-3">
                                                                                                                        <p className="font_15 text-gray">Reply</p>
                                                                                                                        <p className="font_15 text-gray">Share</p>
                                                                                                                        <p className="font_15 text-gray">Report</p>
                                                                                                                    </div> */}
                                                                                                            </div>
                                                                                                            <div className="d-flex column-display justify-content-between">
                                                                                                                <p className="time-define mb-0 font_15">
                                                                                                                    {moment(item2.created).fromNow()}
                                                                                                                </p>
                                                                                                                <div className="d-flex">
                                                                                                                    <p className="mb-0 font_600 font_17 viewp">
                                                                                                                        <img
                                                                                                                            src={process.env.PUBLIC_URL + "/assets/images/icon/thumb-up.png"}
                                                                                                                            alt=""
                                                                                                                            onClick={() => handleformCommentreplyvote(item2.id, true, false, "like")}
                                                                                                                        />
                                                                                                                        {item2.up_vote_count}
                                                                                                                    </p>
                                                                                                                    <img
                                                                                                                        src="assets/images/icon/clarity_flag-solid.png"
                                                                                                                        className="ps-3 object-fit-contain"
                                                                                                                        onClick={() => handleformCommentreplyvote(item2.id, false, true, "dislike")}
                                                                                                                    />
                                                                                                                    {item2.down_vote_count}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </>
                                                                                                )
                                                                                            })}


                                                                                            {item.replies.length > visibleReply && (
                                                                                                <div>
                                                                                                    <p className="mt-3 text-center text-gray font_600 font_15 cursor-pointer" onClick={handleShowMorereply}>
                                                                                                        View More Reply
                                                                                                    </p>
                                                                                                </div>
                                                                                            )}

                                                                                        </div>
                                                                                        <div className={`collapse ${openreply === item.id ? 'show' : ''}`} id={`collapsereply${item.id}`}>
                                                                                            <div className="d-flex gap-3 mt-4 position-relative">

                                                                                                {localStorage.getItem("photo") != undefined && localStorage.getItem("photo") != null && localStorage.getItem("photo") != "" ?
                                                                                                    <img src={localStorage.getItem("photo")} className="account-dropdown add-comment-icon" alt="" /> :
                                                                                                    <img src={process.env.PUBLIC_URL + "/assets/images/defalt.png"} className="account-dropdown add-comment-icon" alt="" />
                                                                                                }

                                                                                                <input
                                                                                                    type="text"
                                                                                                    className="form-control position-relative"
                                                                                                    placeholder="Reply"
                                                                                                    aria-label="Add a comment."
                                                                                                    aria-describedby="basic-addon2"

                                                                                                    id={index}
                                                                                                    name={`input${index}`}
                                                                                                    value={reply}
                                                                                                    onChange={(e) => handlereplys(e, item.id)}
                                                                                                />

                                                                                                {showUserSelect && showUserSelect != null && (reply != "") ?
                                                                                                    <div className='tag-dropdown'>
                                                                                                        {userlist && userlist.map((item, key) => {
                                                                                                            return (
                                                                                                                <>
                                                                                                                    <div className='tag-dropdown-list' key={key}>
                                                                                                                        <p
                                                                                                                            className="custom-event-select "
                                                                                                                            aria-label="Default select example"
                                                                                                                            value={item.id}
                                                                                                                            onClick={() => handleuserlist(key, item.username)}


                                                                                                                        // onChange={handleEventTypeChange}
                                                                                                                        >
                                                                                                                            {item.username}
                                                                                                                        </p>
                                                                                                                    </div>
                                                                                                                </>
                                                                                                            )

                                                                                                        })}
                                                                                                    </div> : <></>
                                                                                                }
                                                                                                {reply !== undefined && reply !== null && reply != "" ?
                                                                                                    <div>
                                                                                                        <img className="add-comment-icon"
                                                                                                            src="assets/images/icon/arrow-right-orange.png"
                                                                                                            alt=""
                                                                                                            onClick={() => {
                                                                                                                if (reply.trim() !== '') {
                                                                                                                    handleReplay(item.id)
                                                                                                                } else {
                                                                                                                    Swal.fire({
                                                                                                                        position: "top-right",
                                                                                                                        icon: "info",
                                                                                                                        title: "Please enter a valid comment.",
                                                                                                                        toast: true,
                                                                                                                        showConfirmButton: false,
                                                                                                                        timer: 2000
                                                                                                                    });
                                                                                                                }
                                                                                                            }


                                                                                                            }
                                                                                                        />
                                                                                                    </div> : <img className="add-comment-icon"
                                                                                                        src="assets/images/icon/arrow-right-orange.png"
                                                                                                        alt=""

                                                                                                    />}

                                                                                            </div>
                                                                                        </div>



                                                                                    </>
                                                                                )
                                                                            })}



                                                                            {item.comments.length > visibleComments && (
                                                                                <div>
                                                                                    <p className="mt-3 text-center text-gray font_600 font_15 cursor-pointer" onClick={handleShowMore}>
                                                                                        View More Comments
                                                                                    </p>
                                                                                </div>
                                                                            )}



                                                                            <div className="hr-line"> </div>
                                                                            <div className="d-flex gap-3 mt-4">

                                                                                {localStorage.getItem("photo") != undefined && localStorage.getItem("photo") != null && localStorage.getItem("photo") != "" ?
                                                                                    <img src={localStorage.getItem("photo")} className="account-dropdown add-comment-icon" alt="" /> :
                                                                                    <img src={process.env.PUBLIC_URL + "/assets/images/defalt.png"} className="account-dropdown add-comment-icon" alt="" />
                                                                                }

                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    placeholder="Add a comment."
                                                                                    aria-label="Add a comment."
                                                                                    aria-describedby="basic-addon2"
                                                                                    value={comment || ''}
                                                                                    onChange={(e) => setComment(e.target.value)}
                                                                                    onKeyDown={(e) => {
                                                                                        if (e.key === 'Enter') {
                                                                                            e.preventDefault(); // Prevent default behavior (e.g., form submission)
                                                                                            if ((comment || '').trim() !== '') {
                                                                                                handleComment(item.id);
                                                                                            } else {
                                                                                                Swal.fire({
                                                                                                    position: "top-right",
                                                                                                    icon: "info",
                                                                                                    title: "Please enter a valid comment.",
                                                                                                    toast: true,
                                                                                                    showConfirmButton: false,
                                                                                                    timer: 2000
                                                                                                });
                                                                                            }
                                                                                        }
                                                                                    }}
                                                                                />
                                                                                <img className="add-comment-icon"
                                                                                    src="assets/images/icon/arrow-right-orange.png"
                                                                                    alt=""
                                                                                    onClick={() => {
                                                                                        if ((comment || '').trim() !== '') {
                                                                                            handleComment(item.id);
                                                                                        } else {
                                                                                            Swal.fire({
                                                                                                position: "top-right",
                                                                                                icon: "info",
                                                                                                title: "Please enter a valid comment.",
                                                                                                toast: true,
                                                                                                showConfirmButton: false,
                                                                                                timer: 2000
                                                                                            });
                                                                                        }
                                                                                    }}

                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                        })}



                                                    </div>


                                                    <div
                                                        className="tab-pane fade"
                                                        id="pills-home"
                                                        role="tabpanel"
                                                        aria-labelledby="pills-home-tab"
                                                    >
                                                        ...
                                                    </div>
                                                    <div
                                                        className="tab-pane fade"
                                                        id="pills-home"
                                                        role="tabpanel"
                                                        aria-labelledby="pills-home-tab"
                                                    >
                                                        ...
                                                    </div>
                                                </div>

                                                {nocontent && nocontent == 204 ? <></> :
                                                    <div className="row justify-content-center mt-sm-5" >
                                                        <div className="col-lg-3 col-xl-3 col-md-4 col-6">
                                                            {/* {currentPage === 1 ? "" : */}
                                                            <button type="button" className={currentPage === 1 ? "load-btn-disable w-100" : "load-btn w-100"} onClick={handlePrevPage} disabled={currentPage === 1}>
                                                                Previous
                                                            </button>
                                                            {/* } */}

                                                        </div>
                                                        <div className="col-lg-3 col-xl-3 col-md-4 col-6">
                                                            {/* {currentPage === Math.ceil(totalCount / itemsPerPage) ? "" : */}
                                                            <button type="button" className={currentPage === Math.ceil(totalCount / itemsPerPage) ? "load-btn-disable w-100" : "load-btn w-100"} onClick={handleNextPage} disabled={currentPage === Math.ceil(totalCount / itemsPerPage)}>
                                                                Next
                                                            </button>
                                                            {/* } */}
                                                        </div>
                                                    </div>}
                                            </div>
                                            {isJoin && isJoin == 1 ? <div className="col-lg-3 col-xl-3 col-xxl-3 col-12">
                                                <button
                                                    onClick={openModal}
                                                    className="start-topic w-100  d-none d-lg-block"
                                                >
                                                    <i className="fa-solid fa-plus pe-2" />
                                                    Request to Post
                                                </button>

                                                <button
                                                    className="start-topic w-100  d-none d-lg-block mt-3"
                                                    onClick={handleleavecommunity}
                                                >
                                                    {/* <i className="fa-solid fa-users pe-2" /> */}
                                                    <i class="fa-solid fa-arrow-right-from-bracket pe-2"></i>
                                                    Leave Community
                                                </button>


                                            </div>
                                                : isJoin && isJoin == 2 ? <div className="col-lg-3 col-xl-3 col-xxl-3 col-12">
                                                    <button
                                                        className="start-topic w-100  d-none d-lg-block"
                                                        onClick={handlejoincommunity}
                                                    >
                                                        <i className="fa-solid fa-users pe-2" />
                                                        Join this Community
                                                    </button>
                                                </div> : ""}



                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </main >

            {showModal &&
                <>
                    <div>

                        {/* Bootstrap Modal */}
                        <div className={`modal fade ${showModal ? 'show' : ''}`} tabIndex="-1" role="dialog" style={{ display: showModal ? 'block' : 'none' }}>
                            <div className="modal-dialog modal-dialog-centered start-topic-modal">
                                <div className="modal-content">
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="modal-header">
                                            <h5 className="modal-title" id="staticBackdropLabel">
                                                <i className="fa-solid fa-circle-exclamation text-white pe-2" />{" "}
                                                Question/Topic Title
                                            </h5>
                                            <button
                                                type="button"
                                                className="btn-close"
                                                data-bs-dismiss="modal"
                                                aria-label="Close"
                                                onClick={closeModal}
                                            />
                                        </div>
                                        <div className="modal-body pt-0">
                                            <div className="mb-3">
                                                <h5 className="modal-title text-white pe-2" id="staticBackdropLabel">
                                                    Question Title
                                                </h5>
                                                <textarea
                                                    className="submit-que form-control"
                                                    id="exampleFormControlTextarea1"
                                                    rows={2}
                                                    placeholder="Ask your question here or share your thoughts with the community"
                                                    defaultValue={""}
                                                    name="communityTitle"
                                                    {...register("communityTitle")}
                                                />
                                                {errors.communityTitle && <p role="alert" className='error-text'>{errors.communityTitle.message}</p>}

                                            </div>
                                        </div>
                                        <div className="modal-body pt-0">
                                            <div className="mb-3">
                                                <h5 className="modal-title text-white pe-2" id="staticBackdropLabel">
                                                    Question description
                                                </h5>
                                                <textarea
                                                    className="submit-que form-control"
                                                    id="exampleFormControlTextarea1"
                                                    rows={3}
                                                    placeholder="Ask your description here or share your thoughts with the community"
                                                    defaultValue={""}
                                                    name="communityDescription"
                                                    {...register("communityDescription")}
                                                />
                                                {errors.communityDescription && <p role="alert" className='error-text'>{errors.communityDescription.message}</p>}
                                            </div>
                                        </div>
                                        <div className="modal-footer border-0 justify-content-start">

                                            <button type="submit" className="btn submit-btn" id='buttonclose'

                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                        {/* End Bootstrap Modal */}
                        {/* Modal Backdrop */}
                        <div className="modal-backdrop fade show"></div>
                    </div>
                </>
            }

        </>
    )
}

export default Communites