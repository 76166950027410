import React, { useState, useEffect } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { Link, useNavigate } from 'react-router-dom'
import OuterHeader from '../components/OuterHeader'
import { useDispatch, useSelector } from 'react-redux';
import { setUserDetails } from '../store/slice/userDetail';
import { userDetails, submitactionplan } from '../api/apiHandler.js';
import Swal from 'sweetalert2'



const Index = () => {
    const [chatBotHideShow, setChatBotHideShow] = useState(localStorage.getItem("Chatbot") === "true");
    const [loginUserData, setLoginUserData] = useState(JSON.parse(localStorage.getItem("loggedUserDetail")));
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handledevlopment = () => {
        navigate("/chatbot")
    }

    const handleChatbot = () => {

        setChatBotHideShow(!chatBotHideShow);
    }

    const handlecontent = () => {
        localStorage.setItem("Contentpage", true)
    }

    const handlelogin = () => {
        navigate("/login")
        localStorage.setItem("Chatbot", true)
    }

    useEffect(() => {
        // Clear the Chatbot item from localStorage after reading it
        localStorage.removeItem("Chatbot");
    }, []);

    return (

        <>
            <main className="helprr-main">
                {localStorage.getItem("islogin") == null ?
                    <Header /> :
                    <OuterHeader />
                }
                <section className="section-index  position-relative">
                    <div className="container">
                        <p className="title font_42">
                            Your Path to Healing and Personal Growth
                        </p>
                    </div>
                    <div className="landing-content">
                        <div className="container">
                            <p className="que-title font_28">
                                How would you like helprr to help you today?
                            </p>
                            <div className="row">
                                <div className="col-lg-9 col-xl-9 col-12">
                                    <div className="module-border-wrap">
                                        <Link to="/resource-search" className="index-tabs">
                                            <img alt="" src={process.env.PUBLIC_URL + "/assets/images/icon/search-icon.png"} />
                                            <p className="mb-0 font_22 text-mave font_600">
                                                Discover Support Around Me
                                            </p>
                                        </Link>
                                    </div>
                                    <div className="module-border-wrap" onClick={handlecontent}>
                                        <Link to="/mindful-content" className="index-tabs">
                                            <img alt="" src={process.env.PUBLIC_URL + "/assets/images/icon/play-icon.png"} />
                                            <p className="mb-0 font_22 text-mave font_600">
                                                Feel Motivated - Explore Mindful Content
                                            </p>
                                        </Link>
                                    </div>
                                    <div className="module-border-wrap">
                                        <Link to="/community" className="index-tabs">
                                            <img alt="" src={process.env.PUBLIC_URL + "/assets/images/icon/community.png"} />
                                            <p className="mb-0 font_22 text-mave font_600">
                                                Connect, Share, and Grow with Community
                                            </p>
                                        </Link>
                                    </div>
                                    <div className="module-border-wrap">
                                        <Link
                                            to="/interests-page"
                                            className="index-tabs"
                                        >
                                            <img alt="" src={process.env.PUBLIC_URL + "/assets/images/icon/help.png"} />
                                            <p className="mb-0 font_22 text-mave font_600" >
                                                Self Help - Action Plans and Book Recommendations
                                            </p>
                                        </Link>
                                    </div>

                                    {localStorage.getItem("islogin") == null ?
                                        <div className="module-border-wrap cursor-pointer" onClick={handlelogin} >
                                            <div className="index-tabs">
                                                <img alt="" src={process.env.PUBLIC_URL + "/assets/images/icon/chat-buddy.png"} />
                                                <p className="mb-0 font_22 text-mave font_600 " >
                                                    Chat with My Buddy helprr
                                                </p>
                                            </div>
                                        </div> :
                                        <div className="module-border-wrap cursor-pointer" onClick={handleChatbot} >
                                            <div className="index-tabs">
                                                <img alt="" src={process.env.PUBLIC_URL + "/assets/images/icon/chat-buddy.png"} />
                                                <p className="mb-0 font_22 text-mave font_600 " >
                                                    Chat with My Buddy helprr
                                                </p>
                                            </div>
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="vectors-group-sec">
                    <div className="d-flex justify-content-between">
                        <div className="right-vector">
                            <img alt="" src={process.env.PUBLIC_URL + "/assets/images/violet-vector.svg"} />
                        </div>
                        <div className="left-vector">
                            <img alt="" src={process.env.PUBLIC_URL + "/assets/images/orange-vector.svg"} />
                        </div>
                    </div>
                </section>
                <Footer isChatbotOpen={chatBotHideShow} />
            </main>



        </>


    )
}

export default Index