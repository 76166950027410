import React, { useEffect } from 'react'
import OuterHeader from '../components/OuterHeader'
import Footer from '../components/Footer'
import { Link } from 'react-router-dom'

const CancelChallengeGodash = () => {

    useEffect(() => {
        const disableBackButton = (e) => {
            e.preventDefault();
            window.history.forward(); // Forward the user when they try to go back
        };

        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', disableBackButton); // Listen for back button press

        return () => {
            window.removeEventListener('popstate', disableBackButton); // Cleanup the event listener when the component unmounts
        };
    }, []);
    return (
        <>
            <main className="helprr-main">
                <OuterHeader />
                <section className="bg-orange">
                    <div className="container">
                        <p className="font_40 text-white font_700 text-center pt-4">
                            No worries!
                        </p>
                        <div className="congrats-vector">
                            <img src={process.env.PUBLIC_URL + "/assets/images/purple-vector.svg"} alt="" />
                        </div>
                        <p className="font_30  text-white text-center">
                            I will be back with a fresh challenge tomorrow, until then, stay
                            hydrated :){" "}
                        </p>
                        <div className="row justify-content-center">
                            <div className="col-lg-3 col-6">
                                <Link to="/actionplan-dashboard" className="dash-btn ">
                                    Dashboard
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </main>

        </>
    )
}

export default CancelChallengeGodash