import React, { useEffect } from 'react'
import { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import OuterHeader from './OuterHeader.js'
import Footer from './Footer.js'
import ScrollToTop from './ScrollToTop.js';
import Swal from 'sweetalert2'
import { ContentTags, ContentList } from '../api/apiHandler.js'
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../store/slice/loaderSlice.js';



const Allbooks = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [content, setContent] = useState([]);
    const UserData = useSelector((state) => JSON.parse(state.userdata))
    const [mybookdata, setmybookdata] = useState(false)
    const [mybook, setmybook] = useState(location.state)
    const [totalCount, setTotalCount] = useState(0);
    const [nocontent, setNocontent] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;




    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < Math.ceil(totalCount / itemsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };


    useEffect(() => {
        contentlistdata({ page: currentPage })
    }, [currentPage])

    const contentlistdata = (data) => {
        ContentList(data).then((response) => {
            if (response.status == 200) {
                setmybook(response.data.results)
                setTotalCount(response.data.total_count);
            }
        }).catch((error) => {
            Swal.fire({
                position: "top-right",
                icon: "error",
                title: error.response.data.errors,
                toast: true,
                showConfirmButton: false,
                timer: 2000
            });

        })
    }



    return (
        <>


            <main className="helprr-main">
                <OuterHeader />
                <section className='more-content-sec'>
                    <div className="container">
                        {mybook && mybook.length > 0 ?
                            <p className="font_46 font_600 mb-0 sec-title">{localStorage.getItem("user_category")}</p> : ""
                        }
                        <div className="testimonial-area">
                            <div className='row'>
                                {mybook && mybook.map((item, idx) => {
                                    return (
                                        <>
                                            <div key={idx} className="col-xl-2 col-lg-3 col-md-4 col-sm-12 col-12 wow fadeInUp" data-wow-delay=".2s"
                                                onClick={() => navigate(`/book-description/${item.id}`)}
                                            >
                                                <div className="single-testi mb-4">
                                                    <Link to="/bookdescription" className="self-care-card d-block">
                                                        <div className='position-relative'>
                                                            <div className="selfcare-img">
                                                                {/* <img src={process.env.PUBLIC_URL + "/assets/images/care-img--1.svg"} alt="" /> */}

                                                                <img src={item && item.thumbnail_url != null ? item.thumbnail_url : item.thumbnail} alt="" />

                                                            </div>
                                                        </div>

                                                        <div className="title">
                                                            <p className="font_20 mb-1">{item.tags_details.tag}</p>
                                                            <p className="font_14 mb-0">{item.authors_details}</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })}

                            </div>

                        </div>
                    </div>
                </section>
                {nocontent && nocontent == 204 ? <></> :
                    <div className='container'>
                        <div className="row justify-content-center mt-xl-5" >
                            <div className="col-lg-2 col-lg-3 col-xl-2 col-md-4 col-6">
                                <button type="button" className={currentPage === 1 ? "load-btn-disable w-100" : "load-btn w-100"} onClick={handlePrevPage} disabled={currentPage === 1}>
                                    Previous
                                </button>

                            </div>
                            <div className="col-lg-2 col-xl-2 col-md-4 col-6">
                                <button type="button" className={currentPage === Math.ceil(totalCount / itemsPerPage) ? "load-btn-disable w-100" : "load-btn w-100"} onClick={handleNextPage} disabled={currentPage === Math.ceil(totalCount / itemsPerPage)}>
                                    Next
                                </button>
                            </div>
                        </div>
                    </div>}


                <div style={{ paddingTop: "1%" }}>
                    <Footer />
                </div>
            </main>


        </>
    )

}

export default Allbooks

