import React from 'react'
import { Link } from 'react-router-dom'

const PeerMentorship = () => {
    return (
        <>
            <main>
                <section className="section-survey">
                    <div className="container">
                        <div>
                            <Link to="/peer-mentorship">
                                <img className='back-arrow-icon' src={process.env.PUBLIC_URL + "/assets/images/icon/left-arrow.svg"} alt="" />
                            </Link>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-lg-7 col-xl-6 col-12">
                                <p className="text-black font_30 font_600 mb-5 mt-4">
                                    What would you like to become?
                                </p>
                                <Link to="/questions" className="select-option" onClick={() => localStorage.setItem("questions_for", "mentor")}>
                                    Mentor
                                </Link>
                                <Link to="/questions" className="select-option" onClick={() => localStorage.setItem("questions_for", "mentee")}>
                                    Mentee
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

        </>
    )
}

export default PeerMentorship