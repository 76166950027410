import React, { useState, useEffect } from 'react'
import Footer from '../components/Footer'
import OuterHeader from '../components/OuterHeader'
import { Link, useNavigate } from 'react-router-dom'
import Select2 from '../pages/Select2';
import '../css2/select2.css'
import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import checkValidation from '../validation/ValidationSchema';
import Swal from 'sweetalert2'
import { ResourceTags } from '../api/apiHandler.js';

import PlacesAutocomplete, { geocodeByAddress, getLatLng, } from "react-places-autocomplete";
import Header from '../components/Header.js';


const SearchPage = () => {

    const navigate = useNavigate();
    const schema = checkValidation(["dropdown"]);

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    const [state, setState] = useState(false);
    const [statecity, setStateCity] = useState(false);
    const [searchQuery, setSearchQuery] = useState(localStorage.getItem("search") != null ? localStorage.getItem("search") : "");
    const tagarray = [
        { "id": 1, "tag": "Depression and Anxiety" },
        { "id": 2, "tag": "Stress Management" },
        { "id": 3, "tag": "Suicide Prevention" },
        { "id": 4, "tag": "Addiction and Substance Abuse" },
        { "id": 5, "tag": "Youth Mental Health" },
        { "id": 6, "tag": "Trauma and PTSD" }
    ]
    const [tags, setTags] = useState(tagarray);

    const [address, setAddress] = useState(localStorage.getItem("full_address") != null ? localStorage.getItem("full_address") : "");
    const [handleAddress, setHandleAddress] = useState(false);
    const [staticOptionsLoaded, setStaticOptionsLoaded] = useState(false);

    const handleSelect = async (value) => {
        try {
            const results = await geocodeByAddress(value);
            const ll = await getLatLng(results[0]);

            // Extract country, state, and city from the address components
            const addressComponents = results[0].address_components || [];
            let country, state, city;

            for (const component of addressComponents) {
                if (component.types.includes('country')) {
                    country = component.long_name;
                } else if (component.types.includes('administrative_area_level_1')) {
                    state = component.long_name;
                } else if (component.types.includes('locality')) {
                    city = component.long_name;
                }
            }

            // You can also store these values in localStorage if needed
            if (country != undefined) {
                localStorage.setItem('country', country);
                localStorage.removeItem('state');
                localStorage.removeItem('city');
            } else {
                localStorage.removeItem('country');
            }

            if (state != undefined) {
                localStorage.setItem('state', state);
                localStorage.removeItem('city');
            } else {
                localStorage.removeItem('state');
            }

            if (city != undefined) {
                localStorage.setItem('city', city);
            } else {
                localStorage.removeItem('city');
            }

            setAddress(value);
            localStorage.setItem("full_address", value)
            setHandleAddress(true);
            // setCoordinates(ll);
        } catch (error) {
            console.error('Error handling selection:', error);
        }
    };

    const handleSearchChange = (e) => {
        localStorage.setItem("search", e.target.value)
        setSearchQuery(e.target.value);
    };

    const handleInputValue = (e) => {
        localStorage.setItem("search", e.target.innerText)
        setSearchQuery(e.target.innerText);
    };

    const filteredItems = tags && tags.filter(item =>
        item.tag.toLowerCase().includes(searchQuery.toLowerCase())

    );

    const SearchData = async() => {
        
        const results = await geocodeByAddress(address);
        
        // Extract country, state, and city from the address components
        const addressComponents = results[0].address_components || [];
        let country, state, city;

        for (const component of addressComponents) {
            if (component.types.includes('country')) {
                country = component.long_name;
            } else if (component.types.includes('administrative_area_level_1')) {
                state = component.long_name;
            } else if (component.types.includes('locality')) {
                city = component.long_name;
            }
        }
        
        // You can also store these values in localStorage if needed
        if (country != undefined) {
            localStorage.setItem('country', country);
            localStorage.removeItem('state');
            localStorage.removeItem('city');
        } else {
            localStorage.removeItem('country');
        }

        if (state != undefined) {
            localStorage.setItem('state', state);
            localStorage.removeItem('city');
        } else {
            localStorage.removeItem('state');
        }

        if (city != undefined) {
            localStorage.setItem('city', city);
        } else {
            localStorage.removeItem('city');
        }

        if (searchQuery != "" && address != "") {
            if (localStorage.getItem("islogin") == null) {
                localStorage.setItem("search_resource", true)
            }
            localStorage.setItem("full_address", address)
            localStorage.removeItem("rating")
            localStorage.removeItem("distance")
            localStorage.removeItem("service")
            localStorage.removeItem("languages")
            localStorage.removeItem("cost")
            // if (handleAddress == false) {
            //     var split_city = address.split(",")
            //     localStorage.setItem("city", split_city[0])
            //     localStorage.removeItem("country")
            //     localStorage.removeItem("state")
            // } else {
            //     setHandleAddress(false);
            // }
            navigate("/resource")
        } else {
            Swal.fire({
                position: "top-right",
                icon: "warning",
                title: "Please select a value",
                toast: true,
                showConfirmButton: false,
                timer: 2000
            });
        }

    }

    // useEffect(() => {

    //     ResourceTags({}).then((response) => {
    //         if (searchQuery !== '') {

    //             setTags(response.data.tags);
    //         } else {

    //             setTags(tagarray);
    //         }
    //     }).catch((error) => {
    //         console.log(error, 'error');
    //     });

    // }, [searchQuery]);

    useEffect(() => {
        if (localStorage.getItem("search_resource")) {
            if ((localStorage.getItem("city") != undefined && localStorage.getItem("city") != "" && localStorage.getItem("city") != null) && (localStorage.getItem("state") != undefined && localStorage.getItem("state") != "" && localStorage.getItem("state") != null) && (localStorage.getItem("country") != undefined && localStorage.getItem("country") != "" && localStorage.getItem("country") != null)) {

                setHandleAddress(true)
                localStorage.removeItem("search_resource")
            }
        }
    })

    return (
        <>
            <>
                <main className="helprr-main">
                    {/* <OuterHeader /> */}
                    {localStorage.getItem("token") ?
                        <OuterHeader /> :
                        <Header />
                    }

                    <section>
                        <div className="container">
                            <div className="section-search">
                                <div className="row justify-content-center">
                                    <div className="col-lg-7 col-xl-7 col-12">
                                        <p className="font_32 pt-3">
                                            Finding help should never be overwhelming and we get that.
                                        </p>
                                        <div className="mb-4" onClick={() => {
                                            setState(!state)
                                            setStateCity(false)
                                        }}>

                                            <div className="position-relative">
                                                <div className="input-group border-0 custom-dropdown mb-3">
                                                    <input
                                                        type="text"
                                                        className="form-control border-0"
                                                        id="toggle"
                                                        placeholder="What do you need help with?"
                                                        aria-label="Recipient's username"
                                                        aria-describedby="basic-addon2"
                                                        autoComplete="off"
                                                        maxLength={64}
                                                        value={searchQuery}
                                                        onChange={handleSearchChange}
                                                    />
                                                    <span
                                                        className="input-group-text arrow-icon border-0 p-2"
                                                        id="basic-addon2"
                                                    >
                                                        <img
                                                            src="assets/images/icon/dropdown-icon.svg"
                                                            alt=""
                                                            id="arrow-image"
                                                            className={state == false ? "" : "rotated-image"}
                                                        />
                                                    </span>
                                                </div>
                                                <div id="content" className={state == false ? "is-hidden drop-sec" : "drop-sec"}>
                                                    <div className="row">
                                                        <div className="col-lg-12 col-xl-12 col-12">

                                                            <ul className="list-unstyled">
                                                                {filteredItems && filteredItems.map((item, index) => (
                                                                    <li key={index} onClick={(e) => handleInputValue(e)} >{item.tag}</li>
                                                                ))}

                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <PlacesAutocomplete
                                            value={address}
                                            onChange={setAddress}
                                            onSelect={handleSelect}
                                            googleCallbackName="initPlaces"
                                        >
                                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                <div className="mb-4" onClick={() => setStateCity(!statecity)}>
                                                    <div className="position-relative">
                                                        <div className="input-group border-0 custom-dropdown mb-3">
                                                            <input
                                                                className="form-control border-0"
                                                                id="address"
                                                                placeholder="Where Are You Looking? (City or Area)"
                                                                {...getInputProps()}
                                                            />
                                                            <span
                                                                className="input-group-text arrow-icon border-0 p-2"
                                                                id="basic-addon2"
                                                            >
                                                                <img
                                                                    src="assets/images/icon/dropdown-icon.svg"
                                                                    alt=""
                                                                    id="arrow-image"
                                                                    className={statecity ? "rotated-image" : ""}
                                                                />
                                                            </span>
                                                        </div>
                                                        <div id="content" className={statecity ? "drop-sec" : "is-hidden drop-sec"}>
                                                            <div className="row">
                                                                <div className="col-lg-12 col-xl-12 col-12">
                                                                    <ul className="list-unstyled">
                                                                        {suggestions.map((suggestion, idx) => {
                                                                            const style = {
                                                                                backgroundColor: suggestion.active
                                                                                    ? "#f1f0ff"
                                                                                    : "#fff",
                                                                                textAlign: "left",
                                                                            };

                                                                            return (
                                                                                <li key={idx}
                                                                                    className="form-control"
                                                                                    {...getSuggestionItemProps(suggestion, {
                                                                                        style,
                                                                                    })}
                                                                                >
                                                                                    {suggestion.description}
                                                                                </li>
                                                                            );
                                                                        })}
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </PlacesAutocomplete>
                                        <button
                                            // to="/resource"
                                            className="search-btn font_18 mt-4 mb-3"
                                            onClick={() => SearchData()}>
                                            Search
                                        </button>
                                        {/* <div class="d-flex justify-content-center">
                          <a href="" class="btn font_22" data-bs-toggle="modal" data-bs-target="#modal-advanced"
                              class="font_24 d-block">Advanced Filters</a>

                      </div> */}
                                    </div>
                                </div>
                                {/* <div class="row justify-content-end">
                  <div class="col-12 d-flex justify-content-end">

                      <a href="search-learn-more.php" class="btn font_22 d-block read-more-top">Read
                          More</a>

                  </div>
              </div> */}
                            </div>
                        </div>
                    </section>
                    <section className="other-res-pad" onClick={() => {
                        setStateCity(false)
                        setState(false)
                    }}>
                        <div className="container">
                            <p className="font_50 font_600">Other Contents</p>
                            <div className="row justify-content-center" >
                                <div className="col-lg-4 col-xl-4 col-md-6 col-12">
                                    <Link to="/content">
                                        <div className="curated">
                                            <div className="cure-img">
                                                <img src={process.env.PUBLIC_URL + "/assets/images/curated-content.svg"} alt='' />
                                            </div>
                                            <p className="font_26 font_600 mt-3 mb-0">Curated Content</p>
                                            {/* <div class="d-flex justify-content-end">
                          <a href="resources.php" class="learn-more font_20">Learn More</a>
                      </div> */}
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-lg-4 col-xl-4 col-md-6 col-12">
                                    <Link to="/community">
                                        <div className="curated bg-mave">
                                            <div className="cure-img">
                                                <img src={process.env.PUBLIC_URL + "/assets/images/community-support.svg"} alt='' />
                                            </div>
                                            <p className="font_26 font_600 mt-3 text-white mb-0">
                                                Community Support
                                            </p>
                                            {/* <div class="d-flex justify-content-end">
                          <a href="group-discussions.php" class="learn-more font_20">Learn More</a>
                      </div> */}
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </main>
                <div
                    className="modal fade"
                    id="modal-advanced"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    aria-labelledby="staticBackdropLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-dialog-centered modal-xl advanced-modal">
                        <div className="modal-content">
                            {/* <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">Modal title</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div> */}
                            <div className="modal-body">
                                <div
                                    className="d-flex gap-3 align-items-center cursor-pointer p-3"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <img
                                        src="assets/images/icon/left-arrow.svg"
                                        className="object-fit-contain"
                                        alt="back"
                                    />
                                    <p className="font_24  mb-0 ">Go Back</p>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-6 col-xl-6 col-12">
                                        <p className="font_40">
                                            Finding help should never be overwhelming and we get that.
                                        </p>
                                        <div className="mb-4 position-relative">
                                            <select
                                                className="country-select bg-light form-control js-example-basic-single"
                                                name="state"
                                                data-placeholder="What Are You Looking For?"
                                                data-dropdown-parent="#modal-advanced"
                                            >
                                                <option />
                                                <option>Mental Health Wellness</option>
                                                <option>Anxiety Help</option>
                                                <option>Depression Help</option>
                                                <option>ADHD Help</option>
                                                <option>Autism Help</option>
                                            </select>
                                        </div>
                                        <div className="mb-4">
                                            <select
                                                className="country-select bg-light form-control js-example-basic-single"
                                                name="state"
                                                data-dropdown-parent="#modal-advanced"
                                                data-placeholder="Where Are You Looking? (City or Area)"
                                            >
                                                <option />
                                                <option>Toronto, Ontario Canada</option>
                                                <option>Oakville, Ontario Canada</option>
                                                <option>Hamilton, Ontario Canada</option>
                                                <option>Sudbury, Ontario Canada</option>
                                                <option>Barrie, Ontario Canada</option>
                                            </select>
                                        </div>
                                        <div className="mb-4">
                                            <select
                                                className="country-select bg-light form-control js-example-basic-single"
                                                name="state"
                                                data-dropdown-parent="#modal-advanced"
                                                data-placeholder="Type of Content"
                                            >
                                                <option />
                                                <option>Books</option>
                                                <option>Articles</option>
                                                <option>Videos</option>
                                                <option>Documentaries</option>
                                            </select>
                                        </div>
                                        <div className="mb-4">
                                            <select
                                                className="country-select bg-light form-control js-example-basic-single"
                                                name="state"
                                                data-dropdown-parent="#modal-advanced"
                                                data-placeholder="Length of Content"
                                            >
                                                <option />
                                                <option>Short (&lt; 5 minutes)</option>
                                                <option>Medium (5-10 minutes)</option>
                                                <option>Long (10-30 minutes)</option>
                                                <option>Super Long (30+ minutes)</option>
                                            </select>
                                        </div>
                                        <a
                                            className="search-btn font_20 mt-4 mb-4"
                                        >
                                            Search
                                        </a>
                                    </div>
                                </div>
                            </div>
                            {/* <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" class="btn btn-primary">Understood</button>
          </div> */}
                        </div>
                    </div>
                </div>
            </>

        </>
    )
}

export default SearchPage