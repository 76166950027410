import React, { useEffect, useState } from 'react'
import OuterHeader from '../components/OuterHeader'
import Footer from '../components/Footer'
import { todayAffirmation, yesterdaysAffirmation } from '../api/apiHandler.js'
import ScrollToTop from '../components/ScrollToTop';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../store/slice/loaderSlice';
import Loader from '../components/Loader';
import Swal from 'sweetalert2'




const DailyAffirmations = () => {
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.loader);
    const [scroll, setScroll] = useState(true)
    const [affirmationdata, setAffirmationdata] = useState([]);
    const [yesterdaysaffirmation, setYesterdaysAffirmation] = useState([]);



    useEffect(() => {
        dispatch(setLoading(true))
        todayAffirmation().then((response) => {
            if (response.status == 200) {
                dispatch(setLoading(false))
                setAffirmationdata(response.data)
            } else if (response.status == 204) {
                Swal.fire({
                    position: "top-right",
                    icon: "info",
                    title: "No affirmation found for today",
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                });
            }
        }).catch((error) => {
            Swal.fire({
                position: "top-right",
                icon: "error",
                title: error.response.data.errors,
                toast: true,
                showConfirmButton: false,
                timer: 2000
            });
        })

        yesterdaysAffirmation().then((response) => {
            dispatch(setLoading(true))
            if (response.status == 200) {
                dispatch(setLoading(false))
                setYesterdaysAffirmation(response.data)
            } else if (response.status == 204) {
                Swal.fire({
                    position: "top-right",
                    icon: "info",
                    title: "No affirmation found for yesterday",
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                });
            }
        }).catch((error) => {
            Swal.fire({
                position: "top-right",
                icon: "error",
                title: error.response.data.errors,
                toast: true,
                showConfirmButton: false,
                timer: 2000
            });
        })


    }, [])



    return (
        <>
            {scroll ? <ScrollToTop /> : null}
            {isLoading ?
                (<Loader loader_status={isLoading} />) :
                (
                    <main className="helprr-main">
                        <OuterHeader />
                        <section className="other-res-pad pt-0">
                            <div className="container">
                                <div className="d-flex justify-content-between align-items-center mb-5">
                                    <p className="font_50 font_600 mb-0">Daily Affirmations</p>
                                </div>

                                {/* <div className="row justify-content-center">
                                    <div className="col-md-6 col-lg-5 col-xl-5 col-xxl-4 col-12">
                                        <div className="affirmation-card lh-1">
                                            <p className="font_64 font_600 mb-0">
                                                You are <br />
                                                <span className="text font_94">Strong!</span>
                                            </p>
                                        </div>
                                        {affirmationdata[0] != undefined ?
                                            <div className="affirmation-card--1">
                                                <p className="font_34 font_600 mb-0">{affirmationdata[0] && affirmationdata[0].affirmation}</p>
                                                <p className="font_32 font_600 white-space-nw">- {affirmationdata[0] && affirmationdata[0].author}</p>
                                            </div> : <></>}
                                    </div>
                                    <div className="col-md-6 col-lg-5 col-xl-5 col-xxl-4 col-12">
                                        {affirmationdata[1] != undefined ? <div className="affirmation-card--2">
                                            <p className="font_34 font_600 mb-0 lh-sm">
                                                {affirmationdata[1] && affirmationdata[1].affirmation}
                                            </p>
                                            <p className="font_32 text-end mb-0 font_600 lh-1">-{affirmationdata[1] && affirmationdata[1].author}</p>
                                        </div> : <></>}
                                        {affirmationdata[2] != undefined ?
                                            <div className="affirmation-card--2 bg-mave">
                                                <p className="font_34 font_600 mb-0 lh-sm text-white">
                                                    {affirmationdata[2] && affirmationdata[2].affirmation}
                                                </p>
                                                <p className="font_32 text-end mb-0 font_600 lh-1 text-white">
                                                    -{affirmationdata[2] && affirmationdata[2].author}
                                                </p>
                                            </div> : <></>}
                                    </div>
                                </div> */}

                                <div className="row justify-content-center">
                                    <div className="col-md-6 col-lg-5 col-xl-5 col-xxl-4 col-12">
                                        {affirmationdata[0] != undefined ?
                                            <div className="affirmation-card lh-1">
                                                <p className="font_34 font_600 mb-0">{affirmationdata[0] && affirmationdata[0].affirmation}</p>
                                                <p className="font_32 font_600 white-space-nw">- {affirmationdata[0] && affirmationdata[0].author}</p>

                                            </div> : <></>}
                                        {affirmationdata[1] != undefined ?
                                            <div className="affirmation-card--1">
                                                <p className="font_34 font_600 mb-0">{affirmationdata[1] && affirmationdata[1].affirmation}</p>
                                                <p className="font_32 font_600 white-space-nw">- {affirmationdata[1] && affirmationdata[1].author}</p>
                                            </div> : <></>}
                                    </div>
                                    <div className="col-md-6 col-lg-5 col-xl-5 col-xxl-4 col-12">
                                        {affirmationdata[2] != undefined ? <div className="affirmation-card--2">
                                            <p className="font_34 font_600 mb-0 lh-sm">
                                                {affirmationdata[2] && affirmationdata[2].affirmation}
                                            </p>
                                            <p className="font_32 text-end mb-0 font_600 lh-1">-{affirmationdata[2] && affirmationdata[2].author}</p>
                                        </div> : <></>}
                                        {affirmationdata[3] != undefined ?
                                            <div className="affirmation-card--2 bg-mave">
                                                <p className="font_34 font_600 mb-0 lh-sm text-white">
                                                    {affirmationdata[3] && affirmationdata[3].affirmation}
                                                </p>
                                                <p className="font_32 text-end mb-0 font_600 lh-1 text-white">
                                                    -{affirmationdata[3] && affirmationdata[3].author}
                                                </p>
                                            </div> : <></>}
                                    </div>
                                </div>

                            </div>
                        </section>
                        <section className="other-res-pad pt-0">
                            <div className="container">
                                <div className="d-flex justify-content-between align-items-center mb-5">
                                    <p className="font_50 font_600 mb-0">Yesterday’s Affirmations</p>
                                </div>
                                {/* <div className="row justify-content-center">
                                    <div className="col-md-6 col-lg-5 col-xl-5 col-xxl-4 col-12">
                                        <div className="affirmation-card lh-1">
                                            <p className="font_64 font_600 mb-0">
                                                You are <br />
                                                <span className="text font_94">Strong!</span>
                                            </p>
                                        </div>
                                        {yesterdaysaffirmation[0] != undefined ?
                                            <div className="affirmation-card--1">
                                                <p className="font_34 font_600 mb-0">{yesterdaysaffirmation[0] && yesterdaysaffirmation[0].affirmation}</p>
                                                <p className="font_32 font_600 white-space-nw">-{yesterdaysaffirmation[0] && yesterdaysaffirmation[0].author}</p>
                                            </div> : <></>}
                                    </div>
                                    <div className="col-md-6 col-lg-5 col-xl-5 col-xxl-4 col-12">
                                        {yesterdaysaffirmation[1] != undefined ?
                                            <div className="affirmation-card--2">
                                                <p className="font_34 font_600 mb-0 lh-sm">
                                                    {yesterdaysaffirmation[1] && yesterdaysaffirmation[1].affirmation}
                                                </p>
                                                <p className="font_32 text-end mb-0 font_600 lh-1">-{yesterdaysaffirmation[1] && yesterdaysaffirmation[1].author}</p>
                                            </div> : <></>}
                                        {yesterdaysaffirmation[2] != undefined ?
                                            <div className="affirmation-card--2 bg-mave">
                                                <p className="font_34 font_600 mb-0 lh-sm text-white">
                                                    {yesterdaysaffirmation[2] && yesterdaysaffirmation[2].affirmation}
                                                </p>
                                                <p className="font_32 text-end mb-0 font_600 lh-1 text-white">
                                                    -{yesterdaysaffirmation[2] && yesterdaysaffirmation[2].author}
                                                </p>
                                            </div> : <></>}
                                    </div>
                                </div> */}

                                <div className="row justify-content-center">
                                    <div className="col-md-6 col-lg-5 col-xl-5 col-xxl-4 col-12">
                                        {yesterdaysaffirmation[0] != undefined ?
                                            <div className="affirmation-card lh-1">
                                                <p className="font_34 font_600 mb-0">{yesterdaysaffirmation[0] && yesterdaysaffirmation[0].affirmation}</p>
                                                <p className="font_32 font_600 white-space-nw">- {yesterdaysaffirmation[0] && yesterdaysaffirmation[0].author}</p>

                                            </div> : <></>}
                                        {yesterdaysaffirmation[1] != undefined ?
                                            <div className="affirmation-card--1">
                                                <p className="font_34 font_600 mb-0">{yesterdaysaffirmation[1] && yesterdaysaffirmation[1].affirmation}</p>
                                                <p className="font_32 font_600 white-space-nw">- {yesterdaysaffirmation[1] && yesterdaysaffirmation[1].author}</p>
                                            </div> : <></>}
                                    </div>
                                    <div className="col-md-6 col-lg-5 col-xl-5 col-xxl-4 col-12">
                                        {yesterdaysaffirmation[2] != undefined ? <div className="affirmation-card--2">
                                            <p className="font_34 font_600 mb-0 lh-sm">
                                                {yesterdaysaffirmation[2] && yesterdaysaffirmation[2].affirmation}
                                            </p>
                                            <p className="font_32 text-end mb-0 font_600 lh-1">-{yesterdaysaffirmation[2] && yesterdaysaffirmation[2].author}</p>
                                        </div> : <></>}
                                        {yesterdaysaffirmation[3] != undefined ?
                                            <div className="affirmation-card--2 bg-mave">
                                                <p className="font_34 font_600 mb-0 lh-sm text-white">
                                                    {yesterdaysaffirmation[3] && yesterdaysaffirmation[3].affirmation}
                                                </p>
                                                <p className="font_32 text-end mb-0 font_600 lh-1 text-white">
                                                    -{yesterdaysaffirmation[3] && yesterdaysaffirmation[3].author}
                                                </p>
                                            </div> : <></>}
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </main>
                )}


        </>
    )
}

export default DailyAffirmations