import { withGoogleMap, GoogleMap, Marker } from "react-google-maps"

const MyMapComponent = withGoogleMap((props) =>
  <GoogleMap
    defaultZoom={11}
    defaultCenter={props.data}
  >
    {props.isMarkerShown && <Marker position={props.data} />}
  </GoogleMap>
)

export default MyMapComponent;