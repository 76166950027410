// import { createSlice } from "@reduxjs/toolkit";

// const masterSlice = createSlice({
//     name: 'masterslice',
//     initialState: null,
//     reducers: {
//         setContentDetails(state, action) {
//             state = action.payload;
//             return state;
//         }
//     }
// });

// export const { setContentDetails } = ContentDetails.actions;
// export default ContentDetails.reducer;

import { createSlice } from "@reduxjs/toolkit"
let initialState = {
    isSearch: "",
}

const MasterSlice = createSlice({
    name: 'masterSlice',
    initialState: initialState,
    reducers: {
        startsearching(initialState, action) {
            initialState.isSearch = action.payload
        },

    },

});

export const { startsearching } = MasterSlice.actions;
export default MasterSlice.reducer;