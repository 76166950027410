import React, { useState, useEffect } from 'react'
import { ContentDefaultlist } from '../api/apiHandler.js';
import OuterHeader from './OuterHeader.js'
import Footer from './Footer.js'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import Header from './Header.js';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../store/slice/loaderSlice';
import Loader from '../components/Loader';




const InterestContent = () => {
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.loader);
    const [defaultdata, setDefaultData] = useState([]);
    const navigate = useNavigate();
    const [nocontent, setNocontent] = useState(204);
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 12;
    const UserData = useSelector((state) => JSON.parse(state.userdata))


    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < Math.ceil(totalCount / itemsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    useEffect(() => {
        defaultinterestdata({ page: currentPage })
    }, [currentPage])

    const defaultinterestdata = (data) => {
        dispatch(setLoading(true))
        ContentDefaultlist(data).then((response) => {
            dispatch(setLoading(false))
            if (response.status == 200) {
                setDefaultData(response.data.results)
                setTotalCount(response.data.total_count);
                setNocontent(200)
            } else if (response.status == 204) {
                Swal.fire({
                    position: "top-right",
                    icon: "info",
                    title: "No data found",
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                });
                setNocontent(204)
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    const handleCreateEventClick = () => {
        Swal.fire({
            position: "top-right",
            icon: "error",
            title: "Subscription required",
            toast: true,
            showConfirmButton: false,
            timer: 2000
        });
    }
    return (
        <>
            {isLoading ?
                (<Loader loader_status={isLoading} />) :
                (
                    <main className="helprr-main">
                        {localStorage.getItem("token") ?
                            <OuterHeader /> :
                            <Header />
                        }
                        <section className='more-content-sec'>
                            <div className="container">
                                {/* {mybook && mybook.length > 0 ?
                            <p className="font_46 font_600 mb-0 sec-title">{localStorage.getItem("user_category")}</p> : ""
                        } */}
                                <div className="testimonial-area">
                                    <div className='row'>
                                        {defaultdata && defaultdata.map((item, idx) => {
                                            return (
                                                <>
                                                    {UserData && UserData.data.is_subscription_active == true ?
                                                        <>
                                                            {item.content_type == "Book" ? (
                                                                <div key={idx} className="col-lg-4 col-xl-2 col-md-4 col-12 mb-3 wow fadeInUp" data-wow-delay=".2s"
                                                                    onClick={() => navigate(`/book-description/${item.id}`)}>
                                                                    <div className="single-testi">
                                                                        <Link to="/bookdescription" className="self-care-card d-block">
                                                                            <div className='position-relative'>
                                                                                <div className="selfcare-img">
                                                                                    <img src={item && item.thumbnail_url != null ? item.thumbnail_url : item.thumbnail} alt="" />
                                                                                </div>

                                                                            </div>

                                                                            <div className="title">
                                                                                <p className="font_20 mb-1">{item.title}</p>
                                                                                <p className="font_14 mb-0">{item.authors_details}</p>
                                                                            </div>
                                                                        </Link>
                                                                    </div>
                                                                </div>) : item.content_type == "Video" ? (
                                                                    <div key={idx} className="col-lg-4 col-xl-2 col-md-4 col-12 mb-3  wow fadeInUp" data-wow-delay=".2s"
                                                                        onClick={() => navigate(`/content-video/${item.id}`)}>
                                                                        <div className="single-testi">
                                                                            <Link to="#" className="self-care-card d-block">
                                                                                <div className='position-relative'>
                                                                                    <div className="selfcare-img">
                                                                                        {/* <img src={process.env.PUBLIC_URL + "/assets/images/care-img--1.svg"} alt="" /> */}
                                                                                        <img src={item.thumbnail} alt="" />
                                                                                    </div>

                                                                                </div>

                                                                                <div className="title">
                                                                                    <p className="font_20 mb-1">{item.title}</p>
                                                                                    <p className="font_14 mb-0">{item.authors_details}</p>
                                                                                </div>
                                                                            </Link>
                                                                        </div>
                                                                    </div>) : item.content_type == "Audio" ? (

                                                                        <div key={idx} className="col-lg-4 col-xl-2 col-md-4 col-12 mb-3  wow fadeInUp" data-wow-delay=".2s"
                                                                            onClick={() => navigate(`/content-audio/${item.id}`)}>
                                                                            <div className="single-testi">
                                                                                <Link to="#" className="self-care-card d-block">
                                                                                    <div className='position-relative'>
                                                                                        <div className="selfcare-img">
                                                                                            {/* <img src={process.env.PUBLIC_URL + "/assets/images/care-img--1.svg"} alt="" /> */}
                                                                                            <img src={item.thumbnail} alt="" />
                                                                                        </div>

                                                                                    </div>

                                                                                    <div className="title">
                                                                                        <p className="font_20 mb-1">{item.title}</p>
                                                                                        <p className="font_14 mb-0">{item.authors_details}</p>
                                                                                    </div>
                                                                                </Link>
                                                                            </div>
                                                                        </div>) :
                                                                <div key={idx} className="col-lg-4 col-xl-2 col-md-4 col-12 mb-3  wow fadeInUp" data-wow-delay=".2s"
                                                                    onClick={() => navigate(`/content-audio/${item.id}`)}>
                                                                    <div className="single-testi">
                                                                        <Link to="#" className="self-care-card d-block">
                                                                            <div className='position-relative'>
                                                                                <div className="selfcare-img">
                                                                                    {/* <img src={process.env.PUBLIC_URL + "/assets/images/care-img--1.svg"} alt="" /> */}
                                                                                    <img src={item.thumbnail} alt="" />
                                                                                </div>

                                                                            </div>

                                                                            <div className="title">
                                                                                <p className="font_20 mb-1">{item.title}</p>
                                                                                <p className="font_14 mb-0">{item.authors_details}</p>
                                                                            </div>
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </> : <>
                                                            {item.content_type == "Book" ? (
                                                                <div key={idx} className="col-lg-4 col-xl-2 col-md-4 col-12 mb-3  wow fadeInUp" data-wow-delay=".2s"
                                                                    onClick={() => navigate(`/book-description/${item.id}`)}>
                                                                    <div className="single-testi">
                                                                        <Link to="/bookdescription" className="self-care-card d-block">
                                                                            <div className='position-relative'>
                                                                                <div className="selfcare-img">
                                                                                    {/* <img src={process.env.PUBLIC_URL + "/assets/images/care-img--1.svg"} alt="" /> */}
                                                                                    <img src={item && item.thumbnail_url != null ? item.thumbnail_url : item.thumbnail} alt="" />

                                                                                </div>

                                                                            </div>

                                                                            <div className="title">
                                                                                <p className="font_20 mb-1">{item.title}</p>
                                                                                <p className="font_14 mb-0">{item.authors_details}</p>
                                                                            </div>
                                                                        </Link>
                                                                    </div>
                                                                </div>) : item.content_type == "Video" ? <> {item.is_paid == true ?
                                                                    <div key={idx} className="col-lg-4 col-xl-2 col-md-4 col-12 mb-3  wow fadeInUp" data-wow-delay=".2s"
                                                                        onClick={handleCreateEventClick}>
                                                                        <div className="single-testi">
                                                                            <Link to="#" className="self-care-card d-block">
                                                                                <div className='position-relative'>
                                                                                    <div className="selfcare-img">
                                                                                        {/* <img src={process.env.PUBLIC_URL + "/assets/images/care-img--1.svg"} alt="" /> */}
                                                                                        <img src={item.thumbnail} alt="" />
                                                                                    </div>
                                                                                    <div class="lock-icon">
                                                                                        <i class="fa-solid fa-lock text-mave font_24"></i>
                                                                                    </div>

                                                                                </div>

                                                                                <div className="title">
                                                                                    <p className="font_20 mb-1">{item.title}</p>
                                                                                    <p className="font_14 mb-0">{item.authors_details}</p>
                                                                                </div>
                                                                            </Link>
                                                                        </div>
                                                                    </div> : <div key={idx} className="col-lg-4 col-xl-2 col-md-4 col-12 mb-3  wow fadeInUp" data-wow-delay=".2s"
                                                                        onClick={() => navigate(`/content-video/${item.id}`)}>
                                                                        <div className="single-testi">
                                                                            <Link to="#" className="self-care-card d-block">
                                                                                <div className='position-relative'>
                                                                                    <div className="selfcare-img">
                                                                                        {/* <img src={process.env.PUBLIC_URL + "/assets/images/care-img--1.svg"} alt="" /> */}
                                                                                        <img src={item.thumbnail} alt="" />
                                                                                    </div>

                                                                                </div>

                                                                                <div className="title">
                                                                                    <p className="font_20 mb-1">{item.title}</p>
                                                                                    <p className="font_14 mb-0">{item.authors_details}</p>
                                                                                </div>
                                                                            </Link>
                                                                        </div>
                                                                    </div>}
                                                                </> : item.content_type == "Audio" ? <>{item.is_paid == true ?

                                                                    <div key={idx} className="col-lg-4 col-xl-2 col-md-4 col-12 mb-3  wow fadeInUp" data-wow-delay=".2s"
                                                                        onClick={handleCreateEventClick}>
                                                                        <div className="single-testi">
                                                                            <Link to="#" className="self-care-card d-block">
                                                                                <div className='position-relative'>
                                                                                    <div className="selfcare-img">
                                                                                        {/* <img src={process.env.PUBLIC_URL + "/assets/images/care-img--1.svg"} alt="" /> */}
                                                                                        <img src={item.thumbnail} alt="" />
                                                                                    </div>
                                                                                    <div class="lock-icon">
                                                                                        <i class="fa-solid fa-lock text-mave font_24"></i>
                                                                                    </div>

                                                                                </div>

                                                                                <div className="title">
                                                                                    <p className="font_20 mb-1">{item.title}</p>
                                                                                    <p className="font_14 mb-0">{item.authors_details}</p>
                                                                                </div>
                                                                            </Link>
                                                                        </div>
                                                                    </div> :
                                                                    <div key={idx} className="col-lg-4 col-xl-2 col-md-4 col-12 mb-3  wow fadeInUp" data-wow-delay=".2s"
                                                                        onClick={() => navigate(`/content-audio/${item.id}`)}>
                                                                        <div className="single-testi">
                                                                            <Link to="#" className="self-care-card d-block">
                                                                                <div className='position-relative'>
                                                                                    <div className="selfcare-img">
                                                                                        {/* <img src={process.env.PUBLIC_URL + "/assets/images/care-img--1.svg"} alt="" /> */}
                                                                                        <img src={item.thumbnail} alt="" />
                                                                                    </div>

                                                                                </div>

                                                                                <div className="title">
                                                                                    <p className="font_20 mb-1">{item.title}</p>
                                                                                    <p className="font_14 mb-0">{item.authors_details}</p>
                                                                                </div>
                                                                            </Link>
                                                                        </div>
                                                                    </div>}

                                                                </> : <>{item.is_paid == true ?
                                                                    <div key={idx} className="col-lg-4 col-xl-2 col-md-4 col-12 mb-3  wow fadeInUp" data-wow-delay=".2s"
                                                                        onClick={handleCreateEventClick}>
                                                                        <div className="single-testi">
                                                                            <Link to="#" className="self-care-card d-block">
                                                                                <div className='position-relative'>
                                                                                    <div className="selfcare-img">
                                                                                        {/* <img src={process.env.PUBLIC_URL + "/assets/images/care-img--1.svg"} alt="" /> */}
                                                                                        <img src={item.thumbnail} alt="" />
                                                                                    </div>
                                                                                    <div class="lock-icon">
                                                                                        <i class="fa-solid fa-lock text-mave font_24"></i>
                                                                                    </div>

                                                                                </div>

                                                                                <div className="title">
                                                                                    <p className="font_20 mb-1">{item.title}</p>
                                                                                    <p className="font_14 mb-0">{item.authors_details}</p>
                                                                                </div>
                                                                            </Link>
                                                                        </div>
                                                                    </div> :
                                                                    <div key={idx} className="col-lg-4 col-xl-2 col-md-4 col-12 mb-3  wow fadeInUp" data-wow-delay=".2s"
                                                                        onClick={() => navigate(`/content-article/${item.id}`)}>
                                                                        <div className="single-testi">
                                                                            <Link to="#" className="self-care-card d-block">
                                                                                <div className='position-relative'>
                                                                                    <div className="selfcare-img">
                                                                                        {/* <img src={process.env.PUBLIC_URL + "/assets/images/care-img--1.svg"} alt="" /> */}
                                                                                        <img src={item.thumbnail} alt="" />
                                                                                    </div>

                                                                                </div>

                                                                                <div className="title">
                                                                                    <p className="font_20 mb-1">{item.title}</p>
                                                                                    <p className="font_14 mb-0">{item.authors_details}</p>
                                                                                </div>
                                                                            </Link>
                                                                        </div>
                                                                    </div>}
                                                            </>
                                                            }
                                                        </>}
                                                </>
                                            )
                                        })}


                                    </div>

                                </div>
                            </div>
                        </section>
                        {nocontent && nocontent == 204 ? <></> :
                            <div className='container'>
                                <div className="row justify-content-center mt-xl-5" >
                                    <div className="col-lg-2 col-lg-3 col-xl-2 col-md-4 col-6">
                                        <button type="button" className={currentPage === 1 ? "load-btn-disable w-100" : "load-btn w-100"} onClick={handlePrevPage} disabled={currentPage === 1}>
                                            Previous
                                        </button>

                                    </div>
                                    <div className="col-lg-2 col-xl-2 col-md-4 col-6">
                                        <button type="button" className={currentPage === Math.ceil(totalCount / itemsPerPage) ? "load-btn-disable w-100" : "load-btn w-100"} onClick={handleNextPage} disabled={currentPage === Math.ceil(totalCount / itemsPerPage)}>
                                            Next
                                        </button>
                                    </div>
                                </div>
                            </div>}


                        <div style={{ paddingTop: "1%" }}>
                            <Footer />
                        </div>
                    </main>)}
        </>
    )
}

export default InterestContent