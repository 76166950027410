import React, { useState, useEffect } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { Link, useNavigate } from 'react-router-dom'
import { interests, addInterests, userDetails, UpdateUserDetails } from '../api/apiHandler.js'
import Loader from '../components/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../store/slice/loaderSlice';
import Swal from 'sweetalert2'
import OuterHeader from '../components/OuterHeader.js'



const Interests = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.loader);
    const [allInterests, setAllInterests] = useState([]);
    const [defaultInterests, setDefaultInterests] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');


    useEffect(() => {
        // dispatch(setLoading(true))
        interests().then((response) => {

            // dispatch(setLoading(false))
            if (response.status === 200) {

                setAllInterests(response.data.interests)
                setDefaultInterests(response.data.interests);
                getUserDetails(response.data.interests)

            }
        }).catch((error) => {

        })

    }, [])

    useEffect(() => {

        allInterests.forEach(element => {

            if (element.is_selected) {
                let userInterestData = document.getElementById(`${element.id}`)
                userInterestData.setAttribute("checked", true)
            }
        });
    }, [allInterests])


    const getUserDetails = (interestData) => {

        // dispatch(setLoading(true))
        userDetails().then((response) => {
            // dispatch(setLoading(false))

            if (response.status === 200) {


                const idArray = response.data.interests.map(obj => obj.id);

                interestData.forEach(element => {
                    element['is_selected'] = false;
                    if (idArray.includes(element.id)) {
                        element['is_selected'] = true;
                        let userInterestData = document.getElementById(`${element.id}`)
                        userInterestData.setAttribute("checked", true)
                        //document.getElementById(element.id).setAttribute("checked", true)
                    }
                });
                setDefaultInterests(interestData)
                setAllInterests(interestData)

            }

        }).catch((error) => {

        })
    }

    const handleIntrest = (interestId, event) => {

        allInterests.forEach(element => {
            if (element.id === interestId) {
                if (element.is_selected) {
                    element['is_selected'] = false;
                }
                else {
                    element['is_selected'] = true;
                }
            }
        })

    }

    const saveIntrest = () => {
        let userSelectedInst = defaultInterests.filter(i => (i.is_selected == true) ? i.id : '');
        userSelectedInst = userSelectedInst.map(item => item.id)

        // if (userSelectedInst.length === 0) {
        //     // Display a pop-up message indicating that the user needs to select interests
        //     Swal.fire({
        //         position: "top-right",
        //         icon: "info",
        //         title: "Please select at least one interest",
        //         toast: true,
        //         showConfirmButton: false,
        //         timer: 2000
        //     });
        //     return; // Exit the function since no interests are selected
        // }

        const intrestobj = {
            interests: userSelectedInst
        }

        // dispatch(setLoading(true))
        localStorage.setItem("total_interest", userSelectedInst.length);
        addInterests(intrestobj).then((response) => {

            // dispatch(setLoading(false))
            if (response.status === 200) {
                if (localStorage.getItem('isupdate') == "true") {
                    Swal.fire({
                        position: "top-right",
                        icon: "success",
                        title: "Interest updated successfully",
                        toast: true,
                        showConfirmButton: false,
                        timer: 2000
                    });
                    localStorage.removeItem("isupdate")
                    localStorage.removeItem("isadd")

                    navigate("/account")

                }

                if (localStorage.getItem("isadd") == "true") {
                    Swal.fire({
                        position: "top-right",
                        icon: "success",
                        title: "Interest added successfully",
                        toast: true,
                        showConfirmButton: false,
                        timer: 2000
                    });
                    navigate("/home")
                }
            } else {

            }
        }).catch((error) => {
            Swal.fire({
                position: "top-right",
                icon: "error",
                title: error.response.data.errors,
                toast: true,
                showConfirmButton: false,
                timer: 2000
            });

        })

    }

    function hasWhiteSpace(s) {
        return /\s/g.test(s);
    }
    const handleSearchChange = (e) => {
        let filteredItems = [];
        setAllInterests(defaultInterests)
        setSearchQuery(e.target.value);

        allInterests.forEach(element => {

            let userInterestData = document.getElementById(`${element.id}`)
            userInterestData.removeAttribute("checked", false)

        });

        if (e.target.value != "" && !hasWhiteSpace(e.target.value)) {

            filteredItems = defaultInterests && defaultInterests.filter(item =>

                item.interest.toLowerCase().includes(e.target.value.toLowerCase())

            );

            setAllInterests(filteredItems)
        }

    };

    const handleSkip = () => {
        localStorage.removeItem("isadd")
        UpdateUserDetails({ "interests_skipped": true }).then((response) => {
            if (response.status == 201) {
                console.log("interested_skipped");
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    useEffect(() => {
        if (localStorage.getItem("isSignup")) {
            const disableBackButton = (e) => {
                e.preventDefault();
                window.history.forward(); // Forward the user when they try to go back
            };

            window.history.pushState(null, null, window.location.pathname);
            window.addEventListener('popstate', disableBackButton); // Listen for back button press

            return () => {
                window.removeEventListener('popstate', disableBackButton); // Cleanup the event listener when the component unmounts
            };
        }
    }, []);



    return (
        <>
            {isLoading ?
                (<Loader loader_status={isLoading} />) :
                (
                    <main className="helprr-main">
                        {/* {localStorage.getItem("islogin") == null ?
                            <Header /> :
                            <OuterHeader />
                        } */}
                        <OuterHeader />
                        <section className="login-sec interest-sec mb-5 pb-5">
                            <div className="container">

                                <p className="font_32 font_700 text-mave">Interests</p>
                                <div>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="input-group mb-4">
                                                <span
                                                    className="input-group-text bg-transparent border-0"
                                                    id="basic-addon1"
                                                >
                                                    <i className="fa-solid fa-magnifying-glass text-white" />
                                                </span>
                                                <input
                                                    type="text"
                                                    className="form-control bg-transparent border-0 ps-0 text-white"
                                                    placeholder="Search"
                                                    aria-label="Username"
                                                    aria-describedby="basic-addon1"
                                                    value={searchQuery}
                                                    onChange={handleSearchChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div>

                                        <form>
                                            <div className='intrest_group'>
                                                {allInterests && allInterests.map((element, index) => (
                                                    <div className='form-group' key={index}>
                                                        <input type="checkbox" id={element.id} onClick={(e) => handleIntrest(element.id, e)} />
                                                        <label htmlFor={element.id}>{element.interest}</label>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="collapse" id="collapseExample">
                                                <div className="card card-body mb-4">
                                                    <p className="font_18 font_600 text-mave mb-0">
                                                        Add a new interest
                                                    </p>
                                                    <hr />
                                                    <div className="row">
                                                        <div className="col-lg-5">
                                                            <p className="text-mave mb-1">Tag</p>
                                                            <input
                                                                type="text"
                                                                className="custom-interest form-control"
                                                                id="exampleFormControlInput1"
                                                                placeholder="Add interest here"
                                                            />
                                                            <button className="add-int">Add</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                        <div className="row justify-content-center">
                                            <div className="col-lg-3 col-md-6">
                                                <a type='button' onClick={saveIntrest} className="add-interest w-100 text-center mt-4 font_18 d-block">

                                                    Save
                                                </a>
                                                {/* <button class="add-interest w-100 text-center mt-4 font_18">Save
                          </button> */}
                                            </div>
                                            <div className="col-lg-3 col-md-6">
                                                {localStorage.getItem("isadd") == "true" ?
                                                    <Link
                                                        to="/home"
                                                        // onClick={() => localStorage.removeItem("isadd")}
                                                        onClick={handleSkip}
                                                        className="add-interest w-100 text-center mt-4 font_18 d-block"
                                                    >
                                                        Skip
                                                    </Link> :
                                                    <Link
                                                        to="/account"
                                                        className="add-interest w-100 text-center mt-4 font_18 d-block"
                                                    >
                                                        Skip
                                                    </Link>

                                                }

                                                {/* <button class="add-interest w-100 text-center mt-4 font_18">Skip
                          </button> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </main>
                )
            }



        </>
    )
}

export default Interests