import React, { useState } from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import checkValidation from '../validation/ValidationSchema';
import { resetPassword } from '../api/apiHandler.js'
import Loader from '../components/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../store/slice/loaderSlice';
import Swal from 'sweetalert2'





const ResetPassword = () => {
    const [isCheck, setIsCheck] = useState("password");
    const [isCheckCp, setIsCheckCp] = useState("password");
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.loader);

    const schema = checkValidation(['Password', 'Confirm_Password']);

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });
    const navigate = useNavigate();


    const onSubmit = (data) => {
        console.log(data);

        const resetpasswordobj = {
            email: localStorage.getItem("email"),
            password: data.Password
        }
        dispatch(setLoading(true))
        resetPassword(resetpasswordobj).then((response) => {
            dispatch(setLoading(false))
            if (response.status === 200) {
                Swal.fire({
                    position: "top-right",
                    icon: "success",
                    title: "Password reset successfully",
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                });
                navigate('/login');
            }

        }).catch((error) => {
            Swal.fire({
                position: "top-right",
                icon: "error",
                title: error.response.data.errors,
                toast: true,
                showConfirmButton: false,
                timer: 2000
            });
            console.log(error);
        })


    };

    return (
        <>
            {isLoading ?
                (<Loader loader_status={isLoading} />) :
                (
                    <main className="helprr-main">
                        <Header />
                        <section className="login-sec">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-lg-6 col-xl-6 col-12">
                                        <p className="title font_50">Reset Password</p>
                                        <form onSubmit={handleSubmit(onSubmit)}>

                                            <div className='mb-4'>
                                                <div className="group-custom input-group ">
                                                    <input
                                                        type={isCheck}
                                                        className="form-control border-0 font_18"
                                                        name='Password'
                                                        placeholder="New Password"
                                                        aria-label="Recipient's username"
                                                        aria-describedby="basic-addon2"
                                                        {...register("Password")}


                                                    />
                                                    {isCheck == "password" ?
                                                        <span className="input-group-text border-0 bg-transparent eye-icon-h" id="basic-addon2" onClick={() => setIsCheck("text")}>
                                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/eye-icon.png"} />
                                                        </span>
                                                        :
                                                        <span className="input-group-text border-0 bg-transparent eye-icon-h" id="basic-addon2" onClick={() => setIsCheck("password")}>
                                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/view-icon.png"} />
                                                        </span>
                                                    }

                                                </div>
                                                {errors.Password && <p role="alert" className='error-text '>{errors.Password.message}</p>}
                                            </div>


                                            <div className="mb-4">
                                                <div className="group-custom input-group ">
                                                    <input
                                                        type={isCheckCp}
                                                        className="form-control border-0 font_18"
                                                        id="exampleFormControlInput1"
                                                        name='Confirm_Password'
                                                        placeholder="Confirm Password"
                                                        {...register("Confirm_Password")}

                                                    />

                                                    {isCheckCp == "password" ?
                                                        <span className="input-group-text border-0 bg-transparent eye-icon-h" id="basic-addon2" onClick={() => setIsCheckCp("text")}>
                                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/eye-icon.png"} />
                                                        </span>
                                                        :
                                                        <span className="input-group-text border-0 bg-transparent eye-icon-h" id="basic-addon2" onClick={() => setIsCheckCp("password")}>
                                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/view-icon.png"} />
                                                        </span>
                                                    }
                                                </div>
                                                {errors.Confirm_Password && <p role="alert" className='error-text'>{errors.Confirm_Password.message}</p>}

                                            </div>


                                            {/* <a href="javascript:void(0)" className="login-btn font_18">
                                    Reset Password
                                </a> */}
                                            <button
                                                type="submit"
                                                className="login-btn font_18">
                                                Reset Password
                                            </button>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </main>
                )
            }

        </>
    )
}

export default ResetPassword