import React from 'react'
import { Link, useLocation } from 'react-router-dom'

const Header = () => {

    const location = useLocation();
    const currentPath = location.pathname.split("/");
    return (
        <>
            <header>
                <nav className="navbar navbar-expand-lg navbar-light navbar-header">
                    <div className="container d-flex">
                        <Link className="navbar-brand" to="/">
                            <img src={process.env.PUBLIC_URL + "/assets/images/logo.png"} />
                        </Link>

                        {currentPath[1] === "login" ? (
                            <form className="d-flex ms-auto">
                                <Link className="primary-btn font_18" to="/signup">
                                    Sign Up
                                </Link>
                            </form>
                        ) : currentPath[1] === "signup" ? (
                            <form className="d-flex ms-auto">
                                <Link className="primary-btn font_18" to="/login">
                                    Login
                                </Link>
                            </form>
                        ) :
                            <form className="d-flex ms-auto">
                                <Link className="primary-btn font_18" to="/login">
                                    Login
                                </Link>
                            </form>}

                    </div>
                </nav>
            </header>
        </>
    )
}

export default Header