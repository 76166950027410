import React, { useState, useEffect } from 'react'
import Footer from '../components/Footer.js'
import { json, useNavigate } from 'react-router-dom'
import Header from '../components/Header.js'
import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import checkValidation from '../validation/ValidationSchema.js';
import { registrationCheck, registration, applesignupuser } from '../api/apiHandler.js'
import { auth, google, facebook, apple } from '../social_login/fire.js'
import { signInWithPopup } from 'firebase/auth'
import Loader from '../components/Loader.js';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../store/slice/loaderSlice.js';
import Swal from 'sweetalert2'


const Signup = () => {
    const isLoading = useSelector((state) => state.loader);
    const [location, setLocation] = useState(null);


    const dispatch = useDispatch();
    const [isCheck, setIsCheck] = useState("password");
    const [isCheckCp, setIsCheckCp] = useState("password");

    const schema = checkValidation(['Username', 'Email', 'Password', 'Confirm_Password', 'age']);

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });
    const navigate = useNavigate();



    // useEffect(() => {
    //     if (navigator.geolocation) {
    //         navigator.geolocation.getCurrentPosition(
    //             (position) => {
    //                 const { latitude, longitude } = position.coords;

    //                 const roundedLatitude = latitude.toFixed(6);
    //                 const roundedLongitude = longitude.toFixed(6);

    //                 setLocation({ latitude: roundedLatitude, longitude: roundedLongitude });
    //                 localStorage.setItem('latitude', roundedLatitude);
    //                 localStorage.setItem('longitude', roundedLongitude);
    //             },
    //             (error) => {
    //                 console.error('Error getting location:', error.message);
    //             }
    //         );
    //     } else {
    //         console.error('Geolocation is not supported by this browser.');
    //     }
    // }, []);


    const onSubmit = (data) => {
        // navigate('/verify-otp');
        const signupobj = {
            email: data.Email,
            username: data.Username,
            password: data.Password,
            is_18_above: data.age,
            login_type: "simple"
        }

        registrationCheck(signupobj).then((response) => {
            // dispatch(setLoading(false))
            if (response.status === 200) {
                localStorage.setItem('userdata', JSON.stringify(response.data))
                localStorage.setItem('verification_for', 'registration')
                localStorage.setItem("resend_otp_for", "registration")
                localStorage.setItem("isragister", "filldata")
                localStorage.setItem("active_actionplan", response.data["has_action_plan"]);
                localStorage.setItem("is_mentee", response.data['is_mentee'] == null ? "false" : response.data['is_mentee']);
                navigate('/verify-otp');
            }
        }).catch((error) => {
            Swal.fire({
                position: "top-right",
                icon: "error",
                title: error.response.data.errors,
                toast: true,
                showConfirmButton: false,
                timer: 2000
            });

        })

    };


    const socialsignup = async (provider, login_type) => {
        try {
            const result = await signInWithPopup(auth, provider)
            const { providerData, email } = result.user;
            console.log(result.user.providerData[0], "result");
            let user_name = result.user.providerData[0].displayName
            let user_email = (email != null) ? email : result.user.providerData[0].email
            const socialobj = {
                username: user_name != null ? user_name.replace(/\s/g, '') : user_name,
                login_type: login_type,
                social_key: result.user.providerData[0].uid,
                email: user_email,
                is_18_above: 1
            }
            // dispatch(setLoading(true))
            if (user_name != null) {
                registration(socialobj).then((response) => {
                    // dispatch(setLoading(false))
                    if (response.status === 201) {
                        localStorage.setItem("uuid", response.data.uuid)
                        localStorage.setItem("token", response.data['X-Token'])
                        localStorage.setItem("isadd", true)
                        localStorage.setItem("current_streak", response.data['current_streak'])
                        localStorage.setItem("longest_streak", response.data['longest_streak'])
                        localStorage.setItem("username", response.data['username'])
                        localStorage.setItem("email", response.data['email'])
                        localStorage.setItem("total_interest", response.data['interests'].length);
                        localStorage.setItem("user_id", response.data['id'])
                        localStorage.setItem("islogin", "true")
                        localStorage.setItem("active_actionplan", response.data["has_action_plan"]);
                        localStorage.setItem("isSignup", "true")
                        localStorage.setItem("is_mentee", response.data['is_mentee'] == null ? "false" : response.data['is_mentee']);
                        navigate('/interests')
                    }
                }).catch((error) => {
                    console.log(error, "error");
                    Swal.fire({
                        position: "top-right",
                        icon: "error",
                        title: error.response.data.errors,
                        toast: true,
                        showConfirmButton: false,
                        timer: 2000
                    });
                })
            }
            else {
                extraSignup(result.user.providerData[0].uid)
            }
        } catch (error) {
            console.log('error: ', error);
            // Handle authentication error
            if (error.code === 'auth/cancelled-popup-request') {
                console.log('Popup request cancelled by the user.');
            } else {
                console.error('Authentication error:', error.message);
            }
        }
    }

    const extraSignup = (key) => {
        applesignupuser({ "social_key": key }).then((response) => {
            // dispatch(setLoading(false))
            if (response.status === 201) {
                localStorage.setItem("uuid", response.data.uuid)
                localStorage.setItem("token", response.data['X-Token'])
                localStorage.setItem("isadd", true)
                localStorage.setItem("current_streak", response.data['current_streak'])
                localStorage.setItem("longest_streak", response.data['longest_streak'])
                localStorage.setItem("username", response.data['username'])
                localStorage.setItem("total_interest", response.data['interests'].length);
                localStorage.setItem("user_id", response.data['id'])
                localStorage.setItem("islogin", "true")
                localStorage.setItem("active_actionplan", response.data["has_action_plan"]);
                localStorage.setItem("isSignup", "true")
                localStorage.setItem("is_mentee", response.data['is_mentee'] == null ? "false" : response.data['is_mentee']);
                navigate('/interests')
            }
            else if (response.status === 204) {
                Swal.fire({
                    position: "top-right",
                    icon: "error",
                    title: "A user with that email already exists",
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                });
            }
        }).catch((error) => {
            Swal.fire({
                position: "top-right",
                icon: "error",
                title: error.response.data.errors,
                toast: true,
                showConfirmButton: false,
                timer: 2000
            });
        });
    }

    return (
        <>

            {isLoading ?
                (<Loader loader_status={isLoading} />) :
                (
                    <main className="helprr-main">
                        <Header />
                        <section className="login-sec pb-0">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-lg-6 col-xl-6 col-12">
                                        <p className="title font_40 pb-0 text-center mb-3">Sign Up with</p>
                                        <div className="d-flex justify-content-center align-items-center gap-3">
                                            <a type="button" className="social-icons" style={{ background: "none" }} >
                                                <img src={process.env.PUBLIC_URL + "/assets/images/icon/facebook.png"} onClick={() => socialsignup(facebook, "facebook")} />
                                            </a>
                                            <a type="button" className="social-icons" style={{ background: "none" }} >
                                                <img src={process.env.PUBLIC_URL + "/assets/images/icon/google.png"} onClick={() => socialsignup(google, "google")} />
                                            </a>
                                            <a type="button" className="social-icons" style={{ background: "none" }} >
                                                <img src={process.env.PUBLIC_URL + "/assets/images/icon/apple.png"} onClick={() => socialsignup(apple, "apple")} />
                                            </a>
                                        </div>
                                        <p className="font_30 font_600 text-center">or</p>
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <div className="mb-4">
                                                <input
                                                    type="text"
                                                    className="form-custom form-control font_16"
                                                    id="exampleFormControlInput1"
                                                    name='Username'
                                                    placeholder="Username"
                                                    {...register("Username")}

                                                />
                                                {errors.Username && <p role="alert" className='error-text' >{errors.Username.message}</p>}


                                            </div>
                                            <div className="mb-4">
                                                <input
                                                    type="email"
                                                    className="form-custom form-control font_16"
                                                    id="exampleFormControlInput1"
                                                    placeholder="Email"
                                                    name='Email'
                                                    {...register("Email")}

                                                />
                                                {errors.Email && <p role="alert" className='error-text'>{errors.Email.message}</p>}


                                            </div>
                                            <div className='mb-4'>
                                                <div className="group-custom input-group ">
                                                    <input
                                                        type={isCheck}
                                                        className="form-control border-0 font_16"
                                                        name='Password'
                                                        placeholder="Password"
                                                        aria-label="Recipient's username"
                                                        aria-describedby="basic-addon2"
                                                        {...register("Password")}


                                                    />
                                                    {isCheck == "password" ?
                                                        <span className="input-group-text border-0 bg-transparent eye-icon-h" id="basic-addon2" onClick={() => setIsCheck("text")}>
                                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/eye-icon.png"} />
                                                        </span>
                                                        :
                                                        <span className="input-group-text border-0 bg-transparent eye-icon-h" id="basic-addon2" onClick={() => setIsCheck("password")}>
                                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/view-icon.png"} />
                                                        </span>
                                                    }

                                                </div>
                                                {errors.Password && <p role="alert" className='error-text '>{errors.Password.message}</p>}
                                            </div>

                                            <div className="mb-4">
                                                <div className="group-custom input-group ">
                                                    <input
                                                        type={isCheckCp}
                                                        className="form-control border-0 font_16"
                                                        id="exampleFormControlInput1"
                                                        name='Confirm_Password'
                                                        placeholder="Confirm Password"
                                                        {...register("Confirm_Password")}

                                                    />

                                                    {isCheckCp == "password" ?
                                                        <span className="input-group-text border-0 bg-transparent eye-icon-h" id="basic-addon2" onClick={() => setIsCheckCp("text")}>
                                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/eye-icon.png"} />
                                                        </span>
                                                        :
                                                        <span className="input-group-text border-0 bg-transparent eye-icon-h" id="basic-addon2" onClick={() => setIsCheckCp("password")}>
                                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/view-icon.png"} />
                                                        </span>
                                                    }
                                                </div>
                                                {errors.Confirm_Password && <p role="alert" className='error-text'>{errors.Confirm_Password.message}</p>}

                                            </div>
                                            <div className="form-check d-flex align-items-center gap-2">
                                                <input
                                                    className="form-custom form-check-input"
                                                    type="checkbox"
                                                    defaultValue=""
                                                    id="flexCheckDefault"
                                                    name='age'
                                                    {...register("age")}


                                                />

                                                <label
                                                    className="form-check-label font_16"
                                                    htmlFor="flexCheckDefault"
                                                >
                                                    I am over the age of 18.{" "}
                                                    <span className="text-decoration-underline">
                                                        What does this change?
                                                    </span>
                                                </label>
                                            </div>
                                            {errors.age && <p role="alert" className='error-text'>{errors.age.message}</p>}

                                            {/* <Link to="/interests" className="login-btn font_16">
                                                Sign Up
                                            </Link> */}

                                            <button
                                                type="submit"
                                                className="login-btn font_16">
                                                Sign Up
                                            </button>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </main>
                )

            }


        </>
    )
}

export default Signup