import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { questionMentee, submitquestion, SetCometChatId } from '../api/apiHandler.js'
import Swal from 'sweetalert2'
import { CometChatUIKit } from "@cometchat/chat-uikit-react";
import { CometChat } from "@cometchat/chat-sdk-javascript";
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../store/slice/loaderSlice';
import Loader from '../components/Loader';

const QForMentor = () => {
    const [isState, setIsState] = useState({});
    const navigate = useNavigate();
    const [questiondata, setquestiondata] = useState()
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [survayId, setSurveyId] = useState();
    const [QuestionAns, setQuestionAns] = useState([]);
    const [Questionid, setQuestionid] = useState();
    const [optionid, setoptionid] = useState();
    const [selectedOptions, setSelectedOptions] = useState({});
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.loader);


    useEffect(() => {
        dispatch(setLoading(true))
        questionMentee().then((response) => {
            if (response.status == 200) {
                dispatch(setLoading(false))
                setquestiondata(response.data[0].questions)
                setSurveyId(response.data[0].id)
            }

        }).catch((error) => {
            Swal.fire({
                position: "top-right",
                icon: "error",
                title: error.response.data.errors,
                toast: true,
                showConfirmButton: false,
                timer: 2000
            });
        })

    }, [])

    // const handleContinue = () => {
    //     // console.log(QuestionAns.length, "QuestionAnslength")
    //     // console.log(currentQuestionIndex, "currentQuestionIndex");

    //     if (currentQuestionIndex < questiondata.length) {
    //         if (isState[Questionid] != 1) {
    //             setIsState((prevOptions) => ({
    //                 ...prevOptions,
    //                 [Questionid]: 0,
    //             }));

    //         }
    //         if (currentQuestionIndex < 3) {
    //             setCurrentQuestionIndex(currentQuestionIndex + 1);
    //         } else {
    //             setCurrentQuestionIndex(currentQuestionIndex)
    //         }

    //         handlesubmitquestion(Questionid, optionid)
    //     }
    // };


    const handleContinue = () => {
        if (currentQuestionIndex < questiondata.length) {
            const currentQuestionId = questiondata[currentQuestionIndex].id;
            if (!selectedOptions[currentQuestionId]) {
                // If no option is selected for the current question, return without proceeding
                return;
            }

            // Proceed to the next question
            setCurrentQuestionIndex(currentQuestionIndex + 1);
            handlesubmitquestion(Questionid, optionid);
        }
    };

    const handlesubmitquestion = (question_id, option_id) => {
        let survay = {
            question_id: question_id,
            option_id: option_id
        }

        if (QuestionAns.length === 0) {
            QuestionAns.push(survay);
        } else {
            const index = QuestionAns.findIndex((item) => item.question_id === question_id && item.option_id === option_id);
            if (index !== -1) {
                QuestionAns.splice(index, 1);
            } else {
                const questionIndex = QuestionAns.findIndex((item) => item.question_id === question_id);
                if (questionIndex !== -1) {
                    QuestionAns.splice(questionIndex, 1);
                }
            }
            QuestionAns.push(survay);
        }

        // console.log(question_id, "qustionidddd");

        if (QuestionAns.length == questiondata.length) {
            const survaydata = {
                survey_id: survayId,
                question_answers: QuestionAns
            }
            dispatch(setLoading(true))
            submitquestion(survaydata).then((response) => {
                // console.log(response.data, "response");
                if (response.status === 201) {
                    dispatch(setLoading(false))
                    Swal.fire({
                        position: "top-right",
                        icon: "success",
                        title: "You are now a mentor, Thank you for your commitment to helping others",
                        toast: true,
                        showConfirmButton: false,
                        timer: 2000
                    });
                    navigate("/feedback")
                    // localStorage.removeItem("questions_for")
                } else if (response.status === 200) {
                    localStorage.setItem("is_mentee", "true");
                    cometChatUserFunction(response.data.assigned_mentor)
                }
            }).catch((error) => {
                console.log(error.response); // Log the error response
                dispatch(setLoading(false))
                if (error.response && error.response.data && error.response.data.errors === "We will notify you once we find the right mentor for you") {

                    Swal.fire({
                        position: "top-right",
                        icon: "info",
                        title: error.response.data.errors,
                        toast: true,
                        showConfirmButton: false,
                        timer: 2000
                    });
                    navigate("/peer-community")

                } else {
                    Swal.fire({
                        position: "top-right",
                        icon: "error",
                        title: error.response.data.errors,
                        toast: true,
                        showConfirmButton: false,
                        timer: 2000
                    });
                    navigate("/peer-community")
                }
            })
        }

    }

    const handleprev = () => {
        if (currentQuestionIndex >= 1) {
            setCurrentQuestionIndex(currentQuestionIndex - 1);
            setoptionid(optionid)
            setIsState((prevOptions) => ({
                ...prevOptions,
                [Questionid]: 1,
            }));
        } else {
            navigate("/peer-community")
        }
    }

    const handlequestion = (question_id, option_id) => {

        setQuestionid(question_id)
        setoptionid(option_id)
        setSelectedOptions((prevOptions) => ({
            ...prevOptions,
            [question_id]: option_id,
        }));

        setIsState((prevOptions) => ({
            ...prevOptions,
            [question_id]: 1,
        }));
    }

    const cometChatUserFunction = (mentor_data) => {
        let UID = mentor_data.cometchat_uid != null ? mentor_data.cometchat_uid : mentor_data.username
        console.log("uid", UID);
        if (mentor_data.cometchat_uid == null) {
            console.log("enter null");
            // user is not found, so we can create new user
            const newUser = new CometChat.User(UID);
            let fullname = (mentor_data.fullname != "" && mentor_data.fullname != undefined && mentor_data.fullname != null) ? mentor_data.fullname : mentor_data.username

            newUser.setName(fullname);
            let metadata = {
                "@private":
                {
                    "email": mentor_data.email
                }
            }

            newUser.setMetadata(JSON.stringify(metadata));
            CometChatUIKit.createUser(newUser, process.env.REACT_APP_COMETCHAT_AUTH_KEY)
                .then((createdUser) => {
                    setCometChatIdFunction(createdUser, mentor_data.id)
                })
                .catch((error) => {
                    dispatch(setLoading(false))
                    console.log('Error from create cometchat user', error);
                    Swal.fire({
                        position: "top-right",
                        icon: "error",
                        title: error.message,
                        toast: true,
                        showConfirmButton: false,
                        timer: 2000
                    });
                })
        } else {
            navigate('/comet-chat')
        }
    }

    const setCometChatIdFunction = (cometChatData, mentor_id) => {
        const cometChatObj = {
            "user": mentor_id,
            "cometchat_uid": cometChatData.uid
        }
        SetCometChatId(cometChatObj).then((response) => {
            console.log("response", response);
            if (response.status == 200) {
                navigate("/comet-chat")
            }
        }).catch((error) => {
            dispatch(setLoading(false))
            console.log('Error from set cometchat id', error);
            Swal.fire({
                position: "top-right",
                icon: "error",
                title: error.response ? error.response.data.errors : "Something went wrong",
                toast: true,
                showConfirmButton: false,
                timer: 2000
            });
        })
    }


    return (
        <>
            {isLoading ?
                (<Loader loader_status={isLoading} />) :
                (
                    <main>
                        <section className="section-survey">
                            <div className="container">
                                <div>
                                    <img className='back-arrow-icon cursor-pointer' onClick={handleprev} src={process.env.PUBLIC_URL + "/assets/images/icon/left-arrow.svg"} alt="" />
                                </div>

                                <div className="row justify-content-center">
                                    {questiondata && questiondata.map((question, index) => (
                                        <div key={question.id} className={`col-lg-7 col-xl-7 col-12 ${index === currentQuestionIndex ? '' : 'd-none'}`}>
                                            <p className="text-black font_30 font_600 mb-5 mt-4">{question.question}</p>
                                            {question.options && question.options.map((option) => (
                                                <Link to="#" className={
                                                    selectedOptions[question.id] === option.id
                                                        ? "select-survey-ans selected-option"
                                                        : "select-survey-ans"
                                                } key={option.id} onClick={() => handlequestion(question.id, option.id)} >
                                                    {option.option}
                                                </Link>
                                            ))}
                                        </div>
                                    ))}
                                </div>

                                <div className="row justify-content-center">
                                    <div className="col-lg-6 col-xl-6 col-12">
                                        <button to="#" onClick={handleContinue} disabled={!selectedOptions[Questionid]} className="select-option mt-4 w-100">
                                            Continue
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </section>
                    </main>
                )
            }

        </>
    )
}

export default QForMentor