
import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import { WaveLoading } from 'react-loadingg'; // Import the WaveLoading component from the react-loadingg library
import {SendRequestFromChatbot} from '../api/apiHandler.js';

const Chatbot = () => {
  const initialMessage = {
    role: 'system',
    content: "Hello! I'm your Buddy Helprr, right here whenever you need a friend or some guidance. How can I make your day a little brighter? Whether you have something specific on your mind, a question, or just feel like sharing, I’m here to listen and help. You are never alone on this journey 😊. Let's tackle this together."
  };

  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState('');
  const [loading, setLoading] = useState(false); // Add a loading state

  const chatRef = useRef(null);

  const handleSendMessage = async () => {
    const question = inputText.trim();
    if (question === '') return;

    setInputText('');
    const updatedMessages = [...messages, { role: 'user', content: question }];
    setMessages(updatedMessages);
    setLoading(true); // Set loading to true when sending a message

    try {

      // call the backend api
      SendRequestFromChatbot({'message' : updatedMessages}).then((response) => {
        
        if(response.status == 200){
          const botResponse = response.data.reply;
          
          setMessages(prevMessages => [...prevMessages, { role: 'system', content: botResponse }]);
        }
        
        }).catch((error) => {
            console.error('Error fetching chat response:', error);
        })

    } catch (error) {
      console.error('Error fetching chat response:', error);
    } finally {
      setLoading(false); // Set loading to false after receiving a response
    }
      // const response = await axios.post(
      //   process.env.REACT_APP_CHATGPT_CHATBOT_API_URL,
      //   {
      //     model: 'gpt-3.5-turbo',
      //     messages: updatedMessages,
      //     max_tokens: updatedMessages.reduce((totalTokens, message) => {
      //       const tokensInMessage = message.content.split(' ').length;
      //       return totalTokens + tokensInMessage;
      //     }, 0) + 700
      //   },
      //   {
      //     headers: {
      //       'Content-Type': 'application/json',
      //       'Authorization': `Bearer ${process.env.REACT_APP_CHATGPT_API_KEY}`
      //     }
      //   }
      // );

     // const botResponse = response.data.choices[0].message.content;
      //console.log('botResponse: ', botResponse);
     // setMessages(prevMessages => [...prevMessages, { role: 'system', content: botResponse }]);
   
  }

  useEffect(() => {
    setMessages([initialMessage]);
  }, []);

  useEffect(() => {
    if (chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <div className="chat-container" style={{ backgroundColor: 'white' }}>
      <div id="chat" className="chat-box" ref={chatRef}>
        {loading && <WaveLoading color="#f27040" size="large" />} {/* Render the WaveLoading component while loading */}
        {messages.map((message, index) => (
          <div
            key={index}
            style={{
              display: 'flex',
              justifyContent: message.role === 'user' ? 'flex-end' : 'flex-start',
              marginBottom: '8px'
            }}
          >
            <div
              style={{
                backgroundColor: message.role === 'user' ? '#f27040' : 'rgb(234, 238, 243)',
                color: message.role === 'user' ? 'rgb(234, 238, 243)' : 'black',
                borderRadius: message.role === 'user' ? '10px 0 0 10px' : '0 10px 10px 0',
                padding: '10px',
                maxWidth: '80%', // Increase this value to increase the width
                wordWrap: 'break-word'
              }}
            >
              {message.role === 'user' ? 'You:' : 'Buddy Helprr:'} {message.content}
            </div>
          </div>
        ))}
      </div>
      <div className='d-flex'>
        <input type="text" id="user-input" placeholder="Type your message..."
          value={inputText} autoComplete='off' onChange={(e) => setInputText(e.target.value)}
          onKeyUp={(e) => e.key === 'Enter' && handleSendMessage()} />
        <button className='chatbutton flex-1' onClick={handleSendMessage}>Send</button>
      </div>
    </div>
  );
};

export default Chatbot;
