import axios from "axios";
import React, { useState, useEffect, useRef } from 'react';

const CheckPlace = () => {

  const [places, setPlaces] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {

    
    const fetchPlaces = async () => {
      console.log(process.env.REACT_APP_GOOGLE_API_KEY)
      try {
        return await fetch('https://maps.googleapis.com/maps/api/place/findplacefromtext/json?' + new URLSearchParams({
          key : 'AIzaSyCTylo2b9iRn-Y-u09VM_9ce-0F17nKReo',
          //key: process.env.REACT_APP_GOOGLE_API_KEY,
          input : 'Centre for Addiction and Mental Health (CAMH), Toronto, Ontario, Canada',
          inputtype: 'textquery', // Replace with desired location coordinates
          fields: 'name,formatted_address,geometry,photo,place_id,rating,opening_hours,type', // Example radius in meter
        }));

      //  return await fetch(
      //     'https://maps.googleapis.com/maps/api/place/findplacefromtext/json',
      //     {
      //       method : 'get',
      //       //mode : 'no-cors',
      //       header : {
      //         'Content-Type': 'application/json'
      //         //'Access-Control-Allow-Origin':'*'
      //       },
      //       param: {
      //         key: process.env.REACT_APP_GOOGLE_API_KEY, // Replace with your Google Places API key
      //         input : 'Centre for Addiction and Mental Health (CAMH), Toronto, Ontario, Canada',
      //         inputtype: 'textquery', // Replace with desired location coordinates
      //         fields: 'name,formatted_address,geometry,photo,place_id,rating,opening_hours,type', // Example radius in meters  
      //       }
      //     }
      //   )
      //   .then(response => {
      //     console.log(response)
      //     console.log(response.text())
      //     return response.text()
      //   })
      //   .then((data) => {
      //     console.log(data)
      //     console.log(JSON.parse(data))
      //     return (data ? JSON.parse(data) : {})
      //   })
        
        
      } catch (error) {
        setError(error.message);
      }
    };

   const responseData = fetchPlaces();
   console.log(responseData)
  }, []); // Empty dependency array ensures the effect runs only once on component mount

  return (
    <div>
      {error && <p>Error: {error}</p>}
      <ul>
        {places.map((place, index) => (
          <li key={index}>{place.name}</li>
        ))}
      </ul>
    </div>
  );
};

export default CheckPlace;