import React, { useState, useEffect } from 'react'
import Footer from '../components/Footer.js'
import { useNavigate, Link } from 'react-router-dom'
import Header from '../components/Header.js'
import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import checkValidation from '../validation/ValidationSchema.js';
import { auth, google, facebook, apple } from '../social_login/fire.js'
import { signInWithPopup, signOut } from 'firebase/auth'
import Loader from '../components/Loader.js';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../store/slice/loaderSlice.js';
import { setUserDetails } from '../store/slice/userDetail.js';
import { login } from '../api/apiHandler.js'
import Swal from 'sweetalert2'





const Login = () => {
    const [isCheck, setIsCheck] = useState("password");
    const schema = checkValidation(['Email', 'Password']);
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.loader);

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });
    // const history = useHistory();
    const navigate = useNavigate();

    const onSubmit = (data) => {

        const loginobj = {
            email: data.Email,
            password: data.Password,
            login_type: "simple"
        }

        // dispatch(setLoading(true))
        login(loginobj).then((response) => {
            // dispatch(setLoading(false))
            if (response.status === 200) {
                const userPhoto = response.data.photo && response.data.photo.image ? response.data.photo.image : '';
                localStorage.setItem("token", response.data['X-Token'])
                localStorage.setItem("uuid", response.data['uuid'])
                localStorage.setItem("current_streak", response.data['current_streak'])
                localStorage.setItem("longest_streak", response.data['longest_streak'])
                localStorage.setItem("username", response.data['username'])
                localStorage.setItem("email", response.data['email'])
                localStorage.setItem("isadd", true)
                localStorage.setItem("islogin", "true")
                localStorage.setItem("photo", userPhoto);
                localStorage.setItem("loggedUserDetail", JSON.stringify(response.data));
                localStorage.setItem("total_interest", response.data['interests'].length);
                localStorage.setItem("user_id", response.data['id'])
                localStorage.setItem("active_actionplan", response.data["has_action_plan"]);
                localStorage.setItem("is_mentee", response.data['is_mentee'] == null ? "false" : response.data['is_mentee']);
                dispatch(setUserDetails(JSON.stringify(response)));


                if (localStorage.getItem("search") != "" && localStorage.getItem("search") != undefined && localStorage.getItem("search") != null && localStorage.getItem("full_address") != "" && localStorage.getItem("full_address") != undefined && localStorage.getItem("full_address") != null) {
                    navigate("/resource-search")
                }
                else if (localStorage.getItem("Contentpage") != "" && localStorage.getItem("Contentpage") != undefined && localStorage.getItem("Contentpage") != null) {
                    navigate("/content")
                }
                else if (localStorage.getItem("Chatbot") != "" && localStorage.getItem("Chatbot") != undefined && localStorage.getItem("Chatbot") != null) {
                    navigate("/")
                    // localStorage.removeItem("Chatbot")
                }
                else if (localStorage.getItem("user_search") != "" && localStorage.getItem("user_search") != undefined && localStorage.getItem("user_search") != null) {
                    navigate("/content")
                }
                else if (localStorage.getItem("Find_events") != "" && localStorage.getItem("Find_events") != undefined && localStorage.getItem("Find_events") != null) {
                    navigate("/events")
                    localStorage.removeItem("Find_events")
                }
                else if (localStorage.getItem("Peer_mentor") != "" && localStorage.getItem("Peer_mentor") != undefined && localStorage.getItem("Peer_mentor") != null) {
                    navigate("/peer-mentorship")
                    localStorage.removeItem("Peer_mentor")
                }
                else if (localStorage.getItem("start_topic") != "" && localStorage.getItem("start_topic") != undefined && localStorage.getItem("start_topic") != null) {
                    navigate("/group-discussions")
                    localStorage.removeItem("start_topic")
                }
                else if (localStorage.getItem("book-question") != "" && localStorage.getItem("book-question") != undefined && localStorage.getItem("book-question") != null) {
                    navigate("/book-question")
                    localStorage.removeItem("book-question")
                }
                else if (localStorage.getItem("actionplan-question") != "" && localStorage.getItem("actionplan-question") != undefined && localStorage.getItem("actionplan-question") != null) {
                    navigate("/actionplan-question")
                    localStorage.removeItem("actionplan-question")
                }
                else if (response.data.interests_added == false && response.data.interests_skipped == false) {
                    navigate("/interests")
                } else {
                    Swal.fire({
                        position: "top-right",
                        icon: "success",
                        title: "User logged in successfully",
                        toast: true,
                        showConfirmButton: false,
                        timer: 2000
                    });
                    navigate("/home")
                }
            }

        }).catch((error) => {
            if (error.response.data.errors == "We have sent one verification email please verify.") {

                Swal.fire({
                    position: "top-right",
                    icon: "info",
                    title: error.response.data.errors,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                });

            } else {
                Swal.fire({
                    position: "top-right",
                    icon: "error",
                    title: error.response.data.errors,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                });

            }

        })

    }


    const socialLogin = async (provider, login_type) => {
        try {
            const result = await signInWithPopup(auth, provider);

            if (result.user) {
                const socialobj = {
                    login_type: login_type,
                    social_key: result.user.providerData[0].uid
                };

                // You should dispatch setLoading(true) here if needed.

                login(socialobj).then((response) => {
                    // You should dispatch setLoading(false) here if needed.

                    if (response.status === 200) {
                        Swal.fire({
                            position: "top-right",
                            icon: "success",
                            title: "User logged in successfully",
                            toast: true,
                            showConfirmButton: false,
                            timer: 2000
                        });
                        const userPhoto = response.data.photo && response.data.photo.image ? response.data.photo.image : '';
                        localStorage.setItem("loggedUserDetail", JSON.stringify(response.data));
                        localStorage.setItem("uuid", response.data['uuid']);
                        localStorage.setItem("token", response.data['X-Token']);
                        localStorage.setItem("islogin", "true")
                        localStorage.setItem("photo", userPhoto);
                        localStorage.setItem("username", response.data['username'])
                        localStorage.setItem("email", response.data['email'])
                        localStorage.setItem("current_streak", response.data['current_streak'])
                        localStorage.setItem("longest_streak", response.data['longest_streak'])
                        localStorage.setItem("user_id", response.data['id'])
                        localStorage.setItem("isadd", true)
                        localStorage.setItem("islogin", "true")
                        localStorage.setItem("active_actionplan", response.data["has_action_plan"]);
                        localStorage.setItem("is_mentee", response.data['is_mentee'] == null ? "false" : response.data['is_mentee']);

                        dispatch(setUserDetails(JSON.stringify(response)));


                        if (localStorage.getItem("search") != "" && localStorage.getItem("search") != undefined && localStorage.getItem("search") != null && localStorage.getItem("full_address") != "" && localStorage.getItem("full_address") != undefined && localStorage.getItem("full_address") != null) {
                            navigate("/resource-search")
                        }
                        else if (localStorage.getItem("Contentpage") != "" && localStorage.getItem("Contentpage") != undefined && localStorage.getItem("Contentpage") != null) {
                            navigate("/content")
                        }
                        else if (localStorage.getItem("Chatbot") != "" && localStorage.getItem("Chatbot") != undefined && localStorage.getItem("Chatbot") != null) {
                            navigate("/")
                            // localStorage.removeItem("Chatbot")
                        }
                        else if (localStorage.getItem("user_search") != "" && localStorage.getItem("user_search") != undefined && localStorage.getItem("user_search") != null) {
                            navigate("/content")
                        }
                        else if (localStorage.getItem("Find_events") != "" && localStorage.getItem("Find_events") != undefined && localStorage.getItem("Find_events") != null) {
                            navigate("/events")
                            localStorage.removeItem("Find_events")
                        }
                        else if (localStorage.getItem("Peer_mentor") != "" && localStorage.getItem("Peer_mentor") != undefined && localStorage.getItem("Peer_mentor") != null) {
                            navigate("/peer-mentorship")
                            localStorage.removeItem("Peer_mentor")
                        }
                        else if (localStorage.getItem("start_topic") != "" && localStorage.getItem("start_topic") != undefined && localStorage.getItem("start_topic") != null) {
                            navigate("/group-discussions")
                            localStorage.removeItem("start_topic")
                        }
                        else if (localStorage.getItem("book-question") != "" && localStorage.getItem("book-question") != undefined && localStorage.getItem("book-question") != null) {
                            navigate("/book-question")
                            localStorage.removeItem("book-question")
                        }
                        else if (localStorage.getItem("actionplan-question") != "" && localStorage.getItem("actionplan-question") != undefined && localStorage.getItem("actionplan-question") != null) {
                            navigate("/actionplan-question")
                            localStorage.removeItem("actionplan-question")
                        }
                        else if (response.data.interests_added == false && response.data.interests_skipped == false) {
                            navigate("/interests")
                        } else {
                            Swal.fire({
                                position: "top-right",
                                icon: "success",
                                title: "User logged in successfully",
                                toast: true,
                                showConfirmButton: false,
                                timer: 2000
                            });
                            navigate("/home")
                        }
                    } else {
                        console.log("Login failed");
                    }
                }).catch((error) => {
                    Swal.fire({
                        position: "top-right",
                        icon: "error",
                        title: error.response.data.errors,
                        toast: true,
                        showConfirmButton: false,
                        timer: 2000
                    });
                });
            }
        } catch (error) {
            if (error.code === 'auth/cancelled-popup-request') {
                console.log('Popup request cancelled by the user.');
            } else {
                console.error('Authentication error:', error.message);
            }
        }
    };

    useEffect(() => {
        if (localStorage.getItem("islogin") == "true") {
            navigate("/home")
        }
    }, [])


    // useEffect(() => {
    //     const disableBackButton = (e) => {
    //         e.preventDefault();
    //         window.history.forward(); // Forward the user when they try to go back
    //     };

    //     window.history.pushState(null, null, window.location.pathname);
    //     window.addEventListener('popstate', disableBackButton); // Listen for back button press

    //     return () => {
    //         window.removeEventListener('popstate', disableBackButton); // Cleanup the event listener when the component unmounts
    //     };
    // }, []);





    return (
        <>
            {isLoading ?
                (<Loader loader_status={isLoading} />) :
                (
                    <main className="helprr-main">

                        <Header />
                        <section className="login-sec">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-lg-6 col-xl-6 col-12">
                                        <p className="title font_40 pb-0 text-center mb-3">Login with</p>
                                        <div className="d-flex justify-content-center align-items-center gap-3">
                                            <span type="button" className='social-icons' style={{ background: "none" }}>
                                                <img src={process.env.PUBLIC_URL + "/assets/images/icon/facebook.png"} onClick={() => socialLogin(facebook, "facebook")} />
                                            </span>
                                            <span type="button" className='social-icons' style={{ background: "none" }} >
                                                <img src={process.env.PUBLIC_URL + "/assets/images/icon/google.png"} onClick={() => socialLogin(google, "google")} />
                                            </span>
                                            <span type="button" className='social-icons' style={{ background: "none" }} >
                                                <img src={process.env.PUBLIC_URL + "/assets/images/icon/apple.png"} onClick={() => socialLogin(apple, "apple")} />
                                            </span>
                                        </div>
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <div className="mb-4 mt-3">
                                                <input
                                                    type="text"
                                                    className="form-custom form-control font_16"
                                                    id="exampleFormControlInput1"
                                                    placeholder="Email"
                                                    name="Email"
                                                    {...register('Email')}

                                                />
                                                {errors.Email && <p role="alert" className='error-text'>{errors.Email.message}</p>}


                                            </div>
                                            <div className='mb-4'>
                                                <div className="group-custom input-group ">
                                                    <input
                                                        type={isCheck}
                                                        className="form-control border-0 font_16"
                                                        name='Password'
                                                        placeholder="Password"
                                                        aria-label="Recipient's username"
                                                        aria-describedby="basic-addon2"
                                                        {...register("Password")}


                                                    />
                                                    {isCheck == "password" ?
                                                        <span className="input-group-text border-0 bg-transparent eye-icon-h" id="basic-addon2" onClick={() => setIsCheck("text")}>
                                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/eye-icon.png"} />
                                                        </span>
                                                        :
                                                        <span className="input-group-text border-0 bg-transparent eye-icon-h" id="basic-addon2" onClick={() => setIsCheck("password")}>
                                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/view-icon.png"} />
                                                        </span>
                                                    }

                                                </div>
                                                {errors.Password && <p role="alert" className='error-text'>{errors.Password.message}</p>}
                                            </div>

                                            <Link
                                                to="/forgot-password"
                                                className="font_16 text-decoration-underline"
                                            >
                                                Forgot Password?
                                            </Link>


                                            <button
                                                type="submit"
                                                className="login-btn font_16">
                                                Login
                                            </button>
                                        </form>
                                        <Link to="/signup" className="font_16 text-decoration-underline">
                                            Don’t have an account? Let’s get you set up
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </main>
                )
            }



        </>
    )
}

export default Login