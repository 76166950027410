import * as Yup from 'yup';
import moment from "moment";

const ValidationSchema = (fields) => {
    const schemaShape = {};

    const validateEmail = (email) => {
        return Yup.string().email().isValidSync(email)

    };

    const validateUser = (username) => {
        return Yup.string().matches(/^[A-Za-z ]*$/, "Please enter a valid Username").isValidSync(username);
    };

    if (fields.includes('email_or_user')) {
        console.log(fields, 'fields');
        schemaShape.email_or_user = Yup.string()
            .required('Username or Email is required')
            .test('email_or_user', 'Email / User is invalid', (value) => {
                return validateEmail(value) || validateUser(value);
            })
    }

    if (fields.includes('name')) {
        schemaShape.name = Yup.string()
            // .required("Please enter name")
            // .min(5)
            // .matches(/^[^\s][A-Za-z ]*$/
            //     , "Please enter a valid name");

            .required("Please enter name")
            .matches(/^[a-zA-Z0-9]+(?: [a-zA-Z0-9]+)*$/,
                "Please enter a valid name"
            )
            .min(2)
            .max(32)
    }


    if (fields.includes('Username')) {
        schemaShape.Username = Yup.string()
            // .required("Please enter username")
            // .min(5)
            // .matches(/^[^\s][A-Za-z]*$/, "Please enter a valid username");
            .required("Please enter username")
            .matches(/^[a-zA-Z0-9!@#$%^&*()_+{}\[\]:;<,>,.?/~\\-]{4,22}$/,
                "Please enter a valid username"
            )
            .min(4)
            .max(22)
        //.matches(/^^(?!^\\s)(?!.*\\s$)[a-zA-Z0-9- _ &]+$/,
        // .matches(/^(?!^\\s)(?!.*\\s$)[A-Za-z_ &@!$%*\\s-]{2,20}$/,
        //     "Only alphabets are allowed for this field"
        // )

    }


    if (fields.includes('Firstname')) {
        schemaShape.Firstname = Yup.string()
            .required("Please enter first name")
            // .matches(/^[a-zA-Z!@#$%^&*()_+{}\[\]:;<,>,.?/~\\-]$/,
            .matches(/^[a-zA-Z]+$/,

                "Please enter a valid first name")

    }

    if (fields.includes('Lastname')) {
        schemaShape.Lastname = Yup.string()
            .required("Please enter last name")
            .matches(/^[a-zA-Z]+$/,
                // .matches(/^[a-zA-Z!@#$%^&*()_+{}\[\]:;<,>,.?/~\\-]$/,
                "Please enter a valid last name")
    }

    if (fields.includes('Message')) {
        schemaShape.Description = Yup.string()
            .optional()
    }

    if (fields.includes('Email')) {
        schemaShape.Email = Yup.string()
            // .trim("The email cannot include leading and trailing spaces")
            .required("Please enter email address")
            .email("Please enter a valid email address")
            .matches(
                /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                "Please enter a valid email address")
        // .strict(true);
    }

    if (fields.includes('Password')) {
        schemaShape.Password = Yup.string()
            .required("Please enter password")
            .min(8)
            // .matches(/^(?!.*\s)(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/,
            .matches('^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$',
                "Password must contain at least one alphabet, one number, one special character")
    }

    if (fields.includes('NewPassword')) {
        schemaShape.NewPassword = Yup.string()
            .required("Please enter new password")
            .min(8)
            .matches('^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$', "Password must contain at least one alphabet, one number, one special character, and cannot start with a blank space")
        // .matches(/^[^\s].*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/, "Password must contain at least one alphabet, one number, one special character, and cannot start with a blank space")

    }


    if (fields.includes('Confirm_Password')) {
        schemaShape.Confirm_Password = Yup.string()
            .required("Please enter confirm password")
            .min(6)
            .oneOf([Yup.ref("Password")], "Confirm password must be same as password")
    }

    if (fields.includes('oldpassword')) {
        schemaShape.oldpassword = Yup.string()
            .required("Please enter password")
            .matches('^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$', "Password must contain at least one alphabet, one number, one special character, and cannot start with a blank space")

        // .matches(/^[^\s].*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/, "Password must contain at least one alphabet, one number, one special character, and cannot start with a blank space")


    }

    if (fields.includes('age')) {
        schemaShape.age = Yup.boolean()
            .oneOf([true], "Please select the age");

    }


    if (fields.includes('otp')) {
        schemaShape.otp = Yup.string()
            .required("Please enter OTP")
            .matches(/^[0-9]{4}$/, "Please enter a valid 4-digit OTP");

    }

    // event validation


    if (fields.includes('EventTitle')) {
        schemaShape.EventTitle = Yup.string()
            .required("Please enter event title")
            .max(64)
            .matches(/^[A-Za-z]+(?:\s+[A-Za-z]+)*$/, "Please enter a valid event title")
    }

    if (fields.includes('EventVenue')) {
        schemaShape.EventVenue = Yup.string()
            .required("Please enter event venue")
            .max(200)

    }






    if (fields.includes('EventStartTime')) {
        schemaShape.EventStartTime = Yup.string()
            .required("Please enter event start time");
    }

    if (fields.includes('EventEndTime')) {
        schemaShape.EventEndTime = Yup.string()
            .required("Please enter event end time")
            .when('EventStartTime', (startTime, schema) => {
                console.log('startTime: ', startTime);
                return schema.test({
                    test: function (endTime) {
                        if (startTime && endTime && endTime <= startTime) {
                            return this.createError({
                                message: 'End time must be greater than start time',
                                path: 'EventEndTime',
                            });
                        }
                        return true;
                    }
                });
            })
    }



    if (fields.includes('EventStartDate')) {
        schemaShape.EventStartDate = Yup.date()
            .required("Please enter event start date")
            .typeError('Please enter a valid date')
    }

    if (fields.includes('EventEndDate')) {
        schemaShape.EventEndDate = Yup.date()
            .required("Please enter event end date")
            .typeError('Please enter a valid date')
    }

    if (fields.includes('EventCategory')) {
        schemaShape.EventCategory = Yup.string()
            .required("Please enter event category")
    }

    if (fields.includes('dropdown')) {
        schemaShape.dropdown = Yup.string()
            .required("Please select an option")
    }

    if (fields.includes('search')) {
        schemaShape.search = Yup.string()
            .required("Please select")
    }


    if (fields.includes('EventImage')) {
        schemaShape.EventImage = Yup.string()
            .required("Please select image")
    }

    if (fields.includes('EventDescription')) {
        schemaShape.EventDescription = Yup.string()
            .required("Please enter event description")
    }

    if (fields.includes('EventBookingLink')) {
        schemaShape.EventBookingLink = Yup.string()
            .required("Please enter event booking link")
            .url('Event booking must be a link')
            .matches(
                /^(ftp|http|https):\/\/[^ "]+$/,
                'Invalid booking link'
            )
    }


    if (fields.includes('QuestionTitle')) {
        schemaShape.QuestionTitle = Yup.string()
            .required("Please enter question title")
            .matches(/^\S/, "Please enter a valid question title without leading spaces")
    }

    if (fields.includes('QuestionDescription')) {
        schemaShape.QuestionDescription = Yup.string()
            .required("Please enter question description")
            .matches(/^\S/, "Please enter a valid question title without leading spaces")

    }


    if (fields.includes('communityTitle')) {
        schemaShape.communityTitle = Yup.string()
            .required("Please enter question title")
            .matches(/^\S/, "Please enter a valid question title without leading spaces")
    }

    if (fields.includes('communityDescription')) {
        schemaShape.communityDescription = Yup.string()
            .required("Please enter question description")
            .matches(/^\S/, "Please enter a valid question title without leading spaces")

    }




    return Yup.object().shape(schemaShape);

}

export default ValidationSchema;