import React, { useState, useEffect, useRef } from 'react'
import OuterHeader from '../components/OuterHeader'
import Footer from '../components/Footer'
import { Link, useNavigate } from 'react-router-dom'
import Select2 from '../pages/Select2';
import '../css2/select2.css'
import Swal from 'sweetalert2'
import axios from 'axios';
import { ResourceTags, ResourceList, GooglePlaceList, ResourceVote, UpdateUserDetails } from '../api/apiHandler.js';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../components/Loader';
import { setLoading } from '../store/slice/loaderSlice';

import PlacesAutocomplete, { geocodeByAddress, getLatLng, } from "react-places-autocomplete";
import MyMapComponent from './GoogleMap';

const FilterResults = () => {
    const navigate = useNavigate();

    const [state, setState] = useState(false);
    const [statecity, setStateCity] = useState(false);

    const [searchQuery, setSearchQuery] = useState(localStorage.getItem("search"));
    // const [searchCity, setSearchCity] = useState(localStorage.getItem("city"));

    // const [chatGptResponse, setChatGptResponse] = useState([]);
    const [resourceListing, setResourceListing] = useState([]);

    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.loader);

    const tagarray = [
        { "id": 1, "tag": "Depression and Anxiety" },
        { "id": 2, "tag": "Stress Management" },
        { "id": 3, "tag": "Suicide Prevention" },
        { "id": 4, "tag": "Addiction and Substance Abuse" },
        { "id": 5, "tag": "Youth Mental Health" },
        { "id": 6, "tag": "Trauma and PTSD" }
    ]

    const [tags, setTags] = useState(tagarray);

    // const [addressForTitle, setAddressForTitle] = useState((localStorage.getItem("city") != undefined && localStorage.getItem("city") != "" && localStorage.getItem("city") != null) ? localStorage.getItem("city") : (localStorage.getItem("state") != undefined && localStorage.getItem("state") != "" && localStorage.getItem("state") != null) ? localStorage.getItem("state") : localStorage.getItem("country"));

    const [address, setAddress] = useState(localStorage.getItem("full_address") != null ? localStorage.getItem("full_address") : "");
    const [tasksPerPage, setTaskPerPage] = useState(5);
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [loadMoreFlag, setLoadMoreFlag] = useState(false);
    const [taskToDisplay, setTaskToDisplay] = useState([]);

    const [mapLat, setMapLat] = useState(0.0);
    const [mapLong, setMapLong] = useState(0.0);

    const [placeRating, setPlaceRating] = useState();
    const [placeDistance, setPlaceDistance] = useState();
    const [handleAddress, setHandleAddress] = useState(false);
    const [showMap, setShowMap] = useState(true);

    // console.log('handleAddress: ', handleAddress);
    const mapRef = useRef(null);

    useEffect(() => {
        // resourceTag()
        resourcePlaceList()
    }, [])

    // useEffect(() => {

    //     ResourceTags({}).then((response) => {
    //         if (searchQuery !== '') {
    //             setTags(response.data.tags);
    //         } else {
    //             setTags(tagarray);
    //         }
    //     }).catch((error) => {
    //         console.log(error, 'error');
    //     });

    // }, [searchQuery]);

    // resource listing

    const resourcePlaceList = (resourcedata) => {
        getFullGoogleAddress();
        if (resourcedata == undefined) {
            dispatch(setLoading(true))
        }
        ResourceList(resourcedata).then((response) => {
            setCurrentPageNo(1)
            if (response.status == 204) {
                setResourceListing([]);
                setMapLat(0.0)
                setMapLong(0.0)
                setTaskToDisplay([]);
                setShowMap(false)
                dispatch(setLoading(false))
                if (resourcedata != undefined) {
                    setLoadMoreFlag(false);
                    Swal.fire({
                        position: "top-right",
                        icon: "info",
                        title: "Didn't found any resources for your searched query",
                        toast: true,
                        showConfirmButton: false,
                        timer: 2000
                    });
                }

            } else {
                    console.log(response.data.resources && response.data.resources.length > 0)
                 console.log((response.data.chatgpt_response && response.data.chatgpt_response.length > 0))   
                //console.log("get data", response.data.resources);
                if (response.data.resources && response.data.resources.length > 0) {
                    dispatch(setLoading(false))
                    setShowMap(true)
                    setResourceListing(response.data.resources);
                    setMapLat(response.data.resources[0].latitude)
                    setMapLong(response.data.resources[0].longitude)
                    const initialTasks = response.data.resources.slice(0, tasksPerPage);
                    setTaskToDisplay(initialTasks);
                } 
                else if(response.data.chatgpt_response && response.data.chatgpt_response.length > 0){
                    googlePlaceApi(response.data.chatgpt_response);
                }
                else {
                    Swal.fire({
                        position: "top-right",
                        icon: "info",
                        title: "No data found",
                        toast: true,
                        showConfirmButton: false,
                        timer: 2000
                    });
                }
            }

        }).catch((error) => {
            dispatch(setLoading(false))
            const timerValue = error.response && error.response.data.errors.length > 200 ? 5000 : 2000;
            // const position = error.response && error.response.data.errors.length > 200 ? "center" : "top-right";
            if (error.response && error.response.data.errors.length > 200) {
                Swal.fire({
                    toast: true,
                    text: error.response ? error.response.data.errors : "Something went wrong",
                    timer: 5000
                });
            } else {
                Swal.fire({
                    position: "top-right",
                    icon: "error",
                    title: error.response ? error.response.data.errors : "Something went wrong",
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                });
            }
            console.log(error, 'error');
        })
    }

    const chatGptApiCall = async () => {

        // let city = localStorage.getItem("city") != undefined ? localStorage.getItem("city") : localStorage.getItem("state") != undefined ? localStorage.getItem("state") : localStorage.getItem("country")

        let city = localStorage.getItem("city") != undefined ? localStorage.getItem("city") : "";
        let state = localStorage.getItem("state") != undefined ? localStorage.getItem("state") : "";
        let country = localStorage.getItem("country") != undefined ? localStorage.getItem("country") : "";

        // // let prompt = `"""Provide a list of top verified and validated resources such as organizations, NGOs, etc., related to ${localStorage.getItem("search")} in ${city}. Make sure these resources have a website and are trusted resources."""`

        // let prompt = `provide top 5 verified resources for the given cause in the given city. Ensure that the resources are trustworthy and helpful. Provide the result in this format: resource name, resource description. Make sure the descriptions are under 40 words, are not repetitive. the resources should be unique too, do not repeat the resources within the same result. You only respond to questions where a valid city and cause is provided, anything irrelevant your response should be "sorry, I cant provide result for this" cause: ${localStorage.getItem("search")}, city: ${city}, state: ${state}, country: ${country}`

        let prompt = `Provide a list of top 10 verified and validated resources such as organizations, NGOs, etc., related to ${localStorage.getItem("search")} in ${city},${state},${country}. Please provide:
        
        1. Name
        2. Description
        3. Service (provide only one service from these counseling, support groups, helplines, workshops keywords)
        4. Languages (e.g., English, Hindi, please do not append . at last language)
        5. Cost (provide only one cost from these free, low cost, costly keywords)

        please provide response in above format only.`

        let requestData = {
            "model": "gpt-3.5-turbo-instruct",
            "prompt": prompt,
            "temperature": 0.8,
            "top_p": 0.8,
            "max_tokens": 712
        };

        try {
            const res = await axios.post(process.env.REACT_APP_CHATGPT_API_URL, requestData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${process.env.REACT_APP_CHATGPT_API_KEY}`
                },
            });

            console.log("chatgpt_res", res.data.choices[0].text);

            // const text = res.data.choices[0].text;
            // // Split the string into individual entries based on new line followed by a number
            // const entries = text.split(/\n\d+\./).slice(1);

            // // Map each entry to an object containing required information
            // const formattedData = entries.map(entry => {
            //     const [place, rest] = entry.split('\nDescription: ');
            //     const [description, services, languages, cost] = rest.split('\n').map(str => str.replace(/^\w+:\s/, ''));
            //     console.log('cost: ', cost);
            //     console.log('services: ', services);

            //     return {
            //         place: place.trim(),
            //         address: localStorage.getItem("full_address"),
            //         description: description.trim(),
            //         languages: languages.trim(),
            //         service: services.trim(),
            //         cost: cost.trim()
            //     };
            // });


            const text = res.data.choices[0].text;
            // Split the string into individual entries based on new line followed by a number
            const entries = text.split(/\n\d+\./).slice(1);

            // Map each entry to an object containing required information
            const formattedData = entries.map(entry => {
                const [place, rest] = entry.split('\nDescription: ');
                const [description, services, languages, cost] = rest.split('\n').map(str => str.replace(/^\w+:\s/, ''));

                // Define mapping functions
                const mapService = service => {
                    switch (service) {
                        case "Counseling":
                        case "Helplines":
                        case "Workshops":
                            return service.toLowerCase();
                        case "Helpline":
                            return "helplines"
                        case "Support groups":
                        case "Support Groups":
                            return "support_groups";
                        default:
                            return service.toLowerCase();
                    }
                };

                const mapCost = cost => {
                    switch (cost) {
                        case "Low cost":
                        case "Low Cost":
                            return "low_cost";
                        case "Free":
                        case "Costly":
                            return cost.toLowerCase();
                        default:
                            return cost.toLowerCase();
                    }
                };

                const firstService = services.split(',')[0].trim();
                const formattedService = mapService(firstService);
                const firstCost = cost.split(',')[0].trim();
                const formattedCost = mapCost(firstCost);

                console.log('cost: ', formattedCost);
                console.log('service: ', formattedService);

                return {
                    place: place.trim(),
                    address: `${city},${state},${country}`,
                    description: description.trim(),
                    languages: languages.trim(),
                    service: formattedService,
                    cost: formattedCost
                };
            });



            console.log("formattedData", formattedData);



            // const text = res.data.choices[0].text;
            // const lines = text.split('\n');

            // const parsedResources = lines.map((line) => {
            //     const match = line.match(/^\d+\.\s((?:(?![,:-])[^])+)(?:\s*[:,-]\s*)(.+)$/);

            //     if (match && match.length === 3) {
            //         const place = match[1].trim() + ", " + `${city}` + ", " + `${state}` + ", " + `${country}`;
            //         const description = match[2].trim();
            //         console.log('description: ', description);
            //         return { place, description };
            //     }
            //     return null;
            // }).filter((resource) => resource !== null);

            if (formattedData.length > 0) {
                // console.log("get converted data",formattedData);
                googlePlaceApi(formattedData);
            } else {
                console.error("No valid resources found");

                // Handle the case where no valid resources are found
                Swal.fire({
                    position: "top-right",
                    icon: "error",
                    title: "Please enter valid details",
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                });
                navigate("/resource-search")
            }
        } catch (error) {
            dispatch(setLoading(false))
            Swal.fire({
                position: "top-right",
                icon: "error",
                title: error.response ? error.response.data.errors : "Something went wrong",
                toast: true,
                showConfirmButton: false,
                timer: 2000
            });
            // console.error("chatgpr---error---------", error);
        }
    }

    const googlePlaceApi = (chatgptdata) => {
        const placeObj = {
            "search_text": `${localStorage.getItem('search')}`,
            "chatgpt_response": chatgptdata
        }

        GooglePlaceList(placeObj).then((response) => {
            dispatch(setLoading(false))
            //console.log("response", response);
            setCurrentPageNo(1)
            setLoadMoreFlag(false);
            if (response.status == 200) {
                setShowMap(true)
                setResourceListing(response.data.resources);
                setMapLat(response.data.resources[0].latitude)
                setMapLong(response.data.resources[0].longitude)
                const initialTasks = response.data.resources.slice(0, tasksPerPage);
                setTaskToDisplay(initialTasks);
            } else {
                Swal.fire({
                    position: "top-right",
                    icon: "info",
                    title: "Didn't found any resources for your searched query",
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                });
            }
        }).catch((error) => {
            dispatch(setLoading(false))
            Swal.fire({
                position: "top-right",
                icon: "error",
                title: error.response ? error.response.data.errors : "Something went wrong",
                toast: true,
                showConfirmButton: false,
                timer: 2000
            });
            console.log(error, 'error');
        })
    }

    const handleSearchChange = (e) => {
        // localStorage.setItem("search", e.target.value)
        setSearchQuery(e.target.value);
    };

    const handleInputValue = (e) => {
        localStorage.setItem("search", e.target.innerText)
        setSearchQuery(e.target.innerText);
    };

    
    const handleSelect = async (value) => {
        
        try {
            const results = await geocodeByAddress(value);
            const ll = await getLatLng(results[0]);
            
            // Extract country, state, and city from the address components
            const addressComponents = results[0].address_components || [];
            let country, state, city;
            
            for (const component of addressComponents) {
                if (component.types.includes('country')) {
                    country = component.long_name;
                } else if (component.types.includes('administrative_area_level_1')) {
                    state = component.long_name;
                } else if (component.types.includes('locality')) {
                    city = component.long_name;
                }
            }

            // You can also store these values in localStorage if needed
            if (country != undefined) {
                localStorage.setItem('country', country);
                localStorage.removeItem('state');
                localStorage.removeItem('city');
            } else {
                localStorage.removeItem('country');
            }

            if (state != undefined) {
                localStorage.setItem('state', state);
                localStorage.removeItem('city');
            } else {
                localStorage.removeItem('state');
            }

            if (city != undefined) {
                localStorage.setItem('city', city);
            } else {
                localStorage.removeItem('city');
            }

            setAddress(value);
            localStorage.setItem("full_address", value)
            setHandleAddress(true);

        } catch (error) {
            console.error('Error handling selection:', error);
        }
    };

    const filteredItems = tags && tags.filter(item =>
        item.tag.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const getFullGoogleAddress = async() => {
        
        const results = await geocodeByAddress(address);
        //console.log(results)
        // Extract country, state, and city from the address components
        const addressComponents = results[0].address_components || [];
        let country, state, city;

        for (const component of addressComponents) {
            //console.log(component)
            if (component.types.includes('country')) {
                country = component.long_name;
            } else if (component.types.includes('administrative_area_level_1')) {
                state = component.long_name;
            } else if (component.types.includes('locality')) {
                //console.log(component.long_name)
                city = component.long_name;
            }
        }
        
        // You can also store these values in localStorage if needed
        if (country != undefined) {
            localStorage.setItem('country', country);
            localStorage.removeItem('state');
            localStorage.removeItem('city');
        } else {
            localStorage.removeItem('country');
        }

        if (state != undefined) {
            localStorage.setItem('state', state);
            localStorage.removeItem('city');
        } else {
            localStorage.removeItem('state');
        }

        if (city != undefined) {
            localStorage.setItem('city', city);
        } else {
            localStorage.removeItem('city');
        }

    }    

    const SearchData = async () => {
       
        getFullGoogleAddress();
       // return;
        
        if (searchQuery != "" && address != "") {
            // setLoadMoreFlag(false);
            // setCurrentPageNo(1)
            localStorage.setItem("search", searchQuery)
            localStorage.setItem("full_address", address)
            localStorage.removeItem("rating")
            localStorage.removeItem("distance")
            localStorage.removeItem("service")
            localStorage.removeItem("languages")
            localStorage.removeItem("cost")
            // if (handleAddress == false) {
            //     var split_city = address.split(",")
            //     localStorage.setItem("city", split_city[0])
            //     localStorage.removeItem("country")
            //     localStorage.removeItem("state")
            // } else {
            //     setHandleAddress(false);
            // }
            resourcePlaceList()
            // navigate("/resource")
        } else {
            Swal.fire({
                position: "top-right",
                icon: "warning",
                title: "Please select a value",
                toast: true,
                showConfirmButton: false,
                timer: 2000
            });
        }

    }

    // user vote for place

    const handleUserVote = (resource_id, up_vote, down_vote) => {
        localStorage.removeItem("city")
        setLoadMoreFlag(false);
        setCurrentPageNo(1)
        const userVoteObj = {
            "resource": resource_id,
            "up_vote": up_vote,
            "down_vote": down_vote
        }
        ResourceVote(userVoteObj).then((response) => {
            if (response.status == 200) {
                resourcePlaceList({ "like_dislike": "like_dislike" });
            }

        }).catch((error) => {
            Swal.fire({
                position: "top-right",
                icon: "error",
                title: error.response.data.errors,
                toast: true,
                showConfirmButton: false,
                timer: 2000
            });
        })
    }

    // pagination

    function loadMoreTasks() {
        const nextPageNo = currentPageNo + 1;
        const startIndex = (nextPageNo - 1) * tasksPerPage;
        const endIndex = Math.min(startIndex + tasksPerPage, resourceListing.length);
        const newTasks = resourceListing.slice(startIndex, endIndex);
        setTaskToDisplay(prevTasks => [...prevTasks, ...newTasks]);
        setCurrentPageNo(nextPageNo);
        checkPagination(nextPageNo);
    }

    function checkPagination(nextPageNo) {
        const startIndex = nextPageNo * tasksPerPage;

        if (startIndex >= resourceListing.length) {
            setLoadMoreFlag(true);
            // Swal.fire({
            //     position: "top-right",
            //     icon: "info",
            //     title: "No more data found",
            //     toast: true,
            //     showConfirmButton: false,
            //     timer: 2000
            // });
        }
        else {
            setLoadMoreFlag(false);
        }
    }

    const handlePagination = () => {
        if (loadMoreFlag) {
            Swal.fire({
                position: "top-right",
                icon: "info",
                title: "No more data found",
                toast: true,
                showConfirmButton: false,
                timer: 2000
            });
        } else {
            loadMoreTasks();
        }
    }

    // place lat long for map

    const handlePlaceLatLong = (item) => {
        setMapLat(item.latitude)
        setMapLong(item.longitude)

        if (mapRef.current) {
            mapRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };


    // availability filter

    // const handleAvailability = (e) => {
    //     setLoadMoreFlag(false);
    //     setCurrentPageNo(1)
    //     if (e.target.value == "available") {
    //         localStorage.removeItem("availability")
    //     } else if (e.target.value == "evening" || e.target.value == "morning") {
    //         localStorage.removeItem("availability")
    //     } else {
    //         localStorage.setItem("availability", e.target.value)
    //         resourcePlaceList({ "availability": e.target.value });
    //     }
    // }


    // rating filter

    const handleRating = (e) => {
        //localStorage.removeItem("city")
        // console.log("handlerating",e.target.value);
        // setPlaceRating(e?.target.value)
        setLoadMoreFlag(false);
        setCurrentPageNo(1)
        // if (e.target.value != "defaultRating") {
        //     localStorage.setItem("rating", e.target.value)
        //     resourcePlaceList({ "rating": e.target.value });
        // } else {
        //     localStorage.removeItem("rating")
        //     resourcePlaceList({"anyrating": "anyrating"});
        // }

        if (e.target.value == "defaultRating" || e.target.value == "review") {
            localStorage.removeItem("rating")
            resourcePlaceList({ "anyrating": "anyrating" });
        }
        // else if (e.target.value == "review") {
        //     localStorage.removeItem("rating")
        // }
        else {
            localStorage.setItem("rating", e.target.value)
            resourcePlaceList({ "rating": e.target.value });
        }
    }


    // distance filter

    // const handleDistance = (e) => {
    //     // console.log("handledistance",e.target.value);
    //     setCurrentPageNo(1)
    //     setLoadMoreFlag(false);
    //     setPlaceDistance(e.target.value)
    //     const loggedUserDetail = (localStorage.getItem('loggedUserDetail')) ? JSON.parse(localStorage.getItem('loggedUserDetail')) : null;
    //     if (loggedUserDetail.latitude == null && loggedUserDetail.longitude == null) {
    //         if (localStorage.getItem('latitude') != null && localStorage.getItem('longitude') != null) {
    //             if (e.target.value != "defaultDistance") {
    //                 localStorage.setItem("distance", e.target.value)
    //                 resourcePlaceList({ "distance": e.target.value });
    //             }
    //             else {
    //                 localStorage.removeItem("distance")
    //                 // resourcePlaceList({"distance" : "default"});
    //             }
    //         } else {
    //             getLatLong();
    //         }
    //     } else {
    //         if (e.target.value != "defaultDistance") {
    //             localStorage.setItem("distance", e.target.value)
    //             resourcePlaceList({ "distance": e.target.value });
    //         }
    //         else {
    //             localStorage.removeItem("distance")
    //             // resourcePlaceList({"distance" : "default"});
    //         }
    //     }
    // }

    // const getLatLong = () => {
    //     if (navigator.geolocation) {
    //         navigator.geolocation.getCurrentPosition(
    //             (position) => {
    //                 const { latitude, longitude } = position.coords;
    //                 const roundedLatitude = latitude.toFixed(6);
    //                 const roundedLongitude = longitude.toFixed(6);
    //                 localStorage.setItem('latitude', roundedLatitude);
    //                 localStorage.setItem('longitude', roundedLongitude);
    //                 updateUserDetails(roundedLatitude, roundedLongitude)
    //             },
    //             (error) => {
    //                 Swal.fire({
    //                     position: "top-right",
    //                     icon: "error",
    //                     title: "Something went wrong",
    //                     toast: true,
    //                     showConfirmButton: false,
    //                     timer: 2000
    //                 });
    //                 console.error('Error getting location:', error.message);
    //             }
    //         );
    //     } else {
    //         console.error('Geolocation is not supported by this browser.');
    //     }
    // }

    // const updateUserDetails = (latitude, longitude) => {
    //     const userLatLongObj = {
    //         "latitude": latitude,
    //         "longitude": longitude
    //     }
    //     UpdateUserDetails(userLatLongObj).then((response) => {
    //         console.log("response", response);
    //         if (response.status == 200) {
    //             resourcePlaceList({ "distance": placeDistance });
    //         }
    //     }).catch((error) => {
    //         Swal.fire({
    //             position: "top-right",
    //             icon: "error",
    //             title: error.response ? error.response.data.errors : "Something went wrong",
    //             toast: true,
    //             showConfirmButton: false,
    //             timer: 2000
    //         });
    //     })
    // }

    // service filter 

    const handleServices = (e) => {
        //localStorage.removeItem("city")
        setLoadMoreFlag(false);
        setCurrentPageNo(1)

        if (e.target.value == "service") {
            localStorage.removeItem("service")
            resourcePlaceList({ "anyservice": "anyservice" });
        } else {
            localStorage.setItem("service", e.target.value)
            resourcePlaceList({ "service": e.target.value });
        }
    }


    // language filter

    const handleLanguages = (e) => {
        //localStorage.removeItem("city")
        setLoadMoreFlag(false);
        setCurrentPageNo(1)

        if (e.target.value == "languages") {
            localStorage.removeItem("languages")
            resourcePlaceList({ "anylanguages": "anylanguages" });
        } else {
            localStorage.setItem("languages", e.target.value)
            resourcePlaceList({ "languages": e.target.value });
        }
    }


    // cost filter

    const handleCost = (e) => {
        //localStorage.removeItem("city")
        setLoadMoreFlag(false);
        setCurrentPageNo(1)

        if (e.target.value == "cost") {
            localStorage.removeItem("cost")
            resourcePlaceList({ "anycost": "anycost" });
        } else {
            localStorage.setItem("cost", e.target.value)
            resourcePlaceList({ "cost": e.target.value });
        }
    }

    return (
        <>
            {isLoading ?
                (<Loader loader_status={isLoading} />) :
                (
                    <main className="helprr-main">
                        <OuterHeader />
                        <section>
                            <div className="container">
                                <p className="font_50 font_600">Results for {localStorage.getItem("search")} in {(localStorage.getItem("city") != undefined && localStorage.getItem("city") != "" && localStorage.getItem("city") != null) ? localStorage.getItem("city") : (localStorage.getItem("state") != undefined && localStorage.getItem("state") != "" && localStorage.getItem("state") != null) ? localStorage.getItem("state") : localStorage.getItem("country")}</p>
                                <div className="section-search">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-7 col-xl-7 col-12">
                                            <p className="font_32 pt-4">
                                                Finding help should never be overwhelming and we get that
                                            </p>

                                            <div className="mb-4"
                                                onClick={() => {
                                                    setState(!state)
                                                    setStateCity(false)
                                                }}>


                                                <div className="position-relative">
                                                    <div className="input-group border-0 custom-dropdown mb-3">
                                                        <input
                                                            type="text"
                                                            className="form-control border-0"
                                                            id="toggle"
                                                            placeholder="What do you need help with?"
                                                            aria-describedby="basic-addon2"
                                                            autoComplete="off"
                                                            maxLength={64}
                                                            value={searchQuery}
                                                            onChange={handleSearchChange}
                                                        />
                                                        <span
                                                            className="input-group-text arrow-icon border-0 p-2"
                                                            id="basic-addon2"
                                                        >
                                                            <img
                                                                src="assets/images/icon/dropdown-icon.svg"
                                                                alt=""
                                                                id="arrow-image"
                                                                className={state == false ? "" : "rotated-image"}
                                                            />
                                                        </span>
                                                    </div>
                                                    <div id="content" className={state == false ? "is-hidden drop-sec" : "drop-sec"}>
                                                        <div className="row">
                                                            <div className="col-lg-12 col-xl-12 col-12">

                                                                <ul className="list-unstyled">
                                                                    {filteredItems && filteredItems.map((item, index) => (
                                                                        <li key={index} onClick={(e) => handleInputValue(e)} >{item.tag}</li>
                                                                    ))}

                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="mb-4" onClick={() => {
                                                setStateCity(!statecity)
                                                setState(false)
                                            }}>

                                                <PlacesAutocomplete
                                                    value={address}
                                                    onChange={setAddress}
                                                    onSelect={handleSelect}
                                                    googleCallbackName="initPlaces"
                                                >
                                                    {({
                                                        getInputProps,
                                                        suggestions,
                                                        getSuggestionItemProps,
                                                        loading,
                                                    }) => (<>
                                                        <div className="mb-4" onClick={() => setStateCity(!statecity)}>
                                                            <div className="position-relative">
                                                                <div className="input-group border-0 custom-dropdown mb-3">
                                                                    <input
                                                                        className="form-control border-0"
                                                                        id="address"
                                                                        placeholder="Where Are You Looking? (City or Area)"
                                                                        defaultValue={address}
                                                                        {...getInputProps()}
                                                                    />
                                                                    <span
                                                                        className="input-group-text arrow-icon border-0 p-2"
                                                                        id="basic-addon2"
                                                                    >
                                                                        <img
                                                                            src="assets/images/icon/dropdown-icon.svg"
                                                                            alt=""
                                                                            id="arrow-image"
                                                                            className={statecity == false ? "" : "rotated-image"}
                                                                        />
                                                                    </span>
                                                                </div>
                                                                <div id="content" className={statecity == false ? "is-hidden drop-sec" : "drop-sec"}>
                                                                    <div className="row">
                                                                        <div className="col-lg-12 col-xl-12 col-12">

                                                                            <ul className="list-unstyled">
                                                                                {suggestions.map((suggestion, idx) => {
                                                                                    const style = {
                                                                                        backgroundColor: suggestion.active
                                                                                            ? "#f1f0ff"
                                                                                            : "#fff",
                                                                                        textAlign: "left",
                                                                                    };

                                                                                    return (
                                                                                        <li key={idx}
                                                                                            className="form-control"
                                                                                            {...getSuggestionItemProps(suggestion, {
                                                                                                style,
                                                                                            })}
                                                                                        >
                                                                                            {suggestion.description}
                                                                                        </li>
                                                                                    );
                                                                                })}
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                    )}
                                                </PlacesAutocomplete>


                                            </div>
                                            <button className="search-btn font_18 mt-4" onClick={() => SearchData()}>
                                                Search
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="other-res-pad" onClick={() => {
                            setStateCity(false)
                            setState(false)
                        }}>
                            <div className="container" ref={mapRef}>
                                {/* <p className="font_48 font_600">Top Ten Resources For {localStorage.getItem("search")}</p> */}
                                <div className="row justify-content-center">
                                    <div className="col-lg-7 col-xl-7 col-md-12 col-12">
                                        <div className="sort-by-sec">
                                            <div>
                                                <p className="mb-0 font_36 font_600">Sort:</p>
                                            </div>
                                            <div className="row w-100">
                                                {/* <div class="col-lg-3 col-xl-3 col-12">
                              <p class="mb-0 font_36 font_600">Sort:</p>
                          </div> */}
                                                {/* <div className="col-md-4 col-lg-4 col-xl-4 col-12">

                                                    <div class="filter-result-select">
                                                        <select className="form-select country-select form-control js-example-basic-single" onChange={handleAvailability}>
                                                            <option value="available">Availability</option>
                                                            <option value="Monday,Tuesday,Wednesday,Thursday,Friday">Mon - Fri</option>
                                                            <option value="Saturday,Sunday">Sat - Sun</option>
                                                            <option value="evening">Evenings Only</option>
                                                            <option value="morning">Mornings Only</option>
                                                        </select>
                                                    </div>

                                                </div> */}

                                                <div className="col-md-4 col-lg-4 col-xl-4 col-12">

                                                    <div class="filter-result-select">
                                                        {/* <Select2 data-minimum-results-for-search="Infinity" className="country-select bg-light form-control js-example-basic-single"
                                                            data={['Any Rating', '3 Stars', '4 Stars', '5 Stars']}
                                                            options={
                                                                {
                                                                    placeholder: 'Reviews',
                                                                }
                                                            }
                                                            // onChange={handleRating}
                                                        /> */}
                                                        <select className="form-select country-select form-control js-example-basic-single" onChange={handleRating}>
                                                            <option value="review">Reviews</option>
                                                            <option value="defaultRating">Any Rating</option>
                                                            <option value="3">3 star</option>
                                                            <option value="4">4 star</option>
                                                            <option value="5">5 star</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                {/* <div className="col-md-4 col-lg-4 col-xl-4 col-12">

                                                    <div class="filter-result-select">
                                                        <select className="form-select country-select form-control js-example-basic-single" onChange={handleDistance}>
                                                            <option value="defaultDistance">Distance</option>
                                                            <option value="5">5 km</option>
                                                            <option value="10">5 - 10 km</option>
                                                            <option value="15">10 - 15 km</option>
                                                            <option value="20">15+ km</option>
                                                        </select>
                                                    </div>
                                                </div> */}
                                                <div className="col-md-4 col-lg-4 col-xl-4 col-12">

                                                    <div class="filter-result-select">
                                                        <select className="form-select country-select form-control js-example-basic-single" onChange={handleServices}>
                                                            <option value="service">Services</option>
                                                            <option value="counseling">Counseling</option>
                                                            <option value="support_groups">Support Groups</option>
                                                            <option value="helplines">Helplines</option>
                                                            <option value="workshops">Workshops</option>
                                                        </select>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-5 col-xl-5 col-md-12 col-12">
                                        <div className="sort-by-sec bg-cream">
                                            <div>
                                                <p className="mb-0 font_36 font_600">Filter:</p>
                                            </div>
                                            <div className="row w-100">
                                                {/* <div className="col-md-6 col-lg-6 col-xl-6 col-12">
                                                    <div class="filter-result-select filter-lang">
                                                        <Select2 data-minimum-results-for-search="Infinity" className="country-select bg-light form-control js-example-basic-single"
                                                            data={['English', 'French', 'Spanish', 'Japanese']}
                                                            options={
                                                                {
                                                                    placeholder: 'Language',
                                                                }
                                                            }
                                                        />
                                                    </div>
                                                </div> */}
                                                <div className="col-md-6 col-lg-6 col-xl-6 col-12">
                                                    <div class="filter-result-select">
                                                        <select className="form-select country-select form-control js-example-basic-single" onChange={handleLanguages}>
                                                            <option value="languages">Languages</option>
                                                            <option value="english">English</option>
                                                            <option value="hindi">Hindi</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                {/* <div className="col-md-6 col-lg-6 col-xl-6 col-12">
                                                    <div class="filter-result-select filter-lang">
                                                        <Select2 data-minimum-results-for-search="Infinity" className="country-select bg-light form-control js-example-basic-single"
                                                            data={['Wheelchair Accessible', 'Visual/Auditory', 'Seizures', 'Learning/Cognitive']}
                                                            options={
                                                                {
                                                                    placeholder: 'Accessibility',
                                                                }
                                                            }
                                                        />
                                                    </div>
                                                </div> */}
                                                <div className="col-md-6 col-lg-6 col-xl-6 col-12">
                                                    <div class="filter-result-select">
                                                        <select className="form-select country-select form-control js-example-basic-single" onChange={handleCost}>
                                                            <option value="cost">Cost</option>
                                                            <option value="free">Free</option>
                                                            <option value="low_cost">Low Cost</option>
                                                            <option value="costly">High Cost</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6 col-xl-6 col-md-12 col-12 flex-order--2">
                                        {taskToDisplay && taskToDisplay.map((item) => {
                                            return (
                                                <div className="card-health bg-cream">
                                                    <Link>
                                                        <p className="font_28 mb-2" onClick={() => handlePlaceLatLong(item)}>{item.name}</p>
                                                    </Link>
                                                    <p className="font_16 mb-2">{item.description} </p>
                                                    {Math.floor(item.rating) == 1 ?
                                                        <div className="d-flex gap-3 stars-img mb-3">
                                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-colored.svg"} alt="" />
                                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-pinkcolored.svg"} alt="" />
                                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-pinkcolored.svg"} alt="" />
                                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-pinkcolored.svg"} alt="" />
                                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-pinkcolored.svg"} alt="" />
                                                        </div> :
                                                        Math.floor(item.rating) == 2 ?
                                                            <div className="d-flex gap-3 stars-img mb-3">
                                                                <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-colored.svg"} alt="" />
                                                                <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-colored.svg"} alt="" />
                                                                <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-pinkcolored.svg"} alt="" />
                                                                <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-pinkcolored.svg"} alt="" />
                                                                <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-pinkcolored.svg"} alt="" />
                                                            </div> :
                                                            Math.floor(item.rating) == 3 ?
                                                                <div className="d-flex gap-3 stars-img mb-3">
                                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-colored.svg"} alt="" />
                                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-colored.svg"} alt="" />
                                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-colored.svg"} alt="" />
                                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-pinkcolored.svg"} alt="" />
                                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-pinkcolored.svg"} alt="" />
                                                                </div> :
                                                                Math.floor(item.rating) == 4 ?
                                                                    <div className="d-flex gap-3 stars-img mb-3">
                                                                        <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-colored.svg"} alt="" />
                                                                        <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-colored.svg"} alt="" />
                                                                        <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-colored.svg"} alt="" />
                                                                        <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-colored.svg"} alt="" />
                                                                        <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-pinkcolored.svg"} alt="" />
                                                                    </div> :
                                                                    Math.floor(item.rating) == 5 ?
                                                                        <div className="d-flex gap-3 stars-img mb-3">
                                                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-colored.svg"} alt="" />
                                                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-colored.svg"} alt="" />
                                                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-colored.svg"} alt="" />
                                                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-colored.svg"} alt="" />
                                                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-colored.svg"} alt="" />
                                                                        </div> :
                                                                        <div className="d-flex gap-3 stars-img mb-3">
                                                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-pinkcolored.svg"} alt="" />
                                                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-pinkcolored.svg"} alt="" />
                                                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-pinkcolored.svg"} alt="" />
                                                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-pinkcolored.svg"} alt="" />
                                                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-pinkcolored.svg"} alt="" />
                                                                        </div>
                                                    }

                                                    {item.phone != null ? <Link to="tel:784545425" className="mb-3"> <i className="fa fa-phone"></i>{item.phone}</Link> : ""}
                                                    <div className="d-flex gap-3 align-items-center mt-3">
                                                        <div className="d-flex gap-1 align-items-center">
                                                            <div className="like" style={{ cursor: "pointer" }} onClick={() => { handleUserVote(item.id, true, false) }}>
                                                                <img
                                                                    src={process.env.PUBLIC_URL + "/assets/images/icon/thumb-up.png"}
                                                                    alt=""
                                                                />
                                                                {/* <p className="mb-0 font_18 font_600">30</p> */}
                                                            </div>
                                                            <p className="mb-0 font_15 font_500">{item.up_vote_count}</p>
                                                        </div>
                                                        <div className="d-flex gap-1 align-items-center">
                                                            <div className="like" style={{ cursor: "pointer" }} onClick={() => { handleUserVote(item.id, false, true) }}>
                                                                <img
                                                                    src={process.env.PUBLIC_URL + "/assets/images/icon/thumb-down.png"}
                                                                    alt=""
                                                                />
                                                                {/* <p className="mb-0 font_18 font_600">30</p> */}
                                                            </div>
                                                            <p className="mb-0 font_15 font_500">{item.down_vote_count}</p>
                                                        </div>
                                                    </div>
                                                    {item.website != null ?
                                                        <div>
                                                            <div className="d-flex align-items-center justify-content-end">

                                                                <p className="font_16 mb-0 me-2">Go To Website</p>
                                                                <Link to={item.website} target="_blank">
                                                                    <img
                                                                        src="assets/images/icon/purple-round-arrow.svg"
                                                                        className="arrow-img-height"
                                                                        alt=""
                                                                    />
                                                                </Link>

                                                            </div>
                                                        </div> :
                                                        ""}
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div className="col-lg-6 col-xl-6 col-md-12 col-12 flex-order--1 ">
                                        <div className="mb-3">
                                            {showMap ?
                                                <MyMapComponent
                                                    key={`${mapLat}-${mapLong}`}
                                                    data={{ lat: parseFloat(mapLat), lng: parseFloat(mapLong) }}
                                                    containerElement={
                                                        <div style={{ height: "600px", width: "100%", maxWidth: "100%" }} />
                                                    }
                                                    mapElement={
                                                        <div style={{ height: "100%", width: "100%" }} />
                                                    }
                                                    isMarkerShown
                                                /> : ""}
                                        </div>
                                    </div>
                                </div>

                                {resourceListing.length > 5 ?
                                    <div className="d-flex justify-content-center mt-4 mb-5">
                                        <button className="primary-btn bg-blue" onClick={handlePagination}>
                                            Load More
                                        </button>
                                    </div> : ""
                                }
                            </div>
                        </section>

                        <Footer />
                    </main>)}
        </>
    )
}

export default FilterResults