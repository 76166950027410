import React, { useState } from 'react'
import OuterHeader from '../components/OuterHeader'
import Footer from '../components/Footer'
import { Link, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import checkValidation from '../validation/ValidationSchema';
import { changePassword } from '../api/apiHandler.js'
import Loader from '../components/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../store/slice/loaderSlice';
import Swal from 'sweetalert2'

const ChangePassword = () => {
    const [isCheck, setIsCheck] = useState("password");
    const [isCheckCp, setIsCheckCp] = useState("password");
    const [isCheckOp, setIsCheckOp] = useState("password");
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.loader);

    const schema = checkValidation(['oldpassword', 'Password', 'Confirm_Password']);
    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });
    const navigate = useNavigate();


    const handlechangePassword = (data) => {

        const changepassobj = {
            old_password: data.oldpassword,
            new_password: data.Password
        }
        // dispatch(setLoading(true))
        changePassword(changepassobj).then((response) => {
            // dispatch(setLoading(false))
            if (response.status === 200) {
                Swal.fire({
                    position: "top-right",
                    icon: "success",
                    title: "Change password successfully ",
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                });
                navigate('/account')
            }

        }).catch((error) => {
            Swal.fire({
                position: "top-right",
                icon: "error",
                title: error.response.data.errors,
                toast: true,
                showConfirmButton: false,
                timer: 2000
            });
            console.log(error);
        })


    }

    const handleback = () => {
        navigate("/account")
    }

    return (
        <>
            {isLoading ?
                (<Loader loader_status={isLoading} />) :
                (
                    <main className="helprr-main">
                        <OuterHeader />
                        <section className="login-sec">
                            <div className="container">
                                <div className="d-flex gap-3 mb-3 align-items-center" onClick={handleback}>
                                    <img className='back-arrow-icon' src={process.env.PUBLIC_URL + "/assets/images/icon/left-arrow.svg"} alt="" />
                                    <p className="mb-0 font_24">Go Back</p>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-6 col-xl-6 col-12">
                                        <p className="title font_64">Change Password</p>
                                        <form onSubmit={handleSubmit(handlechangePassword)}>

                                            <div className="mb-4">
                                                <div className="group-custom input-group ">
                                                    <input

                                                        type={isCheckOp}
                                                        className=" form-control border-0 font_18"
                                                        id="exampleFormControlInput1"
                                                        placeholder="Current Password"
                                                        name="oldpassword"
                                                        {...register("oldpassword")}

                                                    />
                                                    {isCheckOp == "password" ?
                                                        <span className="input-group-text border-0 bg-transparent eye-icon-h" id="basic-addon2" onClick={() => setIsCheckOp("text")}>
                                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/eye-icon.png"} />
                                                        </span>
                                                        :
                                                        <span className="input-group-text border-0 bg-transparent eye-icon-h" id="basic-addon2" onClick={() => setIsCheckOp("password")}>
                                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/view-icon.png"} />
                                                        </span>
                                                    }

                                                </div>
                                                {errors.oldpassword && <p role="alert" className='error-text'>{errors.oldpassword.message}</p>}
                                            </div>
                                            <div className='mb-4'>
                                                <div className="group-custom input-group ">
                                                    <input
                                                        type={isCheck}
                                                        className="form-control border-0 font_18"
                                                        name='NewPassword'
                                                        placeholder="New Password"
                                                        aria-label="Recipient's username"
                                                        aria-describedby="basic-addon2"
                                                        {...register("Password")}


                                                    />
                                                    {isCheck == "password" ?
                                                        <span className="input-group-text border-0 bg-transparent eye-icon-h" id="basic-addon2" onClick={() => setIsCheck("text")}>
                                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/eye-icon.png"} />
                                                        </span>
                                                        :
                                                        <span className="input-group-text border-0 bg-transparent eye-icon-h" id="basic-addon2" onClick={() => setIsCheck("password")}>
                                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/view-icon.png"} />
                                                        </span>
                                                    }

                                                </div>
                                                {errors.Password && <p role="alert" className='error-text '>{errors.Password.message}</p>}
                                            </div>

                                            <div className="mb-4">
                                                <div className="group-custom input-group ">
                                                    <input
                                                        type={isCheckCp}
                                                        className="form-control border-0 font_18"
                                                        id="exampleFormControlInput1"
                                                        name='Confirm_Password'
                                                        placeholder="Confirm Password"
                                                        {...register("Confirm_Password")}

                                                    />

                                                    {isCheckCp == "password" ?
                                                        <span className="input-group-text border-0 bg-transparent eye-icon-h" id="basic-addon2" onClick={() => setIsCheckCp("text")}>
                                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/eye-icon.png"} />
                                                        </span>
                                                        :
                                                        <span className="input-group-text border-0 bg-transparent eye-icon-h" id="basic-addon2" onClick={() => setIsCheckCp("password")}>
                                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/view-icon.png"} />
                                                        </span>
                                                    }
                                                </div>
                                                {errors.Confirm_Password && <p role="alert" className='error-text'>{errors.Confirm_Password.message}</p>}

                                            </div>
                                            {/* <a href="javascript:void(0)" class="font_20 text-decoration-underline">Forgot Password</a> */}
                                            <button type='submit' className="login-btn font_20">
                                                Save
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <Footer />
                    </main>
                )
            }

        </>
    )
}

export default ChangePassword