import React from 'react'
import { Link } from 'react-router-dom'

const Q1 = () => {
    return (
        <>
            <main>
                <section className="section-survey">
                    <div className="container">
                        <div>
                            <Link to={localStorage.getItem("help") == "decide" ? "/content" : "/bookdashboard"}>
                                <img className='back-arrow-icon' src={process.env.PUBLIC_URL + "/assets/images/icon/left-arrow.svg"} alt="" />
                            </Link>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-lg-7 col-xl-6 col-12">
                                <p className="text-black font_30 font_600 mb-5 mt-4 text-center">
                                    Good job on deciding to explore mindful content, answer just a few
                                    questions for a personalized experience.
                                </p>
                                <Link to="/content-question" className="select-option" onClick={() => localStorage.setItem("questions_for", "content")}>
                                    Continue
                                </Link>
                                <Link to="/content" className="select-option">
                                    Skip
                                </Link>
                            </div>
                            <div class="col-12">
                                <div class="q1-comment mt-4">
                                    <p class="mb-0 font_20 text-white font_500 text-center">Did you know the kind of content we
                                        consume
                                        affects our
                                        mood, thoughts, and
                                        mental
                                        well-being. Mindless scrolling results into increased symptoms of anxiety and
                                        depression. By choosing to consume mindful content you are taking a step towards a
                                        better and stronger you.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>





        </>
    )
}

export default Q1