import React, { useState, useEffect } from 'react'
import Sidebar from '../components/Sidebar'
import CommunityHeader from '../components/CommunityHeader'
import { Link } from 'react-router-dom'
import { interests, AddForum, ForumList, AddComment, AddReply, UserList, ForumVote, CommentVote, ReplyVote, MyForumList, ForumDetails, LikedForumList } from '../api/apiHandler.js'
import { startsearching } from '../store/slice/masterslice.js'
import Swal from 'sweetalert2'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux';


const GroupDiscussionsLike = () => {
    const dispatch = useDispatch();
    const isSearching = useSelector((state) => state.masterSlice);
    const [forum, SetForum] = useState()
    const [openItem, setOpenItem] = useState(null);
    const [openreply, setOpenreply] = useState(null);
    const [nocontent, setNocontent] = useState(204);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const itemsPerPage = 10;
    const [visibleComments, setVisibleComments] = useState(3);
    const [visibleReply, setVisibleReply] = useState(1);
    const [viewmore, setviewmore] = useState(null)
    const [reply, setreply] = useState("")
    const [showUserSelect, setShowUserSelect] = useState(false);
    const [userlist, setUserlist] = useState()
    const [userid, Setuserid] = useState()
    const [comment, setComment] = useState()
    const [state, setState] = useState({
        inputValue: '',
    });
    const [handlesearch, sethandlesearch] = useState(true)
    const [usernameSelected, setUsernameSelected] = useState(false);




    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < Math.ceil(totalCount / itemsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    useEffect(() => {
        Grouplikedata({ page: currentPage })
    }, [currentPage, isSearching])

    const Grouplikedata = (data) => {
        if (handlesearch) {
            dispatch(startsearching(""))
        }
        data.serchdata = isSearching?.isSearch
        LikedForumList(data).then((response) => {
            if (response.status == 200) {
                if (response.data.results.length > 0) {
                    sethandlesearch(false)
                    SetForum(response.data.results)
                    setTotalCount(response.data.total_count);
                    setNocontent(200)

                } else {
                    SetForum([])
                    Swal.fire({
                        position: "top-right",
                        icon: "info",
                        title: "No data found",
                        toast: true,
                        showConfirmButton: false,
                        timer: 2000
                    });
                    setNocontent(204)

                }
            } else if (response.status == 204) {
                Swal.fire({
                    position: "top-right",
                    icon: "info",
                    title: "User has not liked any questions",
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                });
                setNocontent(204)
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    const handleCollapseToggle = (itemId) => {
        setOpenItem((prevItem) => (prevItem === itemId ? null : itemId));
        if (openItem !== itemId) {
            setOpenItem(itemId);

            ForumDetails({ forumid: itemId }).then((response) => {
                if (response.status === 200) {
                    Grouplikedata({ page: currentPage });
                }
            }).catch((error) => {
                console.log(error);
            });
        } else {
            setOpenItem(null);
        }
    };

    const handleCollapse2Toggle = (itemId) => {
        setOpenreply((prevItem) => (prevItem === itemId ? null : itemId));
    };


    const handleformvote = (forumid, up_vote, down_vote, like_unlike) => {
        const forumvotedata = {
            forum: forumid,
            up_vote: up_vote,
            down_vote: down_vote
        }

        ForumVote(forumvotedata).then((response) => {
            if (response.status == 200) {
                Grouplikedata({ page: currentPage })

            } else if (response.status == 204) {
                Grouplikedata({ page: currentPage })
                Swal.fire({
                    position: "top-right",
                    icon: "warning",
                    title: "Forum has been removed due to multiple reporting",
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                });
            }

        }).catch((error) => {
            console.log(error);
            // Swal.fire({
            //     position: "top-right",
            //     icon: "error",
            //     title: error.response.data.errors,
            //     toast: true,
            //     showConfirmButton: false,
            //     timer: 2000
            // });
        })

    }



    const handleformCommentvote = (Commentid, up_vote, down_vote, like_unlike) => {

        const forumcommentvotedata = {
            comment: Commentid,
            up_vote: up_vote,
            down_vote: down_vote
        }

        CommentVote(forumcommentvotedata).then((response) => {
            if (response.status == 200) {
                Grouplikedata({ page: currentPage })

            } else if (response.status == 204) {
                Grouplikedata({ page: currentPage })
                Swal.fire({
                    position: "top-right",
                    icon: "warning",
                    title: "Comment has been removed due to multiple reporting",
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                });
            }

        }).catch((error) => {
            Swal.fire({
                position: "top-right",
                icon: "error",
                title: error.response.data.errors,
                toast: true,
                showConfirmButton: false,
                timer: 2000
            });
        })

    }

    const handleformCommentreplyvote = (replyid, up_vote, down_vote, like_unlike) => {

        const forumcommentreplyvotedata = {
            reply: replyid,
            up_vote: up_vote,
            down_vote: down_vote
        }



        ReplyVote(forumcommentreplyvotedata).then((response) => {
            if (response.status == 200) {
                Grouplikedata({ page: currentPage })

            } else if (response.status == 204) {
                Grouplikedata({ page: currentPage })
                Swal.fire({
                    position: "top-right",
                    icon: "warning",
                    title: "Reply has been removed due to multiple reporting",
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                });
            }

        }).catch((error) => {
            Swal.fire({
                position: "top-right",
                icon: "error",
                title: error.response.data.errors,
                toast: true,
                showConfirmButton: false,
                timer: 2000
            });
        })

    }

    const handleShowMorereply = (comment_id) => {
        setviewmore(comment_id)
        setVisibleReply(visibleReply + 2);
        // if (viewmore != comment_id) {
        //     setVisibleReply(visibleReply + 2);
        // } else {

        //     setVisibleReply(1)
        // }

    };

    useEffect(() => {
        const handleUserList = async () => {
            if (reply.includes('@') && !usernameSelected) { // Only show user list if no username has been selected
                const atIndex = reply.lastIndexOf('@');
                const searchQuery = reply.slice(atIndex + 1).trim();
                try {
                    // Call your user list API with the searchQuery
                    const response = await UserList({ username: searchQuery });
                    if (response.status === 200) {
                        setUserlist(response.data.users);
                        setShowUserSelect(true);
                    }
                } catch (error) {
                    console.error('Error fetching user list:', error);
                }
            } else {
                setShowUserSelect(false);
            }
        };

        handleUserList();
    }, [reply, usernameSelected]);

    const handlereplys = (e) => {
        setreply(e.target.value);
        // Reset the usernameSelected state when the user types '@'
        if (e.target.value.includes('@')) {
            setUsernameSelected(false);
        }
    };


    const handleReplay = (items) => {

        const replydata = {
            comment: items,
            // tagged_users: [20, 21, 25],
            reply: reply
        }
        // 

        AddReply(replydata).then((response) => {
            if (response.status == 201) {
                Grouplikedata({ page: currentPage })
                setOpenreply(null)
                setreply("")

            }

        }).catch((error) => {
            console.log(error);
        })
    }


    const handleuserlist = (itemsid, username) => {
        setreply(reply.replace(/@[^@\s]*$/, `@${username} `));
        setShowUserSelect(false);
        setUsernameSelected(true); // Set the state to indicate that a username has been selected
    };

    const handleShowMore = () => {
        setVisibleComments(visibleComments + 3);
    };

    const handleComment = (items) => {
        const commentdata = {
            forum: items,
            comment: comment
        }
        AddComment(commentdata).then((response) => {
            console.log(response, "resssss");
            if (response.status == 201) {
                Grouplikedata({ page: currentPage })
                setComment("")
            }

        }).catch((error) => {
            // Swal.fire({
            //     position: "top-right",
            //     icon: "error",
            //     title: error.response.data.errors,
            //     toast: true,
            //     showConfirmButton: false,
            //     timer: 2000
            // });
            console.log(error);
        })
    }


    return (
        <>
            <main className="helprr-dashboard">
                <CommunityHeader />
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-12">
                                <Sidebar />
                            </div>
                            <div className="col-xxl-9 col-xl-9 col-lg-9 col-12">
                                <div className="body-page">
                                    <div className="body-wrapper">
                                        <div className="row">
                                            <div className="col-lg-9 col-xl-9 col-xxl-9 col-12">
                                                <div className="tab-content" id="pills-tabContent">

                                                    <div className="tab-content" id="pills-tabContent">
                                                        <div
                                                            className="tab-pane fade show active"
                                                            id="pills-home"
                                                            role="tabpanel"
                                                            aria-labelledby="pills-home-tab"
                                                        >
                                                            <div className="start-topic mb-3">Your Likes</div>
                                                            {forum && forum.map((item, key) => {
                                                                return (
                                                                    <>
                                                                        <div className="community-card">
                                                                            <div className="d-flex gap-2 card-wrap">

                                                                                <div className='flex-grow-2'>
                                                                                    <div className="d-flex gap-2 justify-content-between">
                                                                                        <p className="title font_18 mb-0 font_600">
                                                                                            {item.title}
                                                                                        </p>
                                                                                        {/* <Link to="#" className="que-btn">
                                                                                            Question
                                                                                        </Link> */}
                                                                                    </div>
                                                                                    <div>
                                                                                        <p className="text font_15 mb-2">{moment(item.created).format('hh:mm A')}</p>
                                                                                        <p className="title font_16 mb-2">
                                                                                            {item.description}
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="column-card">
                                                                                    {item.user.photo != null && item.user.photo != undefined ?
                                                                                        <img
                                                                                            src={item.user.photo.image}
                                                                                            className="user-img"
                                                                                            alt=""
                                                                                        /> :
                                                                                        <>
                                                                                            <img
                                                                                                src={process.env.PUBLIC_URL + "/assets/images/defalt.png"}
                                                                                                className="user-img"
                                                                                                alt=""
                                                                                            />
                                                                                        </>
                                                                                    }

                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center gap-2 justify-content-end">
                                                                                <p className="mb-0 font_600 font_17 viewp">
                                                                                    <img
                                                                                        src={process.env.PUBLIC_URL + "/assets/images/icon/thumb-up.png"}
                                                                                        alt=""
                                                                                        onClick={() => handleformvote(item.id, true, false, "like")}
                                                                                    />
                                                                                    {item.up_vote_count}
                                                                                </p>
                                                                                {/* <p className="mb-0 font_600 font_17 viewp">
                                                                                    <img
                                                                                        src={process.env.PUBLIC_URL + "/assets/images/icon/thumb-down.png"}
                                                                                        alt=""
                                                                                    />
                                                                                    {item.down_vote_count}
                                                                                </p> */}
                                                                                <p className="mb-0 font_600 font_17 viewp">
                                                                                    <img
                                                                                        src={process.env.PUBLIC_URL + "/assets/images/icon/view-icon.png"}
                                                                                        alt=""
                                                                                    />
                                                                                    {item.view_count}
                                                                                </p>

                                                                                <p
                                                                                    onClick={() => handleCollapseToggle(item.id)}
                                                                                    className="mb-0 font_600 font_17 viewp"
                                                                                    type="button"
                                                                                    data-bs-toggle="collapse"
                                                                                    data-bs-target={`#collapseExample${item.id}`}
                                                                                    aria-expanded={openItem === item.id}
                                                                                    aria-controls={`collapseExample${item.id}`}
                                                                                >
                                                                                    <img
                                                                                        src={process.env.PUBLIC_URL + "/assets/images/icon/chat-bubble.png"}
                                                                                        alt=""
                                                                                    />
                                                                                    {item.comments.length}
                                                                                </p>
                                                                                <img
                                                                                    src="assets/images/icon/clarity_flag-solid.png"
                                                                                    className="ps-3 object-fit-contain"
                                                                                    onClick={() => handleformvote(item.id, false, true, "dislike")}

                                                                                />
                                                                                {item.down_vote_count}

                                                                            </div>
                                                                            {/* <div class="collapse" id={"#collapseExample" + item.id}> */}
                                                                            <div className={`collapse ${openItem === item.id ? 'show' : ''}`} id={`collapseExample${item.id}`}>

                                                                                <div className="hr-line"></div>
                                                                                {item.comments.slice(0, visibleComments).map((item, index) => {
                                                                                    return (
                                                                                        <>
                                                                                            <div key={index}>
                                                                                                <div className="subsec gap-2 card-wrap">
                                                                                                    {item.user.photo != null && item.user.photo != undefined ?
                                                                                                        <img
                                                                                                            src={item.user.photo.image}
                                                                                                            className="user-img"
                                                                                                            alt=""
                                                                                                        /> :
                                                                                                        <>
                                                                                                            <img
                                                                                                                src={process.env.PUBLIC_URL + "/assets/images/defalt.png"}
                                                                                                                className="user-img"
                                                                                                                alt=""
                                                                                                            />
                                                                                                        </>
                                                                                                    }
                                                                                                    <div className="flex-1">
                                                                                                        <p className="mb-0 font_16">
                                                                                                            {item.user.username}
                                                                                                            {/* <img
                                                                                                            src="assets/images/icon/check-blue.png"
                                                                                                            alt="check-blue"
                                                                                                            className="check-icon"
                                                                                                        /> */}
                                                                                                        </p>
                                                                                                        <p className="title font_16 mb-2">
                                                                                                            {item.comment}
                                                                                                        </p>
                                                                                                        <div className="d-flex gap-3">
                                                                                                            <p className="font_15 text-gray" style={{ cursor: "pointer" }} onClick={() => handleCollapse2Toggle(item.id)}
                                                                                                                data-bs-toggle="collapse"
                                                                                                                data-bs-target={`#collapsereply${item.id}`}
                                                                                                                aria-expanded={openItem === item.id}
                                                                                                                aria-controls={`collapsereply${item.id}`}
                                                                                                            >Reply</p>

                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <div className="d-flex column-display justify-content-between">
                                                                                                        <p className="time-define mb-0 font_15">
                                                                                                            {moment(item.created).fromNow()}
                                                                                                        </p>
                                                                                                        <div className="d-flex">
                                                                                                            <p className="mb-0 font_600 font_17 viewp">
                                                                                                                <img
                                                                                                                    src={process.env.PUBLIC_URL + "/assets/images/icon/thumb-up.png"}
                                                                                                                    alt=""
                                                                                                                    onClick={() => handleformCommentvote(item.id, true, false, "like")}
                                                                                                                />
                                                                                                                {item.up_vote_count}
                                                                                                            </p>
                                                                                                            <img
                                                                                                                src="assets/images/icon/clarity_flag-solid.png"
                                                                                                                className="ps-3 object-fit-contain"
                                                                                                                onClick={() => handleformCommentvote(item.id, false, true, "dislike")}
                                                                                                            />
                                                                                                            {item.down_vote_count}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                {item.replies.slice(0, visibleReply).map((item2, key) => {
                                                                                                    return (
                                                                                                        <>
                                                                                                            <div className="subsec gap-2 card-wrap ms-5">
                                                                                                                {item2.user.photo != null && item2.user.photo != undefined ?
                                                                                                                    <img
                                                                                                                        src={item2.user.photo.image}
                                                                                                                        className="user-img"
                                                                                                                        alt=""
                                                                                                                    /> :
                                                                                                                    <>
                                                                                                                        <img
                                                                                                                            src={process.env.PUBLIC_URL + "/assets/images/defalt.png"}
                                                                                                                            className="user-img"
                                                                                                                            alt=""
                                                                                                                        />
                                                                                                                    </>
                                                                                                                }
                                                                                                                <div className="flex-1">
                                                                                                                    <p className="mb-0 font_16">{item2.user.username}</p>

                                                                                                                    <p className="title font_16 mb-2">
                                                                                                                        {item2.reply}
                                                                                                                    </p>
                                                                                                                    {/* <div className="d-flex gap-3">
                                                                                                                        <p className="font_15 text-gray">Reply</p>
                                                                                                                        <p className="font_15 text-gray">Share</p>
                                                                                                                        <p className="font_15 text-gray">Report</p>
                                                                                                                    </div> */}
                                                                                                                </div>
                                                                                                                <div className="d-flex column-display justify-content-between">
                                                                                                                    <p className="time-define mb-0 font_15">
                                                                                                                        {moment(item2.created).fromNow()}
                                                                                                                    </p>
                                                                                                                    <div className="d-flex">
                                                                                                                        <p className="mb-0 font_600 font_17 viewp">
                                                                                                                            <img
                                                                                                                                src={process.env.PUBLIC_URL + "/assets/images/icon/thumb-up.png"}
                                                                                                                                alt=""
                                                                                                                                onClick={() => handleformCommentreplyvote(item2.id, true, false, "like")}
                                                                                                                            />
                                                                                                                            {item2.up_vote_count}
                                                                                                                        </p>
                                                                                                                        <img
                                                                                                                            src="assets/images/icon/clarity_flag-solid.png"
                                                                                                                            className="ps-3 object-fit-contain"
                                                                                                                            onClick={() => handleformCommentreplyvote(item2.id, false, true, "dislike")}
                                                                                                                        />
                                                                                                                        {item2.down_vote_count}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </>
                                                                                                    )
                                                                                                })}


                                                                                                {item.replies.length > visibleReply && (
                                                                                                    <div>
                                                                                                        <p className="mt-3 text-center text-gray font_600 font_15 cursor-pointer" onClick={handleShowMorereply}>
                                                                                                            View More Reply
                                                                                                        </p>
                                                                                                    </div>
                                                                                                )}

                                                                                            </div>
                                                                                            <div className={`collapse ${openreply === item.id ? 'show' : ''}`} id={`collapsereply${item.id}`}>
                                                                                                <div className="d-flex gap-3 mt-4 position-relative">
                                                                                                    {localStorage.getItem("photo") != undefined && localStorage.getItem("photo") != null && localStorage.getItem("photo") != "" ?
                                                                                                        <img src={localStorage.getItem("photo")} className="account-dropdown add-comment-icon" alt="" /> :
                                                                                                        <img src={process.env.PUBLIC_URL + "/assets/images/defalt.png"} className="account-dropdown add-comment-icon" alt="" />
                                                                                                    }
                                                                                                    <input
                                                                                                        type="text"
                                                                                                        className="form-control position-relative"
                                                                                                        placeholder="Reply"
                                                                                                        aria-label="Add a comment."
                                                                                                        aria-describedby="basic-addon2"
                                                                                                        id={index}
                                                                                                        name={`input${index}`}
                                                                                                        value={reply}
                                                                                                        onChange={(e) => handlereplys(e, item.id)}
                                                                                                    />

                                                                                                    {showUserSelect && showUserSelect != null && (reply != "") ?
                                                                                                        <div className='tag-dropdown'>
                                                                                                            {userlist && userlist.map((item3, key) => {
                                                                                                                return (
                                                                                                                    <>
                                                                                                                        <div className='tag-dropdown-list '>
                                                                                                                            <p
                                                                                                                                className="custom-event-select "
                                                                                                                                aria-label="Default select example"
                                                                                                                                value={item3.id}
                                                                                                                                onClick={() => handleuserlist(item3.id, item3.username)}


                                                                                                                            // onChange={handleEventTypeChange}
                                                                                                                            >
                                                                                                                                {item3.username}
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                    </>
                                                                                                                )

                                                                                                            })}
                                                                                                        </div> : <></>
                                                                                                    }
                                                                                                    {reply !== undefined && reply !== null && reply !== "" ?
                                                                                                        <div>
                                                                                                            <img class="add-comment-icon"
                                                                                                                src="assets/images/icon/arrow-right-orange.png"
                                                                                                                alt=""
                                                                                                                onClick={() => {
                                                                                                                    if (reply.trim() !== '') {
                                                                                                                        handleReplay(item.id)
                                                                                                                    } else {
                                                                                                                        Swal.fire({
                                                                                                                            position: "top-right",
                                                                                                                            icon: "info",
                                                                                                                            title: "Please enter a valid comment.",
                                                                                                                            toast: true,
                                                                                                                            showConfirmButton: false,
                                                                                                                            timer: 2000
                                                                                                                        });
                                                                                                                    }
                                                                                                                }

                                                                                                                }
                                                                                                            />
                                                                                                        </div> : <img class="add-comment-icon"
                                                                                                            src="assets/images/icon/arrow-right-orange.png"
                                                                                                            alt=""

                                                                                                        />}

                                                                                                </div>
                                                                                            </div>



                                                                                        </>
                                                                                    )
                                                                                })}

                                                                                {item.comments.length > visibleComments && (
                                                                                    <div>
                                                                                        <p className="mt-3 text-center text-gray font_600 font_15 cursor-pointer" onClick={handleShowMore}>
                                                                                            View More Comments
                                                                                        </p>
                                                                                    </div>
                                                                                )}



                                                                                <div className="hr-line"> </div>
                                                                                <div className="d-flex gap-3 mt-4">
                                                                                    {localStorage.getItem("photo") != undefined && localStorage.getItem("photo") != null && localStorage.getItem("photo") != "" ?
                                                                                        <img src={localStorage.getItem("photo")} className="account-dropdown add-comment-icon" alt="" /> :
                                                                                        <img src={process.env.PUBLIC_URL + "/assets/images/defalt.png"} className="account-dropdown add-comment-icon" alt="" />
                                                                                    }
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        placeholder="Add a comment."
                                                                                        aria-label="Add a comment."
                                                                                        aria-describedby="basic-addon2"
                                                                                        value={comment || ''}
                                                                                        onChange={(e) => setComment(e.target.value)}
                                                                                        onKeyDown={(e) => {
                                                                                            if (e.key === 'Enter') {
                                                                                                e.preventDefault(); // Prevent default behavior (e.g., form submission)
                                                                                                if ((comment || '').trim() !== '') {
                                                                                                    handleComment(item.id);
                                                                                                } else {
                                                                                                    Swal.fire({
                                                                                                        position: "top-right",
                                                                                                        icon: "info",
                                                                                                        title: "Please enter a valid comment.",
                                                                                                        toast: true,
                                                                                                        showConfirmButton: false,
                                                                                                        timer: 2000
                                                                                                    });
                                                                                                }
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                    <img class="add-comment-icon"
                                                                                        src="assets/images/icon/arrow-right-orange.png"
                                                                                        alt=""
                                                                                        onClick={() => {
                                                                                            if ((comment || '').trim() !== '') {
                                                                                                handleComment(item.id);
                                                                                            } else {
                                                                                                Swal.fire({
                                                                                                    position: "top-right",
                                                                                                    icon: "info",
                                                                                                    title: "Please enter a valid comment.",
                                                                                                    toast: true,
                                                                                                    showConfirmButton: false,
                                                                                                    timer: 2000
                                                                                                });
                                                                                            }
                                                                                        }}

                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )
                                                            })}

                                                            {nocontent && nocontent == 204 ? <></> :
                                                                <div className="row justify-content-center mt-sm-5" >
                                                                    <div className="col-lg-3 col-xl-3 col-md-4 col-6">
                                                                        {/* {currentPage === 1 ? "" : */}
                                                                        <button type="button" className={currentPage === 1 ? "load-btn-disable w-100" : "load-btn w-100"} onClick={handlePrevPage} disabled={currentPage === 1}>
                                                                            Previous
                                                                        </button>
                                                                        {/* } */}

                                                                    </div>
                                                                    <div className="col-lg-3 col-xl-3 col-md-4 col-6">
                                                                        {/* {currentPage === Math.ceil(totalCount / itemsPerPage) ? "" : */}
                                                                        <button type="button" className={currentPage === Math.ceil(totalCount / itemsPerPage) ? "load-btn-disable w-100" : "load-btn w-100"} onClick={handleNextPage} disabled={currentPage === Math.ceil(totalCount / itemsPerPage)}>
                                                                            Next
                                                                        </button>
                                                                        {/* } */}
                                                                    </div>
                                                                </div>}

                                                        </div>
                                                        <div
                                                            className="tab-pane fade"
                                                            id="pills-home"
                                                            role="tabpanel"
                                                            aria-labelledby="pills-home-tab"
                                                        >
                                                            ...
                                                        </div>
                                                        <div
                                                            className="tab-pane fade"
                                                            id="pills-home"
                                                            role="tabpanel"
                                                            aria-labelledby="pills-home-tab"
                                                        >
                                                            ...
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </main>

        </>
    )
}

export default GroupDiscussionsLike