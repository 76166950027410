import axios from 'axios';
import { logOutRedirectCall } from '../config/RedirectPathMange';
const AxiosClientApi = axios.create({

    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers:
    {
        'api-key': process.env.REACT_APP_API_KEY,
        'Content-Type': 'application/json'
    }
});


// request AxiosClient
AxiosClientApi.interceptors.request.use(function (request) {
    if (localStorage.getItem("token") != undefined && localStorage.getItem("token") != "") {
        request.headers['Authorization'] = 'Token ' + localStorage.getItem("token");
    }
    return request;
});


// Response AxiosClient
AxiosClientApi.interceptors.response.use(
    function (response) {
        //console.log('response======================>', response);
        // Do something with the response data
        return response;
    },
    function (error) {
        let res = error.response;

        if (res.status == 401) {
            logOutRedirectCall()
        }
        return Promise.reject(error);
    }
);


export default AxiosClientApi;
