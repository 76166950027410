import React, { useState, useEffect } from 'react'
import OuterHeader from '../components/OuterHeader'
import Footer from '../components/Footer'
import { Link, useNavigate } from 'react-router-dom'
import { AllUsersEventList } from '../api/apiHandler'
import Swal from 'sweetalert2'
import { setLoading } from '../store/slice/loaderSlice';
import Loader from '../components/Loader';
import { useDispatch, useSelector } from 'react-redux';



const CommunityEvents = () => {
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.loader);
    const navigate = useNavigate()
    const [userdata, setUserdata] = useState()
    const [eventcount, setEventCount] = useState(0)
    const [tasksPerPage, setTaskPerPage] = useState(10);
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [loadMoreFlag, setLoadMoreFlag] = useState(false);
    const [taskToDisplay, setTaskToDisplay] = useState("");
    const [nocontent, setNocontent] = useState(204);
    const [isloadmore, setisloadmore] = useState(false);


    useEffect(() => {
        Moreeventdata()
    }, [currentPageNo, tasksPerPage])

    const Moreeventdata = () => {
        dispatch(setLoading(true))
        AllUsersEventList().then((response) => {
            dispatch(setLoading(false))
            if (response.status == 200) {
                setEventCount(response.data.events.length)
                // setUserdata(response.data.results)
                // if (response.data.page === response.data.total_page_count) {
                //     setisloadmore(true)
                // }
                // const startIndex = (currentPageNo - 1) * tasksPerPage;
                // const endIndex = startIndex + tasksPerPage;

                // const newTasks = response.data.results.slice(startIndex, endIndex);
                // setTaskToDisplay(prevTasks => [...prevTasks, ...newTasks]);
                const startIndex = (currentPageNo - 1) * tasksPerPage;
                const endIndex = startIndex + tasksPerPage;
                setUserdata(response.data.events.slice(0, endIndex));
                setNocontent(200)

            } else if (response.status == 204) {
                Swal.fire({
                    position: "top-right",
                    icon: "info",
                    title: "No events found",
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                });
                setNocontent(204)
            }

        }).catch((error) => {
            console.log(error);
        })
    }

    function loadMoreTasks() {
        // setHandleLoadData(false);
        const startIndex = (currentPageNo - 1) * tasksPerPage;
        const endIndex = startIndex + tasksPerPage;
        // setTaskToDisplay(savedata.slice(startIndex, endIndex))
        const newTasks = userdata.slice(startIndex, endIndex);
        setTaskToDisplay(prevTasks => [...prevTasks, ...newTasks]);
        // setTaskToDisplay(prevTasks => [...prevTasks, ...savedata.slice(startIndex, endIndex)]);
        setCurrentPageNo(currentPageNo + 1);
        checkPagination();
    }

    function checkPagination() {
        const startIndex = (currentPageNo - 1) * tasksPerPage;
        const endIndex = startIndex + tasksPerPage;
        if (startIndex >= userdata.length - 1) {
            setLoadMoreFlag(true);
        }
        else {
            setLoadMoreFlag(false);
        }
    }


    const handlePagination = () => {
        if (loadMoreFlag) {
            // No more data available
            Swal.fire({
                position: "top-right",
                icon: "info",
                title: "No More Data Available",
                toast: true,
                showConfirmButton: false,
                timer: 2000
            });
        } else {
            loadMoreTasks();
        }
    }



    // function loadMoreTasks() {
    //     checkPagination();
    //     Moreeventdata({ page: currentPageNo + 1 })
    //     // setTaskToDisplay(prevTasks => [...prevTasks, ...savedata.slice(startIndex, endIndex)]);
    //     setCurrentPageNo(currentPageNo + 1);
    // }

    // function checkPagination() {
    //     const startIndex = (currentPageNo - 1) * tasksPerPage;
    //     const endIndex = startIndex + tasksPerPage;
    //     if (startIndex >= userdata.length - 1) {
    //         setLoadMoreFlag(true);
    //     }
    //     else {
    //         setLoadMoreFlag(false);
    //     }
    // }


    // const handlePagination = () => {
    //     if (loadMoreFlag) {
    //         // No more data available
    //         Swal.fire({
    //             position: "top-right",
    //             icon: "info",
    //             title: "No more data available",
    //             toast: true,
    //             showConfirmButton: false,
    //             timer: 2000
    //         });
    //     } else {
    //         loadMoreTasks();
    //     }
    // }
    const handleeventdata = () => {
        navigate("/events")
    }



    return (
        <>
            {isLoading ?
                (<Loader loader_status={isLoading} />) :
                (
                    <main className="helprr-main">
                        <OuterHeader />
                        <section className="bg-orange">
                            <div className="banner-sec mt-0">
                                <div className="container">
                                    <div className='d-flex justify-content-between align-items-center flex-wrap'>
                                        <div className="banner-profile-sec">
                                            <div className="banner-profile-img">
                                                {localStorage.getItem("photo") != undefined && localStorage.getItem("photo") != null && localStorage.getItem("photo") != "" ?
                                                    <img src={localStorage.getItem("photo")} className="account-dropdown" alt="" /> :
                                                    <img src={process.env.PUBLIC_URL + "/assets/images/defalt.png"} className="account-dropdown" alt="" />
                                                }

                                            </div>
                                            <div className="banner-text">
                                                <p className="mb-0 font_18 text-white font_700">Welcome back</p>
                                                <p className="mb-0 font_26 text-white font_700"> {localStorage.getItem("username")}</p>

                                            </div>
                                        </div>
                                        <div>
                                            <Link to="/events" className="decide-btn">Find events</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="back-section pb-5">
                                <div className="container position-relative">
                                    <div className="action-section">
                                        <div className="card-progress first-card">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div>
                                                    <p className="mb-0  font_22">Community Events</p>
                                                    <div className="award-progress mt-4">
                                                        {nocontent && nocontent == 204 ? <p>No Community Events found at the moment. Check back later or create your own event!</p> :
                                                            <div className="mb-4 d-flex gap-3">
                                                                <div className="icon-card">
                                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/award.png"} alt="award" />
                                                                </div>
                                                                <div>
                                                                    <p className="mb-0 font_18 font_500">Total Events</p>
                                                                    <p className="mb-0 font_25 font_700">{eventcount}</p>
                                                                </div>
                                                            </div>}
                                                    </div>
                                                </div>
                                                <div className="card-image">
                                                    <img src={process.env.PUBLIC_URL + "/assets/images/card-Image.png"} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="task-list">
                                        {nocontent && nocontent == 204 ? "" :
                                            <p className="font_30 font_500 text-orange">All Events</p>}
                                    </div>
                                    {userdata && userdata.map((item, key) => {
                                        return (
                                            <>
                                                <div className="card-progress bg-mave">
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <div>
                                                            <p className="mb-0 text font_22">{item.title}</p>
                                                            <p className="mb-0 text font_20 font_700">{item.description}</p>
                                                            <p className="mb-2 font_16 text-decoration-underline " style={{ cursor: "pointer" }} onClick={handleeventdata}>
                                                                Show More
                                                            </p>
                                                            <p className="font_20 font_500 mb-0">{item.attendance_status}</p>
                                                        </div>
                                                        <div className="card-image">
                                                            <img src={item.image} alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )

                                    })}

                                    {
                                        (userdata && userdata.length < 10) || nocontent === 204 ? null : (
                                            <div className="row mt-5 justify-content-center">
                                                <div className="col-lg-3 col-md-6 col-6">
                                                    <button className="edit-btn" onClick={handlePagination}>
                                                        Load More
                                                    </button>
                                                </div>
                                            </div>
                                        )
                                    }


                                </div>
                            </div>
                        </section>
                        <Footer />
                    </main>)}

        </>

    )
}

export default CommunityEvents