import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Swal from 'sweetalert2'
import { Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import moment from 'moment-timezone';

import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from "react-places-autocomplete";
import axios from "axios";


const CreateEvent = () => {
    const [address, setAddress] = useState(null);
    const [coordinates, setCoordinates] = useState("");
    const [startTimeChange, setStartTimeChange] = useState(null);
    // console.log('startTimeChange: ', moment.tz(startTimeChange, "HH:mm", "Canada/Eastern").format('HH:mm'));
    const [endTimeChange, setEndTimeChange] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [imageSrc, setImageSrc] = useState(process.env.PUBLIC_URL + "/assets/images/event--2.png");
    const [saveimage, setSaveimage] = useState()
    const [eventDashboard, setEventDashboard] = useState(false);


    const openEventDashboardModal = () => {
        setEventDashboard(true)
    }

    const closeEventDashboardModal = () => {
        setEventDashboard(false)
    }

    const validationSchema = Yup.object().shape({
        EventTitle: Yup.string().required("Please enter event title").max(64)
            .matches(/^[A-Za-z]+(?:\s+[A-Za-z]+)*$/, "Please enter a valid event title"),

        // EventCategory: Yup.string().required("Please enter event category"),
        dropdown: Yup.string().required("Please select an option"),

        EventVenue: Yup.string()
            .when('dropdown', {
                is: 'in_person',
                then: () => Yup.string().required('Please enter event venue'),
                otherwise: () => Yup.string()
            }),

        // EventVenue: Yup.string().when('dropdown', {
        //     is: value => value === 'in_person',
        //     then: Yup.string().required("Please enter event venue").max(200),
        //     otherwise: Yup.string().max(200)
        // }),

        EventStartDate: Yup.string().required("Please enter event start date").test({
            name: 'valid-date',
            message: 'Please enter a valid date',
            test: (value) => {
                const startDate = new Date(value);
                return !isNaN(startDate.getTime());
            },
        }),
        EventEndDate: Yup.string().required("Please enter event end date").test({
            name: 'valid-date',
            message: 'Please enter a valid date',
            test: (value) => {
                const endDate = new Date(value);
                return !isNaN(endDate.getTime());
            },
        }),
        EventStartTime: Yup.string().required("Please enter event start time"),
        // EventEndTime: Yup.string()
        //     .required("Please enter event end time")
        //     .when(['EventStartDate', 'EventEndDate', 'EventStartTime'], (startDate, endDate, startTime, schema) => {
        //         const startDateTime = new Date(`${startDate}T${startTime}`);
        //         const endDateTime = new Date(`${endDate}T${schema.originalValue}`);
        //         if (endDateTime <= startDateTime) {
        //             return schema.test({
        //                 message: 'End time must be greater than start time',
        //                 test: value => false,
        //             });
        //         }
        //         return schema;
        //     }),
        EventEndTime: Yup.string()
            .required("Please enter event end time")
            .when(['EventStartDate', 'EventEndDate', 'EventStartTime'], (startDate, endDate, startTime, schema) => {
                const startDateTime = new Date(`${startDate}T${startTime}`);
                const endDateTime = new Date(`${endDate}T${schema}`);
                if (endDateTime <= startDateTime) {
                    return schema.test({
                        message: 'End time must be greater than start time',
                        test: value => false,
                    });
                }
                return schema;
            }),

        // search: Yup.string().required("Please select"),
        EventImage: Yup.string()
            .required("Please select image"),
        EventDescription: Yup.string()
            .required("Please enter event description"),
        EventBookingLink: Yup.string()
            .required("Please enter event booking link")
            .url('Event booking must be a link')
            .matches(
                /^(ftp|http|https):\/\/[^ "]+$/,
                'Invalid booking link'
            )
    });

    const formOptions = { resolver: yupResolver(validationSchema) };
    const { control, setValue, register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;

    const navigate = useNavigate()
    const eventhomepage = () => {
        closeEventDashboardModal()
        navigate("/events")
    }

    const handleImageChange = (e) => {
        const selectedFile = e.target.files[0];
        setSaveimage(selectedFile)
        setValue('EventImage', selectedFile, { shouldValidate: true })

        if (selectedFile) {
            const reader = new FileReader();
            reader.onload = (event) => {
                setImageSrc(event.target.result);
            };
            reader.readAsDataURL(selectedFile);
        }
    };

    const handleSelect = async (value) => {

        setValue("EventVenue", value, { shouldValidate: true });
        const results = await geocodeByAddress(value);
        const latLong = await getLatLng(results[0]);
        setAddress(value);
        setCoordinates(latLong);
    };

    // const handleSelectStartDate = (event) => {
    //     setStartDate(new Date(event.target.value))
    //     setValue('EventStartDate', moment(new Date(event.target.value)).format('YYYY-MM-DD'), { shouldValidate: true })
    // }

    const handleSelectStartDate = (event) => {
        const startDateValue = new Date(event.target.value);
        setStartDate(startDateValue);
        // Clear end date when start date changes
        if (endDate && endDate <= startDateValue) {
            setEndDate(null);
            setValue('EventEndDate', '', { shouldValidate: true });
        }
        setValue('EventStartDate', moment(startDateValue).format('YYYY-MM-DD'), { shouldValidate: true });
    }

    const handleSelectEndDate = (event) => {
        setEndDate(new Date(event.target.value))
        setValue('EventEndDate', moment(new Date(event.target.value)).format('YYYY-MM-DD'), { shouldValidate: true })
    }

    const changeSelectStartTime = async (event) => {
        console.log('event: ', event);
        //console.log('starttime: ', moment(event, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)').format('HH:mm:ss'));
        setValue("EventStartTime", event, { shouldValidate: true });
        setStartTimeChange(event)
    }

    const changeSelectEndTime = async (event) => {
        //console.log('endtime: ', moment(event, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)').format('HH:mm:ss'));

        setValue("EventEndTime", event, { shouldValidate: true });
        setEndTimeChange(event)
    }

    const eventRegistration = (data) => {

        // conver the date time into UTC and pass to form
        let startDateTime = new Date(data.EventStartDate + ' ' + moment(data.EventStartTime).format('HH:mm'));
        let endDateTime = new Date(data.EventEndDate + ' ' + moment(data.EventEndTime).format('HH:mm'));

        let formdata = new FormData();
        formdata.append("user", `${localStorage.getItem("user_id")}`);
        formdata.append("title", data.EventTitle)
        formdata.append("description", data.EventDescription)
        formdata.append("type", data.dropdown)
        formdata.append("venue", data.dropdown == "in_person" ? address : null)
        formdata.append("latitude", data.dropdown == "in_person" ? coordinates.lat.toFixed(4) : "")
        formdata.append("longitude", data.dropdown == "in_person" ? coordinates.lng.toFixed(4) : "")
        formdata.append("start_date", moment.utc(startDateTime).format('YYYY-MM-DD'));
        formdata.append("end_date", moment.utc(endDateTime).format('YYYY-MM-DD'));
        formdata.append("start_time", moment.utc(startDateTime).format('HH:mm'));
        formdata.append("end_time", moment.utc(endDateTime).format('HH:mm'));
        formdata.append("event_booking_url", data.EventBookingLink)
        formdata.append("image", saveimage)
        //console.log('formdata: ', formdata);

        axios({
            method: 'post',
            url: process.env.REACT_APP_API_BASE_URL + "/event/events/",
            headers: {
                'api-key': process.env.REACT_APP_API_KEY,
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Token ' + localStorage.getItem("token")
            },
            data: formdata,
        }).then((response) => {
            if (response.status == 201) {

                Swal.fire({
                    position: "top-right",
                    icon: "success",
                    title: "Event created successfully",
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                });
                reset()
                setImageSrc(process.env.PUBLIC_URL + "/assets/images/event--2.png")
                setAddress(null)
                setStartDate(null)
                setEndDate(null)
                setStartTimeChange(null)
                setEndTimeChange(null)
                openEventDashboardModal()

            }

        }).catch((error) => {
            console.error(error);
        })
    }

    const onSubmit = async (data) => {

        if (Object.keys(errors).length == 0) {

            if (moment(startDate).format('YYYY-MM-DD') < moment(endDate).format('YYYY-MM-DD')) {
                eventRegistration(data)
            }
            else {
                if (moment(startTimeChange).format('HH:mm') <= moment(endTimeChange).format('HH:mm')) {
                    eventRegistration(data)
                }
                else {
                    Swal.fire({
                        position: "top-right",
                        icon: "error",
                        title: "End time must be greater than start time",
                        toast: true,
                        showConfirmButton: false,
                        timer: 2000
                    });
                }
            }
        }

    }

    useEffect(() => {
        console.log(address)
        if (address == "") {
            setValue("EventVenue", '', { shouldValidate: true });
        }
        else if (address != null) {
            setValue("EventVenue", address, { shouldValidate: true });
        }
    }, [address])

    return (
        <>
            <>
                <main className="helprr-main events-main">
                    <header className="p-2">
                        <nav className="navbar navbar-expand-lg navbar-light navbar-header">
                            <div className="container-fluid d-flex p-0">
                                <Link className="navbar-brand" to="/">
                                    <img src={process.env.PUBLIC_URL + "/assets/images/logo.png"} />
                                </Link>
                            </div>
                        </nav>
                    </header>
                    <section className="create-event-sec">
                        <div className="container">
                            <p className="font_36 font_700 text-mave text-center">Create Event</p>

                            <form onSubmit={handleSubmit(onSubmit)} enctype="multipart/form-data">
                                <div className="row justify-content-center">
                                    <div className="col-lg-9 col-xl-9 col-12">
                                        <div className="mb-4">
                                            <label
                                                htmlFor="exampleFormControlInput1"
                                                className="form-label text-black font_12"
                                            >
                                                Event Title
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="exampleFormControlInput1"
                                                placeholder="Event title"
                                                name="EventTitle"
                                                {...register("EventTitle")}
                                            />
                                            {errors.EventTitle && <p role="alert" className='error-text'>{errors.EventTitle.message}</p>}

                                        </div>

                                        <div className="col-lg-6 mb-4">
                                            <label
                                                htmlFor="exampleFormControlInput1"
                                                className="form-label text-black font_12"
                                            >
                                                Event Category
                                            </label>

                                            <select {...register("dropdown")} className="form-select event-select" aria-label="Default select example" >
                                                <option value="">Select event category</option>
                                                <option value="in_person">in person</option>
                                                <option value="virtual">virtual</option>

                                            </select>
                                            {errors.dropdown && <p role="alert" className='error-text'>{errors.dropdown.message}</p>}


                                        </div>

                                        <div className="mb-4">

                                            <PlacesAutocomplete
                                                value={address}
                                                onChange={setAddress}
                                                onSelect={handleSelect}
                                                googleCallbackName="initPlaces"

                                            >
                                                {({
                                                    getInputProps,
                                                    suggestions,
                                                    getSuggestionItemProps,
                                                    loading,
                                                }) => (
                                                    <div>
                                                        <div className="input_box">
                                                            <label
                                                                htmlFor="exampleFormControlInput1"
                                                                className="form-label text-black font_12"
                                                            >
                                                                Event Venue
                                                            </label>
                                                            <input
                                                                className="form-control"
                                                                placeholder="where is the event happening?"
                                                                id="address"
                                                                //onKeyDown={(e) => clearInput()}
                                                                defaultValue={address}
                                                                {...register("EventVenue")}
                                                                {...getInputProps()}
                                                            />
                                                        </div>
                                                        <div>
                                                            {loading ? (
                                                                <div className="text-secondary">
                                                                    loading...
                                                                </div>
                                                            ) : null}

                                                            {suggestions.map((suggestion) => {
                                                                const style = {
                                                                    backgroundColor: suggestion.active
                                                                        ? "#f1f0ff"
                                                                        : "#fff",
                                                                    textAlign: "left",
                                                                };

                                                                return (
                                                                    <div
                                                                        className="form-control"
                                                                        {...getSuggestionItemProps(suggestion, {
                                                                            style,
                                                                        })}
                                                                    >
                                                                        {suggestion.description}
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                )}
                                            </PlacesAutocomplete>

                                            {(errors.EventVenue) && <p role="alert" className='error-text'>{errors.EventVenue.message}</p>}
                                        </div>


                                        <div className="row">
                                            <div className="col-lg-6 col-xl-6 col-12">
                                                <div className="mb-4">
                                                    <label
                                                        htmlFor="exampleFormControlInput1"
                                                        className="form-label text-black font_12"
                                                    >
                                                        Start Date
                                                    </label>
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        id="exampleFormControlInput1"
                                                        name="EventStartDate"
                                                        {...register("EventStartDate")}
                                                        value={startDate ? startDate.toISOString().split('T')[0] : ''}
                                                        placeholder="MM/DD/YYYY"
                                                        min={new Date().toISOString().split('T')[0]}
                                                        onChange={(e) => handleSelectStartDate(e)}
                                                    />
                                                    {errors.EventStartDate && <p role="alert" className='error-text'>{errors.EventStartDate.message}</p>}

                                                </div>
                                            </div>


                                            <div className="col-lg-6 col-xl-6 col-12">
                                                <div className="mb-4">
                                                    <label
                                                        htmlFor="exampleFormControlInput1"
                                                        className="form-label text-black font_12"
                                                    >
                                                        End Date
                                                    </label>
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        id="exampleFormControlInput1"
                                                        name="EventEndDate"
                                                        {...register("EventEndDate")}
                                                        value={endDate ? endDate.toISOString().split('T')[0] : ''}
                                                        placeholder="MM/DD/YYYY"
                                                        min={startDate ? startDate.toISOString().split('T')[0] : ''}
                                                        onChange={(e) => handleSelectEndDate(e)}
                                                        disabled={!startDate}
                                                    />
                                                    {errors.EventEndDate && <p role="alert" className='error-text' >{errors.EventEndDate.message}</p>}
                                                </div>
                                            </div>
                                        </div>



                                        <div className="row">
                                            <div className="col-lg-6 col-xl-6 col-12">
                                                <div className="mb-4 column-display  d-flex">
                                                    <label
                                                        htmlFor="exampleFormControlInput1"
                                                        className="form-label text-black font_12"
                                                    >
                                                        Start Time
                                                    </label>

                                                    <Controller
                                                        {...register("EventStartTime")}
                                                        control={control}
                                                        defaultValue={null}
                                                        render={({ field }) => (
                                                            <DatePicker
                                                                selected={startTimeChange}
                                                                // onChange={setStartTimeChange}
                                                                onChange={(e) => changeSelectStartTime(e)}
                                                                showTimeSelect
                                                                showTimeSelectOnly
                                                                timeIntervals={15}
                                                                timeCaption="Time"
                                                                dateFormat="h:mm aa"
                                                                className="form-control"
                                                                id="exampleFormControlInput1"
                                                                placeholder=""
                                                                name="EventStartTime"
                                                                autoComplete="off"

                                                            />
                                                        )}
                                                    />

                                                    {errors.EventStartTime && <p role="alert" className='error-text'>{errors.EventStartTime.message}</p>}

                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-xl-6 col-12">
                                                <div className="mb-4 column-display d-flex">
                                                    <label
                                                        htmlFor="exampleFormControlInput1"
                                                        className="form-label text-black font_12"
                                                    >
                                                        End Time
                                                    </label>


                                                    <Controller
                                                        name="EventEndTime"
                                                        {...register("EventEndTime")}
                                                        control={control}
                                                        defaultValue={null}
                                                        // rules={{ required: "please select st time" }}
                                                        render={({ field }) => (
                                                            <DatePicker
                                                                selected={endTimeChange}
                                                                //onChange={onendtimechange}
                                                                onChange={(e) => changeSelectEndTime(e)}
                                                                showTimeSelect
                                                                showTimeSelectOnly
                                                                timeIntervals={15}
                                                                timeCaption="Time"
                                                                dateFormat="h:mm aa"
                                                                className="form-control"
                                                                id="exampleFormControlInput1"
                                                                placeholder=""
                                                                name="EventEndTime"
                                                                autoComplete="off"
                                                            />
                                                        )}
                                                    />

                                                    {errors.EventEndTime && <p role="alert" className='error-text'>{errors.EventEndTime.message}</p>}

                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                                <p className="font_36 font_700 text-mave text-center pt-5">
                                    Event Description
                                </p>
                                <div className="row justify-content-center">
                                    <div className="col-lg-9 col-xl-9 col-12">
                                        <div className="mb-5">
                                            <label
                                                htmlFor="exampleFormControlInput1"
                                                className="form-label text-black font_12"
                                            >
                                                Event Image
                                            </label>

                                            <div className="event-image">
                                                <img src={imageSrc} alt="" />
                                            </div>

                                            <div className="upload-btn mt-2 text-white" >

                                                <label style={{ cursor: "pointer" }}>
                                                    Event Image
                                                    <input type="file" style={{ display: "none" }} accept='image/*' onChange={handleImageChange} />
                                                    <input type="hidden" {...register('EventImage')} />
                                                </label>
                                                {errors.EventImage && <p role="alert" className='error-text'>{errors.EventImage.message}</p>}
                                            </div>

                                        </div>
                                        <div className="mb-4">
                                            <label
                                                htmlFor="exampleFormControlTextarea1"
                                                className="form-label text-black font_12"
                                            >
                                                Event Description
                                            </label>
                                            <textarea
                                                className="form-control font_14"
                                                id="exampleFormControlTextarea1"
                                                rows={3}
                                                placeholder="Type here..."
                                                defaultValue={""}
                                                name="EventDescription"
                                                {...register("EventDescription")}
                                            />
                                            {errors.EventDescription && <p role="alert" className='error-text'>{errors.EventDescription.message}</p>}

                                        </div>
                                        <div className="mb-5">
                                            <label
                                                htmlFor="exampleFormControlInput1"
                                                className="form-label text-black font_12"
                                            >
                                                Event Booking Link
                                            </label>
                                            <input
                                                type="url"
                                                className="form-control"
                                                id="exampleFormControlInput1"
                                                placeholder="Paste the eventbrite/meetup/rsvp link here"
                                                name="EventBookingLink"
                                                {...register("EventBookingLink")}
                                            />
                                            {errors.EventBookingLink && <p role="alert" className='error-text'>{errors.EventBookingLink.message}</p>}

                                        </div>

                                        <button type="submit" className="load-btn font_18 w-100">
                                            Create Events
                                        </button>


                                    </div>
                                </div>
                            </form>
                        </div>
                    </section>
                </main>
                {eventDashboard &&
                    (<>
                        <div
                            className={`modal fade ${eventDashboard ? 'show' : ''}`}
                            id="staticBackdrop"
                            data-bs-backdrop="static"
                            data-bs-keyboard="false"
                            tabIndex="-1"
                            aria-labelledby="staticBackdropLabel"
                            aria-hidden="true"
                            style={{ display: eventDashboard ? 'block' : 'none' }}
                        >
                            <div className="modal-dialog modal-dialog-centered modal-xl start-topic-modal">
                                <div className="modal-content">

                                    <div className="modal-body p-4">
                                        <div className="d-flex gap-3 align-items-center cursor-pointer" onClick={closeEventDashboardModal}>
                                            <img
                                                src="assets/images/icon/go-back.png"
                                                className="object-fit-contain"
                                                alt="back"
                                                data-bs-dismiss="modal"
                                                aria-label="Close"

                                            />
                                            <p
                                                className="font_24 text-white mb-0 "
                                                aria-label="Close"

                                            >
                                                Go Back
                                            </p>

                                        </div>
                                        <p className="text-center font_40 text-white pt-3">
                                            Congratulations, your event is now live!
                                        </p>
                                    </div>
                                    <div className="modal-footer border-top-0 justify-content-center">
                                        {/* <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Event Dashboard</button> */}
                                        <button
                                            className="btn event-btn"
                                            onClick={eventhomepage}
                                        >
                                            Event Dashboard
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-backdrop fade show"></div>
                    </>)}
            </>

        </>
    );
};

export default CreateEvent;
