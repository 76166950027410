import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import OuterHeader from '../components/OuterHeader'
import Footer from '../components/Footer'
import { actionplantaskhistorylist } from '../api/apiHandler.js'
import moment from 'moment'
import Calendar from 'react-awesome-calendar';
import Swal from 'sweetalert2'


const Stats = () => {

    const [data, setdata] = useState();
    console.log('data: ', data);
    const [count, setCount] = useState(0);
    const [count1, setCount1] = useState(0);
    const [taskdata, setTaskdata] = useState("");
    console.log('taskdata: ', taskdata);

    // const [pasttask, setPastTask] = useState("task-history")
    // console.log('pasttask: ', pasttask);

    // useEffect(() => {

    //     actionplantasklist().then((response) => {
    //         console.log(response.data, "dateeeeeeeeeeeeeee");
    //         if (response.status == 200) {
    //             let statsdata = []
    //             response.data.tasks.forEach((element) => {
    //                 let md = moment(element.task_day_date)
    //                 // Check if end date is greater than start date
    //                 if (!md.isSameOrAfter(moment())) {
    //                     let color = null;
    //                     let title = null;
    //                     if (element.is_completed && element.is_accepted) {
    //                         color = "green"
    //                         title = "Completed"
    //                         setCount(count + 1)
    //                     }
    //                     else if (!element.is_completed && element.is_accepted) {
    //                         color = "#FFA500"
    //                         title = "In Progress"

    //                     }
    //                     else {
    //                         color = "red"
    //                         title = "Not Completed"
    //                         setCount1(count)
    //                         setCount(0)
    //                     }
    //                     statsdata.push({
    //                         id: 12,
    //                         "title": title,
    //                         "color": color,
    //                         "from": element.task_day_date,
    //                         "to": element.task_day_date
    //                     })
    //                     setdata(statsdata)
    //                 }
    //             })
    //         }
    //     }).catch((error) => {
    //         console.log(error);
    //     })
    // }, [])


    useEffect(() => {
        actionplantaskhistorylist().then((response) => {

            if (response.status === 200) {
                let statsdata = [];
                let currentStreak = 0;

                response.data['task-history'].forEach((element) => {
                    setTaskdata(element.task_name)
                    console.log('element: ', element);
                    let md = moment(element.task_day_date);

                    if (!md.isSameOrAfter(moment())) {
                        let color = null;
                        let title = null;

                        if (element.is_completed && element.is_accepted) {
                            color = "green";
                            title = "Completed";
                            currentStreak += 1;
                        } else if (!element.is_completed && element.is_accepted) {
                            color = "#FFA500";
                            title = "In Progress";
                            // You might want to handle in-progress tasks separately
                        } else {
                            color = "red";
                            title = "Not Completed";
                            // You might want to handle not-completed tasks separately
                            currentStreak = 0; // Reset streak on a not-completed day
                        }

                        statsdata.push({
                            id: 12,
                            title: title,
                            color: color,
                            from: element.task_day_date,
                            to: element.task_day_date,
                            taskdetails: element.task_name,
                            taskfeedback: element.task_feedback
                        });
                    }
                });

                setdata(statsdata);
                setCount(currentStreak); // Update the streak count after processing all tasks
            } else if (response.status === 204) {
                Swal.fire({
                    position: "top-right",
                    icon: "error",
                    title: "User has no action plan history",
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                });
            }
        }).catch((error) => {
            Swal.fire({
                position: "top-right",
                icon: "error",
                title: error.response ? error.response.data.errors : "Something went wrong",
                toast: true,
                showConfirmButton: false,
                timer: 2000
            });
        });
    }, []);

    // const handleDateClick = (date) => {
    //     console.log('called', date)
    //     Swal.fire({
    //         title: 'Date Clicked',
    //         text: JSON.stringify(data),
    //         // icon: 'info',
    //         confirmButtonText: 'OK'
    //     });
    // };

    const handleDateClick = (date) => {
        console.log('date: ', date);
        const obj = {
            date_day: date.day,
            date_month: date.month + 1,
            date_year: date.year
        }

        const formattedDate = `${obj.date_year}-${obj.date_month.toString().padStart(2, '0')}-${obj.date_day.toString().padStart(2, '0')}`;




        data.map((item) => {
            if (formattedDate === item.from) {
                if (date.mode === "dailyMode") {
                    // Swal.fire({
                    //     html: `Task Day Date: ${(item.from)}<br/>Task Details: ${(item.taskdetails)}<br/>Task Feedback: ${(item.taskfeedback)}`,
                    //     confirmButtonText: 'OK'
                    // });
                    Swal.fire({
                        html: `
                            <div style="text-align: left; padding: 10px;">
                                <p style="margin: 0;">
                                    <strong>Task Day Date:</strong> ${item.from}
                                </p>
                                <p style="margin: 0;">
                                    <strong>Task Details:</strong> ${item.taskdetails}
                                </p>
                                ${item.taskfeedback !== null ? `
                                    <p style="margin: 0;">
                                        <strong>Task Feedback:</strong> ${item.taskfeedback}
                                    </p>
                                ` : `<p style="margin: 0;">
                                <strong>Task Feedback:</strong> No feedback provided
                            </p>`}
                            </div>
                        `,
                        confirmButtonText: 'OK'
                    });



                }
            }
        })


        // Swal.fire({
        //     title: 'Date Clicked',
        //     text: `You clicked on ${JSON.stringify(date)}`,
        //     icon: 'info',
        //     confirmButtonText: 'OK'
        // });
    };




    return (
        <>
            <OuterHeader />
            <div className='bg-orange'>
                <div className="container">
                    <div className="row justify-content-center">
                        <p className='font_24 font_600 text-center text-white mt-4'>Your Stats</p>
                        <div className='col-lg-12'>
                            <div className='mb-4 d-flex justify-content-center'>
                                <div className='bg-white p-3 w-100 rounded-3'>
                                    {/* <Calendar events={data} onClickEvent={(e) => handleDateClick(e)} /> */}
                                    <Calendar events={data} onChange={(date) => handleDateClick(date)} />
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-12">
                            <div className="title-sec mb-3">
                                <p className="mb-0 text-mave font_20 font_600 text-center">
                                    Complete challenge to build your longest streak of self-care.
                                </p>
                            </div>
                            <div className="daywise-description mb-5">
                                <p className="font_30 font_500 text-mave mb-1">{localStorage.getItem("current_streak")} Day</p>
                                <p className="text-orange font_500 font_16 mb-2">Your current streak</p>
                                <div>
                                    <p className="font_20 font_600 text-mave mb-1">{localStorage.getItem("longest_streak")} Day</p>
                                    <p className="text-orange font_500 font_16">Your longest streak</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <Footer />


        </>
    )
}

export default Stats


