
import React, { useState, useEffect } from 'react'
import OuterHeader from '../components/OuterHeader'
import Footer from '../components/Footer'
import { Link } from 'react-router-dom'
import Select2 from '../pages/Select2';
import '../css2/select2.css'
import Swal from 'sweetalert2'
import { ContentTags, ContentList } from '../api/apiHandler.js'


const Results = (props) => {

    let articledata = props.articledata

    return (
        <>


            <>
                <div className="container">
                    {articledata && articledata.map((item, idx) => {
                        return (
                            <div key={idx} className="card-margin">
                                <div className="row">
                                    <div className="col-lg-4 col-xl-4 col-12">
                                        <div className="results-card-img">
                                            <img src={item.thumbnail} alt="" />
                                        </div>
                                    </div>
                                    <div className="col-lg-8 col-xl-8 col-12">
                                        <div className="d-flex column-display justify-content-between h-100">
                                            <div>
                                                <p className="font_32">{item.title}</p>
                                                <p className="font_22">
                                                    {item.description}
                                                </p>
                                            </div>
                                            <div className="row  align-items-center ">
                                                <div className="col-lg-6 col-xl-5 col-12">
                                                    <div className="d-flex gap-3 stars-img">
                                                        <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-colored.svg"} alt="" />
                                                        <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-colored.svg"} alt="" />
                                                        <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-colored.svg"} alt="" />
                                                        <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-colored.svg"} alt="" />
                                                        <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-colored.svg"} alt="" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-xl-7 col-12">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <p className="font_16 mb-0">Read Time: {item.read_time}</p>
                                                        <div className="d-flex align-items-center">
                                                            <p className="font_16 mb-0 me-2">Go To Article</p>
                                                            <Link to="/resourcearticle">
                                                                <img
                                                                    src="assets/images/icon/purple-round-arrow.svg"
                                                                    className="arrow-img-height"
                                                                    alt=""
                                                                />
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                    <div className="d-flex justify-content-center mt-4 mb-3">
                        <Link to="#" className="primary-btn bg-blue">
                            Load more
                        </Link>
                    </div>
                </div>
            </>

        </>
    )
}

export default Results