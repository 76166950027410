import React, { useState, useEffect } from 'react'
import OuterHeader from '../components/OuterHeader'
import Footer from '../components/Footer'
import { Link, useNavigate } from 'react-router-dom'
import Loader from '../components/Loader';
import { logout, userDetails, SavedContentBookArticle, SavedContentVideoAudio, UsersEventList, CancelSubscription, MyQuestionslist } from '../api/apiHandler.js'
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { setLoading } from '../store/slice/loaderSlice';
import Swal from 'sweetalert2'
import Slider from "react-slick";
import '../css2/select2.css'
import { setUserDetails } from '../store/slice/userDetail';

const Account = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.loader);
    const [forum, SetForum] = useState()
    const [savedcontent, setSavedContent] = useState()
    const [savedvideo, setSavedVideo] = useState()
    const [data, setData] = useState()
    const [userdata, setUserdata] = useState()
    const [nocontent, setNocontent] = useState()
    const [nocontentvideo, setNocontentvideo] = useState()
    const [nocontentevent, setNocontentevent] = useState()
    const [nocontentforum, setNocontentforum] = useState();

    const [loginUserData, setLoginUserData] = useState(JSON.parse(localStorage.getItem("loggedUserDetail")));

    useEffect(() => {
        userData();
    }, []);

    

    const userData = () => {
        userDetails().then((response) => {
            if (response.status === 200) {
                localStorage.setItem("loggedUserDetail", JSON.stringify(response.data));
                localStorage.setItem("active_actionplan", true)
                dispatch(setUserDetails(JSON.stringify(response)));
                setLoginUserData(response.data);
            } else {
                console.log("userdetails failed");
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    const settings = {
        dots: false,
        infinite: false,
        arrows: true,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [{
            breakpoint: 1440,
            settings: {
                dots: false,
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,

            },
        },
        {
            breakpoint: 1025,
            settings: {
                dots: false,
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,

            },
        },
        {
            breakpoint: 992,
            settings: {
                dots: false,
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,

            },
        },
        {
            breakpoint: 575.99,
            settings: {
                dots: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,

            },
        },
        ],
    };

    const settings2 = {
        dots: false,
        infinite: false,
        arrows: true,
        speed: 300,
        slidesToShow: 6,
        slidesToScroll: 1,
        responsive: [{
            breakpoint: 1440,
            settings: {
                dots: false,
                slidesToShow: 6,
                slidesToScroll: 1,
                infinite: true,

            },
        },
        {
            breakpoint: 1025,
            settings: {
                dots: false,
                slidesToShow: 4,
                slidesToScroll: 1,
                infinite: true,

            },
        },
        {
            breakpoint: 992,
            settings: {
                dots: false,
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,

            },
        },
        {
            breakpoint: 575.99,
            settings: {
                dots: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,

            },
        },
        ],
    };

    useEffect(() => {
        dispatch(setLoading(true))
        userDetails().then((response) => {
            if (response.status === 200) {
                dispatch(setLoading(false))
                setData(response.data)
            } else {
                console.log("userdetails failed");
            }
        }).catch((error) => {
            Swal.fire({
                position: "top-right",
                icon: "error",
                title: error.response.data.errors,
                toast: true,
                showConfirmButton: false,
                timer: 2000
            });
            console.log(error);
        })

        // SavedContentList().then((response) => {
        //     console.log(response.data, "resposesssss<<<<<<<<<<<<<");
        //     setSavedContent(response.data.results.content)
        // }).catch((error) => {
        //     console.log(error);
        // })

    }, [])

    useEffect(() => {
        SavedContentBookArticle().then((response) => {
            if (response.status == 200) {
                setSavedContent(response.data.saved_content)
                setNocontent([])

            } else if (response.status == 404 || response.status == 204) {
                setNocontent(null)
            }

        }).catch((error) => {
            console.log(error);
        })

        SavedContentVideoAudio().then((response) => {
            if (response.status == 200) {
                setSavedVideo(response.data.saved_content)
                setNocontentvideo([])

            } else if (response.status == 404 || response.status == 204) {
                setNocontentvideo(null)
            }

        }).catch((error) => {
            console.log(error);
        })
    }, [])


    const Handlelogout = () => {
        logout().then((response) => {

            Swal.fire({
                position: "top-right",
                icon: "success",
                title: "User logged out successfully",
                toast: true,
                showConfirmButton: false,
                timer: 2000
            });
            localStorage.clear();
            navigate('/login')

        }).catch((error) => {
            console.log(error);
        })
    }

    useEffect(() => {
        UsersEventList().then((response) => {
            if (response.status == 200) {
                setUserdata(response.data.events)
                setNocontentevent([])

            } else if (response.status == 404 || response.status == 204) {
                setNocontentevent(null)
            }

        }).catch((error) => {
            console.log(error);
        })

    }, [])




    const handleCancelSubscription = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Canceling your subscription will immediately stop all access to premium features. Are you sure you want to proceed?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, cancel it!',
            cancelButtonText: 'No, keep it'
        }).then((result) => {
            if (result.isConfirmed) {
                CancelSubscription().then((response) => {
                    if (response.status === 200) {
                        Swal.fire({
                            position: "top-right",
                            icon: "success",
                            title: response.data,
                            toast: true,
                            showConfirmButton: false,
                            timer: 2000
                        });
                        userData();
                        localStorage.setItem("active_actionplan", false);
                    } else if (response.status === 204) {
                        Swal.fire({
                            position: "top-right",
                            icon: "info",
                            title: "User has not any subscription",
                            toast: true,
                            showConfirmButton: false,
                            timer: 2000
                        });
                    }
                }).catch((error) => {
                    Swal.fire({
                        position: "top-right",
                        icon: "error",
                        title: error.response.data.errors,
                        toast: true,
                        showConfirmButton: false,
                        timer: 2000
                    });
                    console.log(error);
                })
            }
        });
    }


    useEffect(() => {
        MyQuestionslist(data).then((response) => {
            if (response.status == 200) {
                SetForum(response.data.results)
            } else if (response.status == 204) {
                // Swal.fire({
                //     position: "top-right",
                //     icon: "info",
                //     title: "No data found",
                //     toast: true,
                //     showConfirmButton: false,
                //     timer: 2000
                // });
                setNocontentforum(204)
            }
        }).catch((error) => {
            console.log(error);
        })
    }, [])


    return (
        <>
            {isLoading ?
                (<Loader loader_status={isLoading} />) :
                (
                    <main className="helprr-main">
                        <OuterHeader />
                        <section>
                            <div className="container">

                                <div className="row">
                                    <div className="col-lg-9 col-xl-9 col-xxl-8 col-12 col-md-6 flex-order-2">
                                        <div className="row align-items-end">
                                            <div className="col-lg-6 col-xl-7 col-md-12 col-12">
                                                <div className="acc-img">
                                                    {data != undefined && data.photo != null ?
                                                        <img src={data.photo.image} alt="" /> :
                                                        <img src={process.env.PUBLIC_URL + "/assets/images/defalt.png"} alt="" />
                                                    }

                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-xl-5 col-md-12 col-12 ">

                                                {data ?
                                                    <div className="mb-2">
                                                        <p className="font_50 font_600">{data.fullname}</p>
                                                        <p className="font_20 mb-1">Username: {data.username}</p>
                                                        <p className="font_20 mb-0">Account Since {new Date(data.date_joined).toLocaleString('default', { month: "short" }) + " " + new Date(data.date_joined).getFullYear()}</p>
                                                    </div> :
                                                    <div>
                                                        <p className="font_50 font_600">loading..</p>
                                                    </div>

                                                }
                                                {loginUserData != null && (loginUserData.is_subscription_active == true && loginUserData.company == null) ? <div> <p className="font_14 mb-1 font_600">Subscription auto renews on {moment(data && data.subscription_expiry_date).format('MMMM Do YYYY')}</p>
                                                    <button
                                                        type="button"
                                                        className="primary-btn-outline d-none d-lg-block"
                                                        onClick={handleCancelSubscription}
                                                    >
                                                        Cancel Subscription
                                                    </button></div> : ""}

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-xl-3 col-xxl-4 col-12 col-md-6 flex-order-1">
                                        <div className="d-flex justify-content-end">
                                            <button
                                                className="btn font_20 "
                                                type="button"
                                                data-bs-toggle="offcanvas"
                                                data-bs-target="#offcanvasRight"
                                                aria-controls="offcanvasRight"
                                            >
                                                Settings{" "}
                                                <img
                                                    src="assets/images/icon/setting-icon.svg"
                                                    className="toggle-img"
                                                />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="cutomize offcanvas offcanvas-end"
                                    tabIndex={-1}
                                    id="offcanvasRight"
                                    aria-labelledby="offcanvasRightLabel"
                                >
                                    <div className="offcanvas-header justify-content-end">
                                        {/* <h5 id="offcanvasRightLabel">Offcanvas right</h5> */}
                                        <button
                                            type="button"
                                            className="btn-close text-reset"
                                            data-bs-dismiss="offcanvas"
                                            aria-label="Close"
                                        />
                                    </div>
                                    <div className="offcanvas-body">
                                        <p className="font_40  font_600 text-end">Settings</p>
                                        <ul className="list-unstyled">
                                            <li className="font_20">
                                                <Link to="/change-account" data-bs-dismiss="offcanvas">Change Account Details</Link>
                                            </li>
                                            {loginUserData != null && (loginUserData.login_type === "simple") ?
                                                <li className="font_20">
                                                    <Link to="/change-password" data-bs-dismiss="offcanvas">Change Password</Link>
                                                </li> : ""}
                                            <li className="font_20">
                                                <Link to="/interests" data-bs-dismiss="offcanvas" onClick={() => localStorage.setItem("isupdate", true)}>Update Interest</Link>
                                            </li>
                                            <li className="font_20">
                                                <a type='button' data-bs-dismiss="offcanvas" onClick={Handlelogout}>Log Out</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="other-res-pad more-content-sec">

                            <div className="container">

                                <p className="font_50 font_600 mb-2">Saved Content</p>
                                {nocontent && nocontent != null ?
                                    <div className="d-flex align-items-center mb-4">
                                        <p className="font_40 font_600 mb-0">Books & Articles</p>
                                    </div> :
                                    ""
                                }

                                <div className="row">
                                    <div className="testimonial-area">
                                        <Slider {...settings2}>
                                            {savedcontent && savedcontent.map((item, idx) => {
                                                return (
                                                    <>
                                                        <div key={idx} className="col-lg-12 col-xl-12 col-md-12 col-12">
                                                            {item.content.content_type == "Book" ?
                                                                <div className="self-care-card me-3" onClick={() => navigate(`/book-description/${item.content.id}`)}>
                                                                    <div className="selfcare-img">
                                                                        <img src={item.content.thumbnail_url} alt="" />
                                                                    </div>
                                                                    <div className="title">
                                                                        <p className="font_20 mb-1">{item.content.tags_details[0].tag}</p>
                                                                        <p className="font_14 mb-0">{item.content.authors_details}</p>
                                                                    </div>
                                                                </div> :
                                                                <div className="self-care-card me-3" onClick={() => navigate(`/content-article/${item.content.id}`)}>
                                                                    <div className="selfcare-img">
                                                                        <img src={item.content.thumbnail} alt="" />
                                                                    </div>
                                                                    <div className="title">
                                                                        <p className="font_20 mb-1">{item.content.title}</p>
                                                                        <p className="font_14 mb-0">{item.content.authors_details}</p>
                                                                    </div>
                                                                </div>

                                                            }

                                                        </div>
                                                    </>
                                                )
                                            })}
                                        </Slider>
                                    </div>
                                </div>

                            </div>
                        </section>
                        <section className="other-res-pad more-content-sec pt-0">
                            <div className="container">

                                {nocontentvideo && nocontentvideo != null ?
                                    <div className="d-flex align-items-center mb-4">
                                        <p className="font_40 font_600 mb-0">Videos & Audios</p>
                                    </div> :
                                    ""
                                }

                                <div className="row">
                                    <div className="testimonial-area">
                                        <div>

                                            <Slider {...settings2}>
                                                {savedvideo && savedvideo.map((item, idx) => {
                                                    return (
                                                        <>
                                                            <div key={idx} className="col-lg-12 col-xl-12 col-md-12 col-12">
                                                                {item.content.content_type == "Video" ?
                                                                    <div className="self-care-card me-3" onClick={() => navigate(`/content-video/${item.content.id}`)}>
                                                                        <div className="selfcare-img">
                                                                            <img src={item.content.thumbnail} alt="" />
                                                                        </div>
                                                                        <div className="title">
                                                                            <p className="font_20 mb-1">{item.content.tags_details[0].tag}</p>
                                                                            <p className="font_14 mb-0">{item.content.authors_details}</p>
                                                                        </div>
                                                                    </div> :
                                                                    <div className="self-care-card me-3" onClick={() => navigate(`/content-audio/${item.content.id}`)}>
                                                                        <div className="selfcare-img">
                                                                            <img src={item.content.thumbnail} alt="" />
                                                                        </div>
                                                                        <div className="title">
                                                                            <p className="font_20 mb-1">{item.content.title}</p>
                                                                            <p className="font_14 mb-0">{item.content.authors_details}</p>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </>
                                                    )
                                                })}
                                            </Slider>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </section>



                        <div className="container">
                            {nocontentevent && nocontentevent != null ?
                                <p className="font_40 font_600">Your Events</p> :
                                ""
                            }

                            <div className="row">
                                <div className="testimonial-area">
                                    <Slider {...settings}>
                                        {userdata && userdata.map((item, key) => {
                                            return (
                                                <>
                                                    <div className="col-lg-12 col-xl-12 col-md-12 col-12">
                                                        <div className="event-card me-3" onClick={() => { navigate(`/event-description/${item.id}`) }}>

                                                            <div className="event-img">
                                                                <img src={item.image} alt="event" />
                                                                <div className="free-tab">free</div>
                                                            </div>
                                                            <div>
                                                                <p className="text-black mb-2">
                                                                    {item.title}-
                                                                    {item.type == "virtual" ? "Virtual Event" :
                                                                        item.venue}
                                                                </p>
                                                                <p className="font_12 text-purple mb-2">
                                                                    {moment(item.start_date).format('dddd')}, {item.start_date}, {moment.utc(item.start_date + " " + item.start_time).tz("Canada/Eastern").format("h:mm A")} (EST)
                                                                </p>
                                                                {item.type == "virtual" ?
                                                                    <p className="font_12 text-gray mb-0">
                                                                        ONLINE EVENT - Attend anywhere
                                                                    </p> :
                                                                    <p className="font_12 text-gray mb-0">
                                                                        IN-PERSON EVENT - Attend in person
                                                                    </p>
                                                                }
                                                            </div>

                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })}
                                    </Slider>
                                </div>

                            </div>
                        </div>

                        {nocontentforum && nocontentforum === 204 ? (
                            ""
                        ) : (
                            <section className="other-res-pad pt-0">
                                <div className="container">
                                    <Link to="/my-questions" className="font_25 font_600">
                                        <span style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                                            Your Questions
                                        </span>
                                    </Link>
                                </div>
                            </section>
                        )}

                        <Footer />
                    </main>

                )
            }

        </>
    )
}

export default Account