import { createSlice } from "@reduxjs/toolkit";

const matchedMentorListSlice = createSlice({
    name : 'mentordata',
    initialState : null,
    reducers : {
        setMentorDetails(state, action){
            state = action.payload;
            return state;
        }
    }
});

export const { setMentorDetails } = matchedMentorListSlice.actions;
export default matchedMentorListSlice.reducer;