import React, { useEffect, useState } from 'react'
import Footer from '../components/Footer'
import OuterHeader from '../components/OuterHeader'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../components/Loader';
import { setLoading } from '../store/slice/loaderSlice';

import { CometChatUIKit } from "@cometchat/chat-uikit-react";
import { CometChat } from "@cometchat/chat-sdk-javascript";
import { CometChatUsersWithMessages } from '@cometchat/chat-uikit-react';

import { SetMentor, SetCometChatId, MatchedMentor } from '../api/apiHandler.js'
import Swal from 'sweetalert2'

const MatchedMentorListing = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [mentorData, setMentorData] = useState(null);
    const isLoading = useSelector((state) => state.loader);

    useEffect(() => {
        dispatch(setLoading(true))
        MatchedMentor({}).then((response) => {
            //console.log(response, "mentor>>>");
            dispatch(setLoading(false))
            //console.log("response", response);
            if (response.status == 200) {
                setMentorData(response.data.matched_mentors)
            } else if (response.status == 204) {
                Swal.fire({
                    position: "top-right",
                    icon: "info",
                    title: "No matched mentors found",
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                });
            }
        }).catch((error) => {
            dispatch(setLoading(false))
            Swal.fire({
                position: "top-right",
                icon: "error",
                title: "Something went wrong",
                toast: true,
                showConfirmButton: false,
                timer: 2000
            });
        })
    }, [])


    const handleMentor = (mentor_data) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Are you sure you want to change your mentor?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No, keep it'
        }).then((result) => {
            if (result.isConfirmed) {
                const mentorObj = {
                    "mentor": mentor_data.id
                }

                SetMentor(mentorObj).then((response) => {

                    if (response.status == 201) {
                        cometChatUserFunction(mentor_data)
                    }
                }).catch((error) => {
                    navigate('/comet-chat')
                })
            }
        });



    }

    const cometChatUserFunction = (mentor_data) => {
        let UID = mentor_data.cometchat_uid != null ? mentor_data.cometchat_uid : mentor_data.username
        // console.log(UID)
        // console.log(mentor_data.cometchat_uid)
        if (mentor_data.cometchat_uid == null) {
            // user is not found, so we can create new user
            const newUser = new CometChat.User(UID);
            let fullname = (mentor_data.fullname != "" && mentor_data.fullname != undefined && mentor_data.fullname != null) ? mentor_data.fullname : mentor_data.username
            newUser.setName(fullname);
            let metadata = {
                "@private":
                {
                    "email": mentor_data.email
                }
            }

            newUser.setMetadata(JSON.stringify(metadata));
            CometChatUIKit.createUser(newUser, process.env.REACT_APP_COMETCHAT_AUTH_KEY)
                .then((createdUser) => {
                    setCometChatIdFunction(createdUser, mentor_data.id)
                })
                .catch((error) => {
                    console.log('Error from create cometchat user', error);

                    navigate('/comet-chat')
                })
        } else {
            navigate('/comet-chat')
        }
    }

    const setCometChatIdFunction = (cometChatData, mentor_id) => {
        const cometChatObj = {
            "user": mentor_id,
            "cometchat_uid": cometChatData.uid
        }

        SetCometChatId(cometChatObj).then((response) => {
          
            if (response.status == 200) {
                navigate("/comet-chat")
            }
        }).catch((error) => {
            // navigate("/comet-chat")
            console.log('Error from set cometchat id', error);
            Swal.fire({
                position: "top-right",
                icon: "error",
                title: error.response ? error.response.data.errors : "Something went wrong",
                toast: true,
                showConfirmButton: false,
                timer: 2000
            });
        })
    }

    return (
        <>
            {isLoading ?
                (<Loader loader_status={isLoading} />) :
                (
                    <main className="helprr-main">
                        <OuterHeader />
                        <section className="other-res-pad pt-5">
                            <div className="container">
                                <p className="font_50 font_600 mb-0">Mentor Listing</p>
                                <div className="row">
                                    {mentorData && mentorData.map((item) => {
                                        return (
                                            <div className="col-lg-4 col-xl-4 col-12 mb-4" style={{ "cursor": "pointer" }} key={item.id} onClick={() => handleMentor(item)}>
                                                <div className="get-matched h-100 m-0" data-match-height="groupName">
                                                    <p className="font_30">{item.username}</p>
                                                    <p className="font_20 text">
                                                        {item.mentor_description}
                                                    </p>
                                                </div>
                                            </div>)
                                    })}
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </main>
                )
            }
        </>
    )
}

export default MatchedMentorListing;