import React, { useState, useEffect, useRef } from 'react'
import OuterHeader from '../components/OuterHeader'
import Footer from '../components/Footer'
import { Link, useNavigate } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCards } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-cards';
import Swal from 'sweetalert2'
import { actionplantasklist, actionplantasktoday, actionplantaskupdate, userDetails, submitactionplan, HealingJourneydata } from '../api/apiHandler.js'
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { setLoading } from '../store/slice/loaderSlice';
import Loader from '../components/Loader';
import { setUserDetails } from '../store/slice/userDetail';


const ActionPlanDashboard = () => {
    const actionplanData = useSelector((state) => state.actionplandetails);
    const UserData = useSelector((state) => JSON.parse(state.userdata))
    const isLoading = useSelector((state) => state.loader);
    const dispatch = useDispatch();
    const [data, setData] = useState()
    const [todaytask, setTodayTask] = useState()
    const [tasksPerPage, setTaskPerPage] = useState(10);
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [loadMoreFlag, setLoadMoreFlag] = useState(false);
    const [taskToDisplay, setTaskToDisplay] = useState("");
    const [nocontent, setNocontent] = useState();
    const [todaycheck, setTodayCheck] = useState()
    const [progressPercentage, setProgressPercentage] = useState(0)
    const [wohooSlider, setWohooSlider] = useState(0);
    const [actionplanday, setactionplanday] = useState()
    const [healingdata, sethealingdata] = useState("")
    const [loginUserData, setLoginUserData] = useState(JSON.parse(localStorage.getItem("loggedUserDetail")));
    const navigate = useNavigate()

    const textareaRef = useRef(null);
    const swiperRef = useRef(null);
    const submitButtonRef = useRef(null);
    const skipButtonRef = useRef(null);
    const today = moment();

    // const loadMore = () => {
    //     setCurrentPage((prevPage) => prevPage + 1);
    // };



    useEffect(() => {
        actionplan()
    }, []);

    const userData = () => {
        userDetails().then((response) => {
            if (response.status === 200) {
                localStorage.setItem("loggedUserDetail", JSON.stringify(response.data));
                localStorage.setItem("active_actionplan", response.data.has_action_plan);
                dispatch(setUserDetails(JSON.stringify(response)));
                setLoginUserData(response.data);

            } else {
                console.log("userdetails failed");
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    const actionplan = () => {
        if (localStorage.getItem("actionplansurvay") != undefined) {
            const survaydata = JSON.parse(localStorage.getItem("actionplansurvay"))
            console.log('survaydata: ', survaydata);
            submitactionplan(survaydata).then((response) => {
                if (response.status === 201) {
                    // localStorage.setItem("active_actionplan", true);
                    localStorage.removeItem("actionplansurvay")
                    userData();

                    // localStorage.removeItem("questions_for")
                }
            }).catch((error) => {
                Swal.fire({
                    position: "top-right",
                    icon: "error",
                    title: error.response.data.errors,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                });
            })

        }
    }


    // useEffect(() => {
    //     handleshowactionplanlist()
    // }, [])

    useEffect(() => {
        if (actionplanData != null) {
            handleshowactionplanlist()
        }

    }, [actionplanData])

    function myFunctionswiperactionplan() {

        if (todaytask && todaytask.is_accepted == false) {
            navigate("/today-task")
        } else {
            const flexCheckChecked1 = document.getElementById("flexCheckChecked1");
            const flexCheckChecked2 = document.getElementById("flexCheckChecked2");

            flexCheckChecked1.checked = false
            flexCheckChecked2.checked = false

            var element = document.getElementById("panel2");
            element.classList.add("stylepanel");
            actionplantasktoday().then((response) => {
                //console.log(response.data, "tasktodayyyyyyyyyyyyyyyyyyyyyyyyy");
            }).catch((error) => {
                console.log(error);
            })

        }


    }

    function closemodal() {
        let tasknote
        if (textareaRef.current.value != "") {
            tasknote = {
                "is_completed": true,
                "task_feedback": textareaRef.current.value
            }
        } else {
            tasknote = {
                "is_completed": true,
                "task_feedback": null
            }
        }
        actionplantaskupdate(tasknote).then((response) => {
            if (response.status == 200) {
                localStorage.setItem("todayplan", response.data.day)
                setTodayTask(response.data)
                handleshowactionplanlist()
            }
        }).catch((error) => {
            console.log(error);
        })
        textareaRef.current.value = "";
        document.getElementById("panel2").classList.remove("stylepanel");
    }

    function firstclosemodal() {
        // setWohooSlider(0)
        document.getElementById("panel2").classList.remove("stylepanel");
    }

    const handleslider = () => {
        const flexCheckChecked1 = document.getElementById("flexCheckCheckedaction1");
        const flexCheckChecked2 = document.getElementById("flexCheckCheckedaction2");
        if (flexCheckChecked1.checked && flexCheckChecked2.checked) {
            const swiper = swiperRef.current.swiper;
            swiper.activeIndex = 2

            setWohooSlider(1)
        }
    }

    const getvalue = () => {
        const textareaValue = textareaRef.current.value;
        if (textareaValue != "") {
            submitButtonRef.current.style.cursor = "pointer"
            submitButtonRef.current.style.pointerEvents = "auto"
            skipButtonRef.current.style.cursor = "default"
            skipButtonRef.current.style.pointerEvents = "none"
        } else {
            submitButtonRef.current.style.cursor = "default"
            submitButtonRef.current.style.pointerEvents = "none"
            skipButtonRef.current.style.cursor = "pointer"
            skipButtonRef.current.style.pointerEvents = "auto"
        }
    }



    useEffect(() => {
        actionplantasktoday().then((response) => {
            if (response.status == 200) {
                setTodayTask(response.data);
            }
        }).catch((error) => {
            console.log(error);
        })
        if (UserData && UserData.data.is_subscription_active == true) {
            handleshowactionplanlist()
        }

    }, [currentPageNo, tasksPerPage])

    const handleshowmore = (day) => {
        localStorage.setItem("actionplandays", day)
        setactionplanday(day)
        navigate("/actionplan-details")
    }


    const handleshowactionplanlist = () => {
        dispatch(setLoading(true))
        actionplantasklist().then((response) => {
            dispatch(setLoading(false))
            if (response.status == 200) {
                const startIndex = (currentPageNo - 1) * tasksPerPage;
                const endIndex = startIndex + tasksPerPage;
                setData(response.data.tasks.slice(0, endIndex));
                //console.log(response.data, "myactionplan");
                // setData((prevData) => (prevData ? [...prevData, ...response.data.tasks] : response.data.tasks));
                setProgressPercentage(response.data.progress_percentage.toFixed(2))
            } else if (response.status == 204) {
                setNocontent(204)
                Swal.fire({
                    position: "top-right",
                    icon: "info",
                    title: "User has no active action plan",
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                });
            }

        }).catch((error) => {
            console.log(error);
        })
    }


    function loadMoreTasks() {
        // setHandleLoadData(false);
        const startIndex = (currentPageNo - 1) * tasksPerPage;
        const endIndex = startIndex + tasksPerPage;
        // setTaskToDisplay(savedata.slice(startIndex, endIndex))
        const newTasks = data.slice(startIndex, endIndex);
        setTaskToDisplay(prevTasks => [...prevTasks, ...newTasks]);
        // setTaskToDisplay(prevTasks => [...prevTasks, ...savedata.slice(startIndex, endIndex)]);
        setCurrentPageNo(currentPageNo + 1);
        checkPagination();
    }

    function checkPagination() {
        const startIndex = (currentPageNo - 1) * tasksPerPage;
        const endIndex = startIndex + tasksPerPage;
        if (startIndex >= data.length - 1) {
            setLoadMoreFlag(true);
        }
        else {
            setLoadMoreFlag(false);
        }
    }

    const handlePagination = () => {
        if (loadMoreFlag) {
            // No more data available
            Swal.fire({
                position: "top-right",
                icon: "info",
                title: "No more data found",
                toast: true,
                showConfirmButton: false,
                timer: 2000
            });
        } else if (data && data.length === 30) {

            Swal.fire({
                position: "top-right",
                icon: "info",
                title: "No more data found",
                toast: true,
                showConfirmButton: false,
                timer: 2000
            });
        }
        else {
            loadMoreTasks();

        }
    }


    const handleCreateEventClick = () => {
        Swal.fire({
            position: "top-right",
            icon: "error",
            title: "Subscription required",
            toast: true,
            showConfirmButton: false,
            timer: 2000
        });
    }

    const handleshowmoretoday = () => {
        actionplantasktoday().then((response) => {
            if (response.status == 200) {
                localStorage.setItem("actionplandays", response.data.day)
                navigate("/actionplan-details")
            } else if (response.status == 204) {
                Swal.fire({
                    // position: "top-right",
                    iconHtml: '<i class="fas fa-thumbs-up"></i>',
                    // icon: "success",
                    title: "Congratulations!",
                    text: "You’ve completed your 30 day challenge, start a new one and let the momentum continue 😊",
                    // toast: true,
                    showConfirmButton: true,
                    showCancelButton: true,
                    confirmButtonText: "Start new", // Add a "Start New" button
                    timer: 4000
                }).then((result) => {
                    if (result.isConfirmed) {
                        console.log("confirm");
                        localStorage.setItem('questions_for', "action plan")
                        navigate("/actionplan-question");
                        // Handle "Start New" button click
                        // For example, navigate to the start new page
                    }
                });

            }

        }).catch((error) => {
            console.log(error);
        })
    }

    useEffect(() => {

        HealingJourneydata().then((response) => {
            if (response.status == 200) {
                sethealingdata(response.data.healing_journey_data)
            }
        }).catch((error) => {
            console.log(error);
        })

    }, [])






    return (
        <>
            {isLoading ?
                (<Loader loader_status={isLoading} />) :
                (
                    <main className="helprr-main">
                        <OuterHeader />
                        <section className="bg-orange">
                            <div className="banner-sec mt-0">
                                <div className="container">
                                    <div className='d-flex justify-content-between align-items-center flex-wrap'>
                                        <div className="banner-profile-sec">
                                            <div className="banner-profile-img">
                                                {localStorage.getItem("photo") != undefined && localStorage.getItem("photo") != null && localStorage.getItem("photo") != "" ?
                                                    <img src={localStorage.getItem("photo")} className="account-dropdown" alt="" /> :
                                                    <img src={process.env.PUBLIC_URL + "/assets/images/defalt.png"} className="account-dropdown" alt="" />
                                                }
                                            </div>
                                            <div className="banner-text">
                                                <p className="mb-0 font_18 text-white font_700">Welcome back</p>
                                                <p className="mb-0 font_26 text-white font_700"> {localStorage.getItem("username")}</p>
                                            </div>

                                        </div>

                                        <div>
                                            <Link to="/actionplan-question" className="decide-btn" onClick={() => localStorage.setItem('questions_for', "action plan")}>Recommend me a new action plan</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="back-section action-plan-dash pb-5">
                                <div className="container position-relative">
                                    <div className="action-section">
                                        <div className="card-progress first-card">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div>
                                                    <p className="mb-0  font_22">30 day Challenge</p>
                                                    {UserData && UserData.data.is_subscription_active == true ?
                                                        <Link to="/stats" className="mb-0 font_16 text-decoration-underline font_500">
                                                            Access Stats
                                                        </Link> : ""}
                                                    <div className="award-progress mt-4">

                                                        <div className="mb-4 d-flex gap-3">
                                                            {healingdata.self_care_challenge >= 1 && healingdata.self_care_challenge <= 5 ?
                                                                <div className="icon-card">
                                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/award.png"}
                                                                        alt=""
                                                                        className="object-fit-contain"
                                                                    />
                                                                </div> : healingdata.self_care_challenge >= 5 && healingdata.self_care_challenge <= 10 ?
                                                                    <div className="icon-card">
                                                                        <img src={process.env.PUBLIC_URL + "/assets/images/icon/award.png"}
                                                                            alt=""
                                                                            className="object-fit-contain"
                                                                        />
                                                                    </div> : healingdata.self_care_challenge >= 10 ?
                                                                        <div className="icon-card">
                                                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/Award-1.png"}
                                                                                alt=""
                                                                                className="object-fit-contain"
                                                                            />
                                                                        </div> : ""}

                                                            {/* <div className="icon-card">
                                                                <img src={process.env.PUBLIC_URL + "/assets/images/icon/award.png"} alt="award" />
                                                            </div> */}
                                                            <div>
                                                                <p className="mb-0 font_18 font_500">Your Progress</p>
                                                                <p className="mb-0 font_25 font_700">{progressPercentage && progressPercentage}%</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {healingdata.self_care_challenge >= 1 && healingdata.self_care_challenge <= 5 ?
                                                    <div className="card-image">
                                                        <img src={process.env.PUBLIC_URL + "/assets/images/icon/Bronze-Medal.png"}
                                                            alt=""
                                                            className="object-fit-contain"
                                                        />
                                                    </div> : healingdata.self_care_challenge >= 5 && healingdata.self_care_challenge <= 10 ?
                                                        <div className="card-image">
                                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/Silver-Medal.png"}
                                                                alt=""
                                                                className="object-fit-contain"
                                                            />
                                                        </div> : healingdata.self_care_challenge >= 10 ?
                                                            <div className="card-image">
                                                                <img src={process.env.PUBLIC_URL + "/assets/images/icon/Gold-Medal.png"}
                                                                    alt=""
                                                                    className="object-fit-contain"
                                                                />
                                                            </div> : ""}
                                            </div>
                                            <div className="award-progress">
                                                <div className="progress">
                                                    <div
                                                        className="progress-bar"
                                                        role="progressbar"
                                                        style={{ width: `${progressPercentage}%` }}
                                                        aria-valuenow={10}
                                                        aria-valuemin={0}
                                                        aria-valuemax={100}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="task-list">
                                        {nocontent && nocontent == 204 ?
                                            "" :
                                            <p className="font_30 font_500 text-orange">All Challenges</p>}
                                    </div>

                                    {data && data.map((option, idx) => (
                                        <div key={idx} className="card-progress bg-mave">

                                            <div className="d-flex align-items-center justify-content-between">
                                                <React.Fragment key={option.day}>
                                                    <div>
                                                        <p className="mb-0 text font_22"> {option.task_name}</p>
                                                        <p className="mb-0 text font_20 font_700"> {option.task_description}</p>
                                                        {moment(option.task_day_date) < today ?
                                                            <button
                                                                // to="/actionplan-details"
                                                                className="mb-2 font_16 text-decoration-underline 1"
                                                                onClick={() => handleshowmore(option.day)}
                                                            >
                                                                Show More
                                                            </button> :

                                                            ""
                                                        }
                                                        <p className={`font_20 font_500 mb-0 ${option.is_completed && option.is_accepted ? 'text-success' : option.is_accepted && !option.is_completed ? 'text-warning' : 'text-danger'}`}>
                                                            Day {option.day} • {option.is_completed && option.is_accepted ? 'Completed' : option.is_accepted && !option.is_completed ? 'In-Progress' : 'Not Completed'}
                                                        </p>
                                                    </div>
                                                    <div className="card-image">
                                                        <img src={process.env.PUBLIC_URL + "/assets/images/icon/Card-Image-2.png"} alt="" />
                                                    </div>

                                                </React.Fragment>
                                            </div>

                                        </div>
                                    ))}
                                    {nocontent && nocontent == 204 ?
                                        "" :
                                        <div className="row mt-5 justify-content-center" >
                                            <div className="col-lg-3 col-md-6 col-6">

                                                {/* {todaytask && todaytask.is_completed == true ? */}
                                                <button
                                                    // onClick={myFunctionswiperactionplan}
                                                    onClick={handleshowmoretoday}
                                                    className="edit-btn" >
                                                    Today’s Task
                                                </button>


                                            </div>
                                            <div className="col-lg-3 col-md-6 col-6">
                                                {/* {!loadMoreFlag && ( // Only render the button if loadMoreFlag is false */}
                                                <button className="edit-btn" onClick={handlePagination}>
                                                    Load More
                                                </button>
                                                {/* )} */}
                                            </div>
                                        </div>}

                                </div>
                            </div>

                        </section>
                        <section id="panel2" className="panel">
                            <Swiper effect={'cards'} grabCursor={true} fadeEffect={{ crossFade: true }} modules={[EffectCards]}
                                className="mySwiper"
                                ref={swiperRef}  >
                                <div className="swiper mySwiper">
                                    <div className="swiper-wrapper">
                                        <div className="swiper-slide">
                                            <SwiperSlide key={0}>
                                                <div className="notify-card">
                                                    <div className="d-flex justify-content-end">
                                                        <img
                                                            id="close-btn"
                                                            src="assets/images/icon/close-modal.png"
                                                            alt=""
                                                            onClick={firstclosemodal}
                                                        />
                                                    </div>
                                                    <div className="d-flex align-items-center justify-content-center">
                                                        <p className="font_28 title mb-0">Hey you!</p>
                                                        <img
                                                            src={process.env.PUBLIC_URL + "/assets/images/Avocado.png"}
                                                            className="avacado-img"
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className="hr-line mb-4" />
                                                    <p className="mb-0 text">
                                                        {todaytask && todaytask.task_category}
                                                    </p>
                                                </div>
                                            </SwiperSlide>
                                        </div>
                                        <div className="swiper-slide">
                                            <SwiperSlide key={1}>
                                                <div className="notify-card">
                                                    <div className="d-flex align-items-center justify-content-center">
                                                        <p className="font_28 title mb-0">Today</p>
                                                        <img
                                                            src={process.env.PUBLIC_URL + "/assets/images/Avocado.png"}
                                                            className="avacado-img"
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className="hr-line mb-4"></div>
                                                    <div className="d-flex gap-2">
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                defaultValue=""
                                                                id="flexCheckCheckedaction1"
                                                                defaultChecked=""

                                                            />
                                                        </div>
                                                        <div>
                                                            <p className=" text font_18 font_700 d-flex align-items-center mb-1">
                                                                {todaytask && todaytask.task_name}
                                                            </p>
                                                            <p className="text  text-white">
                                                                {todaytask && todaytask.task_description}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex gap-2">
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                defaultValue=""
                                                                id="flexCheckCheckedaction2"
                                                                defaultChecked=""

                                                            />
                                                        </div>
                                                        <div>
                                                            <p className=" text font_18 font_700 d-flex align-items-center mb-1">
                                                                Affirmation of the day
                                                            </p>
                                                            <p className="text  text-white">
                                                                {todaytask && todaytask.task_affirmation}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <Link onClick={handleslider}

                                                        className="accept-btn"
                                                    // data-bs-toggle="modal"
                                                    // data-bs-target="#actiontask3"
                                                    // data-bs-dismiss="modal"
                                                    >
                                                        {" "}
                                                        I did it!
                                                    </Link>
                                                </div>
                                            </SwiperSlide>

                                        </div>

                                        {wohooSlider == 1 ?
                                            <div className="swiper-slide" id="actiontask3">
                                                <SwiperSlide key={2}>
                                                    <div className="notify-card bg-white">
                                                        <div className="d-flex align-items-center justify-content-center">
                                                            <p className="font_28 title mb-0 text-orange">Wohoo!</p>
                                                            <img
                                                                src={process.env.PUBLIC_URL + "/assets/images/Avocado.png"}
                                                                className="avacado-img"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="hr-line mb-4"></div>
                                                        <p className="text text-orange">
                                                            Congrats on taking steps towards a better you. How do you feel
                                                            after completing the task?
                                                        </p>
                                                        <textarea
                                                            ref={textareaRef}
                                                            className="form-control"
                                                            id="exampleFormControlTextarea1"
                                                            rows={2}
                                                            placeholder="type here.."
                                                            // defaultValue={""}
                                                            onKeyUp={getvalue}
                                                        />

                                                        <a type='button'
                                                            href="javascript:void(0)"
                                                            ref={submitButtonRef}
                                                            className="skip-btn font_700 btn-disabled"
                                                            data-bs-dismiss="modal"
                                                            style={{ cursor: "default", pointerEvents: "none" }}
                                                            onClick={closemodal}
                                                        >
                                                            Submit
                                                        </a>

                                                        <a
                                                            href="javascript:void(0)"
                                                            ref={skipButtonRef}
                                                            className="skip-btn font_700"
                                                            data-bs-dismiss="modal"
                                                            style={{ cursor: "pointer" }}
                                                            onClick={closemodal}
                                                        >
                                                            Skip
                                                        </a>


                                                    </div>
                                                </SwiperSlide>

                                            </div> :
                                            ""
                                        }

                                    </div>
                                </div>

                            </Swiper>
                        </section>
                        <Footer />
                    </main>)}

        </>
    )
}

export default ActionPlanDashboard
