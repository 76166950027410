import React, { useState, useEffect } from 'react'
import OuterHeader from '../components/OuterHeader'
import Footer from '../components/Footer'
import { Link, useParams } from 'react-router-dom'
import { UpdateBookStatus, MyBooksDetails, ContentRating } from '../api/apiHandler.js'
import Swal from 'sweetalert2'



const BookNotesDetailed = () => {
    const params = useParams();
    const [data, setData] = useState(0)
    const [mybook, SetMyBook] = useState()
    const [feedbackdata, setFeedbackData] = useState("")

    const [handleSaveEditBtn, setHandleSaveEditBtn] = useState(1);
    const [userrating, setuserRating] = useState()
    const [rating, setRating] = useState()



    useEffect(() => {
        MyBooksDetails({ id: params.id }).then((response) => {
            if (response.status == 200) {
                SetMyBook(response.data)
                setFeedbackData(response.data.book_note)
            }
        }).catch((error) => {
            Swal.fire({
                position: "top-right",
                icon: "error",
                title: error.response.data.errors,
                toast: true,
                showConfirmButton: false,
                timer: 2000
            });
        })
    }, [])


    const handlesave = () => {
        if (feedbackdata != null) {
            const booknotedata = {
                book_note: feedbackdata
            }
            UpdateBookStatus(booknotedata).then((response) => {
                if (response.status == 200) {
                    setHandleSaveEditBtn(1)

                    Swal.fire({
                        position: "top-right",
                        icon: "success",
                        title: "Book note added successfully",
                        toast: true,
                        showConfirmButton: false,
                        timer: 2000
                    });
                }

            }).catch((error) => {
                Swal.fire({
                    position: "top-right",
                    icon: "error",
                    title: error.response.data.errors,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                });
            })
        }

    }


    // const handleRatingClick = (params, rating) => {
    //     const ratingdata = {
    //         content: params.id,
    //         rating: rating
    //     }
    //     // console.log("-----------",ratingdata);
    //     ContentRating(ratingdata).then((response) => {
    //         console.log(response.data);
    //         if (response.status == 200) {
    //             Swal.fire({
    //                 position: "top-right",
    //                 icon: "success",
    //                 title: "Thank you for your review",
    //                 toast: true,
    //                 showConfirmButton: false,
    //                 timer: 2000
    //             });

    //         }
    //     }).catch((error) => {
    //         // dispatch(setLoading(false))
    //         console.log(error, 'error');
    //     })
    // }


    // const ratingdata = {
    //     content: params.id,
    //     rating: userrating
    // }
    // ContentRating(ratingdata).then((response) => {
    //     console.log(response.data);
    //     if (response.status == 200) {
    //         Swal.fire({
    //             position: "top-right",
    //             icon: "success",
    //             title: "Thank you for your review",
    //             toast: true,
    //             showConfirmButton: false,
    //             timer: 2000
    //         });

    //     }
    // }).catch((error) => {
    //     console.log(error, 'error');
    // })


    return (
        <>
            <main className="helprr-main">
                <OuterHeader />
                <section>
                    <div className="container">
                        <div className="d-flex justify-content-between align-items-center">
                            <div>
                                <p className="font_30 font_700 text-orange pt-4">{mybook && mybook.content.title}</p>
                                <p className="font_24 font_700 text-orange">{mybook && mybook.content.authors_details}</p>
                            </div>
                            <div>
                                <div className="text-center rate-bookdash">

                                    {/* <div class="star-rating">
                                 <input id={`star-15-${params.id}`} type="radio" name="rating-3" value="star-5" />
                                 <label for="star-15" title="5 stars">
                                     <i class="active fa fa-star" aria-hidden="true" onClick={() => handleRatingClick(params.id, 5)}></i>
                                 </label>
                                 <input id="star-14" type="radio" name="rating-3" value="star-4" />
                                 <label for="star-14" title="4 stars">
                                     <i class="active fa fa-star" aria-hidden="true" onClick={() => handleRatingClick(params.id, 4)}></i>
                                 </label>
                                 <input id="star-13" type="radio" name="rating-3" value="star-3" />
                                 <label for="star-13" title="3 stars">
                                     <i class="active fa fa-star" aria-hidden="true" onClick={() => handleRatingClick(params.id, 3)}></i>
                                 </label>
                                 <input id="star-12" type="radio" name="rating-3" value="star-2" />
                                 <label for="star-12" title="2 stars">
                                      <i class="active fa fa-star" aria-hidden="true" onClick={() => handleRatingClick(params.id, 2)}></i>
                                  </label>
                                 <input id="star-11" type="radio" name="rating-3" value="star-1" />
                                 <label for="star-11" title="1 star">
                                      <i class="active fa fa-star" aria-hidden="true" onClick={() => handleRatingClick(params.id, 1)}></i>
                                  </label>
                                 </div> */}
                                    {/* <p className="text-orange font_20 mb-0 text-center" onClick={() => handleRatingClick(params.id)}>Rate this book</p> */}
                                </div>
                            </div>
                        </div>
                        <p className="text-mave">
                            {mybook && mybook.content.description}
                        </p>
                        <hr />
                        <p className="font_22 text-orange font_700">Book Notes</p>

                        {/* <p className="text-mave">
                            {feedbackdata}
                        </p> */}

                        {handleSaveEditBtn !== 1 ? (
                            <textarea
                                className="collapse border-2"
                                defaultValue={feedbackdata != null && feedbackdata !== '' ? feedbackdata : ''}
                                onChange={(e) => setFeedbackData(e.target.value)}
                                name="taskfeedback"
                                id="collapseExample"
                                cols="100"
                                rows="5"
                            />
                        ) : (
                            <p className="text-mave">{feedbackdata}</p>
                        )}

                        <textarea className="collapse border-2 responsive-textarea" defaultValue={feedbackdata != null && feedbackdata != "" ? feedbackdata : ""} onChange={(e) => setFeedbackData(e.target.value)} name="taskfeedback" id="collapseExample" cols="100" rows="5"></textarea>
                        <div className="row mt-5 justify-content-center mb-4">
                            {handleSaveEditBtn != 1 ?

                                <div className="col-lg-3">
                                    <a className="edit-btn" data-bs-toggle="collapse" href="#collapseExample" onClick={() => handlesave()} >
                                        Save
                                    </a>
                                </div> :
                                <div className="col-lg-3">
                                    <a className="edit-btn" data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample" onClick={() => setHandleSaveEditBtn(0)}>
                                        Edit
                                    </a>
                                </div>
                            }
                            <div className="col-lg-3">
                                <Link to="/bookdashboard" className="goback-btn">
                                    Go Back
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </main>

        </>
    )
}

export default BookNotesDetailed